import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next"

function EndBar({ login }) {
  const { t } = useTranslation()
  const [classchange, setClassChange] = useState(false);
  const [serviceOpen, setServiceOpen] = useState(false);
  let clickRef = useRef();
  useEffect(() => {
    const outclickhandel = (e) => {
      if (!clickRef.current.contains(e.target)) {
        setServiceOpen(false);
      }
    };
    document.addEventListener("mousedown", outclickhandel);

    return () => {
      document.removeEventListener("mousedown", outclickhandel);
    };
  });

  return (
    <div className="header-main bg-white" ref={clickRef}>
      <div className="container-fluid">
        <nav
          className="navbar navbar-expand-lg navbar-light w-100 nav-border-bottom "
          id="header-navbar"
        >
          {login ? (
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setClassChange(!classchange);
                setServiceOpen(classchange);
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>
          ) : (
            <></>
          )}

          {login ? (
            <div
              className={`collapse navbar-collapse ${classchange && "show"}`}
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-left ">
                {/* <li className="nav-item">
                  <NavLink className="nav-link" to={"/"}>
                    Home{" "}
                  </NavLink>
                </li> */}
                <li className="nav-item ">
                  <NavLink className="KhmerOSsiemreaptext nav-link" to={"/dashboard"}>
                  {t("2938c7f7e560ed972f8a4f68e80ff834")}{" "}
                  </NavLink>
                </li>

                <li
                // className={`nav-item dropdown no-caret ${
                //   serviceOpen && "show"
                // }`}
                // className="nav-item"
                >
                  {/* <NavLink
                    className="nav-link dropdown-toggle"
                    className="nav-link "
                    id="navbarDropdownDocs"
                    role="button"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => setServiceOpen(!serviceOpen)}
                    to={"/selectService"}
                  >
                    Services
                    <svg
                      className="svg-inline--fa fa-chevron-right fa-w-10 dropdown-arrow"
                      aria-hidden="true"
                      focusable="false"
                      data-prefix="fas"
                      data-icon="chevron-right"
                      role="img"
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 320 512"
                      data-fa-i2svg=""
                    >
                      <path
                        fill="currentColor"
                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z"
                      />
                    </svg>
                    <i class="fas fa-chevron-right dropdown-arrow"></i>
                  </NavLink>  */}
                  {/* {serviceOpen && (
                    <div
                      className="dropdown-menu dropdown-menu-left animated--fade-in-up show"
                      aria-labelledby="navbarDropdownDocs"
                    >
                      <Link
                        className="dropdown-item py-3"
                       to={"/legalizationWork"}
                        
                      >
                        <div className="icon-stack bg-primary-soft text-primary mr-4">
                          <svg
                            className="svg-inline--fa fa-book-open fa-w-18"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="book-open"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"
                            />
                          </svg>
                          
                        </div>
                        <div>Legalization Work</div>
                      </Link>
                      <div className="dropdown-divider m-0" />
                      <Link
                        className="dropdown-item py-3"
                        to={"/civilRegistrationWork"}
                        
                      >
                        <div className="icon-stack bg-primary-soft text-primary mr-4">
                          <svg
                            className="svg-inline--fa fa-code fa-w-20"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="code"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"
                            />
                          </svg>
                          
                        </div>
                        <div>Civil Registration Work</div>
                      </Link>
                      <div className="dropdown-divider m-0" />
                      <Link
                        className="dropdown-item py-3"
                        to={"/tourismLicenseWork"}
                        
                      >
                        <div className="icon-stack bg-primary-soft text-primary mr-4">
                          <svg
                            className="svg-inline--fa fa-file fa-w-12"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="file"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                            />
                          </svg>
                         
                        </div>
                        <div>Tourism License</div>
                      </Link>
                    </div>
                  )} */}
                </li>

                {/* <li className="nav-item">
                  <NavLink className="nav-link" to={"/trackApplicationwork"}>
                    Track Application{" "}
                  </NavLink>
                </li> */}
              </ul>
            </div>
          ) : (
            <></>
          )}
        </nav>
      </div>
    </div>
  );
}

export default EndBar;
