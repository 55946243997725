import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

function Footer() {
  const { t } = useTranslation();
  const [year, setYear] = useState("");

  useEffect(() => {
    let cYear = new Date().getFullYear();
    setYear(cYear);
  }, []);
  return (
    <footer className="footer mt-auto footer-light">
      <div className="">
        <div
          className="col-md-12 col-lg-12"
          style={{ backgroundColor: "#f9f7fe", padding: "20px 0;" }}
        >
          <div className="row">
            <div className="col-md-12 col-lg-12">
              <div className="row" style={{ padding: "7px" , textAlign: "center"}}>
                {/* <a
                  className="navbar-brand col-md-2 col-lg-2"
                  href="index-dashbaord.html"
                >
                  <img
                    className="img-fluid logo"
                    src="/images/logo-footer.png"
                    style={{ width: "110px" }}
                  />
                </a> */}

                <p className="KhmerOSmuollighttext col-md-12 col-lg-12" style={{marginBottom : '0.2rem'}}>
                {t("d0a072f8c6db8d2219c9bddb8d4136d6")} <br />
                {t("9c252df9ccfce5ce64905a0fd2615534")} <br />
                  <h6><p style={{fontSize: '12pt', lineHeight: '28px', fontFamily: 'KhmerOSsiemreap'}} >{t("d1a07649fef423c80acdd86348bd4cb4")}</p></h6>  
                </p>
                <p className="KhmerOSmuollighttext col-md-12 col-lg-12">Tel: (855) 23 726 861 / (855) 23 220 935 <br />  Email: info@owso.gov.kh</p>
              </div>
            </div>

            {/* <div
              className="col-md-4 col-lg-4"
              style={{
                border_left: "1px solid #e5e5e5",
                border_right: "1px solid #e5e5e5;",
              }}
            >
              <ul className="footer-ul">
                <li>
                  <p className="KhmerOSmuollighttext" style={{ font_size: "17px;" }}>{t("a4d3b161ce1309df1c4e25df28694b7b")}</p>

                  <p>
                    <a className="KhmerOSsiemreaptext" href="#">{t("483a5da192f46e3cfe8391d3ef234fc4")}</a>
                  </p>
                  <p>
                    <a className="KhmerOSsiemreaptext" href="#">{t("508c75c8507a2ae5223dfd2faeb98122")}</a>
                  </p>
                  <p>
                    <a className="KhmerOSsiemreaptext" href="#">{t("2515fd4225325c0afa289e50ec4307a6")}</a>
                  </p>
                  <p>
                    <a className="KhmerOSsiemreaptext" href="#">{t("992a0f0542384f1ee5ef51b7cf4ae6c4")}</a>
                  </p>
                </li>
              </ul>
            </div>
            <div className="col-md-2 col-lg-2">
              <ul className="footer-ul">
                <li>
                  <p className="KhmerOSmuollighttext" style={{ font_size: "17px" }}>{t("9cfc9b74983d504ec71db33967591249")}</p>

                  <p>
                    <a className="KhmerOSsiemreaptext" href="#">{t("57887c3861660f4c3fb94939f5446b7c")} : (855)23 726861 / (855)23 220 935</a>
                  </p>
                  <p>
                    <a className="KhmerOSsiemreaptext" href="#"> {t("8be5338b5414092f30160584e760bb01")} : info@owso.gov.kh</a>
                  </p>
                </li>
              </ul>
            </div> */}
          </div> 
        </div>

        <div className="row">
          <div
            className="KhmerOSsiemreaptext col-md-12 small text-center"
            style={{
              backgroundColor: "#117ebf",
              padding: "10px",
              color: "#fff",
            }}
          >
            { ` © ${year} `}  {t("546b55e23c5e052f1a4a1d46d293603e")}
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;

// to_convert
// Department of Functions and Resources of the General Department of Administration, Ministry of Interior Building S, Ground Floor,Preah Norodom Blvd,Khan Chamkarmon, Phnom Penh
// All rights reserved. ONE WINDOW SERVICE OFFICE. Term and Condition