import React from 'react'

function SpanError({ errorReason }) {
  return (
    <p
      className="KhmerOSsiemreaptext mt-1"
      style={{
        fontSize: "15px",
        fontStyle: "italic",
        color: "red",
        float: "left",
      }}
    >
      {errorReason}
    </p>
  )
}

export default SpanError