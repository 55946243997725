import React, { useState } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function ChangePassword(props) {
  const { t } = useTranslation();

  const [newPassword, setNewPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [type, setType] = useState(false);
  const [typenew, setNewType] = useState(false);
  const PasswordKey = process.env.REACT_PAASSWORD_ENCRTPTKEY;
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;
  const salt = bcrypt.genSaltSync(10);
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [isloading, setIsloading] = useState(false);
  const isLoginpage = true;
  let navigate = useNavigate();

  const backProcessB = () => {
    props.handelclick(0);
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(() => {
    //   setErrorBlock(false);
    // }, 4000);
  };

  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  const finalSubmit = () => {
    if(newPassword==""){
      setError("d351d57d7bafd005cdaabd11a85da85d");
      errorHandel(0)
      return
    }
    if (newPassword.length < 6) {
      setError("c957da28ed3428f64bdabb32b0197d87");
      errorHandel(0);
      return;
    } else {
      if (newPassword === confirmPassword) {
        handleFormSubmit();
        const hashedPassword = bcrypt.hashSync(newPassword, PasswordKey);
        const registerData = {
          ...props.registerDataA,
          password: newPassword,
        };
        setIsloading(true);
        const forgotData = {
          code: props.customer.IdCard,
          password: newPassword,
        };

        // forgot password change service call
        axios
          .post(`${LoginKey}/login/resetpassword`, forgotData)
          .then((response) => {
            setIsloading(false);

            if (response.data.responseObject.ErrorMessage == null) {
              props.handelclick(2);
            } else {
              setError(response.data.responseObject.ErrorMessage);
              errorHandel(0);
            }
          })
          .catch((err) => {
            setIsloading(false);
            console.log(err);
          });

        //api for update password
      } else {
        setError("8c6e407ab1ae8c30ee8cd92d0eca90d7");
        errorHandel(0);
      }
    }
  };

  const handleFormSubmit = () => {
    if(newPassword==""){
      setError("d351d57d7bafd005cdaabd11a85da85d");
      errorHandel(0)
      return
    }
    if (newPassword.length < 6) {
      setError("c957da28ed3428f64bdabb32b0197d87");
      errorHandel(0);
      return;
    } else {
      setError("");
      setErrorBlock(false);
    }
  };

  return (
    <div>
      {/* {errorblock && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )} */}
      {errorblock && errorClass == "errorMessage" && (
          <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
        )}
      <div className="card">
        <div id="headingThree" />
        <div
          id="collapseThree"
          className="collapse show"
          aria-labelledby="headingThree"
          data-parent="#accordionExample"
          style={{}}
        >
          <div className="card-body" style={{ width: "648px!important" }}>
            <div className="form-row" style={{ marginBottom: 80 }}>
              {/* Form Group (first name)*/}
              <div className="form-group col-md-12">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputFirstName">
                  {t("8e233234a83a277872b8ce305dceb914")}
                </label>
                <div className="input-group mb-3">
                  <input
                    type="text"
                    className="KhmerOSsiemreaptext form-control input-dark"
                    placeholder={t("8e233234a83a277872b8ce305dceb914")}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    defaultValue={props.customer.IdCard}
                    disabled
                  />
                </div>
              </div>
              <hr />
              {/* Form Group (last name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("e725ac0b5e81b34eab1749b730141462")}
                </label>
                <div className="input-group mb-3">
                  <input
                    type={type ? "text" : "password"}
                    className="KhmerOSsiemreaptext form-control"
                    placeholder={t("887f7db126221fe60d18c895d41dc8f6")}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onChange={(e) => {
                      setNewPassword(e.target.value);
                    }}
                    onBlur={(e) => {
                      handleFormSubmit();
                    }}
                  />
                  <button
                    type="button"
                    id="button-addon2"
                    style={{
                      position: "absolute",
                      right: 0,
                      border: "none",
                      marginTop: 10,
                      backgroundColor: "transparent",
                    }}
                    onClick={(e) => setType(!type)}
                  >
                   { !type ? <img
                      width={20}
                      height={20}
                      src="https://img.icons8.com/ios-glyphs/30/hide.png"
                      alt="hide"
                    /> :  <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/visible--v1.png" alt="visible--v1"/>}
                  </button>
                </div>
              </div>
              {/* Form Group (last name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("887f7db126221fe60d18c895d41dc8f6")}
                </label>
                <div className="input-group mb-3">
                  <input
                    type={typenew ? "text" : "password"}
                    className="KhmerOSsiemreaptext form-control"
                    placeholder={t("887f7db126221fe60d18c895d41dc8f6")}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    onChange={(e) => {
                      setConfirmPassword(e.target.value);
                    }}
                  />
                  <button
                    type="button"
                    id="button-addon2"
                    style={{
                      position: "absolute",
                      right: 0,
                      border: "none",
                      marginTop: 10,
                      backgroundColor: "transparent",
                    }}
                    onClick={(e) => setNewType(!typenew)}
                  >
                   { !typenew ? <img
                      width={20}
                      height={20}
                      src="https://img.icons8.com/ios-glyphs/30/hide.png"
                      alt="hide"
                    /> : <img width="20" height="20" src="https://img.icons8.com/ios-glyphs/30/visible--v1.png" alt="visible--v1"/>}
                  </button>
                </div>
              </div>
              <hr />
            </div>
            <div className="col-12 fot-row row">
              <div className="d-flex justify-content-between col-6">
                <button
                  className="KhmerOSsiemreaptext btn btn-primary collapsed"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseTwo"
                  aria-expanded="false"
                  aria-controls="collapseTwo"
                  style={{
                    backgroundColor: "#a1a1a1",
                    borderColor: "#7a7a7a",
                  }}
                  onClick={backProcessB}
                >
                  {t("0557fa923dcee4d0f86b1409f5c2167f")}
                </button>
              </div>
              <div className="d-flex flex-row justify-content-end col-6">
                <button
                  className="KhmerOSsiemreaptext btn btn-primary next"
                  type="button"
                  data-toggle="collapse"
                  data-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  onClick={finalSubmit}
                >
                  {t("a4d3b161ce1309df1c4e25df28694b7b")}
                </button>
                <a href="#" className="" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <Footer></Footer> */}
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default ChangePassword;

// to_convert
// done !
