import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
function SuccessModal({ currentDate, navigate }) {
  const { t } = useTranslation();
  let Navigate = useNavigate();
  return (
    <div className={`${"loderdiv"}`}>
      <div
        className={`modal fade ${"show"} modal-img`}
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: `${"block"}` }}
      >
        <div className="modal-dialog mt-15" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ padding: "0" }}
              >
                {t("0f98b7f230f3c91292f0de4c99e263f2")} 
              </h5>
              {/* <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button> */}
            </div>

            <div className="card-body" style={{ width: "648px!important" }}>
            <div className="form-card">
              <div className="row justify-content-center">
                <div className="col-3">
                  {" "}
                  <img
                    src="https://img.icons8.com/color/96/000000/ok--v2.png"
                    className="fit-image"
                    style={{ width: '50px', height: '50px'}}
                  />{" "}
                </div>
              </div>
             
              
              <div className="row justify-content-center">
                <div className="col-12 text-center">
                  {/* <h5 className="fs-title text-center">
                  {t("d66e469700ca174e38e4ab43d079c797")}
                  </h5> */}
                  <label className="KhmerOSsiemreaptext">
                   <b>{t("d66e469700ca174e38e4ab43d079c797")}</b>
                     <br />  <br />
                      {t("b55afee3a3deb460e6149b486d8d9a49")}<br />
                    {/* <br /> Application Number #AP-65438000 <br /> */}
                    {/* {t("6e9a10b2d4dd5f977fa0b1e74b0c0ae5")} {currentDate} <br /> */}
                    {/* Users can login post activation */}
                  </label>
                </div>
              </div>
            </div>
          </div>

            <div className="modal-footer text-center" style={{padding: '2rem'}}>
              <div className="col-12 fot-row row">
                {/* <div className="d-flex justify-content-between col-6">
                                    <button
                                      className="btn btn-primary collapsed"
                                      type="button"
                                      onClick={getApplicationReceipt}
                                    >
                                      {t("4240abaacda205bcafd1df82fd1586de")}{" "}
                                      &nbsp; &nbsp;
                                      <img
                                        width="25"
                                        height="25"
                                        src="https://img.icons8.com/dotty/80/download.png"
                                        alt="download"
                                      />
                                    </button>
                                  </div> */}
                <div className="d-flex flex-row justify-content-end col-12">
                  <button
                    type="button"
                    className="KhmerOSsiemreaptext btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => Navigate(navigate)}
                  >
                    {t("d3d2e617335f08df83599665eef8a418")}
                  </button>
                  <a href="#" className=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SuccessModal;
