import React from "react";
import Navigation from "./NavigationPanel/Navigation";
import Footer from "./Footer";
import { useTranslation } from "react-i18next";
import { NavLink, Link } from "react-router-dom";


function Index_landing() {
  const { t } = useTranslation();

  const isLoginpage = false;
  return (
    <div>
      <Navigation page={isLoginpage}></Navigation>

      <main>
        <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10">
          <div className="container-fluid">
            <div className="page-header-content">
              <div className="row align-items-center justify-content-between">
                <div className="col-auto">
                  {/* <h1 className="page-header-title">
                    One <br />
                    Window <br />
                    Service Office
                  </h1> */}
                  {/* <div className="page-header-subtitle">
                    “Online Application Portal(Pilot)”
                    <br />
                    <br />
                    <a href="#" title="Login" className="btn-border-blue">
                    {t("d9e0971b6595d63994d4f83613f910dc")}
                    </a>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
        </header>
        {/* Main page content*/}
        <div className="container">
          {/* Example Colored Cards for Dashboard Demo*/}
          
                <div className="login_createaccount_container">
                  <ul className="login_createaccount_ul">
                    <li className="cred_button">
                      <Link to={"/login"} title="Login" className="KhmerOSmuollighttext link_button">
                        <button className="button_design">{t("303b1b49212f7c122245bbcdbe085e1d")}</button>
                        
                      </Link>
                    </li>
                    <li className="cred_button">
                      <Link className="KhmerOSmuollighttext link_button" to={"/registrationHolder"}>
                        <button className="button_design">{t("2fe4fe0a0ebea41ce43eb3dc3fce4ea3")}</button>
                        
                      </Link>
                    </li>
                  </ul>
                  
                </div>
           
          <div className="row">
            <h1 className=" KhmerOSmuollighttext page-header-title col-md-12 col-lg-12 hd-text"
                style={{ margin: '10px 20px', padding: '10px 10px'}}  >
              <b>
              {t("d939b36471f438cfa529245db0d43d0c")}
              </b>
            </h1>
            <div className="pattern" />
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div id="news-slider14" className="owl-carousel home-upperslider" >
                <div className="post-slide14 col-lg-3 col-md-5 ">
                  {/* Dashboard info widget 1*/}
                  <div className="card card-height300 h-100 active">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-1.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="KhmerOSmuollighttext h5">{t("b586adf1514064b107d04c9c3b93d397")}</div>
                          <div className="KhmerOSsiemreaptext small font-weight-bold mb-1" style={{fontSize: '12pt', lineHeight: '28px'}}>
                            {t("974d85368434c4d537b8753b3290b2ba")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="post-slide14 col-lg-3 col-md-5">
                  {/* Dashboard info widget 1*/}
                  <div className="card card-height300 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-1.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="KhmerOSmuollighttext h5">{t("4807012fa7215eb20165544a9abc6807")}</div>
                          <div className="KhmerOSsiemreaptext small font-weight-bold mb-1" style={{fontSize: '12pt', lineHeight: '28px'}}>
                            {t("3eb5e5170f04ef4694f1e59edae82cae")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="post-slide14 col-lg-3 col-md-5">
                  {/* Dashboard info widget 3*/}
                  <div className="card card-height300 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-1-b.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="KhmerOSmuollighttext h5">{t("6af6eb958c9583f6f45517d7f0b00d3a")}</div>
                          <div className="KhmerOSsiemreaptext small font-weight-bold mb-1" style={{fontSize: '12pt', lineHeight: '28px'}}>
                            {t("831b9d2d4c1a9394e373ea6d00d5d910")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="post-slide14 col-lg-3 col-md-5">
                  {/* Dashboard info widget 2*/}
                  <div className="card card-height300 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-2b.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="KhmerOSmuollighttext h5">
                            {t("5e0661015dd7d12f54a8b77cfad55b0d")}
                          </div>
                          <div className="KhmerOSsiemreaptext small font-weight-bold mb-1" style={{fontSize: '12pt', lineHeight: '28px'}}>
                            {t("1789b0193f4a1c75bd646c4098131fb4")}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                {/* <div className="post-slide14">
                
                  <div className="card card-height300 h-100 active">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-1.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="h5">Select a Services</div>
                          <div className="small font-weight-bold mb-1">
                            select te administrative services you wish to
                            request
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-slide14">
                 
                  <div className="card card-height300 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-1.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="h5">{t("1c0a9e6f20b9f1b59d22290d9f0f31a7")}</div>
                          <div className="small font-weight-bold mb-1">
                            scan &amp; upload the required documents
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-slide14">
                  
                  <div className="card card-height300 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-1-b.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="h5">Submit Application</div>
                          <div className="small font-weight-bold mb-1">
                            After reviewing the application submit for further
                            processing.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="post-slide14">
            
                  <div className="card card-height300 h-100">
                    <div className="card-body">
                      <div className="d-flex align-items-center">
                        <div className="flex-grow-1">
                          <div className="ml-2">
                            <img
                              className="img-fluid logo"
                              src="images/icon-2b.png"
                              style={{ width: "auto", margin: 10 }}
                            />
                          </div>
                          <div className="h5">
                            Document Review &amp; Verification
                          </div>
                          <div className="small font-weight-bold mb-1">
                            complete the form and wait for the inspection by the
                            professional officer
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
            </div>
          </div>

          <div className="row">
            <h1 className="KhmerOSmuollighttext page-header-title col-md-12 col-lg-12 hd-text">
              <b>{t("992a0f0542384f1ee5ef51b7cf4ae6c4")}</b>
            </h1>
            <div className="pattern" />
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div id="news-slider9" className="owl-carousel home-downslider" >

                <div className="post-slide9 col-lg-4 col-md-10 col-sm-8">
                  <div className="post-img">
                    <img className="pic-1" src="images/service1.jpg" style={{ width:'348px', height:'241px'}}/>
                    
                  </div>
                  <div className="post-review">
                    <h3 className="post-title KhmerOSmuollighttext">
                      <a href="#">{t("8a1137e7b69b472d6d8194ded93915f1")}</a>
                    </h3>
                    <p className="KhmerOSsiemreaptext post-description" style={{fontSize: '12pt', lineHeight: '28px'}}>
                      {t("12da2dfe9c2b1e6e85de4314fa2d8425")} <br />
                      1. {t("7310da7e7015ec7cd1e83f324c7c030d")}
                      <br />
                      2. {t("9664e57905eeded61792b9effefffb24")} <br />
                      3. {t("32971b063d5269a5738fc229c09413f6")}
                      <br />
                    </p>
                    <a href="" className="KhmerOSsiemreaptext read-more">
                      {t("d959bbe25cc020e2db5276cb42927c7c")}
                    </a>
                  </div>
                </div>

                <div className="post-slide9 col-lg-4 col-md-10 col-sm-8">
                  <div className="post-img">
                    <img
                      className="pic-1"
                      src="images/service2.jpg"
                      style={{ width:'348px', height:'241px'}}
                    />
                  </div>
                  <div className="post-review">
                    <h3 className="post-title KhmerOSmuollighttext">
                      <a href="#">{t("325d21423ea977ca0f03ad45e1a1a96a")}</a>
                    </h3>
                    <p className="KhmerOSsiemreaptext post-description" style={{fontSize: '12pt', lineHeight: '28px'}}>
                      {t("e40b85c6a16360fb06937166006b90df")}
                      <br />
                      1. {t("773a80f11ab5ede4633a0e4efdcfe934")} <br />
                      2. {t("eb53ddf05aa3e851aa0919f1a0de47dd")} <br />
                      3. {t("0b9d99533417a878ec88ab29bcbc4ae6")}
                    </p>
                    <a href="" className="KhmerOSsiemreaptext read-more">
                      {t("d959bbe25cc020e2db5276cb42927c7c")}
                    </a>
                  </div>
                </div>

                <div className="post-slide9 col-lg-4 col-md-10 col-sm-8">
                  <div className="post-img">
                    <img
                      className="pic-1"
                      src="images/service3.jpg"
                      style={{ width:'348px', height:'241px'}}
                    />
                  </div>
                  <div className="post-review">
                    <h3 className="post-title KhmerOSmuollighttext">
                      <a href="#">{t("268b63b0d615455e291d0d76de333ede")}</a>
                    </h3>
                    <p className="KhmerOSsiemreaptext post-description" style={{fontSize: '12pt', lineHeight: '28px'}}>
                      {t("06b73ba1ed30c1eee8ceed79c4bc9ba1")}
                      <br />
                      1. {t("a3695c001c7d4f3c56623ea23f53f39a")}
                      <br />
                      2.  {t("e268a3b6f3cb9a98c774da5c8ecdae3c")}
                      <br />
                      3.  {t("fe0c1d5144abaacd7c96594549af8324")}
                    </p>
                    <a href="#" className="KhmerOSsiemreaptext read-more">
                      {t("d959bbe25cc020e2db5276cb42927c7c")}
                    </a>
                  </div>
                </div>

                {/* <div className="post-slide9">
                    <div className="post-img">
                      <img className="pic-1" src="images/judge_online_4.jpg" />
                    </div>
                    <div className="post-review">
                      <h3 className="post-title">
                        <a href="#">Legalization Work</a>
                      </h3>
                      <p className="post-description">
                        Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                        Curabitur eleifend a massa rhoncus gravida. Nullam in
                        viverra sapien. Nunc bibendum nec lectus et vulputate.
                        Nam.
                      </p>
                      <a href="" className="read-more">
                        read more
                      </a>
                    </div>
                  </div> */}
              </div>
            </div>
          </div>
        </div>
      </main>

      <Footer></Footer>
    </div>
  );
}

export default Index_landing;

// to_convert
// Four Steps to Apply
// select the administrative services you wish to request
// scan
// upload the required documents
// After reviewing the application submit for further processing
// Document Review
// Verification
// complete the form and wait for the inspection by the professional officer
// This service is for the authorization of various ID types such as
// Civil Registration Work
// Death Certificate
// Wedding Certificate
// Birth Certificate
// This service is for the authorization of different certificates such as
// read more
// Residence Book
// Family book
// ID card
// Tourism License
// This service is to request for the tourism license for Guesthouse. Citizens can request for
// New Guesthouse License
// Renewal of Guesthouse License
// Change owner of Guesthouse