import axios from "axios";
import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import ModalError from "../Error-Span-Modal/ModalError";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function OtpValidation(props) {
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState(" ");
  const isLoginpage = true;
  const [disable, setDisable] = useState(false);
  const [submitdisable, setSubmitDisable] = useState(true);
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [otpText, setOtpText] = useState("4f2df4c330b8df125a3d75f2d69a9b5e");
  const [otpToken, setOtpToken] = useState("");
  const [otp, setOtp] = useState("");
  const [isloading, setIsloading] = useState(false);
  let navigate = useNavigate();

  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;
  //   const backProcessA = () => {
  //    props.handelclick(0);
  //   };
  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setDisable(false);
          setSubmitDisable(true);
          setError("");
          setErrorBlock(false);
        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) setErrorClass("errorMessage");
    else {
      setErrorClass("successMessage");
    }
    // setTimeout(()=>{
    //   setErrorBlock(false)
    // },4000)
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const sentOtp = () => {
    const sendOTP = {
      user_id: props.customer.IdCard,
      mobileno: props.customer.Phone,
    };

    // api call for otp send
    axios
      .post(`${LoginKey}/registration/sendOTP`, sendOTP)
      .then((response) => {
        // setIsloading(false);
        if (response.data.status == 1) {
          setError("a6968077c45b80366958453abe562984");
          setOtpToken(response.data.responseObject.otp_token);
          setOtpText("e0cf58fa3413df99ae83109ebb9a41a3");
          errorHandel(1);
          setDisable(true);
          setMinutes(2);
          setSeconds(59);
          setSubmitDisable(false);
        } else if (response.data.status == 0) {
          setError("ab1c0422d06e8d88fa2b627ab9c4db69");
          errorHandel(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const verifyOTp = () => {
    setIsloading(true);

    const registerData = {
      phoneNumber: phoneNumber,
    };

    const submitOTP = {
      otp: otp,
      otp_token: otpToken,
    };

    axios
      .post(`${LoginKey}/registration/validateOTP`, submitOTP)
      .then((response) => {
        setIsloading(false);
        if (response.data.status == 1) {
          props.handelData(registerData);
          props.handelclick(1);
        } else if (response.data.status == 0) {
          setError("a558ffd7c2756724f0369642825c1014");
          errorHandel(0);
        }
      })
      .catch((err) => {
        console.log(err);
        setIsloading(false);
      });
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  return (
    <div>
      {errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}

        {errorblock && errorClass == "errorMessage" && (
          <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
        )}

      <div className="card">
        <div id="headingTwo" />
        <div
          id="collapseTwo"
          className="collapse show"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
          style={{}}
        >
          <div className="card-body" style={{ width: "648px!important" }}>
            <div className="form-row pb-4">
              {/* Form Group (first name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputFirstName">
                  {t("bbb4ecb7471b9219f45ac6cb913f22b0")}
                  {/* <span
                    style={{
                      paddingLeft: "15px",
                      fontSize: "12px",
                      color: "#b10909",
                    }}
                  >
                    {error}
                  </span> */}
                  <span style={{ float: "right" }}>
                    {disable &&
                      `${minutes < 10 ? `0${minutes}` : minutes}:${
                        seconds < 10 ? `0${seconds}` : seconds
                      }`}
                  </span>
                </label>

                <div className="input-group mb-3">
                  <input
                    type="tel"
                    className="KhmerOSsiemreaptext form-control"
                    placeholder={`*******${props.customer.Phone.substr(6, 10)}`}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    disabled
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                  <button
                    className={`KhmerOSsiemreaptext btn btn-outline-secondary ${
                      disable && "input-dark"
                    }`}
                    type="button"
                    id="button-addon2"
                    disabled={disable}
                    onClick={sentOtp}
                  >
                    {t(`${otpText}`)}
                  </button>
                </div>
              </div>
              {/* Form Group (last name)*/}
              <div className="form-group col-md-6" style={{ marginBottom: 80 }}>
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("2f96a82a2d4bb7206c760a26cdcb2cc8")}
                </label>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputFirstName"
                  type="tel"
                  placeholder={t("2f96a82a2d4bb7206c760a26cdcb2cc8")}
                  disabled={submitdisable}
                  onChange={(e) => setOtp(e.target.value)}
                />
              </div>
              <hr />
              <div className="col-12 fot-row row">
                {/* <div className="d-flex justify-content-between col-6">
                  <button
                    className="btn btn-primary collapsed"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    style={{
                      backgroundColor: "#a1a1a1",
                      borderColor: "#7a7a7a",
                    }}
                    onClick={backProcessA}
                  >
                    Back
                  </button>
                </div> */}
                <div className="d-flex flex-row justify-content-end col-12">
                  <button
                    className="KhmerOSsiemreaptext btn btn-primary next"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    onClick={verifyOTp}
                    disabled={submitdisable}
                  >
                    {t("60bf4ca5718f38278204d94c03113615")}
                  </button>
                  <a href="#" className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default OtpValidation;

// to_convert
// The entered OTP is incorrect. Please try again
