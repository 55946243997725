import React, { useState } from "react";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function ForgetPassword(props) {
  const isLoginpage = true;
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [id, setId] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(()=>{
    //   setErrorBlock(false)
    // },4000)
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  // forgot password change function
  const forgotPasswordHandel = (e) => {
    e.preventDefault();
    if (id === "") {
      setError("504c46e2c369d441b6eb6c581101fc85");
      errorHandel(0);
    } else {
      setIsloading(true);
      const forgotData = {
        idNumber: id,
        userCode: id,
      };

      // forgot password change service call
      axios
        .post(
          `${LoginKey}/registration/checkExistingIdCardOnRegistration`,
          forgotData
        )
        .then((response) => {
          setIsloading(false);
          if (response.data.responseObject.Data.Customer != null) {
            props.handelValidate(true);
            props.customerData(response.data.responseObject.Data.Customer);
          } else {
            setError("213673e7c3b86758ff85fcc27459bf54");
            errorHandel(0);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
          setError("4b2b25d11213a09ce090cda61c0ea175");
          errorHandel(0);
        });
    }
  };



  return (
    <div>
      {/* <Navigation page={isLoginpage}></Navigation> */}
      {/* {errorblock && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )} */}
      {errorblock && errorClass == "errorMessage" && (
          <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
        )}
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 paddingTop-9">
            <div className="container-fluid d-flex justify-content-center col-md-4">
              <div
                className="card card-lg mb-4"
                style={{
                  backgroundColor: "rgb(17,126,191, 0.8)",
                  width: "380px",
                }}
              >
                <div className="KhmerOSmuollighttext card-header" style={{ color: "#fff" }}>
                  {t("6d909c2ed76c42670f090b269a478b7f")}
                </div>

                <div
                  className="card-body"
                  style={{
                    padding_top: "0.35rem",
                    padding_bottom: "0.35rem",
                    text_align: "left",
                  }}
                >
                  <form>
                    {/* <!-- Form Group (username)--> */}
                    <div className="form-group col-md-12">
                      <label className="KhmerOSsiemreaptext small mb-1" for="inputUsername">
                        {t("8e233234a83a277872b8ce305dceb914")}
                        {/* <span
                    style={{
                      paddingLeft: "15px",
                      fontSize: "12px",
                      color: "#b10909",
                    }}
                  >
                    {error}</span> */}
                      </label>
                      <input
                        className="KhmerOSsiemreaptext form-control"
                        id="inputUsername"
                        type="number"
                        placeholder={t("504c46e2c369d441b6eb6c581101fc85")}
                        onChange={(e) => {
                          setId(e.target.value);
                        }}
                      />
                    </div>

                    <div className="form-row">
                      {/* <!-- Form Group (last name)--> */}
                      <div className="d-flex justify-content-center form-group col-md-12">
                        <button
                          className="KhmerOSsiemreaptext btn btn-primary boderR login-acc"
                          onClick={forgotPasswordHandel}
                        >
                          {" "}
                          {t("d0cab90d8d20d57e2f2b9be52f7dd25d")}
                        </button>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        for="remember-me"
                        className="KhmerOSsiemreaptext text-info col-md-12 col-lg-12 text-center"
                        style={{ text_align: "right" }}
                      >
                        <Link to={"/login"}>
                          {t("9288098bf51c8184caf86bea0722543f")}
                        </Link>
                      </label>
                    </div>
                  </form>
                </div>

                {/* <!-- Save changes button--> */}
              </div>
            </div>
          </header>
        </main>
      </div>

      {/* <Footer></Footer> */}
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default ForgetPassword;

// to_convert...
// done !
