import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

 
function ChangeSuccess() {

  const { t } = useTranslation();

  const navigate = useNavigate();

  // useEffect(() => {
  //   setTimeout(() => {
  //     navigate("/login");
  //   }, 4000);
  // });
  
  return (
    <div>
      <div className="card">
        <div id="headingFour" />
        <div
          id="collapseFour"
          className="collapse show"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
          style={{}}
        > 
          <div className="card-body" style={{ width: "648px!important" }}>
            <div className="form-card">
              <div className="row justify-content-center">
                <div className="col-3">
                  {" "}
                  <img
                    src="https://img.icons8.com/color/96/000000/ok--v2.png"
                    className="fit-image"
                  />{" "}
                </div>
              </div>
              <label className="KhmerOSsiemreaptext fs-title text-center">
              {t("5cb59474869eb4d9ae4cef26f9418edf")}
              </label>
              <div className="row justify-content-center">
                <div className="col-7 text-center">
                  {/* <h6>
                  Current Status-Application under review
                  <br /> Application Number #AP-65438000 <br />
                  {t("6e9a10b2d4dd5f977fa0b1e74b0c0ae5")} 07/11/23 <br />
                  Users can login post activation
                </h6> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeSuccess;


// translation done !