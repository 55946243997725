import React, { useState, useEffect, useContext, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import DatePicker from "react-datepicker";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import axios from "axios";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { RejectionResponseContext } from "../../ContextApi";
import SpanError from "../Error-Span-Modal/SpanError";

import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";
import ModalImage from "../Error-Span-Modal/ModalImage";
import SuccessModal from "../Error-Span-Modal/SuccessModal";
import moment from "moment";
const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};
function RejectionApplication() { 
  const { t } = useTranslation();
  const isLoginpage = true;
  const isrejectionLogedin = true;
  const [id, setId] = useState("");
  const inputRefIDCardFrontSide = useRef(null);
  const inputRefIDCardBackSide = useRef(null);

  const districtRef = useRef(null);
  const provinceRef = useRef(null);
  const communeRef = useRef(null);
  const villageRef = useRef(null);

  // data fileds
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [latinFirstName, setLatinFirstName] = useState("");
  const [latinLastName, setLatinLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateExpired, setDateExpired] = useState("");
  const [dobAddress, setDobAddress] = useState("");
  const [sex, setSex] = useState(0);
  const [capitalCode, setCapitalCode] = useState("");
  const [capitalId, setCaptialId] = useState("");
  const [districtCode, setDisctirctCode] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [communeCode, setCommuneCode] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [villageCode, setVillageCode] = useState("");
  const [villageId, setVillageId] = useState("");
  const [email, setEmail] = useState("");
  const [partner, setPartner] = useState("");

  //image fields
  const [frontID, setFrontID] = useState();
  const [backID, setBackID] = useState();
  const [frontName, setFronName] = useState("");
  const [backName, setBackName] = useState("");
  const [frontImage, setFrontImage] = useState();
  const [backImage, setBackImage] = useState();
  const [frontBase64, setFrontBase64] = useState("");
  const [backBase64, setBackBase64] = useState("");
  const [frontExistingID,setFrontExistingID]=useState("")
  const [backExistingId,setBackExistingId]=useState("")

  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [frontError, setfronError] = useState("");
  const [backError, setbackError] = useState("");
  //data coming from api
  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);
  const [rejectionData, setRejectionData] = useState([]);
  const [customerData, setCustomerData] = useState({});
  const years = range(1950, getYear(new Date()) + 50, 1);
  const currentYear = range(1850, getYear(new Date()) + 1, 1);
  const [isValid, setIsValid] = useState(true);


  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);

  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpText, setOtpText] = useState("4f2df4c330b8df125a3d75f2d69a9b5e");
  const [disable, setDisable] = useState(false);
  const [submitdisable, setSubmitDisable] = useState(true);
  const [otpToken, setOtpToken] = useState("");
  const [otp, setOTP] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [otpVlaidate, setOtpValidate] = useState(false);
  const [viewDocument,setViewDocumet]=useState(false)
  const [successModal,setSuccessModal]=useState(false);
  // let navigate = useNavigate();
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;
  const Masterkey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const RegisterKey = process.env.REACT_APP_REGISTTATION_SERVICE;
  const DMSKey = process.env.REACT_APP_DMS_SERVICE;

  let Navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      Navigate('/');
    }
  }, [isAuthenticated]);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const [errorData, setErrorData] = useState({});
  const { rejectionResponse } = useContext(RejectionResponseContext);
  const [currentDate, setCurrentDate] = useState("");
  // const rejectionResponse = "1002003006";
  let frontApiID=" ";
let backApiID=" ";

const emailHandler = (e) => {
  setEmail(e.target.value);
  const time = setTimeout(() => {
    const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/;
    setIsValid(emailRegex.test(e.target.value));
  },2500)

  return () => clearTimeout(time);
  
};
  useEffect(() => {

    if(rejectionResponse==""){
      Navigate("/login")
    }
    var date = moment();

    setCurrentDate(date.format("DD/MM/YYYY"));
    setIsloading(true);
    const rejectionId = {
      idCards: [rejectionResponse.idCard],
      loggedinUserCode: rejectionResponse.idCard,
    };

    
    let config = {
      headers: {
        Authorization: rejectionResponse.accessToken,
        AuthToken: "Bearer " + rejectionResponse.authToken
      },
    };

    const data = {
      idNumber: rejectionResponse.idCard,
      userCode: rejectionResponse.idCard,
    };

    
    // api for rejection list
    axios
      .post(`${LoginKey}/registration/getRejectedAccountStatus`, rejectionId, config)
      .then((response) => {
        setRejectionData(
          response.data.responseObject.RejectedReasonData.RejectedReason
        );
        for (
          let i = 0;
          i <
          response.data.responseObject.RejectedReasonData.RejectedReason.length;
          i++
        ) {
          let error = {};
          error.errorReason =
            response.data.responseObject.RejectedReasonData.RejectedReason[
              i
            ].Msg;
            error.errorStatus =  response.data.responseObject.RejectedReasonData.RejectedReason[i].Status;
          let field =
            response.data.responseObject.RejectedReasonData.RejectedReason[i]
              .Field;
          errorData[field] = error;
        }
      })
      .catch((error) => {
        console.log(error);
      });

    // api for get existing customer data
    axios
      .post(`${LoginKey}/registration/checkExistingIdCardOnRegistration`, data)
      .then((response) => {
        setId(response.data.responseObject.Data.Customer.IdCard);
        setfirstName(response.data.responseObject.Data.Customer.FirstName);
        setlastName(response.data.responseObject.Data.Customer.LastName);
        setLatinFirstName(
          response.data.responseObject.Data.Customer.LatinFirstName
        );
        setLatinLastName(
          response.data.responseObject.Data.Customer.LatinLastName
        );
        setDateExpired(response.data.responseObject.Data.Customer.DateExpired);
        setCaptialId(response.data.responseObject.Data.Customer.CapitalId);
        setDistrictId(response.data.responseObject.Data.Customer.DistrictId);
        setCommuneId(response.data.responseObject.Data.Customer.CommuneId);
        setVillageId(response.data.responseObject.Data.Customer.VillageId);
        setCapitalCode(response.data.responseObject.Data.Customer.CapitalCode);
        setDisctirctCode(
          response.data.responseObject.Data.Customer.DistrictCode
        );
        setCommuneCode(response.data.responseObject.Data.Customer.CommuneCode);
        setVillageCode(response.data.responseObject.Data.Customer.VillageCode);
        setSex(response.data.responseObject.Data.Customer.Sex);
        setDobAddress(response.data.responseObject.Data.Customer.DobAddress);
        setEmail(response.data.responseObject.Data.Customer.Email);
        setDateOfBirth(response.data.responseObject.Data.Customer.Dob);
        setPhoneNumber(response.data.responseObject.Data.Customer.Phone);
        setFronName(response.data.responseObject.Data.Customer.IdCardFront);
        setBackName(response.data.responseObject.Data.Customer.IdCardBack);
        setFrontExistingID(response.data.responseObject.Data.Customer.IdCardFront)
        setBackExistingId(response.data.responseObject.Data.Customer.IdCardBack)
        
      })
      .catch((error) => {
        console.log(error);
      });

    // for (let i = 0; i < rejectionData.length; i++) {
    //   let error = {};
    //   error.errorReason = rejectionData[i].Msg;
    //   let field = rejectionData[i].Field;
    //   errorData[field] = error;

    

    // api for get front Idcard
    axios
      .post(
        `${DMSKey}/downloadFile`,
        // {
        //   identityType: "FrontIdCard", // To fetch Front side of the id card
        //   idCardNumber: rejectionResponse.idCard,
        // },
        {
          "fileName": frontExistingID  
        },
        config 
      )
      .then((response) => {
        setFrontImage(response.data.responseObject);
        setFrontBase64(response.data.responseObject);
        setFronName(fileName(response.data.responseObject));
        })
        .catch((error) => {
        // console.log(rejectionResponse.authToken);
        console.log(error);
      });

    // api for get back Idcard
    axios
      .post(
        // `${LoginKey}/registration/getIdCardImageInBase64Format`,
        `${DMSKey}/downloadFile`,
        // {
        //   identityType: "BackIdCard", // To fetch Back side of the id card
        //   idCardNumber: rejectionResponse.idCard,
        // },
        {
          "fileName": backExistingId
        },
        config
      )
      .then((response) => {
        setBackImage(response.data.responseObject);
        setBackBase64(response.data.responseObject);
        setBackName(fileName(response.data.responseObject));
      })
      .catch((error) => {
        console.log(error);
      });

    // api for get capitals from database
    axios
      .post(
        `${Masterkey}/getAllCapital`,
        { userCode: "1" }
        //config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get districts from database
    axios
      .post(
        `${Masterkey}/getAllDistrictByCapital`,
        {
          userCode: "string",
          capitalId: capitalId,
          online: true,
        }
        //config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get commune from database
    axios
      .post(
        `${Masterkey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: districtId,
        }
        //config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /// api for get villages from database
    axios
      .post(
        `${Masterkey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: communeId,
        }
        // config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
      setIsloading(false);
  }, [frontExistingID,backExistingId]);

  useEffect(() => {
    if (otpVlaidate) {
      return;
    } else {
      const interval = setInterval(() => {
        if (seconds > 0) {
          setSeconds(seconds - 1);
        }

        if (seconds === 0) {
          if (minutes === 0) {
            clearInterval(interval);
            setDisable(false);
            setSubmitDisable(true);
            setError("");
            erroBlockHandel();
          } else {
            setSeconds(59);
            setMinutes(minutes - 1);
          }
        }
      }, 1000);

      return () => {
        clearInterval(interval);
      };
    }
  }, [seconds]);

  const resendOTP = () => {
    if (phoneNumber == "") {
      setError("8c4ed5656b26e589227db99ed6d8e5b9");
      errorHandel(0);
      return;
    }
    const phoneRegex = /^\d{8,10}$/;
    const isValidPhone = phoneRegex.test(phoneNumber);

    if (!isValidPhone) {
      setError("613ef4baa62da2b5aa61e3cbfcd03cce");
      errorHandel(1);
      return;
    }

    const sendOTP = {
      user_id: rejectionResponse.idCard,
      mobileno: phoneNumber,
    };
    
    axios
      .post(`${LoginKey}/registration/sendOTP`, sendOTP)
      .then((response) => {
        // setIsloading(false);
        if (response.data.status == 1) {
          setError("a6968077c45b80366958453abe562984");
          setOtpToken(response.data.responseObject.otp_token);
          setOtpText("e0cf58fa3413df99ae83109ebb9a41a3");
          errorHandel(1);
          setDisable(true); 
          setMinutes(2);
          setSeconds(59);
          setSubmitDisable(false);
        } else if (response.data.status == 0) {
          setError("613ef4baa62da2b5aa61e3cbfcd03cce");
          errorHandel(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  // const resendOTP = () => {
  //             setSuccessModal(true)

  // }

  

  const verifyOTp = () => {
    // api call for verify otp
    // setIsloading(true);

    const submitOTP = {
      otp: otp,
      otp_token: otpToken,
    };

    axios
      .post(`${LoginKey}/registration/validateOTP`, submitOTP)
      .then((response) => {
        // setIsloading(false);
        if (response.data.status == 1) {
          setError("c76fb95fc7cdcb4a12f2423da63cc61b");
          errorHandel(1);
          setOtpValidate(true);
        } else if (response.data.status == 0) {
          setError("a558ffd7c2756724f0369642825c1014");
          errorHandel(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const IDcardFronthandel = (e) => {
    // formData.append("idCardFront", file);
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      inputRefIDCardFrontSide.current.value = '';
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
      setFronName("");
    } else if (e.target.files[0].size >= 10 * 1024 * 1024) {
      inputRefIDCardFrontSide.current.value = '';
      setBackID(null);
      setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
      errorHandel(0);
      return;
    }
    else {
      let file = e.target.files[0];
      setFronName(e.target.files[0].name);
      setFrontID(file);
      setfronError(" ");
      getBase64(file).then((res) => setFrontBase64(res));
      // console.log(frontBase64);
    }
  };

  const IDcardBackhandel = (e) => {
    // formData.append("idCardBack", file);
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      inputRefIDCardBackSide.current.value = '';
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
      setBackName("");
    } else if (e.target.files[0].size >= 10 * 1024 * 1024) {
      inputRefIDCardBackSide.current.value = '';
      setBackID(null);
      setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
      errorHandel(0);
      return;
    } else {
      let file = e.target.files[0];
      setBackName(e.target.files[0].name);
      setBackID(file);
      setfronError(" ");
      getBase64(file).then((res) => setBackBase64(res));
    }
  };

  //function convert base64 to file
  const fileConvert = (b64Data, contentType, contentName, sliceSize) => {
    contentType = contentType || "";
    sliceSize = sliceSize || 512;

    let imageFile = b64Data.split(",");
    let byteCharacters = atob(imageFile[1]);
    let byteArrays = [];
    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      let slice = byteCharacters.slice(offset, offset + sliceSize);

      let byteNumber = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumber[i] = slice.charCodeAt(i);
      }

      let byteArray = new Uint8Array(byteNumber);
      byteArrays.push(byteArray);
    }

    return new File(byteArrays, contentName, { type: contentType });
  };

  // function convet file to base64
  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };
  // function get file name fron the base64
  const fileName = (base64) => {
    let file = base64.split(";");
    file = file[0].split("/");
    if (file[1] == "pdf") {
      return `fileName.pdf`;
    } else {
      return `fileName.${file[1]}`;
    }
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else if (e == 1) {
      setErrorClass("successMessage");
    }
  };

  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  const capitalChangeHandel = (e) => {
    let capital = JSON.parse(e.target.value);
    setCapitalCode(capital.Code);
    setCaptialId(capital.Id);
    console.log(capital)
    // api for get district
    axios
      .post(`${Masterkey}/getAllDistrictByCapital`, {
        userCode: "string",
        capitalId: capital.Id,
        online: true,
      })
      .then((response) => {
        if (response.data.status === "1") {
          districtRef.current.value = '0'
          setApiDistricts(response.data.responseObject.Data.Districts);
          setApiCommune([]);
          setApiVillage([]);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const districtChangeHandel = (e) => {
    let district = JSON.parse(e.target.value);
    setDisctirctCode(district.Code);
    setDistrictId(district.Id);

    // api for get commune
    axios
      .post(`${Masterkey}/getCommuneByDistrict`, {
        userCode: "string",
        districtId: district.Id,
      })
      .then((response) => {
        if (response.data.status == 1) {
          communeRef.current.value = '0'
          setApiCommune(response.data.responseObject.Data.Communes);
          setApiVillage([]);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const CommuneChnagehandel = (e) => {
    let commune = JSON.parse(e.target.value);
    setCommuneCode(commune.Code);
    setCommuneId(commune.Id);

    // api for get villages
    axios
      .post(`${Masterkey}/getVillageByCommune`, {
        userCode: "string",
        communeId: commune.Id,
      })
      .then((response) => {
        if (response.data.status == 1) {
          villageRef.current.value = '0'
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const villageChangeHandel = (e) => {
    let village = JSON.parse(e.target.value);
    setVillageCode(village.Code);
    setVillageId(village.Id);
  };

  const handelSubmit = () => {
    // if (otpVlaidate == false) {
    //   setError("Phone Number is not validate");
    //   errorHandel(0);
    //   return;
    // }

    if (
      id === "" ||
      firstName === "" ||
      lastName === "" ||
      latinFirstName === "" ||
      latinLastName === "" ||
      dobAddress === "" ||
      sex === "" ||
      dateOfBirth === "" ||
      capitalCode === "" ||
      districtCode === "" ||
      communeCode === "" ||
      villageCode === "" ||
      dateExpired === "" ||
      // email === "" ||
      frontName === "" ||
      backName === ""
    ) {
      setError("2d13aa4a4d1a2a0dedf1a03323d294b8");
      errorHandel(0);
      return;
    }
    if(!isValid && email !== ''){
      setError("d9d12ce1088807d8fb826c2d0dda4ba3");
      errorHandel(0);
      return;
    }
    setIsloading(true);
    // let frontImageFile;
    // if (frontID == null) {
    //   frontImageFile = fileConvert(frontImage, "image/jpeg", "frontImage");
    //   // setFrontID(frontImageFile);
    // }
    // let backImageFile;
    // if (backID == null) {
    //   backImageFile = fileConvert(backImage, "image/jpeg", "backImage");
    //   // setBackID(backImageFile);
    // }
    const registerData = {
      createUid: id,
      createDate: "",
      modifiedUid: id,
      modifiedDate: "",
      versionNo: 0,
      isSubmitted: "",
      isActive: "",
      isDeleted: "",
      assigneeId: "",

      idCardNumber: id,
      firstName: firstName,
      lastName: lastName,
      latinFirstName: latinFirstName,
      latinLastName: latinLastName,
      dobAddress: dobAddress,
      sex: sex,
      dob: dateOfBirth,
      capitalCode: capitalCode,
      districtCode: districtCode,
      communeCode: communeCode,
      villageCode: villageCode,
      dateExpired: dateExpired,
      email: email,
      partner: "ONLINE DMK",
      partnerRefno: "",
      mobile: phoneNumber,
    };

    const form = new FormData();
    form.append("onlineRegistrationRequest", JSON.stringify(registerData));
    form.append("idCardFront", frontID == null ? null: frontID);
    form.append("idCardBack", backID == null ?null: backID);
    console.log(form);
  
    let config = {
      headers: {
        "Content-Type": "multipart/form-data",
        Authorization: rejectionResponse.accessToken,
        AuthToken: "Bearer " + rejectionResponse.authToken
      },
    };

    axios
      .post(`${LoginKey}/registration/updateOnlineRegistration`, form, config)
      .then((response) => {
        setIsloading(false);
        if (response.data.status == 1) {
          // Navigate("/login");
          setSuccessModal(true)
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  };

  const [ImageName,setImageName]=useState("");
  const [ImageBase64,setImageBase64]=useState("");
  const handelDocumentView=(ImageName,ImageBase64)=>{
    if(ImageBase64!=""){
    setViewDocumet(true);
    setImageName(ImageName);
    setImageBase64(ImageBase64);
    }
  }
  const imageClose=(e)=>{
    setViewDocumet(e);
  }
  return (
    <div>
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
      <>
        {errorblock && errorClass == "successMessage" && (
          <div
            className={`popup ${errorClass}`}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div>{t(`${error}`)}</div>
            <div>
              <button
                onClick={erroBlockHandel}
                className={`download ${errorClass}`}
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        )}
        {errorblock && errorClass == "errorMessage" && (
          <ModalError
            error={t(`${error}`)}
            errorClose={errorClose}
          ></ModalError>
        )}

        {viewDocument && (
          <ModalImage
            imageName={ImageName}
            imageBase64={ImageBase64}
            imageClose={imageClose}
          ></ModalImage>
        )}

        {successModal &&(
          <SuccessModal currentDate={currentDate} navigate={"/login"}></SuccessModal>
        )}
        <Navigation page={isLoginpage} rejectionlogin={isrejectionLogedin}></Navigation>

        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 paddingTop-9" />
          {/* Main page content*/}
          <div
            className="container mt-n10"
            style={{ marginTop: "-10rem !important" }}
          >
            {/* Example Colored Cards for Dashboard Demo*/}
            <div className="row d-flex justify-content-center">
              <div className="col-md-12 col-sm-12 col-lg-12">
                <div className="card card-lg card-other">
                  <div className="row" style={{ marginBottom: 80 }}>
                    <form style={{ width: "100%" }}>
                      <h5 style={{color: 'red'}}><b>{t("f1acdf432550d4a68f146eabf2c92f2d")}</b></h5>
                      <hr />
                      {/* Form Row*/}
                      <section>
                        <div className="container">
                          <div className="accordion" id="accordionExample">
                            <div className="card">
                              <div id="headingOne" />
                              <div
                                id="collapseOne"
                                className="collapse show"
                                aria-labelledby="headingOne"
                                data-parent="#accordionExample"
                              >
                                <div className="card-body">
                                  <div className="form-row">
                                    {/* Form Group (first name)*/}
                                    <div className="form-group col-md-12">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputFirstName"
                                      >
                                        {t("dae903caf35016e727adebc1e3c18b2a")}
                                      </label>
                                      <input
                                        className="form-control input-dark"
                                        id="inputUsername"
                                        type="text"
                                        placeholder={t(
                                          "504c46e2c369d441b6eb6c581101fc85"
                                        )}
                                        disabled
                                        value={id}
                                        onChange={(e) => setId(e.target.value)}
                                      />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputOrgName"
                                      >
                                        {t("2c34abc1b734258621340369de150576")}
                                        <span className="error">
                                          {frontError}
                                        </span>
                                      </label>
                                      <span className="mandatory">*</span>
                                      <div className="text-left">
                                        {frontName.match(/\.(pdf)$/i) ? (
                                          <>
                                          <iframe
                                            style={{
                                              border: `${
                                                errorData?.IdCardFront &&
                                                "2px solid red"
                                              }`,
                                              borderRadius: `${
                                                errorData?.IdCardFront &&
                                                "0.35rem"
                                              }`,
                                            }}
                                            width={150}
                                            height={150}
                                            src={frontBase64}
                                          />
                                           <div className="iframe-modal" onClick={()=>handelDocumentView(frontName,frontBase64)}></div>
                                          </>
                                        ) : (
                                          <img
                                            style={{
                                              border: `${
                                                errorData?.IdCardFront?.errorStatus === '2' &&
                                                "2px solid red"
                                              }`,
                                              borderRadius: `${
                                                errorData?.IdCardFront?.errorStatus === '2' &&
                                                "0.35rem"
                                              }`,
                                            }}
                                            width={150}
                                            height={150}
                                            src={frontBase64}
                                            alt="file"
                                            onClick={()=>handelDocumentView(frontName,frontBase64)}
                                          />
                                        )}
                                        <br />
                                        <br />
                                        <input
                                          type="file"
                                          ref={inputRefIDCardFrontSide}
                                          className="text-center center-block file-upload"
                                          onChange={IDcardFronthandel}
                                          disabled={errorData?.IdCardFront?.errorStatus !== '2'}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <SpanError
                                            errorReason={
                                              errorData?.IdCardFront
                                                ?.errorReason
                                            }
                                          />
                                          <p
                                            className="mt-1"
                                            style={{
                                              fontSize: "10px",
                                              fontStyle: "italic",
                                              color: "red",
                                            }}
                                          >
                                            {t(
                                              "2ffe94058afaea3b875be2082941ec0b"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputOrgName"
                                      >
                                        {t("9c22570c0feeb5cea41f628ffa7e8790")}
                                        <span className="error">
                                          {backError}
                                        </span>
                                      </label>
                                      <span className="mandatory">*</span>
                                      <div className="text-left">
                                        {backName.match(/\.(pdf)$/i) ? (
                                          <>
                                          <iframe
                                            style={{
                                              border: `${
                                                errorData?.IdCardBack?.errorStatus === '2' &&
                                                "2px solid red"
                                              }`,
                                              borderRadius: `${
                                                errorData?.IdCardBack?.errorStatus === '2' &&
                                                "0.35rem"
                                              }`,
                                            }}
                                            width={150}
                                            height={150}
                                            src={backBase64}
                                          />
                                           <div className="iframe-modal" onClick={()=>handelDocumentView(backName,backBase64)}></div>
                                          </>
                                        ) : (
                                          <img
                                            style={{
                                              border: `${
                                                errorData?.IdCardBack?.errorStatus === '2' &&
                                                "2px solid red"
                                              }`,
                                              borderRadius: `${
                                                errorData?.IdCardBack?.errorStatus === '2' &&
                                                "0.35rem"
                                              }`,
                                            }}
                                            width={150}
                                            height={150}
                                            // src="https://img.icons8.com/plasticine/100/file.png"
                                            src={backBase64}
                                            alt="file"
                                            onClick={()=>handelDocumentView(backName,backBase64)}
                                          />
                                        )}
                                        <br />
                                        <br />
                                        <input
                                          type="file"
                                          ref={inputRefIDCardBackSide}
                                          className="text-center center-block file-upload"
                                          onChange={IDcardBackhandel}
                                          disabled={errorData?.IdCardBack?.errorStatus !== '2'}
                                        />
                                        <div
                                          style={{
                                            display: "flex",
                                            flexDirection: "column",
                                          }}
                                        >
                                          <SpanError
                                            errorReason={
                                              errorData?.IdCardBack?.errorReason
                                            }
                                          />
                                          <p
                                            className="mt-1"
                                            style={{
                                              fontSize: "10px",
                                              fontStyle: "italic",
                                              color: "red",
                                            }}
                                          >
                                            {t(
                                              "2ffe94058afaea3b875be2082941ec0b"
                                            )}
                                          </p>
                                        </div>
                                      </div>
                                    </div>

                                    {/* date expired */}

                                    {/* <div className="form-group col-md-12"> */}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("0ec0352c0f61ad1c9a1c3babb7c370f1")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <div
                                        style={{
                                          border: `${
                                            errorData?.DateExpired?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                          borderRadius: `${
                                            errorData?.DateExpired?.errorStatus === '2' && "0.35rem"
                                          }`,
                                        }}
                                      >
                                        <DatePicker
                                          showIcon
                                          toggleCalendarOnIconClick
                                          onKeyDown={(e) => e.preventDefault()}
                                          renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                          }) => (
                                            <div
                                              style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <select
                                                value={getYear(date)}
                                                onChange={({
                                                  target: { value },
                                                }) => changeYear(value)}
                                              >
                                                {years.map((option) => (
                                                  <option
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                ))}
                                              </select>

                                              <select
                                                value={months[getMonth(date)]}
                                                onChange={({
                                                  target: { value },
                                                }) =>
                                                  changeMonth(
                                                    months.indexOf(value)
                                                  )
                                                }
                                              >
                                                {months.map((option) => (
                                                  <option
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          )}
                                          className="form-control width-100"
                                          dateFormat="dd/MM/yyyy"
                                          selected={dateExpired}
                                          // value={dateExpired}
                                          onChange={(date) =>
                                            setDateExpired(date)
                                          }
                                          disabled={errorData?.DateExpired?.errorStatus !== '2'}
                                        />
                                      </div>
                                      <SpanError
                                        errorReason={
                                          errorData?.DateExpired?.errorReason
                                        }
                                      />
                                    </div>

                                    {/* </div> */}
                                    <div className="form-group col-md-6" />
                                    {/* Form Group (last name)*/}
                                    

                                    {/* Form Group (phone number)*/}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputPhone"
                                      >
                                        {t("d51f2f5018481db5780056d73b18babf")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <input
                                        style={{
                                          border: `${
                                            errorData?.FirstName?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control"
                                        id="inputPhone"
                                        type="text"
                                        placeholder={t(
                                          "d51f2f5018481db5780056d73b18babf"
                                        )}
                                        value={firstName}
                                        onChange={(e) => {
                                          setfirstName(
                                            e.target.value
                                          )
                                        }}
                                        disabled={errorData?.FirstName?.errorStatus !== '2'}
                                      />
                                      <SpanError
                                        errorReason={
                                          errorData?.FirstName?.errorReason
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputPhone"
                                      >
                                        {t(
                                          "8f1373fa0f2d32523259b56ce11e1975"
                                        )}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <input
                                        style={{
                                          border: `${
                                            errorData?.LastName?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control"
                                        id="inputPhone"
                                        type="text"
                                        placeholder="Name (KH)"
                                        value={lastName}
                                        onChange={(e) => {
                                          setlastName(
                                            e.target.value
                                          );
                                        }}
                                        disabled={errorData?.LastName?.errorStatus !== '2'}
                                      />
                                      <SpanError
                                        errorReason={
                                          errorData?.LastName?.errorReason
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("bc910f8bdf70f29374f496f05be0330c")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <input
                                        style={{
                                          border: `${
                                            errorData?.LatinFirstName?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control"
                                        id="inputFirstName"
                                        type="text"
                                        placeholder={t(
                                          "bc910f8bdf70f29374f496f05be0330c"
                                        )}
                                        value={latinFirstName}
                                        onChange={(e) => {
                                          setLatinFirstName(
                                            e.target.value
                                          );
                                        }}
                                        disabled={errorData?.LatinFirstName?.errorStatus !== '2'}
                                      />
                                      <SpanError
                                        errorReason={
                                          errorData?.LatinFirstName?.errorReason
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t(
                                          "9186d01018279f3b2ef5a022d71ba743"
                                        )}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <input
                                        style={{
                                          border: `${
                                            errorData?.LatinLastName?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control"
                                        id="inputFirstName"
                                        type="text"
                                        placeholder="Name (EN)"
                                        value={latinLastName}
                                        onChange={(e) => {
                                          setLatinLastName(
                                            e.target.value
                                          );
                                        }}
                                        disabled={errorData?.LatinLastName?.errorStatus !== '2'}
                                      />
                                      <SpanError
                                        errorReason={
                                          errorData?.LatinLastName?.errorReason
                                        }
                                      />
                                    </div>
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("8be5338b5414092f30160584e760bb01")}
                                      </label>
                                      {/* <span className="mandatory">*</span> */}
                                      <input
                                        style={{
                                          border: `${
                                            errorData?.Email?.errorStatus === '2' && "1px solid red"
                                          }`,
                                        }}
                                        className="form-control"
                                        id="inputFirstName"
                                        type="text"
                                        placeholder="abc@gmail.com"
                                        value={email}
                                        onChange={emailHandler}
                                        disabled={errorData?.Email?.errorStatus !== '2'}
                                      />
                                      <SpanError
                                      // style={{margin: '4px 20px 2px -30px'}}
                                        errorReason={
                                          errorData?.Email?.errorReason
                                        
                                        }
                                      />
                                      <br />
                                      <SpanError
                                       
                                        errorReason={
                                          // errorData?.Email?.errorReason
                                          
                                          !isValid && `${t('d9d12ce1088807d8fb826c2d0dda4ba3')}` 
                                        }
                                      />
                                    </div>
                                    {/* Form Group (last name)*/}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("10803b83a68db8f7e7a33e3b41e184d0")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <div
                                        style={{
                                          border: `${
                                            errorData?.Dob?.errorStatus === '2' && "1px solid red"
                                          }`,
                                          borderRadius: `${
                                            errorData?.Dob && "0.35rem"
                                          }`,
                                        }}
                                      >
                                        <DatePicker
                                          maxDate={new Date()}
                                          onKeyDown={(e) => e.preventDefault()}
                                          showIcon
                                          toggleCalendarOnIconClick
                                          renderCustomHeader={({
                                            date,
                                            changeYear,
                                            changeMonth,
                                            decreaseMonth,
                                            increaseMonth,
                                            prevMonthButtonDisabled,
                                            nextMonthButtonDisabled,
                                          }) => (
                                            <div
                                              style={{
                                                margin: 10,
                                                display: "flex",
                                                justifyContent: "center",
                                              }}
                                            >
                                              <select
                                                value={getYear(date)}
                                                onChange={({
                                                  target: { value },
                                                }) => changeYear(value)}
                                              >
                                                {currentYear.map((option) => (
                                                  <option
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                ))}
                                              </select>

                                              <select
                                                value={months[getMonth(date)]}
                                                onChange={({
                                                  target: { value },
                                                }) =>
                                                  changeMonth(
                                                    months.indexOf(value)
                                                  )
                                                }
                                              >
                                                {months.map((option) => (
                                                  <option
                                                    key={option}
                                                    value={option}
                                                  >
                                                    {option}
                                                  </option>
                                                ))}
                                              </select>
                                            </div>
                                          )}
                                          className="form-control width-100"
                                          dateFormat="dd/MM/yyyy"
                                          selected={dateOfBirth}
                                          onChange={(e) => setDateOfBirth(e)}
                                          disabled={errorData?.Dob?.errorStatus !== '2'}
                                        />
                                      </div>
                                      <SpanError
                                        errorReason={
                                          errorData?.Dob?.errorReason
                                        }
                                      />
                                    </div>
                                    {/* Sex */}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputPhone"
                                      >
                                        {t("ee583624fabcb93a41504c81eaf561be")}{" "}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <select
                                        style={{
                                          border: `${
                                            errorData?.Sex?.errorStatus === '2' && "1px solid red"
                                          }`,
                                        }}
                                        className="form-control col-sm col-md-12"
                                        data-val="true"
                                        name="StateId"
                                        value={sex}
                                        onChange={(e) => setSex(e.target.value)}
                                        disabled={errorData?.Sex?.errorStatus !== '2'}
                                      >
                                        <option value="">
                                          {t(
                                            "c6be2b6191e6660291b6b0c92bd2f0df"
                                          )}
                                        </option>
                                        <option value={1} selected="">
                                          {t(
                                            "63889cfb9d3cbe05d1bd2be5cc9953fd"
                                          )}
                                        </option>
                                        <option value={2} selected="">
                                          {t(
                                            "b719ce180ec7bd9641fece2f920f4817"
                                          )}
                                        </option>
                                      </select>
                                      <SpanError
                                        errorReason={
                                          errorData?.Sex?.errorReason
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-12">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("f984cd4a2d763ce723eeaeb59d835bb9")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <textarea
                                        style={{
                                          border: `${
                                            errorData?.DobAddress?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control"
                                        id="inputFirstName"
                                        type="text"
                                        placeholder={t(
                                          "f984cd4a2d763ce723eeaeb59d835bb9"
                                        )}
                                        value={dobAddress}
                                        maxLength={200}
                                        // onPaste={handlePaste}
                                        // onBeforeInput={handleKeyPressForDOBAddress}
                                        onChange={(e) => {
                                          setDobAddress(e.target.value);
                                        }}
                                        disabled={errorData?.DobAddress?.errorStatus !== '2'}
                                      />
                                      <SpanError
                                        errorReason={
                                          errorData?.DobAddress?.errorReason
                                        }
                                      />
                                    </div>

                                    <div className="form-group col-md-6"></div>
                                    {/* <hr /> */}
                                    <h5
                                      style={{ textAlign: "left" }}
                                      className="col-12"
                                    >
                                      <b>{t("861a00de3a9b1a4751e4e3190944227e")}</b> 
                                    <hr />
                                    </h5>

                                    {/* Province */}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <select
                                        style={{
                                          border: `${
                                            errorData?.LivingAddress?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control col-sm col-md-12"
                                        data-val="true"
                                        name="StateId"
                                        value={
                                          errorData?.LivingAddress?.errorStatus !== '2' ? capitalId:null
                                        }
                                        // value={
                                        //   capitalId 
                                        // }
                                        // defaultValue={capitalId}
                                        onChange={capitalChangeHandel}
                                        ref={provinceRef}

                                        disabled={errorData?.LivingAddress?.errorStatus !== '2'}
                                      >
                                        <option value="0">
                                          {t(
                                            "c6be2b6191e6660291b6b0c92bd2f0df"
                                            // `${!errorData?.LivingAddress}`
                                          )}
                                        </option>
                                        {apicapital.map((capital) => (
                                          <option
                                            value={
                                              errorData?.LivingAddress?.errorStatus !== '2' 
                                                ? capital.Id
                                                : JSON.stringify(capital)
                                            }
                                            selected=""
                                          >
                                            {capital.Name}
                                          </option>
                                        ))}
                                      </select>
                                      <SpanError
                                        errorReason={
                                          errorData?.LivingAddress?.errorReason
                                        }
                                      />
                                    </div>
                                    {/* Form Group (last name)*/}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("e0bb2f66e5b61be3235bf8969979de19")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <select
                                        style={{
                                          border: `${
                                            errorData?.LivingAddress?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control col-sm col-md-12"
                                        data-val="true"
                                        name="StateId"
                                        // defaultValue={props.isExistCustomer ? props.customer.DistrictCode:districtCode}
                                        value={
                                          errorData?.LivingAddress?.errorStatus !== '2' ? districtId:null
                                        }
                                        onChange={districtChangeHandel}
                                        ref={districtRef}

                                        disabled={errorData?.LivingAddress?.errorStatus !== '2'}
                                      >
                                        <option value="0">
                                          {t(
                                            "c6be2b6191e6660291b6b0c92bd2f0df"
                                          )}
                                        </option>
                                        {apidistricts.map((districts) => (
                                          <option
                                            value={
                                              errorData?.LivingAddress?.errorStatus !== '2'
                                                ? districts.Id
                                                : JSON.stringify(districts)
                                            }
                                            selected=""
                                          >
                                            {districts.Name}
                                          </option>
                                        ))}
                                      </select>
                                      <SpanError
                                        errorReason={
                                          errorData?.LivingAddress?.errorReason
                                        }
                                      />
                                    </div>

                                    {/* Form Group (last name)*/}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("04beeb76f7acc97bb1e309f654fdee7a")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <select
                                        style={{
                                          border: `${
                                            errorData?.LivingAddress?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control col-sm col-md-12"
                                        data-val="true"
                                        name="StateId"
                                        value={
                                          errorData?.LivingAddress?.errorStatus !== '2' ? communeId:null
                                        }
                                        onChange={CommuneChnagehandel}
                                        ref={communeRef}

                                        disabled={errorData?.LivingAddress?.errorStatus !== '2'}
                                      >
                                        <option value="0">
                                          {t(
                                            "c6be2b6191e6660291b6b0c92bd2f0df"
                                          )}
                                        </option>
                                        {apicommune.map((commune) => (
                                          <option
                                            value={
                                              errorData?.LivingAddress?.errorStatus !== '2'
                                                ? commune.Id
                                                : JSON.stringify(commune)
                                            }
                                            selected=""
                                          >
                                            {commune.Name}
                                          </option>
                                        ))}
                                      </select>
                                      <SpanError
                                        errorReason={
                                          errorData?.LivingAddress?.errorReason
                                        }
                                      />
                                    </div>
                                    {/* Form Group (last name)*/}
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("abc63490c815af81276f930216c8d92b")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <select
                                        style={{
                                          border: `${
                                            errorData?.LivingAddress?.errorStatus === '2' &&
                                            "1px solid red"
                                          }`,
                                        }}
                                        className="form-control col-sm col-md-12"
                                        data-val="true"
                                        name="StateId"
                                        value={
                                          errorData?.LivingAddress?.errorStatus !== '2' ? villageId:null
                                        }
                                        onChange={villageChangeHandel}
                                        ref={villageRef}

                                        disabled={errorData?.LivingAddress?.errorStatus !== '2'}
                                      >
                                        <option value="0">
                                          {t(
                                            "c6be2b6191e6660291b6b0c92bd2f0df" 
                                          )}
                                        </option>
                                        {apiVillage.map((village) => (
                                          <option
                                            value={
                                              errorData?.LivingAddress?.errorStatus !== '2'
                                                ? village.Id
                                                : JSON.stringify(village)
                                            }
                                            selected=""
                                          >
                                            {village.Name}
                                          </option>
                                        ))}
                                      </select>
                                      <SpanError
                                        errorReason={
                                          errorData?.LivingAddress?.errorReason
                                        }
                                      />
                                    </div>
                                    {/* <div className="form-group col-md-6" /> */}
                                    {/* DOB Address */}
                                    
                                    <div className="form-group col-md-6">
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputFirstName"
                                      >
                                        {t("7385b9abf62f68950925436c288c6746")}
                                        <span style={{ float: "right" }}>
                                          {disable &&
                                            !otpVlaidate &&
                                            `${
                                              minutes < 10
                                                ? `0${minutes}`
                                                : minutes
                                            }:${
                                              seconds < 10
                                                ? `0${seconds}`
                                                : seconds
                                            }`}
                                        </span>
                                      </label>
                                      <span className="mandatory">*</span>
                                      <div className="input-group mb-3">
                                        <input
                                          type="number"
                                          className="form-control"
                                          placeholder={t(
                                            "7385b9abf62f68950925436c288c6746"
                                          )}
                                          aria-label="Recipient's username"
                                          aria-describedby="button-addon2"
                                          // disabled={props.isExistCustomer?true:disable}
                                          value={phoneNumber}
                                          disabled={
                                            otpVlaidate ? true : disable
                                          }
                                          onChange={(e) => {
                                            setPhoneNumber(e.target.value);
                                          }}
                                        />
                                        <button
                                          className={`btn btn-outline-secondary ${
                                            otpVlaidate
                                              ? "input-dark"
                                              : `${disable && "input-dark"}`
                                          }`}
                                          type="button"
                                          id="button-addon2"
                                          disabled={
                                            otpVlaidate ? true : disable
                                          }
                                          onClick={resendOTP}
                                        >
                                          {t(`${otpText}`)}
                                        </button>
                                      </div>
                                    </div>
                                    {/* Form Group (last name)*/}
                                    <div
                                      className="form-group col-md-6"
                                      style={{ marginBottom: 80 }}
                                    >
                                      <label
                                        className="small mb-1"
                                        htmlFor="inputLastName"
                                      >
                                        {t("2f96a82a2d4bb7206c760a26cdcb2cc8")}
                                      </label>
                                      <span className="mandatory">*</span>
                                      <div className="input-group mb-3">
                                        <input
                                          className="form-control"
                                          id="inputFirstName"
                                          type="text"
                                          placeholder={t(
                                            "2f96a82a2d4bb7206c760a26cdcb2cc8"
                                          )}
                                          onChange={(e) =>
                                            setOTP(e.target.value)
                                          }
                                          disabled={
                                            otpVlaidate ? true : submitdisable
                                          }
                                        />
                                        <button
                                          className={`btn btn-outline-secondary ${
                                            otpVlaidate
                                              ? "input-dark"
                                              : `${
                                                  submitdisable && "input-dark"
                                                }`
                                          }`}
                                          type="button"
                                          id="button-addon2"
                                          disabled={
                                            otpVlaidate ? true : submitdisable
                                          }
                                          onClick={verifyOTp}
                                        >
                                          {t(
                                            "c76fb95fc7cdcb4a12f2423da63cc61b"
                                          )}
                                        </button>
                                      </div>
                                    </div>
                                    <hr />
                                    <div className="col-12 fot-row row">
                                      <div className="d-flex flex-row justify-content-end col-12">
                                        <button
                                          className="btn btn-primary next"
                                          type="button"
                                          data-toggle="collapse"
                                          data-target="#collapseTwo"
                                          aria-expanded="false"
                                          aria-controls="collapseTwo"
                                          onClick={handelSubmit}
                                          disabled={!otpVlaidate}
                                        >
                                          {t(
                                            "a4d3b161ce1309df1c4e25df28694b7b"
                                          )}
                                        </button>
                                        {/* <a href="#" className="" /> */}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </section>
                    </form>
                  </div>
                </div>
              </div>
              <div style={{ margin: 30, width: "100%" }} />
            </div>
          </div>
        </main>

        <Footer></Footer>
      </>
    </div>
  );
}

export default RejectionApplication;

// to_convert
//  done !
