import axios from "axios";
import React, { useState, useEffect, useRef } from "react";
import { NavLink, Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import ModalUnAuthorised from "../Error-Span-Modal/ModalUnAuthorised";
import { BsEyeFill } from "react-icons/bs";
// import {useIdle} from '../NavigationPanel/useIdleTimeout'
import useIdle from "./useIdleTimeout";

function MidBar({ login, rejectionlogin }) {
  // console.log(login);
  // console.log(rejectionlogin);
  const { t } = useTranslation();
  const [classchange, setClassChange] = useState(false);
  const [existingName, setExistingName] = useState("");
  const [email, setEmail] = useState("");
  const [showprofile, setShowprofile] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [requestService, setRequestService] = useState(false);
  const [notificationCount, setnotificationCount] = useState(0);
  const [notificationDetails, setnotificationDetails] = useState([]);
  const [counter, setCounter] = useState(0);
  const [convertedDate, setconvertedDate] = useState("");
  const [idleTimeoutMessage, setIdleTimeoutMessage] = useState(true);

  const [idleMinutes, setIdleMinutes] = useState(2);
  const [idleSeconds, setIdleSeconds] = useState(30);
  const [firstTimeout, setFirstTimeout] = useState(true);
  const [error, setError] = useState("");
  const [idleMessage, setIdleMessage] = useState(true);

  const [errorblock, setErrorBlock] = useState(false);

  let clickRef = useRef();
  let navigate = useNavigate();
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

  const Masterkey = process.env.REACT_APP_OWCS_MASTER_SERVICE;

  const [idleErrorBlock, setIdleErrorBlock] = useState(false); // Assuming user starts as logged in

  const [timeoutId, setTimeoutId] = useState(null);
  const [firstFlag, setFirstFlag] = useState(true);
  // const [idlemessageButton, setIdlemessageButton] = useState(true)

  function notificationDate(dateString) {
    console.log(dateString);
    const [year, month, day] = dateString.split("-");
    const formattedDate = `${day}-${month}-${year}`;
    return formattedDate;
    }
    
  const handleIdle = () => {
    setIdleErrorBlock(true);
    setIdleMessage(true);
    setIdleMinutes(2);
    setIdleSeconds(30);
  }
  const {idleTimer} = useIdle({ onIdle: handleIdle, idleTime: 480 })

  const stay = () => {
    setIdleErrorBlock(false)
    idleTimer.reset()
  }

  const handleLogout = () => {
    // logout()
    setIdleErrorBlock(false)
    logoutHandel();
  }
  
  // const timeout = () => {
  //   const newtimeoutId = setTimeout(() => {
  //     console.log()
  //     setIdleErrorBlock(true);
  //     setIdleMinutes(0);
  //     setIdleSeconds(30);
      
  //   }, 15 * 1000);
  //   setTimeoutId(newtimeoutId)
  // }

    // console.log(timeoutId);


    // const resetTimeout = () => {
    //   // setIdleMinutes(0);
    //   // setIdleSeconds(30);
    //   console.log('listening');
    //   clearTimeout(timeoutId);
    //   timeout();
    // }
  

  // const events = ['keydown', 'click', 'scroll']; 
  
  // useEffect(() => 
  //   {
  //   if(!idleErrorBlock){
  //     if(firstFlag){
  //       resetTimeout();
  //       setFirstFlag(false);
  //     }

  //     events.forEach((event) => {
  //       window.addEventListener(event, resetTimeout);
  //     })
  //   }
  
  //     return () => {
  //       if(timeoutId) clearTimeout(timeoutId);
  //       events.forEach((event) => {
  //         window.removeEventListener(event, resetTimeout)
  //       })
  //     }
    
   
  // },[ timeoutId, ...events])
  

  
  useEffect(() => {

    const interval = setInterval(() => {
      if(idleSeconds > 0) setIdleSeconds(idleSeconds - 1);
      else if(idleSeconds == 0){
        if(idleMinutes == 0){
          setIdleMessage(false)
        }
        else{
          setIdleSeconds(59)
          setIdleMinutes(idleMinutes - 1);
        }
    }
    }, (1000));

    return () => {
      clearInterval(interval)
    }
    
  },[idleSeconds])

  // const checkInactivity = () => {
  //   const expireTime = sessionStorage.getItem('expireTime');

  //   if(expireTime < Date.now()){
  //     // console.log('logged out');
  //     setIdleErrorBlock(true);
  //   }
  // }

  

  

  

 

  

  const errorClose = (e) => {
    // console.log(e);
    
    // setErrorBlock(false);
    if(e){
      // setIdleMinutes(0);
      // setIdleSeconds(30)
      stay();


    }
    else{
      setIdleMessage(true);
      handleLogout();
    }
   
    
    if(idleErrorBlock){
      setIdleErrorBlock(false);
      // setIdlemessageFlag(true);
      }
    if(errorblock){
      setErrorBlock(false);
      } 
      // resetTimeout();
    //  if(!e){
    //   navigate("/login");
    //   sessionStorage.removeItem("accessToken");
    //   sessionStorage.removeItem("authToken");
    //   sessionStorage.removeItem("customerId");
    //   sessionStorage.removeItem("userId");
    //   sessionStorage.removeItem("userName");
    //   sessionStorage.removeItem("email");
    //   sessionStorage.removeItem("refreshToken");
    //  }


  };
  

  

  useEffect(() => {
    const outclickhandel = (e) => {
      if (!clickRef.current.contains(e.target)) {
        setShowAlert(false);
        setShowprofile(false);
        setRequestService(false);
      }
    };
    document.addEventListener("mousedown", outclickhandel);

    return () => {
      document.removeEventListener("mousedown", outclickhandel);
    }; 
  });

  useEffect(() => {
    if (sessionStorage.getItem("userName") === null) {
      if (sessionStorage.getItem("userId")) {
        const id = sessionStorage.getItem("userId");
        const data = {
          idNumber: id,
          userCode: id,
        };

        let config = {
          headers: {
            AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
          },
        };

        axios
          .post(
            `${LoginKey}/registration/checkExistingIdCardOnRegistration`,
            data,
            config
          )
          .then((response) => {
            if (response.data.status == 1) {
              setExistingName(
                response.data.responseObject.Data.Customer.LatinName
              );
              setEmail(response.data.responseObject.Data.Customer.Email);
              sessionStorage.setItem(
                "userName",
                response.data.responseObject.Data.Customer.LatinName
              );
              sessionStorage.setItem(
                "email",
                response.data.responseObject.Data.Customer.Email
              );
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    } else {
      setExistingName(sessionStorage.getItem("userName"));
      setEmail(sessionStorage.getItem("email"));
    }
    if (sessionStorage.getItem("customerId")) {
      const customerId = sessionStorage.getItem("customerId");
      const data = {
        // idNumber: id,
        // userCode: id,
        customer_id: customerId,
      };

      let config = {
        headers: {
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      axios
        .post(`${Masterkey}/getNotificationCount`, data, config)
        .then((response) => {
          console.log(response.status);
          setnotificationCount(response.data.newnotificationcount);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.status == 401 || err.code == "ERR_NETWORK") {
            setErrorBlock(true);
            setError("7f80a7038c33611c6a6f5f84eae30a8a");
          }
          console.log(err);
        });

      axios
        .post(`${Masterkey}/getNotificationDetails`, data, config)
        .then((response) => {
          setnotificationDetails(response.data);
        })
        .catch((err) => {
          console.log(err);
        });
    }

    if (sessionStorage.getItem("refreshToken")) {
      const data = {
        refreshToken: sessionStorage.getItem("refreshToken"),
      };

      let config = {
        headers: {
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };
      axios
        .post(`${LoginKey}/login/getAuthToken`, data, config)
        // .post(`http://localhost:9298/login/getAuthToken`, data, config)
        .then((response) => {
          sessionStorage.setItem(
            "authToken",
            response.data.responseObject.authToken
          );
          sessionStorage.setItem(
            "refreshToken",
            response.data.responseObject.refreshToken
          );
        })
        .catch((err) => {
          console.log(err);
        });
    } 

    setTimeout(() => {
      setCounter((counter) => counter + 1);
    }, 115000);
  }, [counter]);

  const logoutHandel = () => {
    let tokenss = sessionStorage.getItem("accessToken");
    let id = sessionStorage.getItem("userId");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };
    const reqObj = {
      refreshToken: sessionStorage.getItem("refreshToken"),
      authToken: sessionStorage.getItem("authToken"),
      userCode: id,
    };
    axios
      .post(`${LoginKey}/login/sessionLogout`, reqObj, config)
      // .post("http://localhost:9298/login/sessionLogout",reqObj, config)
      .then((response) => {
        console.log(response);
        navigate("/login");
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("customerId");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("refreshToken");
      });
  };

  const profileHandler = () => {
    navigate("/profile");
  }

  const changePasswordHandler = () => {
    navigate("/changepassword");
  }
  

  const notificationRead=(id)=>{
 let tokenss = sessionStorage.getItem("accessToken");
 let config = {
  headers: {
    Authorization: tokenss,
    AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
  },
};

axios
  .post(`${Masterkey}/manageNotificationCount`,{userApplicationNotificationId:id}, config)
  .then((response) => {
   
  })
  .catch((err) => {
    console.log(err);
  });
  }

  return (
    <div className="header-main bg-white" ref={clickRef}>
      <div className="container-fluid">
        {errorblock && (
          <ModalUnAuthorised
            error={t(`${error}`)}
            errorClose={errorClose}
            buttonText={false}
          ></ModalUnAuthorised>
        )}

        {idleErrorBlock && login && (
          <ModalUnAuthorised
            error= {idleMessage ? ` ${t('b99a0ba6078a67f87f8bd7fec0cbe43d')} ${idleMinutes} : ${idleSeconds}. ${t('92d97b03d514956058a3a3b7f2c460cd')} ` : `${t('06ce5f184800597b5c63eaccbc012b87')}`}
            errorClose={errorClose}
            buttonText={idleMessage}
          ></ModalUnAuthorised>
        )}

        



        {!login ? (
          <nav
            className="navbar navbar-expand-lg navbar-light w-100"
            id="header-navbar"
          >
           { rejectionlogin ? <Link className="navbar-brand ps-3" to={"/rejectionApplication"}>
              <img
                className="img-fluid logo"
                src={"/images/new_logo.png"}
                style={{ width: "100%", margin: "10px" }}
              ></img>
            </Link> : <Link className="navbar-brand ps-3" to={"/"}>
              <img
                className="img-fluid logo"
                src={"/images/new_logo.png"}
                style={{ width: "100%", margin: "10px" }}
              ></img>
            </Link> 
            }
 
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
              onClick={() => {
                setClassChange(!classchange);
              }}
            >
              <span className="navbar-toggler-icon" />
            </button>

            {!login ? (
             rejectionlogin ? <div
             className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0 navbar-collapse"
             id="navbarSupportedContent"
           >
             <ul className="navbar-nav ml-auto">
               
               <li className="nav-item">
                 <button
                   className="btn btn-icon btn-transparent-dark "
                   title="Profile"
                   id="navbarDropdownUserImage"
                   href="javascript:void(0);"
                   role="button"
                   data-toggle="dropdown"
                   aria-haspopup="true"
                   aria-expanded="false"
                   onClick={() => {
                     setShowprofile(!showprofile);
                     setShowAlert(false);
                     setRequestService(false);
                   }}
                 >
                   <img
                     width={29}
                     height={29}
                     src="https://img.icons8.com/ios-filled/50/user-male-circle.png"
                     alt="user-male-circle"
                   />
                 </button>
                 <div
                   className={`dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up  ${
                     showprofile && "show"
                   }`}
                   aria-labelledby="navbarDropdownUserImage"
                 >
                   <h6
                     className="dropdown-header d-flex align-items-center"
                     style={{ padding: "0.3rem" }}
                   >
                     <img
                       width={49}
                       height={29}
                       className="dropdown-user-img"
                       src="images/user.svg"
                     />
                     <div className="dropdown-user-details">
                       <div className="dropdown-user-details-name">
                         {existingName}
                       </div>
                       {/* <div className="dropdown-user-details-email">
                         {email==null ? "" : email}
                       </div> */}
                     </div>
                   </h6>
                   <div className="dropdown-divider" />
                   <div style={{margin: '5px 0px'}}>
                     {/* <button
                       className="dropdown-item"
                       style={{ width: "100%" }}
                     >
                       <div className="dropdown-item-icon">
                         <i data-feather="settings" />
                       </div>
                       Account
                     </button> */}
                    

                     {/* <button
                       className="KhmerOSsiemreaptext dropdown-item"
                       style={{ width: "100%" }}
                       onClick={profileHandler}
                     >
                       <div className="dropdown-item-icon">
                         <i data-feather="log-out" />
                       </div>
                       View Profile
                     </button> */}

                     {rejectionlogin ? '' : <button
                       className="KhmerOSsiemreaptext dropdown-item"
                       style={{ width: "100%" }}
                       onClick={changePasswordHandler}
                     >
                       <div className="dropdown-item-icon">
                         <i data-feather="log-out" />
                       </div>
                       {t("8f1e77e0d2be21da93cd4d9a939148f7")}
                     </button>}

                     <button
                       className="KhmerOSsiemreaptext dropdown-item"
                       style={{ width: "100%" }}
                       onClick={logoutHandel}
                     >
                       <div className="dropdown-item-icon">
                         <i data-feather="log-out" />
                       </div>
                       {t("0323de4f66a1700e2173e9bcdce02715")}
                     </button>
                   </div>
                 
                 </div>
                 
               </li>

 
             </ul>
           </div> : ''
            ) : (
              <></>
            )}
          </nav>
        ) : (
          <nav
            className="navbar navbar-expand navbar-light w-100"
            id="header-navbar"
          >
            <Link className="navbar-brand ps-3" to={"/dashboard"}>
              <img
                className="img-fluid logo"
                src={"/images/new_logo.png"}
                style={{ width: "100%", margin: "10px" }}
              ></img>
            </Link>
            {!login ? (
              <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarSupportedContent"
                aria-controls="navbarSupportedContent"
                aria-expanded="false"
                aria-label="Toggle navigation"
                onClick={() => {
                  setClassChange(!classchange);
                }}
              >
                <span className="navbar-toggler-icon" />
              </button>
            ) : (
              <></>
            )}

            {!login ? (
              <></>
            ) : (
              <div
                className="d-none d-md-inline-block form-inline ms-auto me-0 me-md-3 my-2 my-md-0 navbar-collapse"
                id="navbarSupportedContent"
              >
                <ul className="navbar-nav ml-auto">
                  <li className="nav-item" style={{ marginRight: "16px" }}>
                    {/* <button
                      className="btn btn-icon btn-transparent-dark "
                      title="Add New Service Request"
                      id="navbarDropdownUserImageN"
                      href="javascript:void(0);"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        // setRequestService(!requestService);
                        // setShowAlert(false)
                        // setShowprofile(false);
                        navigate("/serviceSelectorNew");
                      }}
                    >
                      Request for New Service
                      <img
                        width={24}
                        height={24}
                        src="https://img.icons8.com/material-two-tone/24/add-property.png"
                        alt="add-property"
                      />
                    </button>
                    <span className="badge badge-danger badge-counter nw">
                      Add{" "}
                    </span> */}

                    <button
                      className="KhmerOSsiemreaptext btn nw"
                      id="navbarDropdownUserImageNw"
                      href="javascript:void(0);"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        // setRequestService(!requestService);
                        // setShowAlert(false)
                        // setShowprofile(false);
                        navigate("/serviceSelectorNew");
                      }}
                    >
                      {t("e8cf0e32319e8f596f9da9673e916bf0")}
                      <img
                        width="30"
                        height="30"
                        src="/images/icons8-plus-30.png"
                        alt="plus"
                        style={{ marginLeft: "7px" }}
                      />
                    </button>

                    {/* <div
                      className={`dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up  ${
                        requestService && "show"
                      }`}
                      aria-labelledby="navbarDropdownDocs"
                    >
                      <Link
                        className="dropdown-item py-3"
                        to={"/legalizationWork"}
                      >
                        <div className="icon-stack bg-primary-soft text-primary mr-4">
                          <svg
                            className="svg-inline--fa fa-book-open fa-w-18"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="book-open"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 576 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M542.22 32.05c-54.8 3.11-163.72 14.43-230.96 55.59-4.64 2.84-7.27 7.89-7.27 13.17v363.87c0 11.55 12.63 18.85 23.28 13.49 69.18-34.82 169.23-44.32 218.7-46.92 16.89-.89 30.02-14.43 30.02-30.66V62.75c.01-17.71-15.35-31.74-33.77-30.7zM264.73 87.64C197.5 46.48 88.58 35.17 33.78 32.05 15.36 31.01 0 45.04 0 62.75V400.6c0 16.24 13.13 29.78 30.02 30.66 49.49 2.6 149.59 12.11 218.77 46.95 10.62 5.35 23.21-1.94 23.21-13.46V100.63c0-5.29-2.62-10.14-7.27-12.99z"
                            />
                          </svg>
                         
                        </div>
                        <div>Legalization Work</div>
                      </Link>
                      <div className="dropdown-divider m-0" />
                      <Link
                        className="dropdown-item py-3"
                        to={"/civilRegistrationWork"}
                      >
                        <div className="icon-stack bg-primary-soft text-primary mr-4">
                          <svg
                            className="svg-inline--fa fa-code fa-w-20"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="code"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 640 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M278.9 511.5l-61-17.7c-6.4-1.8-10-8.5-8.2-14.9L346.2 8.7c1.8-6.4 8.5-10 14.9-8.2l61 17.7c6.4 1.8 10 8.5 8.2 14.9L293.8 503.3c-1.9 6.4-8.5 10.1-14.9 8.2zm-114-112.2l43.5-46.4c4.6-4.9 4.3-12.7-.8-17.2L117 256l90.6-79.7c5.1-4.5 5.5-12.3.8-17.2l-43.5-46.4c-4.5-4.8-12.1-5.1-17-.5L3.8 247.2c-5.1 4.7-5.1 12.8 0 17.5l144.1 135.1c4.9 4.6 12.5 4.4 17-.5zm327.2.6l144.1-135.1c5.1-4.7 5.1-12.8 0-17.5L492.1 112.1c-4.8-4.5-12.4-4.3-17 .5L431.6 159c-4.6 4.9-4.3 12.7.8 17.2L523 256l-90.6 79.7c-5.1 4.5-5.5 12.3-.8 17.2l43.5 46.4c4.5 4.9 12.1 5.1 17 .6z"
                            />
                          </svg>
                          
                        </div>
                        <div>Civil Registration Work</div>
                      </Link>
                      <div className="dropdown-divider m-0" />
                      <Link
                        className="dropdown-item py-3"
                        to={"/tourismLicenseWork"}
                      >
                        <div className="icon-stack bg-primary-soft text-primary mr-4">
                          <svg
                            className="svg-inline--fa fa-file fa-w-12"
                            aria-hidden="true"
                            focusable="false"
                            data-prefix="fas"
                            data-icon="file"
                            role="img"
                            xmlns="http://www.w3.org/2000/svg"
                            viewBox="0 0 384 512"
                            data-fa-i2svg=""
                          >
                            <path
                              fill="currentColor"
                              d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm160-14.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                            />
                          </svg>
                          
                        </div>
                        <div>Tourism License</div>
                      </Link>
                    </div> */}
                  </li>

                  <li className="nav-item" style={{ marginRight: "16px" }}>
                    <button
                      className="btn btn-icon btn-transparent-dark "
                      title="Bell"
                      id="navbarDropdownUserImage"
                      href="javascript:void(0);"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setShowAlert(!showAlert);
                        setShowprofile(false);
                        setRequestService(false);
                      }}
                    >
                      <img
                        width={28}
                        height={28}
                        src="https://img.icons8.com/ios/50/appointment-reminders--v1.png"
                        alt="appointment-reminders--v1"
                      />
                    </button>
                    <span className="badge badge-danger badge-counter">
                      {notificationCount==0?"":notificationCount}
                    </span>
                    <div
                      className={`dropdown-list dropdown-menu dropdown-menu-right shadow animated--grow-in  ${
                        showAlert && "show"
                      }`}
                      aria-labelledby="alertsDropdown"
                      style={{ "min-width": "425px" }}
                    >
                      <h6 className="dropdown-header">
                        <div className="dropdown-user-details">
                          <div className="dropdown-user-details-name">
                            {t("7ecf1b4d119c86aa2e6c03684492ac1d")}
                          </div>
                        </div>
                      </h6>
                      <hr />
                      <div style={{ height: "320px", "overflow-y": "scroll" }}>
                        {notificationDetails.map((notification) => (
                          <>
                            <a
                              className="dropdown-item d-flex align-items-center"
                             
                              style={{height:"60px"}}
                            >
                              <div className="mr-3">
                                <div className="icon-circle bg-primary">
                                  <svg
                                    className="svg-inline--fa fa-file-alt fa-w-12 text-white"
                                    aria-hidden="true"
                                    focusable="false"
                                    data-prefix="fas"
                                    data-icon="file-alt"
                                    role="img"
                                    xmlns="http://www.w3.org/2000/svg"
                                    viewBox="0 0 384 512"
                                    data-fa-i2svg=""
                                  >
                                    <path
                                      fill="currentColor"
                                      d="M224 136V0H24C10.7 0 0 10.7 0 24v464c0 13.3 10.7 24 24 24h336c13.3 0 24-10.7 24-24V160H248c-13.2 0-24-10.8-24-24zm64 236c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-64c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12v8zm0-72v8c0 6.6-5.4 12-12 12H108c-6.6 0-12-5.4-12-12v-8c0-6.6 5.4-12 12-12h168c6.6 0 12 5.4 12 12zm96-114.1v6.1H256V0h6.1c6.4 0 12.5 2.5 17 7l97.9 98c4.5 4.5 7 10.6 7 16.9z"
                                    />
                                  </svg>
                                  <i className="fas fa-file-alt text-white"></i>
                                </div>
                              </div>
                              <div>
                                <div className="KhmerOSsiemreaptext small text-gray-500">
                                  {notificationDate(notification.created_on)}
                                </div>
                                <div
                                  style={{
                                    display: "flex",
                                    columnGap: "100px",
                                  }}
                                >
                                  <div className="KhmerOSsiemreaptext font-weight-bold">
                                    {notification.notification_text}
                                  </div>
                                  <BsEyeFill
                                    style={{ cursor: "pointer" }}
                                    onClick={() =>{navigate("/viewService");sessionStorage.setItem("isVisitFromNotification",true);sessionStorage.setItem("serviceDiteals",JSON.stringify({applicationId:notification.user_application_id,serviceId:notification.serviceId}) );notificationRead(notification.user_application_notification_id)}}
                                  />
                                </div>
                              </div>
                            </a>
                            <hr />
                          </>
                        ))}
                      </div>

                      {/* <a
                        className="dropdown-item d-flex align-items-center"
                        href="#"
                      >
                        <div className="mr-3">
                          <div className="icon-circle bg-success">
                            <svg
                              className="svg-inline--fa fa-donate fa-w-16 text-white"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="donate"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 512 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M256 416c114.9 0 208-93.1 208-208S370.9 0 256 0 48 93.1 48 208s93.1 208 208 208zM233.8 97.4V80.6c0-9.2 7.4-16.6 16.6-16.6h11.1c9.2 0 16.6 7.4 16.6 16.6v17c15.5.8 30.5 6.1 43 15.4 5.6 4.1 6.2 12.3 1.2 17.1L306 145.6c-3.8 3.7-9.5 3.8-14 1-5.4-3.4-11.4-5.1-17.8-5.1h-38.9c-9 0-16.3 8.2-16.3 18.3 0 8.2 5 15.5 12.1 17.6l62.3 18.7c25.7 7.7 43.7 32.4 43.7 60.1 0 34-26.4 61.5-59.1 62.4v16.8c0 9.2-7.4 16.6-16.6 16.6h-11.1c-9.2 0-16.6-7.4-16.6-16.6v-17c-15.5-.8-30.5-6.1-43-15.4-5.6-4.1-6.2-12.3-1.2-17.1l16.3-15.5c3.8-3.7 9.5-3.8 14-1 5.4 3.4 11.4 5.1 17.8 5.1h38.9c9 0 16.3-8.2 16.3-18.3 0-8.2-5-15.5-12.1-17.6l-62.3-18.7c-25.7-7.7-43.7-32.4-43.7-60.1.1-34 26.4-61.5 59.1-62.4zM480 352h-32.5c-19.6 26-44.6 47.7-73 64h63.8c5.3 0 9.6 3.6 9.6 8v16c0 4.4-4.3 8-9.6 8H73.6c-5.3 0-9.6-3.6-9.6-8v-16c0-4.4 4.3-8 9.6-8h63.8c-28.4-16.3-53.3-38-73-64H32c-17.7 0-32 14.3-32 32v96c0 17.7 14.3 32 32 32h448c17.7 0 32-14.3 32-32v-96c0-17.7-14.3-32-32-32z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div>
                          <div className="small text-gray-500">
                            December 7, 2019
                          </div>
                          $290.29 has been deposited into your account!
                        </div>
                      </a>
                      <hr />
                      <a
                        className="dropdown-item d-flex align-items-center"
                        href="#"
                      >
                        <div className="mr-3">
                          <div className="icon-circle bg-warning">
                            <svg
                              className="svg-inline--fa fa-exclamation-triangle fa-w-18 text-white"
                              aria-hidden="true"
                              focusable="false"
                              data-prefix="fas"
                              data-icon="exclamation-triangle"
                              role="img"
                              xmlns="http://www.w3.org/2000/svg"
                              viewBox="0 0 576 512"
                              data-fa-i2svg=""
                            >
                              <path
                                fill="currentColor"
                                d="M569.517 440.013C587.975 472.007 564.806 512 527.94 512H48.054c-36.937 0-59.999-40.055-41.577-71.987L246.423 23.985c18.467-32.009 64.72-31.951 83.154 0l239.94 416.028zM288 354c-25.405 0-46 20.595-46 46s20.595 46 46 46 46-20.595 46-46-20.595-46-46-46zm-43.673-165.346l7.418 136c.347 6.364 5.609 11.346 11.982 11.346h48.546c6.373 0 11.635-4.982 11.982-11.346l7.418-136c.375-6.874-5.098-12.654-11.982-12.654h-63.383c-6.884 0-12.356 5.78-11.981 12.654z"
                              />
                            </svg>
                          </div>
                        </div>
                        <div>
                          <div className="small text-gray-500">
                            December 2, 2019
                          </div>
                          Spending Alert: We've noticed unusually high spending
                          for your account.
                        </div>
                      </a>
                      <hr /> */}
                      <a
                        className="dropdown-item text-center small text-gray-500"
                        href="#"
                      >
                        {t("6126b961015a0c3a64697db377e22226")}
                      </a>
                    </div>
                  </li>

                  <li className="nav-item">
                    <button
                      className="btn btn-icon btn-transparent-dark "
                      title="Profile"
                      id="navbarDropdownUserImage"
                      href="javascript:void(0);"
                      role="button"
                      data-toggle="dropdown"
                      aria-haspopup="true"
                      aria-expanded="false"
                      onClick={() => {
                        setShowprofile(!showprofile);
                        setShowAlert(false);
                        setRequestService(false);
                      }}
                    >
                      <img
                        width={29}
                        height={29}
                        src="https://img.icons8.com/ios-filled/50/user-male-circle.png"
                        alt="user-male-circle"
                      />
                    </button>
                    <div
                      className={`dropdown-menu dropdown-menu-right border-0 shadow animated--fade-in-up  ${
                        showprofile && "show"
                      }`}
                      aria-labelledby="navbarDropdownUserImage"
                    >
                      <h6
                        className="dropdown-header d-flex align-items-center"
                        style={{ padding: "0.3rem" }}
                      >
                        <img
                          width={49}
                          height={29}
                          className="dropdown-user-img"
                          src="images/user.svg"
                        />
                        <div className="dropdown-user-details">
                          <div className="dropdown-user-details-name">
                            {existingName}
                          </div>
                          {/* <div className="dropdown-user-details-email">
                            {email==null ? "" : email}
                          </div> */}
                        </div>
                      </h6>
                      <div className="dropdown-divider" />
                      <div style={{margin: '5px 0px'}}>
                        {/* <button
                          className="dropdown-item"
                          style={{ width: "100%" }}
                        >
                          <div className="dropdown-item-icon">
                            <i data-feather="settings" />
                          </div>
                          Account
                        </button> */}
                       

                        {/* <button
                          className="KhmerOSsiemreaptext dropdown-item"
                          style={{ width: "100%" }}
                          onClick={profileHandler}
                        >
                          <div className="dropdown-item-icon">
                            <i data-feather="log-out" />
                          </div>
                          View Profile
                        </button> */}

                        <button
                          className="KhmerOSsiemreaptext dropdown-item"
                          style={{ width: "100%" }}
                          onClick={changePasswordHandler}
                        >
                          <div className="dropdown-item-icon">
                            <i data-feather="log-out" />
                          </div>
                          {t("8f1e77e0d2be21da93cd4d9a939148f7")}
                        </button>

                        <button
                          className="KhmerOSsiemreaptext dropdown-item"
                          style={{ width: "100%" }}
                          onClick={logoutHandel}
                        >
                          <div className="dropdown-item-icon">
                            <i data-feather="log-out" />
                          </div>
                          {t("0323de4f66a1700e2173e9bcdce02715")}
                        </button>
                      </div>
                    
                    </div>
                    
                  </li>
                </ul>
              </div>
            )}
          </nav>
        )}
      </div>
    </div>
  );
}

export default MidBar;

// to_convert
// Spending Alert: We've noticed unusually high spending for your account
