import React, { useContext, useEffect, useState } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import axios from "axios";
import { OthersComponentContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";

function SelfDetails({ customer }) {
  const { t } = useTranslation();
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);
  const [homeNO, setHomeNo] = useState("");
  const [streetNO, setStreetNO] = useState("");
  const [capitalId, setCaptialId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [villageId, setVillageId] = useState("");
  const { setStreetHome } = useContext(OthersComponentContext);
  const currentYear = range(1850, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    setCaptialId(customer.CapitalId);
    setDistrictId(customer.DistrictId);
    setCommuneId(customer.CommuneId);
    setVillageId(customer.VillageId);
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    //api for get capital from database
    axios
      .post(`${MasterKey}/getAllCapital`, { userCode: "1" }, config)
      .then((response) => {
        if (response.data.status === "1") {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
    // api for get district from database
    axios
      .post(
        `${MasterKey}/getAllDistrictByCapital`,
        {
          userCode: "string",
          capitalId: capitalId,
          online: true,
        },
        config
      )
      .then((response) => {
        if (response.data.status === "1") {
          setApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get commune from database
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: districtId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /// api for get villages from database
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: communeId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [capitalId, districtId, communeId, villageId]);

  useEffect(() => {
    const streetHome = {
      streetNO: streetNO,
      homeNO: homeNO,
    };
    setStreetHome(streetHome);
  }, [streetNO, homeNO]);

  console.log(customer.CapitalId)
  console.log(customer.DistrictId)

  return (
    <div className="form-row">
      {/* <!-- Form Group (first name)--> */}

      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
          {t("dae903caf35016e727adebc1e3c18b2a")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className="KhmerOSsiemreaptext form-control input-dark"
          id="inputUsername"
          type="text"
          placeholder={t("504c46e2c369d441b6eb6c581101fc85")}
          disabled
          value={customer.IdCard}
        />
      </div>
      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("10803b83a68db8f7e7a33e3b41e184d0")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <DatePicker
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {currentYear.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          // showIcon
          // toggleCalendarOnIconClick
          disabled
          className={`KhmerOSsiemreaptext form-control input-dark`}
          dateFormat="dd/MM/yyyy"
          selected={customer.Dob}
        />
      </div>

      {/* <div className="form-group col-md-6">
        <label className="small mb-1" for="inputOrgName">
          ID Card (Front Side) <span style={{ color: "red" }}>*</span>
        </label>
        <div className="text-left">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVR4nO2by04UQRSGS0x0IbjyAvgG+BAgKwlG4yXGNyC6QBcgIa4UcOnlKYwrCK7HhRNXRrxtjSi606jgJZoonyk5kxQEenqqq6erp8+X9GrO+avqn6ozU91dxiiKoiiKoiiKoiiK0nbwRL8qQQ3MSKszSmfgFtTAHAwERoEPwHtgpFl8pWF7A61xDVaaxZcC4ARQA777Fv4kWjUwMN9kbKN5mTdLzpjN7Y2IiSvA8TYY6DKTx8yz/AImgL7A+v/JKz6lZh8wKWMk6EwEHoroRDDRyAx0tK+KfC2kqK0Plt5govEa2CvyayFFt+0w8Bio56UfKj7v/ngLAnXgkafmTeB3Q9vHQMFqzPn0oZl+vIJmU1kIwVoVDZzbOgM9sRqzpTSQQDUwNkpRA2OmFEs4ZtTAjGgNzIjWwIzoEu5UA4FD9lYR8EzuKdprCbgBHDSREGUNBM7ZHUPCH+JV4IyJgOhqIBvmrYvMPDAI7JNrCFiQz/4Cp9P2JwStjNebLIJsLNvGzJtMiJuSmK/AgTT9qYqBM5I+nyJ2UWKvmwKJqgYCzyV9MEU7xyR2yVTEwHqzGugs3+4U7fTkcXuqVWJbwl9aMHC/xH5O058s5DXe4ILAU0kfShE7LLFPKmEg6WrgNUlfSNHOA4mdNgUSWw08DHwSiamEuGmJ+Wj/+pgCiWoJW4BTwB+RWZRf2265hp2ZZ2NOmoKJzkCL3aY5u5HtWE+zCxGtYCTpm1Bk3QuzYd5yivEsV2orR5MaCOwC7jj9fQGMAwPOXvgocBl46cTdtrmmINpmYDOAu5JqX9gZA7oSYruAi87LPbdMlQ0EzjrmDbaQN+SYWMjtrcJrILAXeCdpYx7tXZLct8CenfqTBZ/xtq0GAhecmtfl0d5u4JVonO9YA3cCuC8p48YT4Ipo3PPVyNB24Qa+kZSBDG3aX2fLa1+NDG0XXgN/SkpPhjYbt7Z++GqUwcC6vhtTxDcSOYXXwLJTeA0sO1oDIzSw8WAo6AGbGAGOyFhXQ4raM2SJD8Y7BecBf9CDNvboKbLJtyd5+k2HAfSLeY0X38MeOnTeMKgCM0HN2zITa4HPeMRCvsddFUVRFEVRFEVRFEVRFJOdf+dxttTxQOFBAAAAAElFTkSuQmCC" alt="" />
        </div>
      </div>

      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputOrgName">
          ID Card (Back Side) <span style={{ color: "red" }}>*</span>
        </label>
        <div className="text-left">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVR4nO2by04UQRSGS0x0IbjyAvgG+BAgKwlG4yXGNyC6QBcgIa4UcOnlKYwrCK7HhRNXRrxtjSi606jgJZoonyk5kxQEenqqq6erp8+X9GrO+avqn6ozU91dxiiKoiiKoiiKoiiK0nbwRL8qQQ3MSKszSmfgFtTAHAwERoEPwHtgpFl8pWF7A61xDVaaxZcC4ARQA777Fv4kWjUwMN9kbKN5mTdLzpjN7Y2IiSvA8TYY6DKTx8yz/AImgL7A+v/JKz6lZh8wKWMk6EwEHoroRDDRyAx0tK+KfC2kqK0Plt5govEa2CvyayFFt+0w8Bio56UfKj7v/ngLAnXgkafmTeB3Q9vHQMFqzPn0oZl+vIJmU1kIwVoVDZzbOgM9sRqzpTSQQDUwNkpRA2OmFEs4ZtTAjGgNzIjWwIzoEu5UA4FD9lYR8EzuKdprCbgBHDSREGUNBM7ZHUPCH+JV4IyJgOhqIBvmrYvMPDAI7JNrCFiQz/4Cp9P2JwStjNebLIJsLNvGzJtMiJuSmK/AgTT9qYqBM5I+nyJ2UWKvmwKJqgYCzyV9MEU7xyR2yVTEwHqzGugs3+4U7fTkcXuqVWJbwl9aMHC/xH5O058s5DXe4ILAU0kfShE7LLFPKmEg6WrgNUlfSNHOA4mdNgUSWw08DHwSiamEuGmJ+Wj/+pgCiWoJW4BTwB+RWZRf2265hp2ZZ2NOmoKJzkCL3aY5u5HtWE+zCxGtYCTpm1Bk3QuzYd5yivEsV2orR5MaCOwC7jj9fQGMAwPOXvgocBl46cTdtrmmINpmYDOAu5JqX9gZA7oSYruAi87LPbdMlQ0EzjrmDbaQN+SYWMjtrcJrILAXeCdpYx7tXZLct8CenfqTBZ/xtq0GAhecmtfl0d5u4JVonO9YA3cCuC8p48YT4Ipo3PPVyNB24Qa+kZSBDG3aX2fLa1+NDG0XXgN/SkpPhjYbt7Z++GqUwcC6vhtTxDcSOYXXwLJTeA0sO1oDIzSw8WAo6AGbGAGOyFhXQ4raM2SJD8Y7BecBf9CDNvboKbLJtyd5+k2HAfSLeY0X38MeOnTeMKgCM0HN2zITa4HPeMRCvsddFUVRFEVRFEVRFEVRFJOdf+dxttTxQOFBAAAAAElFTkSuQmCC" alt=""/>
        </div>
      </div> */}

      {/* <!-- Form Group (last name)--> */}


      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
          {t("dbcc71284f11a659aa72716396584a4c")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className={`KhmerOSsiemreaptext form-control input-dark`}
          id="inputPhone"
          type="text"
          placeholder={t("dbcc71284f11a659aa72716396584a4c")}
          disabled
          value={customer.KhmerName}
        />
      </div>

      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("4ac58405bbbe783528ee33e7ac3fcc5c")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className={`KhmerOSsiemreaptext form-control input-dark`}
          id="inputFirstName"
          type="text"
          placeholder={t("4ac58405bbbe783528ee33e7ac3fcc5c")}
          disabled
          value={customer.LatinName}
        />
      </div>

      {/* <!-- Form Group (last name)--> */}


      {/* <!-- Form Group (phone number)--> */}
      {/* <div className="form-group col-md-6">
        <label className="small mb-1" for="inputPhone">
          Nationality <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`form-control col-sm col-md-12  input-dark`}
          data-val="true"
          name="StateId"
          disabled
          value={33}
        >
          <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          <option value="33" selected="">
            Cambodia
          </option>
        </select>
      </div> */}
      {/* <div className="form-group col-md-6"></div> */}
      <hr />
      <h5 style={{ textAlign: "left", color: "#fff", marginTop: "20px" }} className="col-12">
        <b>{t("861a00de3a9b1a4751e4e3190944227e")}</b>
      </h5>
      <hr style={{ width: "100%" }} />

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("c1a3626600b7d8a8b3e0b2474ab5c202")}
        </label>
        <input
          className={`KhmerOSsiemreaptext form-control `}
          id="inputFirstName"
          type="text"
          placeholder={t("c1a3626600b7d8a8b3e0b2474ab5c202")}
          onChange={(e) => setHomeNo(e.target.value)}
        />
      </div>

      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("070a6f943470aec6d12c7090f290e10f")}
        </label>
        <input
          className={`KhmerOSsiemreaptext form-control `}
          id="inputFirstName"
          type="text"
          placeholder={t("070a6f943470aec6d12c7090f290e10f")}
          onChange={(e) => setStreetNO(e.target.value)}
        />
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`KhmerOSsiemreaptext form-control col-sm col-md-12  input-dark`}
          data-val="true"
          name="StateId"
          value={customer.CapitalId}
          disabled
        //   onChange={(e)=>setCaptialId(e.target.value)}
        >
          <option value="" className={`KhmerOSsiemreaptext`}>{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apicapital.map((capital) => (
            <option value={capital.Id} key={capital.Id} className={`KhmerOSsiemreaptext`}>
              {capital.Name}
            </option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
          data-val="true"
          name="StateId"
          disabled
          value={customer.DistrictId}
        //   onChange={(e)=>setDistrictId(e.target.value)}
        >
          <option value="" className={`KhmerOSsiemreaptext`}>{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apidistricts.map((districts) => (
            <option value={districts.Id} key={districts.Id} className={`KhmerOSsiemreaptext`}>
              {districts.Name}
            </option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("04beeb76f7acc97bb1e309f654fdee7a")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`KhmerOSsiemreaptext form-control col-sm col-md-12  input-dark`}
          data-val="true"
          name="StateId"
          disabled
          value={customer.CommuneId}
        //   onChange={(e)=>setCommuneId(e.target.value)}
        >
          <option value="" className={`KhmerOSsiemreaptext`}>{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apicommune.map((commune) => (
            <option value={commune.Id} className={`KhmerOSsiemreaptext`}>{commune.Name}</option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
          {t("abc63490c815af81276f930216c8d92b")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
          data-val="true"
          name="StateId"
          disabled
          value={customer.VillageId}
        >
          <option value="" className={`KhmerOSsiemreaptext`}>{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apiVillage.map((village) => (
            <option value={village.Id} key={village.Id} className={`KhmerOSsiemreaptext`}>
              {village.Name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}

export default SelfDetails;

// to_convert
// checked
