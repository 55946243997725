import React from "react";
import { useTranslation } from "react-i18next";
function ModalError({ error, errorClose, success}) {
  const { t } = useTranslation();
  return (
    <div className={`  loderdiv`}>
      <div
        className={`modal fade  show modal-img`}
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: ` block` }}
      >
        <div className="modal-dialog mt-15" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ padding: "0"}}
                
              >
                {success ? `${t("8f1e77e0d2be21da93cd4d9a939148f7")}` : ''} 
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>

            <div className="modal-body">
              <div className="row justify-content-center ">
                <div className={success ? "col-3" : "col-2"}>
                  {success ?  <img
                    width="48" height="48"
                    src="https://img.icons8.com/color/96/000000/ok--v2.png"
                    className="fit-image"
                  /> :  <img width="48" height="48" src="https://img.icons8.com/color/48/cancel--v1.png" alt="cancel--v1" />}
                 
                </div>
              </div>
              <div className="KhmerOSsiemreaptext col-md-12 col-lg-12 mt-2 text-center text-black">
                {/* {t("1769908af5b99c90bf8f8fcea46ba6da")} */}
                {t(`${error}`)}
                {/* {success ? `${t(`${error}`)}` : ''} */}
              </div>
              <br />
              <div className="row" style={{ marginBottom: "30px" }}></div>
            </div>

            <div className="modal-footer text-center">
              <div className="col-12 fot-row row">
                <div className="d-flex flex-row justify-content-end col-12">
                  <button
                    type="button"
                    className="KhmerOSsiemreaptext btn btn-secondary"
                    data-dismiss="modal"
                    onClick={success ? () => errorClose(true) : () => errorClose(false)}
                  >
                  {success ?`${t("fd9242b35808986c4f749114e90875ec")}` :  `${t("d3d2e617335f08df83599665eef8a418")}`}
                  </button>
                  <a href="#" className=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalError;
