import i18n from "i18next"
import languageDetector from "i18next-browser-languagedetector"
import {initReactI18next} from "react-i18next"
import global_en from "./translation/en/global.json"
import global_kh from "./translation/kh/global.json"

i18n.use(languageDetector).use(initReactI18next).init(
    {
        debug:true,
        lng:"kh",
        resources:{
            kh:{
                translation:global_kh
            },
            en:{
                translation:global_en
            },
          
        }
    }
)