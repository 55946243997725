import axios from "axios";
import Navigation from "./NavigationPanel/Navigation";

import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MdDelete } from "react-icons/md";
import { BsEyeFill } from "react-icons/bs";

import { useTranslation } from "react-i18next";
import ModalError from "./Error-Span-Modal/ModalError";
import SuccessModal from "./Error-Span-Modal/SuccessModal";

function ChangePassword(){
  const { t } = useTranslation();
    const isLoginpage = true;
    const isLogedin = true;
    const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

    const [error, setError] = useState("");
    const [errorblock, setErrorBlock] = useState(false);
    const [errorClass, setErrorClass] = useState("");
    const [modalError, setModalError] = useState(false);
    const [modalSuccess, setModalSuccess] = useState(false);


    const [oldPassword, setOldPassword] = useState('');
    const [newPassword, setNewPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [idCardNumber, setIdCardNumber] = useState();

    const navigate = useNavigate();
    const [isAuthenticated, setIsAuthenticated] = useState(true);
    useEffect(() => {
      if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
        console.log('accesstoken', sessionStorage.getItem('accessToken'))
        console.log('authtyoken', sessionStorage.getItem('authToken'))
        setIsAuthenticated(true);
      }
      else{
        console.log('not verified')
        setIsAuthenticated(false);
      }
    },[])
   
    
    
    useEffect(() => {
      if (!isAuthenticated) {
        console.log('here to navigate')
        navigate('/');
      }
    }, [isAuthenticated]);

    useEffect(() => {
        if (sessionStorage.getItem("userName") != null) {
            if (sessionStorage.getItem("userId")) {
              const id = sessionStorage.getItem("userId");
              const data = {
                idNumber: id,
                userCode: id,
              };
      
              let config = {
                headers: {
                  AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
                },
              };
      
              axios
                .post(
                  `${LoginKey}/registration/checkExistingIdCardOnRegistration`,
                  data,
                  config
                )
                .then((response) => {
                  if (response.data.status == 1) {
                    
                    const customerData = response.data.responseObject.Data.Customer; // Check the structure of your response here
  
                    setIdCardNumber(customerData.IdCard)
  
                  } else {
                  }
                })
                .catch((err) => {
                  console.log(err);
                });
            }
          } else {
          //   setExistingName(sessionStorage.getItem("userName"));
          //   setEmail(sessionStorage.getItem("email"));
          }
    },[])

    function changeOldPassword(){
        const loginData = {
            userCode: idCardNumber,
            password: oldPassword,
        };

        if (oldPassword === "" || newPassword === "" || confirmPassword === "") {
            setError("2d13aa4a4d1a2a0dedf1a03323d294b8");
            errorHandel(0);
            return;
        }
        if (newPassword != confirmPassword) {
          console.log('new!=confirm')
          setError("8c6e407ab1ae8c30ee8cd92d0eca90d7");
          errorHandel(0);
          return;
        }
        else{
        axios
        .post(`${LoginKey}/login/citizenLogin`, loginData)
        .then((response) => {
            
            if (
              response.data.status == 1 && response.data.responseObject.citizenLoginResponse != undefined
            ) {
                if (
                response.data.responseObject.citizenLoginResponse
                    .citizenLoginDetailsResponseData.result.status == 1
                ) {
                 

                  if(oldPassword == newPassword){
                    console.log('new!=confirm')
                    setError("a23e6ee03cbc285874e8f1863b41aeb8");
                    errorHandel(0);
                    return;
                  }
                  
                  if (newPassword.length < 6) {
                    setError("c957da28ed3428f64bdabb32b0197d87");
                    errorHandel(0);
                    return;
                  }
                  else{
                      handleFormSubmit();
                    //   const hashedPassword = bcrypt.hashSync(newPassword, PasswordKey);
                    //   const registerData = {
                    //     ...props.registerDataA,
                    //     password: newPassword,
                    //   };
                    //   setIsloading(true);
                      const forgotData = {
                        code: idCardNumber,
                        password: newPassword,
                      };
                    //   console.log('here')
              
                      // forgot password change service call
                      axios
                        .post(`${LoginKey}/login/resetpassword`, forgotData)
                        .then((response) => {
                        //   setIsloading(false);
                        // console.log(response)
              
                          if (response.data.responseObject.ErrorMessage == null) {
                            // props.handelclick(2);
                            setError("41980e9b51cf92345135030789a5be73");
                            errorHandel(0);
                            setModalSuccess(true);
    
                          } else {
                            setError(response.data.responseObject.ErrorMessage);
                            errorHandel(0);
                          }
                        })
                        .catch((err) => {
                        //   setIsloading(false);
                          console.log(err);
                        });
              
                      //api for update password
                  }
                  } 
            }
               
            
            else{
              setError("0ef5a9dd1c9dc036e14d9452a9d9ead6");
              errorHandel(0);
              return;  
            }
            
            })
        .catch((err) => {
        console.log(err)
        });
        }

    
          
          
    }

    const handleFormSubmit = () => {
        if(newPassword==""){
        //   setError("d351d57d7bafd005cdaabd11a85da85d");
        //   errorHandel(0)
          return
        }
        if (newPassword.length < 6) {
        //   setError("c957da28ed3428f64bdabb32b0197d87");
        //   errorHandel(0);
          return;
        } else {
        //   setError("");
        //   setErrorBlock(false);
        }
    };

    const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
        setErrorClass("errorMessage");
    } else {
        setErrorClass("successMessage");
    }
    // setTimeout(()=>{
    //   setErrorBlock(false)
    // },4000)
    };

    const erroBlockHandel = () => {
    setErrorBlock(false);
    };
    const errorClose = (e) => {
      if(e){
        setModalSuccess(false);
        navigate('/login');
        sessionStorage.removeItem("accessToken");
        sessionStorage.removeItem("authToken");
        sessionStorage.removeItem("customerId");
        sessionStorage.removeItem("userId");
        sessionStorage.removeItem("userName");
        sessionStorage.removeItem("email");
        sessionStorage.removeItem("refreshToken");
      }
      else{
        setErrorBlock(e);
      }
    };
    
    

    return (

        <main>
            {/* {errorblock && errorClass == "successMessage" && (
              <div
                className={`popup ${errorClass}`}
                style={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-evenly",
                }}
              >
                <div>{t(`${error}`)}</div>
                <div>
                  <button
                    onClick={erroBlockHandel}
                    className={`download ${errorClass}`}
                  >
                    <i class="fa fa-times" aria-hidden="true"></i>
                  </button>
                </div>
              </div>
            )} */}
           
            {errorblock && (
                <ModalError error={error} errorClose={errorClose}></ModalError>
            )}
            {modalSuccess && (
                <ModalError error={error} errorClose={errorClose} success={modalSuccess}></ModalError>
            )}
            {/* {SuccessModal && (
                <SuccessModal success={SuccessModal} navigate={'/login'} ></SuccessModal>
            )} */}
            <Navigation page={isLoginpage} login={isLogedin}></Navigation>
               <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 paddingTop-9" />
               <div
              className="container mt-n10"
              // style={{ marginTop: "10rem !important" }}
            >
              {/* Example Colored Cards for Dashboard Demo*/}
              <div className="row d-flex justify-content-center">
                <div className="col-md-12 col-sm-12 col-lg-8">
                  <div className="card card-lg card-other">
                    <div className="row" style={{ marginBottom: 80 }}>
                      <form style={{ width: "100%" }}>
                        <h5><b>{t("8f1e77e0d2be21da93cd4d9a939148f7")}</b></h5>
                        <hr />
                        {/* Form Row*/}
                        <section>
                          <div className="container">
                            <div className="accordion" id="accordionExample">
                              <div>
          
         
          <div className="card">
            <div id="headingOne" />
            <div
              id="collapseOne"
              className="collapse show"
              aria-labelledby="headingOne"
              data-parent="#accordionExample"
            >
              <div className="card-body">
                <div className="form-row">
                 
                 
                  {/* Form Group (last name)*/}
                  <div className="form-group col-md-6">
                    <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                    {t("2b73e1b2459fcecd4c5e17250a078680")}
                    </label>
                    <span className="mandatory">*</span>
                    <input
                      className="KhmerOSsiemreaptext form-control"
                      id="inputPhone"
                      type="tel"
                      placeholder=''
                      value={idCardNumber}
                      disabled
                      
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                    {t("2f4c21028f40ed284818401e09633efa")}
                    </label>
                    <span className="mandatory">*</span>
                    <input
                      className="KhmerOSsiemreaptext form-control"
                      id="inputPhone"
                      type="password"
                      placeholder={t("2f4c21028f40ed284818401e09633efa")}
                      value={oldPassword}
                      onChange={(e) => setOldPassword(e.target.value)}
                    //   disabled
                      
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                    {t("0af31c2b84951552e970ef33523a3d38")}
                    </label>
                    <span className="mandatory">*</span>
                    <input
                      className="KhmerOSsiemreaptext form-control"
                      id="inputFirstName"
                      type="password"
                      placeholder={t("0af31c2b84951552e970ef33523a3d38")}
                      value={newPassword}
                      onChange={(e) => setNewPassword(e.target.value)}
                    //   disabled
                     
                    />
                  </div>

                  <div className="form-group col-md-6">
                    <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                    {t("887f7db126221fe60d18c895d41dc8f6")}
                    </label>
                    <span className="mandatory">*</span>
                    <input
                      className="KhmerOSsiemreaptext form-control"
                      id="inputFirstName"
                      type="password"
                      placeholder={t("e9387ec9e6a1f462f2ea052059b9cc9d")}
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                    //   disabled
                     
                    />
                  </div>
                  {/* Form Group (phone number)*/}
                  
    
                  {/* <div className="form-group col-md-6">
                    <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                      {t("8be5338b5414092f30160584e760bb01")}
                    </label>
                    <span className="mandatory">*</span>
                    <input
                      className="KhmerOSsiemreaptext form-control"
                      id="inputFirstName"
                      type="text"
                      placeholder="abc@gmail.com"
                      value={userDetails.email}
                      disabled
                     
                    />
                  </div> */}
  
               
  
  
               
    
                  {/* Form Group (last name)*/}
                
    
                
    
                  
                  <div
                  className="view-profile"
                  style={{ "background-color": "#ffffff", display: 'flex', justifyContent: 'flex-end', columnGap: '15px'}}
                >
                   <button
                      className="KhmerOSsiemreaptext btn btn-primary next"
                      type="button"
                      data-toggle="collapse"
                      data-target="#collapseTwo"
                      aria-expanded="false"
                      aria-controls="collapseTwo"
                      onClick={changeOldPassword}
                    >
                      {t("a4d3b161ce1309df1c4e25df28694b7b")}
                    </button>
                   
                </div>
                 
                 
                </div>
              </div>
            </div>
          </div>
        </div>
                            </div>
                          </div>
                        </section>
                      </form>
                     
                    </div>
                  </div>
                </div>
                <div style={{ margin: 30, width: "100%" }} />
              </div>
          </div>
          </main>
  
       
      );
}

export default ChangePassword