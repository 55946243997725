import React, { useContext, useEffect, useState, useRef } from "react";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import { useTranslation } from "react-i18next";
import { DashboardContext } from "../../ContextApi";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { BsEyeFill } from "react-icons/bs";
import axios from "axios";
import { ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function ViewService() {
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const LegalKey = process.env.REACT_APP_LEGALIZATION_KEY;
  const DMSKey = process.env.REACT_APP_DMS_SERVICE;
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

  const { serviceViewDetails } = useContext(DashboardContext);
  const isLoginpage = true;
  const isLogedin = true;
  const [cardDisplay, setCardDisplay] = useState(true);
  const { t } = useTranslation();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  const [ownerAccountVerified, setOwnerAccountVerified] = useState(true);
  const Navigate = useNavigate();

  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      Navigate('/');
    }
  }, [isAuthenticated]);
  const [serviceData, setServiceData] = useState({
    customerId: "",
    latinName: "",
    khamerName: "",
    dateOfBirth: new Date(),
    sex: "",
    homeNo: "",
    streetNo: "",
    capitalId: "",
    districtId: "",
    communeId: "",
    villageId: "",
    dobAddress: "",
    identityType: "",

    serviceName: "",
    serviceDuration: "",
    quantity: "",
    servicePrice: "",

    guesthouseName: "",
    guesthouseLatinName: "",
    guesthousePhoneNo: "",
    guesthouseHomeNo: "",
    guesthouseStreetNo: "",
    businessCapitalId: "",
    businessDistrictId: "",
    businessCommuneId: "",
    businessVillageId: "",
    guesthouseAddress: "",
    viewDocument:'',

    requiredDocument: [],
  });

  const [serviceDetails, setServiceDetails] = useState({
    serviceName: "",
    serviceDuration: "",
    servicePrice: "",
  });

  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);

  const [businessApicapital, setBusinessApiCaptial] = useState([]);
  const [businessApidistricts, setBusinessApiDistricts] = useState([]);
  const [businessApicommune, setBusinessApiCommune] = useState([]);
  const [businessApiVillage, setBusinessApiVillage] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const  [serviceId,setServiceId]=useState("");
  const currentYear = range(1850, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    let applicationId;
    let serviceId;
    let isvisitFromNotification=sessionStorage.getItem("isVisitFromNotification")

    if(isvisitFromNotification==="true"){
      let serviceDiteals=JSON.parse(sessionStorage.getItem("serviceDiteals"))
      applicationId=serviceDiteals.applicationId;
      serviceId=serviceDiteals.serviceId
      setServiceId(serviceDiteals.serviceId)
    }else{
      applicationId=serviceViewDetails.requestId;
      serviceId=serviceViewDetails.ServiceId;
      setServiceId(serviceViewDetails.ServiceId)
    }
    if (sessionStorage.getItem("userId")) {
      const id = sessionStorage.getItem("userId");

      // existing diteals check by id
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };
      const reqObj = {
        // serviceId: serviceViewDetails.requestId,
        serviceId: applicationId,
        loggedInUserCode: id,
      };
      axios
        .post(`${LegalKey}/fetchLegalizationServiceDetails`, reqObj, config)
        .then((response) => {
          if (response.data.status == 1) {
            setServiceData({
              ...serviceData,
              customerId: response.data.responseObject.Json.Owner.IdCard,
              latinName: response.data.responseObject.Json.Owner.LatinName,
              khamerName: response.data.responseObject.Json.Owner.KhmerName,
              dateOfBirth: response.data.responseObject.Json.Owner.Dob,
              sex: response.data.responseObject.Json.Owner.Sex,
              homeNo: response.data.responseObject.Json.Owner.HomeNo,
              streetNo: response.data.responseObject.Json.Owner.StreetNo,
              capitalId: response.data.responseObject.Json.Owner.CapitalId,
              districtId: response.data.responseObject.Json.Owner.DistrictId,
              communeId: response.data.responseObject.Json.Owner.CommuneId,
              villageId: response.data.responseObject.Json.Owner.VillageId,
              dobAddress: response.data.responseObject.Json.Owner.Address, 
              identityType: response.data.responseObject.Json.Owner.IdentityType, 

              quantity: response.data.responseObject.Json.Register.Quantity,

              guesthouseName: response.data.responseObject.Json.Business.Name,
              guesthouseLatinName:
                response.data.responseObject.Json.Business.LatinName,
              guesthousePhoneNo:
                response.data.responseObject.Json.Business.Phone,
              guesthouseHomeNo:
                response.data.responseObject.Json.Business.HomeNo,
              guesthouseStreetNo:
                response.data.responseObject.Json.Business.StreetNo,
              businessCapitalId:
                response.data.responseObject.Json.Business.CapitalId,
              businessDistrictId:
                response.data.responseObject.Json.Business.DistrictId,
              businessCommuneId:
                response.data.responseObject.Json.Business.CommuneId,
              businessVillageId:
                response.data.responseObject.Json.Business.VillageId,
              guesthouseAddress:
                response.data.responseObject.Json.Business.Address,

              requiredDocument: response.data.responseObject.Json.Attachments,

              viewDocument: response.data.responseObject.Json.Attachments.Content
            });
          }
        });

      const serviceData = {
        serviceId: serviceId,
        userCode: id,
      };
      axios
        .post(`${MasterKey}/getServiceDetailById`, serviceData, config)
        .then((response) => {
          if (response.data.status == 1) {
            setServiceDetails({
              ...serviceDetails,
              serviceName: response.data.responseObject.Json.Service.Name,
              serviceDuration:
                response.data.responseObject.Json.Service.ProcessDay,
              servicePrice: response.data.responseObject.Json.Service.Price,
            });
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      const data = {
        identityType: serviceData.identityType,
        code: serviceData.customerId,
      };

      axios
        .post(`${LoginKey}/login/getCustomerInfo`, data, config)
        .then((response) => {
          setIsloading(false);
          if (response.data.status == 1) {
            console.log('here in api')
            console.log(response.responseObject);
            setOwnerAccountVerified(response.data.responseObject.Data.Customer.verify)
            // setExistingOtherData(response.data.responseObject.Data.Customer);
            // setCaptialId(response.data.responseObject.Data.Customer.CapitalId);
            // setDistrictId(
            //   response.data.responseObject.Data.Customer.DistrictId
            // );
            // setCommuneId(response.data.responseObject.Data.Customer.CommuneId);
            // setVillageId(response.data.responseObject.Data.Customer.VillageId);
            // props.handelCustomerData(
            //   response.data.responseObject.Data.Customer
            // );
            console.log('inside success !')
          } else {
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
  },[serviceData])

  useEffect(() => {
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };
    
    
    // api for get capital
    axios
      .post(`${MasterKey}/getAllCapital`, { userCode: "1" }, config)
      .then((response) => {
        if (response.data.status === "1") {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get district
    axios
      .post(
        `${MasterKey}/getAllDistrictByCapital`,
        {
          userCode: "string",
          capitalId: serviceData.capitalId,
          online: true,
        },
        config
      )
      .then((response) => {
        if (response.data.status === "1") {
          setApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get commune
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: serviceData.districtId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: serviceData.communeId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // business address api's
    // api for get capital
    axios
      .post(`${MasterKey}/getCapital`, { userCode: "1" }, config)
      .then((response) => {
        if (response.data.status === "1") {
          setBusinessApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get district
    axios
      .post(
        `${MasterKey}/getDistrictByCapital`,
        {
          userCode: "string",
          capitalId: serviceData.businessCapitalId,
          online: true,
        },
        config
      )
      .then((response) => {
        if (response.data.status === "1") {
          setBusinessApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get commune
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: serviceData.businessDistrictId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setBusinessApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: serviceData.businessCommuneId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setBusinessApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    setIsloading(false);
  }, [
    serviceData.capitalId,
    serviceData.districtId,
    serviceData.communeId,
    serviceData.villageId,
    serviceData.businessCapitalId,
    serviceData.businessDistrictId,
    serviceData.businessVillageId,
    serviceData.businessCommuneId,
  ]);

  const openPdfInNewTab = (content) => {
    // console.log('content',content)
    

    let config = {
      headers: {
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get front Idcard
    axios
      .post(
        `${DMSKey}/downloadFile`,
        // {
        //   identityType: "FrontIdCard", // To fetch Front side of the id card
        //   idCardNumber: rejectionResponse.idCard,
        // },
        {
          "fileName": content  
        },
        config 
      )
      .then((response) => {
        console.log(response);
        const newTab = window.open();
        let file = content.split(".");
        // file = file[0].split("/");

        if (file[1] == "pdf") {
          newTab.document.write(
            '<iframe src="' + response.data.responseObject + '" width="100%" height="100%"></iframe>'
          );
        } else {
          newTab.document.write(
            '<img src="' + response.data.responseObject + '" width="100%" height="100%"></img>'
          );
        }
        // setFrontImage(response.data.responseObject);
        // setFrontBase64(response.data.responseObject);
        // setFronName(fileName(response.data.responseObject));
      })
      .catch((error) => {
        console.log(error);
      });

  };

 console.log(ownerAccountVerified);
  return (
    <div>
      <Navigation page={isLoginpage} login={isLogedin}></Navigation>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-5 pt-2">
            <div className="container-fluid">
              <div className="page-header-content padding-0">
                <div className="row d-flex">
                  <div className="p-2 col-4 col-sm-12 col-md-6 col-lg-12">
                    <h1
                      className="page-header-title"
                      style={{ fontSize: "20px" }}
                    >
                      <div className="page-header-icon">
                        <i data-feather="activity"></i>
                      </div>
                      {t("161bfa9de5fe00c6158aa942ed89fe7f")}
                    </h1>
                  </div>

                  <hr style={{ width: "100%", padding: 0, margin: 0 }}></hr>
                </div>
              </div>
            </div>
          </header>

          <div className="container-fluid mt-sm-n5">
            <div className={`row ${!cardDisplay && "display-none"}`}>
              {/* Customer Details Card */}
              <div className="col-lg-5 col-md-5 col-sm-12">
                <div className="card card-lg card-other drk">
                  <div className="row">
                    <form style={{ marginBottom: "80px" }}>
                      <h5 style={{ color: "white" }}><b>
                        {t("732cabde6bfc84822b5fd47a5288b789")}
                      </b></h5>
                      <hr /> 
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1 tm" for="inputFirstName">
                            {t("dae903caf35016e727adebc1e3c18b2a")}
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control input-dark`}
                            id="inputUsername"
                            type="text"
                            placeholder={t("504c46e2c369d441b6eb6c581101fc85")}
                            value={serviceData.customerId}
                            // onChange={(e)=>{...serviceData,customerId:e.target.value}}
                            disabled
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("dbcc71284f11a659aa72716396584a4c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control input-dark`}
                            id="inputPhone"
                            type="text"
                            placeholder={t("dbcc71284f11a659aa72716396584a4c")}
                            value={serviceData.khamerName}
                            disabled
                          />
                        </div> 

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("4ac58405bbbe783528ee33e7ac3fcc5c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control input-dark`}
                            id="inputFirstName"
                            type="text"
                            placeholder={t("4ac58405bbbe783528ee33e7ac3fcc5c")}
                            value={serviceData.latinName}
                            disabled
                          />
                        </div>
                        
                        {/* ---------------------- owner DOB ---------------------- */}
                        { ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("10803b83a68db8f7e7a33e3b41e184d0")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div>
                            <DatePicker
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {currentYear.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                              )}
                              showIcon
                              // toggleCalendarOnIconClick
                              className={`KhmerOSsiemreaptext form-control input-dark`}
                              dateFormat="dd/MM/yyyy"
                              selected={serviceData.dateOfBirth}
                              disabled
                            />
                          </div>
                        </div>}

                        {/* ---------------------- ownernationalty ---------------------- */}
                        { ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("14606eeaca7845ed80684e9e20a4c983")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                            data-val="true"
                            name="StateId"
                            value={33}
                            disabled
                          >
                            <option value="" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            <option value="33" selected="" className="KhmerOSsiemreaptext">
                              {t("06c7a3c3a3344a62864b951aec4df830")}
                            </option>
                          </select>
                        </div>}

                        {/* ---------------------- owner gender ---------------------- */}
                        { ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                            {t("ee583624fabcb93a41504c81eaf561be")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className="KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark"
                            data-val="true"
                            name="StateId"
                            value={serviceData.sex}
                            disabled
                          >
                            <option value="" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            <option value={1} selected="" className="KhmerOSsiemreaptext">
                              {t("63889cfb9d3cbe05d1bd2be5cc9953fd")}
                            </option>
                            <option value={2} selected="" className="KhmerOSsiemreaptext">
                              {t("b719ce180ec7bd9641fece2f920f4817")}
                            </option>
                          </select>
                        </div>}

                        <div className="form-group col-md-6"></div>
                        <hr />
                        {/* ---------------------- address details heading ---------------------- */}
                        { ownerAccountVerified && <h5
                          style={{ textAlign: "left", color: "#fff" }}
                          className="col-12"
                        > <b>
                            {t("861a00de3a9b1a4751e4e3190944227e")}
                          </b>
                        </h5>}
                        <hr style={{ width: "100%" }} />

                        { ownerAccountVerified &&<div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("c1a3626600b7d8a8b3e0b2474ab5c202")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control input-dark`}
                            id="inputFirstName"
                            type="text"
                            placeholder={t("c1a3626600b7d8a8b3e0b2474ab5c202")}
                            value={serviceData.homeNo}
                            disabled
                          />
                        </div>}

                       { ownerAccountVerified &&<div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("d7dbce89503da95fa05b5e38b106adb4")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control input-dark`}
                            id="inputFirstName"
                            type="text"
                            placeholder={t("d7dbce89503da95fa05b5e38b106adb4")}
                            value={serviceData.streetNo}
                            disabled
                          />
                        </div>}

                        { ownerAccountVerified &&<div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12  input-dark`}
                            data-val="true"
                            name="StateId"
                            value={serviceData.capitalId}
                            disabled
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apicapital.map((capital) => (
                              
                              <option value={capital.Id} selected="" className="KhmerOSsiemreaptext">
                                {capital.Name}
                              </option>
                            ))}
                          </select>
                        </div>}

                        { ownerAccountVerified &&<div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                            data-val="true"
                            name="StateId"
                            value={serviceData.districtId}
                            disabled
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apidistricts.map((districts) => (
                              <option value={districts.Id} selected="" className="KhmerOSsiemreaptext">
                                {districts.Name}
                              </option>
                            ))}
                          </select>
                        </div>}

                        { ownerAccountVerified &&<div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("04beeb76f7acc97bb1e309f654fdee7a")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                            data-val="true"
                            name="StateId"
                            value={serviceData.communeId}
                            disabled
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apicommune.map((commune) => (
                              <option value={commune.Id} selected="" className="KhmerOSsiemreaptext">
                                {commune.Name}
                              </option>
                            ))}
                          </select>
                        </div>}

                        { ownerAccountVerified &&<div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("abc63490c815af81276f930216c8d92b")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12  input-dark`}
                            data-val="true"
                            name="StateId"
                            value={serviceData.villageId}
                            disabled
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apiVillage.map((village) => (
                              <option value={village.Id} selected="" className="KhmerOSsiemreaptext">
                                {village.Name}
                              </option>
                            ))}
                          </select>
                        </div>}

                        { ownerAccountVerified &&<div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                            {t("f984cd4a2d763ce723eeaeb59d835bb9")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputFirstName"
                            type="text"
                            value={serviceData.dobAddress}
                            placeholder={t("f984cd4a2d763ce723eeaeb59d835bb9")}
                            disabled
                          />
                        </div>}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              {/* Business Details Card */}
              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="card card-lg card-other">
                  <div className="row" style={{ marginBottom: "80px" }}>
                    <form>
                      <h5 style={{ color: "#0061f2" }}><b>{t("026eef87021de60dcac96ee4acd8f518")}</b></h5>
                      <hr />
                      {/* <!-- Form Row--> */}
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
                            {t("46140fd4f90101f3beccfd3428bea873")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputFirstName"
                            type="text"
                            placeholder={t("6b3c4fac8f71637289b968e251d4429c")}
                            value={serviceDetails.serviceName}
                            disabled
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("5139a143878751e4282ab73759c95079")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputFirstName"
                            type="text"
                            placeholder="3 Working Days (After acceptance of application)"
                            title="3 Working Days (After acceptance of application by front office )"
                            value={serviceDetails.serviceDuration}
                            disabled
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("1ee0372b6b39ec230a9bbc9f84de24d6")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputPhone"
                            type="number"
                            placeholder={t("4bb55d367528dab71bd30ba3689b27b7")}
                            value={serviceData.quantity}
                            disabled
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("a90dc8c8855d760675981f47db71a98e")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputPhone"
                            type="tel"
                            placeholder="0"
                            disabled
                            value={serviceDetails.servicePrice}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("70b626c98cf6be5204a2e76bc47f39bb")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputPhone"
                            type="tel"
                            placeholder="0"
                            value={
                              serviceDetails.servicePrice * serviceData.quantity
                            }
                            disabled
                          />
                        </div>

                        <hr />
                        { serviceId == 2428 || serviceId == 2429 || serviceId == 5026 || serviceId == 2446 || serviceId == 2447 || serviceId == 2448 ?
                          <>
                            <h5
                              style={{ textAlign: "left", fontweight: "bold", color: "#0061f2" }}
                              className="col-12 mb-0 mt-2"
                            ><b>
                                {t("1d050b6f2ffbe0d3ac300b00c5c0410f")} <br /> 
                                ( {t("af5c68eaf55a17f5031761da7f2e1c77")} )
                              </b></h5>
                            <hr style={{ width: "100%" }} />
                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                                data-val="true"
                                name="StateId"

                                value={serviceData.businessCapitalId}
                                disabled
                              >
                                <option value="0">
                                  {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                                </option>
                                {businessApicapital.map((capital) => (
                                  <option value={capital.Id} selected="">
                                    {capital.Name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                                data-val="true"
                                name="StateId"
                                value={serviceData.businessDistrictId}
                                disabled
                              >
                                <option value="0">
                                  {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                                </option>
                                {businessApidistricts.map((districts) => (
                                  <option value={districts.Id} selected="">
                                    {districts.Name}
                                  </option>
                                ))}
                              </select>
                            </div>
                          </>
                          : <></>
                        }

                        {serviceId == 2519 || serviceId == 4861 || serviceId == 4862 ?
                          <>
                            <h5
                              style={{
                                textAlign: "left",
                                fontweight: "bold",
                                color: "#0061f2",
                              }}
                              className="col-12 mb-0 mt-2"
                            ><b>
                                {t("d6e6cb19e3b9c02f89d6cd54cfa7c613")}
                              </b></h5>
                            <hr style={{ width: "100%" }} />


                           

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                              {t("a55aee8acc3abf18159a13674136052a")} (KH) {" "}
                                
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                className={`KhmerOSsiemreaptext form-control input-dark`}
                                id="inputFirstName"
                                type="text"
                                placeholder="Enter Business Latin Name"
                                value={serviceData.guesthouseName}
                                disabled
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("a55aee8acc3abf18159a13674136052a")} (EN) {" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                className={`KhmerOSsiemreaptext form-control input-dark`}
                                id="inputFirstName"
                                type="text"
                                placeholder={t("a55aee8acc3abf18159a13674136052a")}
                                value={serviceData.guesthouseLatinName}

                                disabled
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("464972ecbc84025453afcb389470c928")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <input
                                className={`KhmerOSsiemreaptext form-control input-dark`}
                                id="inputFirstName"
                                type="number"
                                placeholder={t("464972ecbc84025453afcb389470c928")}
                                value={serviceData.guesthousePhoneNo}
                                disabled
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("c1a3626600b7d8a8b3e0b2474ab5c202")}
                              </label>
                              <input
                                className={`KhmerOSsiemreaptext form-control input-dark`}
                                id="inputFirstName"
                                type="text"
                                placeholder={t("c1a3626600b7d8a8b3e0b2474ab5c202")}
                                value={serviceData.guesthouseHomeNo}
                                disabled
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("070a6f943470aec6d12c7090f290e10f")}
                              </label>
                              <input
                                className={`KhmerOSsiemreaptext form-control input-dark `}
                                id="inputFirstName"
                                type="text"
                                placeholder={t("070a6f943470aec6d12c7090f290e10f")}
                                value={serviceData.guesthouseStreetNo}
                                disabled
                              />
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                                data-val="true"
                                name="StateId"
                                value={serviceData.businessCapitalId}
                                disabled
                              >
                                <option value="0">
                                  {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                                </option>
                                {businessApicapital.map((capital) => (
                                  <option value={capital.Id} selected="">
                                    {capital.Name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                                data-val="true"
                                name="StateId"
                                value={serviceData.businessDistrictId}
                                disabled
                              >
                                <option value="0">
                                  {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                                </option>
                                {businessApidistricts.map((districts) => (
                                  <option value={districts.Id} selected="">
                                    {districts.Name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("04beeb76f7acc97bb1e309f654fdee7a")}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                                data-val="true"
                                name="StateId"
                                value={serviceData.businessCommuneId}
                                disabled
                              >
                                <option value="0">
                                  {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                                </option>
                                {businessApicommune.map((commune) => (
                                  <option value={commune.Id} selected="">
                                    {commune.Name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col-md-6">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("abc63490c815af81276f930216c8d92b")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <select
                                className={`KhmerOSsiemreaptext form-control col-sm col-md-12 input-dark`}
                                data-val="true"
                                name="StateId"
                                value={serviceData.businessVillageId}
                                disabled
                              >
                                <option value="0">
                                  {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                                </option>
                                {businessApiVillage.map((village) => (
                                  <option value={village.Id} selected="">
                                    {village.Name}
                                  </option>
                                ))}
                              </select>
                            </div>

                            <div className="form-group col-md-12">
                              <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                                {t("861a00de3a9b1a4751e4e3190944227e")}{" "}
                                <span style={{ color: "red" }}>*</span>
                              </label>
                              <textarea
                                className={`KhmerOSsiemreaptext form-control input-dark`}
                                id="inputFirstName"
                                type="text"
                                placeholder={t("861a00de3a9b1a4751e4e3190944227e")}
                                value={serviceData.guesthouseAddress}
                                disabled
                              />
                            </div>
                          </>
                          : <></>
                        }

                        <div className="form-group col-md-12">
                          <label
                            className="KhmerOSsiemreaptext small mt-3"
                            for="inputLocation"
                            style={{ fontweight: "bold", color: "#0061f2" }}
                          ><b>
                              {t("1c0a9e6f20b9f1b59d22290d9f0f31a7")}{" "}
                              <span style={{ color: "red" }}>*</span>
                              <p
                                className="KhmerOSsiemreaptext mt-1"
                                style={{
                                  fontSize: "11.5px",
                                  fontStyle: "italic",
                                  color: "red",
                                }}
                              >
                                {t("2ffe94058afaea3b875be2082941ec0b")}
                              </p>
                            </b></label>
                          <table
                            className="table"
                            style={{ marginTop: "-5px", marginBottom: "7px" }}
                          >
                            <thead className="thead-light">
                              <tr>
                                <th
                                  className="KhmerOSsiemreaptext small text-center"
                                  scope="col"
                                  style={{ width: "3rem" }}
                                >
                                  <b>{t("598d42810b342196884f255912974d69")}</b>
                                </th>
                                <th
                                  className="KhmerOSsiemreaptext small text-center"
                                  style={{ width: "30rem" }}
                                >
                                  <b>{t("3d756c429d3a9fe70f5f1ac25a561a9a")}</b>
                                </th>
                                <th
                                  className="KhmerOSsiemreaptext small text-center"
                                  style={{ width: "10rem" }}
                                >
                                  <b>{t("25b2a90d6504b30bf67144df2a9db1c7")}</b>
                                </th>
                                <th
                                  className="KhmerOSsiemreaptext textAlign-center small"
                                  style={{ width: "5rem" }}
                                >
                                  <b>
                                    {" "}
                                    {t("004bf6c9a40003140292e97330236c53")}
                                  </b>
                                </th>
                              </tr>
                            </thead>
                            <tbody>
                              {serviceData.requiredDocument.map(
                                (docklink, index) => {
                                  {
                                    // slno_document = slno_document + 1; // Increment serial number if condition is met
                                    return (
                                      <tr key={index}>
                                        <td className="KhmerOSsiemreaptext text-center">
                                          {index + 1}
                                        </td>
                                        <td className="KhmerOSsiemreaptext text-center">
                                          {docklink.Title}
                                        </td>
                                        <td className="KhmerOSsiemreaptext text-center">
                                          {docklink.FileType}
                                        </td>
                                        <td className="text-center">
                                          <BsEyeFill
                                            className="mx-1"
                                            onClick={() => {
                                              openPdfInNewTab(docklink.Content);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: "#0000ffdb",
                                            }}
                                          //   disable={icon}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                }
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </form>
                  </div>

                  <div className="col-12 fot-row row">
                    <div className="d-flex justify-content-between col-6"></div>

                    <div className="d-flex flex-row justify-content-end col-6">
                      {/* <!-- Button trigger modal --> */}
                      <button
                        type="submit"
                        className="KhmerOSsiemreaptext btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={() => Navigate("/dashboard")}
                      >
                        {t("0557fa923dcee4d0f86b1409f5c2167f")}
                      </button>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ margin: "30px", width: "100%" }}></div>
            </div>
          </div>
        </main>
      </div>
      <Footer></Footer>
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default ViewService;
