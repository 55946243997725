import React, { useState } from "react";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import RegistrationProcessB from "../registration-process/RegistrationProcessB";
import RegistrationProcessC from "../registration-process/RegistrationProcessC";
import OtpValidation from "./OtpValidation";
import ChangePassword from "./ChangePassword";
import ChangeSuccess from "./ChangeSuccess";
import ForgetPassword from "./ForgetPassword";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

function PasswordChangeHolder() {
  const { t } = useTranslation();
  const isLoginpage = true;
  const [registrationdata, setRegistrationdata] = useState({});
  const [process, setProcess] = useState(0);
  const [isValidate, setIsValidate] = useState(false);
  const [customer, setCustomer] = useState({});
  const navigate = useNavigate();

  // redirecting to homepage after changing password successfully
  function redirectHandler(){
    navigate('/')
  }

  // function for change the process of registration page
  const handelclick = (step) => {
    setProcess(step);
  };

  //
  const handelValidate = (e) => {
    setIsValidate(e);
  };

  //
  const customerData = (data) => {
    setCustomer(data);
  };

  // function for get data from registrationProcessA
  const handelDataA = (dataA) => {
    setRegistrationdata(dataA);
  };

  // function for get data from registrationProcessB
  const handelDataB = (dataB) => {
    setRegistrationdata(dataB);
  };
  return (
    <div>
      <Navigation page={isLoginpage}></Navigation>
      {!isValidate ? (
        <ForgetPassword
          handelValidate={handelValidate}
          customerData={customerData}
        />
      ) : (
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 paddingTop-9" />
          {/* Main page content*/}
          <div
            className="container mt-n10"
            style={{ marginTop: "-10rem !important" }}
          >
            {/* Example Colored Cards for Dashboard Demo*/}
            <div className="row d-flex justify-content-center">
              <div className="col-md-12 col-sm-12 col-lg-8">
                <div className="card card-lg card-other">
                  <div className="row" style={{ marginBottom: '30px' }}>
                    <form>
                      <h5><b>{t("6d909c2ed76c42670f090b269a478b7f")}</b></h5>
                      <hr />
                      {/* Form Row*/}
                      <section>
                        <div className="container">
                          <div className="accordion" id="accordionExample">
                            {(() => {
                              switch (process) {
                                case 0:
                                  return (
                                    <OtpValidation
                                      handelclick={handelclick}
                                      handelData={handelDataA}
                                      customer={customer}
                                    />
                                  );
                                case 1:
                                  return (
                                    <ChangePassword
                                      handelclick={handelclick}
                                      registerDataA={registrationdata}
                                      customer={customer}
                                    />
                                  );
                                case 2:
                                  return <ChangeSuccess />;
                                default:
                                  return null;
                              }
                            })()}

                            {/* <Outlet /> */}
                          </div>
                        </div>
                      </section>
                    </form>
                    {process === 2 && <button
                          className="KhmerOSsiemreaptext btn btn-primary"
                          onClick={redirectHandler}
                          style={{ margin: 'auto'}}
                        >
                          {" "}
                          Close
                    </button>}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </main>
      )}
      <Footer></Footer>
    </div>
  );
}

export default PasswordChangeHolder;

// to-convert
// done !
