import React, { useEffect, useState, useRef } from "react";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";
import RegistrationNav from "./RegistrationNav";
import RegistrationProcessA from "./RegistrationProcessA";
import RegistrationProcessB from "./RegistrationProcessB";
import RegistrationProcessC from "./RegistrationProcessC";
import RegistrationProcessD from "./RegistrationProcessD";
import Registration from "../registration/Registration";
import { useNavigate } from "react-router-dom";

import { useTranslation } from "react-i18next";
// const { t } = useTranslation();

function RegistrationHolder() {
  const isLoginpage = true;
  const [registrationdata, setRegistrationdata] = useState({});
  const [process, setProcess] = useState(0);
  const [steps, setSteps] = useState(0);
  const [formData, setformData] = useState({});
  const [isValidate, setIsValidate] = useState(false);
  const { t } = useTranslation();

  const [customer, setCustomer] = useState({});
  const [id, setId] = useState("");
  const [isExistCustomer, setIsExistCustomer] = useState(false);
  const navigate = useNavigate();
  // function for change the process of registration page
  const handelclick = (step) => {
    setProcess(step);
  };
  //
  const handelValidate = (e) => {
    setIsValidate(e);
  };

  function redirectHandler(){
    navigate('/')
  }
  //
  const customerData = (data) => {
    setCustomer(data);
  };

  //
  const handelID = (id) => {
    setId(id);
  };
  //
  const handelresponse = (e) => {
    setIsExistCustomer(e);
  };

  // function for get data from registrationProcessA
  const handelDataA = (dataA) => {
    setRegistrationdata(dataA);
  };

  // function for get data from registrationProcessB
  const handelDataB = (dataB) => {
    setRegistrationdata(dataB);
  };

  // function for get form data from registrationProcessA
  const handelform = (form) => {
    setformData(form);
  };

  useEffect(() => {
    if (process === 0) {
      setSteps(1);
    } else if (process === 1) {
      setSteps(2);
    } else if (process == 2) {
      setSteps(3);
    } else {
      setSteps(4);
    } 
  }, [process]);

  return (
    <div>
      <Navigation page={isLoginpage}></Navigation>
      {!isValidate ? (
        <Registration
          handelValidate={handelValidate}
          customerData={customerData}
          handelID={handelID}
          handelresponse={handelresponse}
        />
      ) : (
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 paddingTop-9" />
          {/* Main page content*/}
          <div
            className="container mt-n10"
            style={{ marginTop: "-10rem !important" }}
          >
            {/* Example Colored Cards for Dashboard Demo*/}
            <div className="row d-flex justify-content-center">
              <div className="col-md-12 col-sm-12 col-lg-8">
                <div className="card card-lg card-other">
                  <div className="row" style={{ marginBottom: 80 }}>
                    <form style={{ width: "100%" }}>
                      <h5 style={{ display: 'flex', justifyContent: 'center'}}><b>{t("0f98b7f230f3c91292f0de4c99e263f2")}</b></h5>
                      <hr />
                      {/* Form Row*/}
                      <section>
                        <div className="container">
                          <div className="accordion" id="accordionExample">
                            <RegistrationNav steps={steps}></RegistrationNav>

                            {(() => {
                              switch (process) {
                                case 0:
                                  return (
                                    <RegistrationProcessA
                                      handelclick={handelclick}
                                      handelData={handelDataA}
                                      handelform={handelform}
                                      id={id}
                                      customer={customer}
                                      isExistCustomer={isExistCustomer}
                                    />
                                  );
                                case 1:
                                  return (
                                    <RegistrationProcessB
                                      handelclick={handelclick}
                                      handelData={handelDataB}
                                      registerDataA={registrationdata}
                                      id={id}
                                      customer={customer}
                                      isExistCustomer={isExistCustomer}
                                    />
                                  );
                                case 2:
                                  return (
                                    <RegistrationProcessC
                                      handelclick={handelclick}
                                      registerDataB={registrationdata}
                                      formData={formData}
                                      id={id}
                                      customer={customer}
                                      isExistCustomer={isExistCustomer}
                                    />
                                  );
                                case 3:
                                  return <RegistrationProcessD />;
                                default:
                                  return null;
                              }
                            })()}

                            {/* <Outlet /> */}
                          </div>
                        </div>
                      </section>
                    </form>
                    {process === 3 && <button
                          className="KhmerOSsiemreaptext btn btn-primary"
                          onClick={redirectHandler}
                          style={{ margin: 'auto'}}
                        >
                          {" "} 
                          {t("d3d2e617335f08df83599665eef8a418")}                         
                    </button>}
                  </div>
                </div>
              </div>
              <div style={{ margin: 30, width: "100%" }} />
            </div>
          </div>
        </main>
      )}
      <Footer></Footer>
    </div>
  );
}

export default RegistrationHolder;

// to_convert
// completed...
//  done !
