import moment from "moment";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";

function RegistrationProcessD() {
  const { t } = useTranslation();
  const [currentDate, setCurrentDate] = useState("");
  const isLoginpage = true;
  let navigate = useNavigate();
  useEffect(() => {
    var date = moment();

    setCurrentDate(date.format("DD/MM/YYYY"));
    // setTimeout(() => {
    //   navigate("/login");
    // }, 5000);
  }, []);
  return (
    <div>
      <div className="card">
        <div id="headingFour" />
        <div
          id="collapseFour"
          className="collapse show"
          aria-labelledby="headingFour"
          data-parent="#accordionExample"
          style={{}}
        >
          <div className="card-body" style={{ width: "648px!important" }}>
            <div className="form-card">
              <div className="row justify-content-center">
                <div className="col-3">
                  {" "}
                  <img
                    src="https://img.icons8.com/color/96/000000/ok--v2.png"
                    className="fit-image"
                  />{" "}
                </div>
              </div>
             
              
              <div className="row justify-content-center">
                <div className="col-7 text-center">
                  {/* <h5 className="fs-title text-center">
                  {t("d66e469700ca174e38e4ab43d079c797")}
                  </h5> */}
                  <label className="KhmerOSsiemreaptext">
                   <b>{t("d66e469700ca174e38e4ab43d079c797")}</b>
                     <br />  <br />
                      {t("b55afee3a3deb460e6149b486d8d9a49")}<br />
                    {/* <br /> Application Number #AP-65438000 <br /> */}
                    {/* {t("6e9a10b2d4dd5f977fa0b1e74b0c0ae5")} {currentDate} <br /> */}
                    {/* Users can login post activation */}
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationProcessD;

// to_convert
// User Registration Submitted Successfully !
// Current Status-Account under review