import React, { useEffect, useState, useRef, useContext } from "react";
import { BsEyeFill } from "react-icons/bs";
import { MdDelete } from "react-icons/md";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import moment from "moment";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { OthersComponentContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";
import ModalDeleteConfirm from "../Error-Span-Modal/ModalDeleteConfirm";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function TourismServiceDetails({
  formChangeOfCustomer,
  customer,
  serviceDiteals,
  otherIDCard,
}) {
  const { t } = useTranslation();
  const [documentData, setDocumentData] = useState({
    uploadFile: "",
    uploadFileType: "",
  });
  // let flag = true;
  let slno_template = 0;
  const communeRef = useRef(null);
  const districtRef = useRef(null);
  const provinceRef = useRef(null);
  const villageRef = useRef(null);
  let [countofDocuments, setCountofDocuments] = useState(0);
  const [flag, setFlag] = useState(false)
  const [icon, setIcon] = useState(true);
  const [requiredDocument, setRequiredDocument] = useState([]);
  const [documentArr, setDocumentArr] = useState([]);
  const [doclinkLength, setDoclinkLength] = useState(0);
  const [quantity, setQuantity] = useState("1");
  const [attachDocID, setAttachDocID] = useState("");
  const [upload, setUpload] = useState([]);
  const [documentType, setDocumentType] = useState([]);
  const [newUpload, setNewUpload] = useState();
  const [modal, setModal] = useState(false);
  const [currentDate, setCurrentDate] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [existingData, setExistingData] = useState({});
  const [formChange, setFormChange] = useState(formChangeOfCustomer);
  const [provinceCode, setProvinceCode] = useState(0);
  // const [districtCode, setDistrictCode] = useState(0);
  const [otherCustomer, setOtherCustomer] = useState({});
  const [khamerName, setKhamerName] = useState("");
  const [homeNO, setHomeNo] = useState("");
  const [streetNO, setStreetNO] = useState("");
  const [guesthouseVillageCode, setGuesthouseVillageCode] = useState("");
  const [businessLatinName, setBusinessLatinName] = useState("");
  const [rawbusinessLatinName, setRawbusinessLatinName] = useState("");
  const [guesthouseCommuneCode, setGuesthouseCommuneCode] = useState("");
  const [customerId, setCustomerId] = useState("");
  const [submitResponse, setSubmitResponse] = useState({});
  const [fileExtension, setFileExtension] = useState({});
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [file, setFile] = useState(null);
  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);
  const [guesthouseCapitalCode, setGuesthouseCapitalCode] = useState("");
  const [guesthouseDistrictCode, setGuesthouseDistrictCode] = useState("");
  const [guesthouseName, setGuesthouseName] = useState("");
  const [guesthousePhone, setGuesthousePhone] = useState("");
  const [guesthouseAddress, setGuesthouseAddress] = useState("");

  const [guesthuouseProvinceName, setGuesthouseProvinceName] = useState("");
  const [guesthouseDistrictName, setGuesthouseDistrictName] = useState("");
  const [guesthouseVillageName, setguesthouseVillageName] = useState("");
  const [guesthouseCommuneName, setGuesthouseCommuneName] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [flagTemplate, setFlagTemplate] = useState(0);
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [documentIndex, setDocumentIndex] = useState();

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [isChecked, setIsChecked] = useState(false);

  // const [disabledInputs, setDisabledInputs] = useState(Array(4).fill(false));
  const [inputfilevalue, setInputfilevalue] = useState('');

  const { streetHome, isApplyforOthersDataAvialble } = useContext(
    OthersComponentContext
  );
  const inputFile = useRef(null);
  const [documentId, setDocumentId] = useState("");
  const [inputKey, setInputKey] = useState(0);
  let Navigate = useNavigate();
  const Attachments = [];
  const LegalizationKey = process.env.REACT_APP_LEGALIZATION_KEY;
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const [templateIndex, setTemplateIndex] = useState(1);



  const setAttachedDoc = (e) => {
    // setAttachDocID(e.target.value)
    setDocumentId(e.target.value);
    const selectDocumentType = JSON.parse(e.target.value);
    setAttachDocID(selectDocumentType.ServiceRequireDocId);
    const documentTypeList = [...documentType];

    if (!documentTypeList.includes(selectDocumentType.ServiceRequireDocId)) {
      documentTypeList.push(selectDocumentType.ServiceRequireDocId);
      setDocumentType(documentTypeList);
    }
    setDataOfDocument(e.target.name, selectDocumentType);

    documentArr.map((ele) => {
      if (ele.uploadFileType.Id === selectDocumentType.Id) {
        setError("1eaa431017273c99dc1e8bc68da1c66a");
        errorHandel(0);
        return;
      }
    });
    // if(documentArr.includes)
    // } else {
    //   setError("1eaa431017273c99dc1e8bc68da1c66a");
    //   errorHandel(0);
    //    setAttachDocID('');
    // }
  };
  // console.log(upload);
  // console.log(selectedFiles)
  // console.log(disabledInputs)
  console.log(requiredDocument)
  const handleFileChange = (event, index) => {
    console.log('IN')
    let file = event.target.files[0];
   

    if (!file.name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      setError("621b7de2db36267f3ca7e72abc4781c5");
      setInputKey(prevKey => prevKey + 1);
      errorHandel(0);
      return;
    }
    else if (file.size >= 10 * 1024 * 1024) {
      setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
      setInputKey(prevKey => prevKey + 1);
      errorHandel(0);
      return;
    }
    else {


      let newRequiredDocument = [...requiredDocument];
      newRequiredDocument[index].filesuploaded = file



      setRequiredDocument(newRequiredDocument);



    }

    // if (file) {

    //   const newSelectedFiles = [...selectedFiles];
    //   const newDisabledInputs = [...disabledInputs];

    //   if (!newSelectedFiles[index]) {
    //     if (!file.name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
    //       setError("621b7de2db36267f3ca7e72abc4781c5");
    //       errorHandel(0);
    //       return;
    //     }

    //     else {
    //       if (event.target.files[0].size >= 2 * 1024 * 1024) {
    //         setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
    //         errorHandel(0);
    //         return;
    //         } 

    //       newSelectedFiles[index] = file;
    //       setDataOfDocument(event.target.name, file);
    //       setFileExtension(file.name.split(".").pop());
    //       const uploadList = [...upload];
    //       // console.log(inputFile.current.files[0]);
    //       uploadList.push({fileDetails: file, ind : index});
    //       setUpload(uploadList);
    //       setNewUpload(file);
    //     }
    //   }
    //   else {
    //     setError('Only one file can be selected at a time');
    //     errorHandel(0);
    //   }

    //   newDisabledInputs[index] = true;
    //   setSelectedFiles(newSelectedFiles);
    //   setDisabledInputs(newDisabledInputs);
    // }

  };








  // const selectedFile = event.target.files[0];
  // if (selectedFile.size <= 2 * 1024 * 1024) { // 2 MB in bytes
  //     setFile(selectedFile);
  // } else {
  //     setError("File size exceeds the limit of 2MB.");
  // }


  const setDataOfDocument = (name, val) => {
    setDocumentData({ ...documentData, [name]: val });
  };

  
 

  let { uploadFile, uploadFileType } = documentData;
  // console.log(documentArr);
  // console.log(newUpload);
  const setDocumentTable = () => {
    // setDataOfDocument(e.target.name, selectDocumentType);
    let flag = false;
    // coonsole.log(documentArr);
    documentArr.map((ele) => {
      if (ele.uploadFileType.Mention === documentData.uploadFileType.Mention) {
        setError("1eaa431017273c99dc1e8bc68da1c66a");
        errorHandel(0);
        flag = true;
        return;
      }
    });
    if (!flag && documentArr.length < doclinkLength) {
      setDocumentArr([...documentArr, { uploadFile, uploadFileType }]);
      const uploadList = [...upload];
      // console.log(inputFile.current.files[0]);
      uploadList.push(newUpload);
      setUpload(uploadList);
      // setDocumentData({ uploadFile: "", uploadFileType: "" })
    } else if (documentArr.length === doclinkLength) {
      setError("39d252de291bc891f7b9356c54b6c1b1");
      errorHandel(0);
    }

  };

  const handleDocumentView = (index) => {
    // console.log(selectedFile)
    if (requiredDocument[index].filesuploaded) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result;
        openPdfInNewTab(fileContent);
      };
      reader.readAsDataURL(requiredDocument[index].filesuploaded);
    }
    else {
      setError('e518f09e0a344d4a4dc9c0b01d3cb382');
      errorHandel(0);
    }


  };


  const openPdfInNewTab = (fileContent) => {
    const newTab = window.open();
    newTab.document.write(
      '<iframe src="' + fileContent + '" width="100%" height="100%"></iframe>'
    );
  };

  // const fileReader = new FileReader();
  // fileReader.onload = () => {
  //   setFileContents(fileReader.result);
  // };
  // fileReader.readAsText(selectedFiles[index]);
  // console.log(selectedFiles);
  const handleDocumentDelete = (index) => {
    // const updatedUploadList = [...documentArr];
    // const updatedNewFileList = [...upload];
    // updatedUploadList.splice(index, 1);
    // updatedNewFileList.splice(index, 1);
    // setDocumentArr(updatedUploadList);
    // setUpload(updatedNewFileList);

    // const updatedDocumentType = [...documentType];
    // updatedDocumentType.splice(index, 1);
    // setDocumentType(updatedDocumentType);

    // const updatedSelectedFiles = [...selectedFiles];
    // updatedSelectedFiles[index] = null;
    const newRequiredDocument = [...requiredDocument];
    newRequiredDocument[index].filesuploaded = '';

    // let newSelectedFiles = [...selectedFiles];
    // newSelectedFiles[index] = ''

    // const updatedDisabledInputs = [...disabledInputs];
    // updatedDisabledInputs[index] = false;

    // countofDocuments -= 1;
    // setCountofDocuments(countofDocuments);
    // setSelectedFiles(updatedSelectedFiles);
    setRequiredDocument(newRequiredDocument);
    const inputElement = document.getElementById(`${index}`);
    if (inputElement) {
      inputElement.value = '';
    }
    // setDisabledInputs(updatedDisabledInputs);
    // setFlag(false);

    setInputfilevalue('');

    // if (updatedSelectedFiles.length == 0) {
    //   if (inputFile.current) {
    //     inputFile.current.value = "";
    //     inputFile.current.type = "text";
    //     inputFile.current.type = "file";
    //   }
    //   setSelectedFiles([]);
    //   // setDocumentType([]);
    //   setDocumentId("0");
    // }
  };

  const handelAttrachmentDelete = (index) => {
    if(requiredDocument[index].filesuploaded){
      setDeleteMessage(true);
      setDocumentIndex(index);
    }
    else{
      setError('e518f09e0a344d4a4dc9c0b01d3cb382');
      errorHandel(0);
    }
  };
  const deleteConfirm = () => {
    handleDocumentDelete(documentIndex);
    setDeleteMessage(false);
  };
  const deleteCancel = (e) => {
    setDocumentIndex("");
    setDeleteMessage(e);
  };
  //  console.log(requiredDocument)
  useEffect(() => {
    var count = 0;
    let newRequiredDocument = [...requiredDocument];
    serviceDiteals.DocLinks.map((item) => {
      if (item.IsRequired === "true") {
        count = count + 1;
        // console.log(item)
        newRequiredDocument.push({ item: item, filesuploaded: '' })
      }
    });

    if (!flag) {
      setDoclinkLength(count);
      setRequiredDocument(newRequiredDocument);
      setFlag(true);
    }


  }, [])
  // console.log(requiredDocument)


  useEffect(() => {
    var date = moment();
    setCurrentDate(date.format("DD/MM/YYYY"));


    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    axios
      .post(`${MasterKey}/getCapital`, { userCode: "1" }, config)
      .then((response) => {
        if (response.data.status === "1") {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    for (let i = 0; i < serviceDiteals.DocLinks.length; i++) {
      if (serviceDiteals.DocLinks[i].DocLink != null) {
        setFlagTemplate(1);
      }
    }
  }, []);


  useEffect(() => {
    if (guesthouseVillageName === "" || guesthouseVillageName === undefined || guesthouseCommuneName === undefined || guesthouseDistrictName === undefined || guesthuouseProvinceName === undefined) {
      setGuesthouseAddress("");
      return
    }
    let address = `ភូមិ ${guesthouseVillageName} ឃុំ-សង្កាត់ ${guesthouseCommuneName} ក្រុង-ស្រុក-ខណ្ឌ  ${guesthouseDistrictName} រាជធានី-ខេត្ត ${guesthuouseProvinceName}`
    setGuesthouseAddress(address)
  }, [guesthouseVillageName, guesthouseCommuneName, guesthouseDistrictName, guesthuouseProvinceName])

  console.log(selectedFiles.length);
  const serviceSubmithandel = () => {
    // console.log(countofDocuments);
    // let newSelectedFiles = [...selectedFiles];

    for (let i = 0; i < requiredDocument.length; i++) {
      if (requiredDocument[i].filesuploaded != '') {
        countofDocuments += 1;
        // newSelectedFiles.push(requiredDocument[i].filesuploaded)
      }
    }
    setCountofDocuments(countofDocuments);
    // setSelectedFiles(newSelectedFiles);
    if (
      guesthouseCapitalCode === "" ||
      guesthouseDistrictCode === "" ||
      guesthouseVillageCode === "" ||
      guesthouseCommuneCode === "" ||
      // streetNO === "" ||
      // homeNO === "" ||
      guesthouseName === "" ||
      guesthousePhone === "" ||
      guesthouseAddress === "" ||
      quantity < 1
    ) {
      setError("2d13aa4a4d1a2a0dedf1a03323d294b8");
      errorHandel(0);
      setCountofDocuments(0);

      return;
    }
    if (Object.keys(customer).length === 0) {
      setError("2d13aa4a4d1a2a0dedf1a03323d294b8");
      errorHandel(0);
      setCountofDocuments(0);

      return;
    }
    // special condition for otherditeals with data owner side image auto null
    if(isApplyforOthersDataAvialble != null){
      otherIDCard=""
    }
    if (formChangeOfCustomer != "ApplyforSelf" && otherIDCard == undefined) {
      setError("b52ef790f7319f17de8bd89212cc4b99");
      errorHandel(0);
      setCountofDocuments(0);

      return;
    }
    if (countofDocuments == 0) {
      console.log(countofDocuments)
      setError("9e4e9d4664a53b9c0683f8215a6d8f20");
      errorHandel(0);
      setCountofDocuments(0);

      return;
    }

    if (countofDocuments < doclinkLength) {

      console.log(countofDocuments);
      console.log(doclinkLength)
      setError("85cd03dff046c778472e474b87769c66");
      errorHandel(0);
      setCountofDocuments(0);

      return;
    }

    if(!isChecked){
      setError("a619141aea5f7ad57c083dbfb5db655f");
      errorHandel(0);
      setCountofDocuments(0);

      return
    }

    const newSelectedFiles = [];

    for (let i = 0; i < requiredDocument.length; i++) {
      if (requiredDocument[i].filesuploaded != '') {
        // countofDocuments += 1;
        console.log(requiredDocument[i].filesuploaded)
        newSelectedFiles.push(requiredDocument[i].filesuploaded)
        // console.log(newSelectedFiles)
      }
    }
    // setCountofDocuments(countofDocuments);
    // console.log(newSelectedFiles);
    setSelectedFiles(newSelectedFiles);
    // console.log(selectedFiles.length);






    // if (upload.length < doclinkLength) {
    //   setError("85cd03dff046c778472e474b87769c66");
    //   errorHandel(0);
    //   return;
    // }
    if (formChangeOfCustomer != "ApplyforSelf") {  // age 18 validation only applicable for others 
      if (serviceDiteals.Service.IsCheckAge18) {
        if (
          new Date().getFullYear() - new Date(customer.Dob).getFullYear() >
          18
        ) {
          setError("6f4ad87299c6dfec22d4035c77a52a31");
          errorHandel(0);
          return;
        }
      }
    }
    setIsloading(true);
    handelAttach();

    let formatDobDate = "";
    if (
      formChangeOfCustomer != "ApplyforSelf" &&
      isApplyforOthersDataAvialble == null
    ) {
      formatDobDate = `${customer.Dob.getFullYear()}-${customer.Dob.getMonth() + 1 < 10
        ? `0${customer.Dob.getMonth() + 1}`
        : customer.Dob.getMonth() + 1
        }-${customer.Dob.getDate() < 10
          ? `0${customer.Dob.getDate()}`
          : customer.Dob.getDate()
        }`;
    } else {
      formatDobDate = customer.Dob.substr(0, 10);
    }

    const Sid = sessionStorage.getItem("customerId");
    let OwnerId="";
    if (formChangeOfCustomer != "ApplyforSelf"){
      if(customer.CustomerId!=undefined){
        OwnerId=customer.CustomerId
      }
    }else{
      OwnerId=Sid;
    }
    const submitData = {
      createUid: customer.IdCard,
      createDate: "",
      modifiedUid: customer.IdCard,
      modifiedDate: "",
      versionNo: 0,
      isSubmitted: "",
      isActive: "",
      isDeleted: "",
      assigneeId: "",
      Register: {
        MstServiceId: serviceDiteals.Service.Id,
        CustomerId: Sid,
        ProCode: guesthouseCapitalCode,
        DistrictCode: guesthouseDistrictCode,
        TypeId: 3, //district level
        Quantity: quantity,
        OwnerId: OwnerId,
        Partner: "ONLINE DMK",
        PartnerRefNo: "",
      },
      Business: {
        Name:  businessLatinName,
        LatinName: guesthouseName,
        Phone: guesthousePhone,
        HomeNo: homeNO,
        StreetNo: streetNO,
        Address: guesthouseAddress,
        Capital: guesthouseCapitalCode,
        District: guesthouseDistrictCode,
        Commune: guesthouseCommuneCode,
        Village: guesthouseVillageCode,
      },
      Customer: {
        IdCard: customer.IdCard,
        FirstName: customer.FirstName,
        LastName: customer.LastName,
        LatinFirstName: customer.LatinFirstName,
        LatinLastName: customer.LatinLastName,
        Address: customer.Address,
        Sex: customer.Sex,
        //         Dob:`${formChangeOfCustomer!=" ApplyforSelf"?formatDobDate:customer.Dob. substr(0,10)}`,
        Dob: formatDobDate,
        StreetNo: streetHome.streetNO,
        HomeNo: streetHome.homeNO,
        ProCode: customer.CapitalCode,
        IdentityType: `${formChangeOfCustomer === "ApplyforSelf" ? "" : 1}`,
        ContentBack: "",
      },
      Attachments: Attachments,
    };

    let reUpload;

    if (formChangeOfCustomer === "ApplyforSelf" || isApplyforOthersDataAvialble != null) {
      reUpload = null;
    } else {
      reUpload = otherIDCard;
    }
    const form = new FormData();
    form.append("SubmitLegalizationRequest", JSON.stringify(submitData));
    console.log(newSelectedFiles)
    // console.log(countofDocuments)

    newSelectedFiles.forEach((item) => form.append("attachments", item))

    form.append("fileContentBack", reUpload);
    if (sessionStorage.getItem("accessToken")) {
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      axios
        .post(`${LegalizationKey}/submitRegisterLegalization`, form, config)
        .then((response) => {
          setIsloading(false);
          if (response.data.status == 1) {
            setSubmitResponse(response.data.responseObject.Json.Register);
            setModal(true);
          } else if (response.data.responseObject.IsError) {
            setError(response.data.responseObject.ErrorMessage);
            errorHandel(0);
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    }
  };

  const handelAttach = () => {
    // let title = "";
    // for (let i = 0; i < serviceDiteals.DocLinks.length; i++) {
    //   if (attachDocID === serviceDiteals.DocLinks[i].ServiceRequireDocId) {
    //     title = serviceDiteals.DocLinks[i].Mention;
    //   }
    // }
    // console.log(documentArr[1].uploadFileType);
    let attachmentDiteals = {};
    for (let i = 0; i < requiredDocument.length; i++) {
      // console.log(documentArr[i].uploadFileType);
      attachmentDiteals = {
        Title: requiredDocument[i].item.Mention,
        FileType: requiredDocument[i].filesuploaded.name.split(".").pop(),
        ServiceRequireDocId: requiredDocument[i].item.ServiceRequireDocId,
        Content: "",
      };
      Attachments.push(attachmentDiteals);
    }



  };

  const getApplicationReceipt = () => {
    let tokenss = sessionStorage.getItem("accessToken");
    let data = {
      serviceApplicationRegisterCode: submitResponse.RegisterCode,
      loggedinUserCode: sessionStorage.getItem("userId"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
      responseType: "blob",
      body: JSON.stringify(data),
    };
    fetch(
      `${LegalizationKey}/downloadLegalizationApplication`,
      requestOptions
    ).then((response) => {
      response.blob().then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${serviceDiteals.Service.Name}_${submitResponse.RegisterCode}.pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
    });
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };
  const errorClose = (e) => {
    setErrorBlock(e);
  };
  // console.log(guesthuouseProvinceName);
  // console.log(guesthouseDistrictName);
  // console.log(guesthouseCommuneName);
  // console.log(guesthouseVillageName);
  const setProvinceData = (e) => {
    if (e.target.value) {
      console.log(e)
      let capital = JSON.parse(e.target.value);

      setGuesthouseCapitalCode(capital.Code);
      setGuesthouseProvinceName(capital.Name);
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      // api for get district
      axios
        .post(
          `${MasterKey}/getDistrictByCapital`,
          {
            userCode: "string",
            capitalId: capital.Id,
            online: true,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            districtRef.current.value = "0";
            setApiDistricts(response.data.responseObject.Data.Districts);
            setGuesthouseDistrictName('');
            setApiCommune([]);
            setGuesthouseCommuneName('');
            setApiVillage([]);
            setguesthouseVillageName('');
          }
        })
        .catch((err) => {
          console.log(err);
        });

    }
    else return;
  };


  const setDistrictData = (e) => {
    if (e.target.value) {
      let district = JSON.parse(e.target.value);

      setGuesthouseDistrictCode(district.Code);
      setGuesthouseDistrictName(district.Name);
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      // api for get commune
      axios
        .post(
          `${MasterKey}/getCommuneByDistrict`,
          {
            userCode: "string",
            districtId: district.Id,
          },
          config
        )
        .then((response) => {

          if (response.data.status == 1) {
            communeRef.current.value = "0";
            setApiCommune(response.data.responseObject.Data.Communes);
            setGuesthouseCommuneName('');
            setApiVillage([]);
            setguesthouseVillageName('');
          }


        })
        .catch((err) => {
          console.log(err);
        });
    }

    else return;
  };

  const setCommuneData = (e) => {

    let commune = JSON.parse(e.target.value);
    setGuesthouseCommuneCode(commune.Code);
    setGuesthouseCommuneName(commune.Name);
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: commune.Id,
        },
        config
      )
      // response.data.responseObject && response.data.responseObject.Data.Villages.length === 0
      .then((response) => {
        if (response.data.status == 1) {
          villageRef.current.value = "0";

          setApiVillage(response.data.responseObject.Data.Villages);
          setguesthouseVillageName('');
        }
      })
      .catch((err) => {
        console.log(err);
      });



  };

  const setVillageData = (e) => {
    if (e.target.value) {
      let village = JSON.parse(e.target.value);
      setGuesthouseVillageCode(village.Code);
      setguesthouseVillageName(village.Name);
    }

    else return;
  };

  const handlePhoneNumber = (e) => {
    const inputValue = e.target.value;
    const phoneRegex = /^\d{8,10}$/;
    const isValidPhone = phoneRegex.test(inputValue);
    setGuesthousePhone(inputValue);
    // setIsValid(isValidPhone);
    if (inputValue.length === 0 || inputValue.length === 8 || inputValue.length === 9 || inputValue.length === 10) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const handleTemplateDownload = (e) => {
    let data = e.split(".");
    console.log(data[0]);
    window.open(
      `https://newdmkapi.owso.gov.kh/api/file/doclink/${data[0]}`,
      "_blank"
    );
    // newdmkapi.owso.gov.kh
  };

  return (
    <div className="col-lg-7 col-md-7 col-sm-12">
      {errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
      {errorblock && errorClass == "errorMessage" && (
        <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
      )}
      {deleteMessage && (
        <ModalDeleteConfirm
          deleteConfirm={deleteConfirm}
          deleteCancel={deleteCancel}
        ></ModalDeleteConfirm>
      )}
      <div className="card card-lg card-other">
        <div className="row" style={{ marginBottom: "80px" }}>
          <form>
            <h5 style={{ fontweight: "bold", color: "#0061f2" }}><b>{t("026eef87021de60dcac96ee4acd8f518")}</b></h5>
            <hr />
            {/* <!-- Form Row--> */}
            <div className="form-row">
              {/* <!-- Form Group (first name)--> */}
              <div className="form-group col-md-12">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
                  {t("46140fd4f90101f3beccfd3428bea873")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                {/* <span className="mandatory">*</span> */}
                {/* <select
                className="form-control col-sm col-md-12"
                data-val="true"
                data-val-number="The field Select State/UT must be a number."
                data-val-required="Select State/UT"
                id="StateId"
                name="StateId"
              >
                <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                <option value="33">ID Card</option>
                <option value="33">Family Book</option>
                <option value="33">Residence Book</option>
              </select> */}
                <input
                  className="KhmerOSsiemreaptext form-control input-dark"
                  id="inputFirstName"
                  type="text"
                  placeholder={t("6b3c4fac8f71637289b968e251d4429c")}
                  value={serviceDiteals.Service.Name}
                  disabled
                />
              </div>

              {/* <!-- Form Group (last name)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("5139a143878751e4282ab73759c95079")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="KhmerOSsiemreaptext form-control input-dark"
                  id="inputFirstName"
                  type="text"
                  placeholder="3 Working Days (After acceptance of application)"
                  title="3 Working Days (After acceptance of application by front office )"
                  value={serviceDiteals.Service.ProcessDay}
                  disabled
                />
              </div>
              {/* <!-- Form Group (phone number)--> */}

              {/* <!-- Form Group (last name)--> */}
              {/* <div className="form-group col-md-6">
              <label className="small mb-1" for="inputLastName">
                Document ID
              </label>
              <input
                className="form-control"
                id="inputFirstName"
                type="text"
                placeholder="Enter Document ID"
              />
            </div> */}

              {/* <!-- Form Group (phone number)--> */}

              {/* <!-- Form Group (phone number)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                  {t("694e8d1f2ee056f98ee488bdc4982d73")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputPhone"
                  type="text"
                  placeholder={t("4bb55d367528dab71bd30ba3689b27b7")}
                  value={quantity}
                  onChange={(e) => {
                    setQuantity(e.target.value.replace(/[^0-9]+/g, ''))
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                  {t("a90dc8c8855d760675981f47db71a98e")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="KhmerOSsiemreaptext form-control input-dark"
                  id="inputPhone"
                  type="tel"
                  placeholder="0"
                  disabled
                  value={serviceDiteals.Service.Price}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                  {t("70b626c98cf6be5204a2e76bc47f39bb")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className="KhmerOSsiemreaptext form-control input-dark"
                  id="inputPhone"
                  type="tel"
                  placeholder="0"
                  value={serviceDiteals.Service.Price * quantity}
                  disabled
                />
              </div>
              {/* Business Section*/}
              <hr />
              <h5
                style={{ textAlign: "left", fontweight: "bold", color: "#0061f2" }}
                className="col-12 mb-0 mt-2"
              >
                <b>{t("d6e6cb19e3b9c02f89d6cd54cfa7c613")}</b>
              </h5>
              <hr style={{ width: "100%" }} />

              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("a55aee8acc3abf18159a13674136052a")} (KH) {" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className={`KhmerOSsiemreaptext form-control `}
                  id="inputFirstName"
                  type="text"
                  placeholder={t("a55aee8acc3abf18159a13674136052a")}
                  onChange={(e) =>
                    setBusinessLatinName(e.target.value.replace(/[<>\/]/g, ""))
                  }
                />
              </div>


              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("a55aee8acc3abf18159a13674136052a")} (EN) {" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className={`KhmerOSsiemreaptext form-control `}
                  id="inputFirstName"
                  type="text"
                  placeholder={t("a55aee8acc3abf18159a13674136052a")}
                  onChange={(e) =>
                    setGuesthouseName(e.target.value.replace(/[<>\/]/g, ""))
                  }
                  
                />
              </div>


              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("464972ecbc84025453afcb389470c928")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <input
                  className={`KhmerOSsiemreaptext form-control`}
                  id="inputFirstName"
                  type="number"
                  placeholder={t("464972ecbc84025453afcb389470c928")}
                  value={guesthousePhone}
                  onChange={handlePhoneNumber}
                // {(e) => setGuesthousePhone(e.target.value)}
                />
                {isValid ? (
                  <p style={{ color: "red" }}>{t("613ef4baa62da2b5aa61e3cbfcd03cce")}</p>
                ) : null}
              </div>

              {/* <!-- Form Group (last name)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("c1a3626600b7d8a8b3e0b2474ab5c202")}
                </label>
                <input
                  className={`KhmerOSsiemreaptext form-control `}
                  id="inputFirstName"
                  type="text"
                  placeholder={t("c1a3626600b7d8a8b3e0b2474ab5c202")}
                  onChange={(e) => setHomeNo(e.target.value)}
                />
              </div>

              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("070a6f943470aec6d12c7090f290e10f")}
                </label>
                <input
                  className={`KhmerOSsiemreaptext form-control  `}
                  id="inputFirstName"
                  type="text"
                  placeholder={t("070a6f943470aec6d12c7090f290e10f")}
                  onChange={(e) => setStreetNO(e.target.value)}
                />
              </div>

              {/* <!-- Form Group (last name)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                  data-val="true"
                  name="StateId"
                  onChange={setProvinceData}
                  ref={provinceRef}

                //   value={existingOtherData.CapitalId}
                //   disabled
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apicapital.map((capital) => (
                    <option value={JSON.stringify(capital)} selected="" className="KhmerOSsiemreaptext">
                      {capital.Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <!-- Form Group (last name)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                  data-val="true"
                  name="StateId"
                  onChange={setDistrictData}
                  ref={districtRef}
                //   value={existingOtherData.DistrictId}
                //   disabled
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apidistricts.map((districts) => (
                    <option value={JSON.stringify(districts)} selected="" className="KhmerOSsiemreaptext">
                      {districts.Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <!-- Form Group (last name)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("04beeb76f7acc97bb1e309f654fdee7a")}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                  data-val="true"
                  name="StateId"
                  onChange={setCommuneData}
                  ref={communeRef}
                //   value={existingOtherData.CommuneId}
                //   disabled
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apicommune.map((commune) => (
                    <option value={JSON.stringify(commune)} selected="" className="KhmerOSsiemreaptext">
                      {commune.Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* <!-- Form Group (last name)--> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("abc63490c815af81276f930216c8d92b")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <select
                  className={`KhmerOSsiemreaptext form-control col-sm col-md-12 `}
                  data-val="true"

                  name="StateId"
                  onChange={setVillageData}
                  ref={villageRef}
                //   value={existingOtherData.VillageId}
                //   disabled
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apiVillage.map((village) => (
                    <option value={JSON.stringify(village)} selected="" className="KhmerOSsiemreaptext">
                      {village.Name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-12">
                <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                  {t("861a00de3a9b1a4751e4e3190944227e")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <textarea
                  className={`KhmerOSsiemreaptext form-control `}
                  id="inputFirstName"
                  type="text"
                  placeholder={t("861a00de3a9b1a4751e4e3190944227e")}
                  value={guesthouseAddress}
                  disabled
                />
              </div>

              {/* <!-- Form Group (organization name)--> */}
              {/* {t("25b2a90d6504b30bf67144df2a9db1c7")} */}
              {/* <div className="form-group col-md-6 mb-0">
                <label className="small mb-1" for="inputOrgName" style={{fontweight: "bold",marginTop: "17px",color: "#0061f2"}}>
                  {t("4f67fe16b274bf31a67539fbedb8f8d3")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>
                <div className="text-left">
                  <select
                    className="form-control col-sm col-md-12"
                    data-val="true"
                    data-val-number="The field Select State/UT must be a number."
                    data-val-required="Select State/UT"
                    id="StateId"
                    name="uploadFileType"
                    value={documentId}
                    onChange={setAttachedDoc}
                  >
                    <option value="0">
                      {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                    </option>
                    {serviceDiteals.DocLinks.map(
                      (docklink) =>
                        docklink.IsRequired === "true" && (
                          <option
                            style={{ paddingRight: "5px" }}
                            value={JSON.stringify(docklink)}
                            key={docklink.ServiceRequireDocId}
                            
                          >
                            {docklink.Mention}
                          </option>
                        )
                    )}
                  </select>
                  
                </div>
              </div> */}
              {/* <!-- Form Group (location)--> */}
              {/* {t("523c9b00a728a0dad486e9fdcedc716c")} */}
              {/* <div className="form-group col-md-6 mb-0">
                <label className="small mb-2" for="inputLocation" style={{marginTop: "17px"}}>
                  {t("523c9b00a728a0dad486e9fdcedc716c")}{" "}
                  <span style={{ color: "red" }}>*</span>
                </label>

                
                <input
                  type="file"
                  ref={inputFile}
                  className="text-center center-block file-upload"
                  disabled={attachDocID == ""}
                  onChange={handleFileChange}
                  name="uploadFile"
                />
                <p
                  className="mt-1"
                  style={{
                    fontSize: "13px",
                    fontStyle: "italic",
                    color: "red",
                  }}
                >
                  {t("2ffe94058afaea3b875be2082941ec0b")}
                </p>
              </div> */}
              {/* add attachment button */}
              {/* {newUpload != undefined ? (
                <div className="form-group col-md-12">
                  <div className="text-center">
                    <button
                      className="btn btn-primary"
                      type="button"
                      onClick={setDocumentTable}
                      style={{ height: "32px" }}
                    >
                      {t("671f3f212fe0533aaa7cea889ee04ef8")}
                    </button>
                  </div>
                </div>
              ) : null} */}

              {flagTemplate == 1 && (
                <div className="form-group col-md-12">
                  <label className="KhmerOSsiemreaptext small mt-3" for="inputLocation" style={{ fontweight: "bold", color: "#0061f2", marginBottom: '4px' }}>
                    {t("62ae9fe7b10b8c20909c1cef5970d5ad")}{" "}
                    <span style={{ color: "red" }}>*</span>
                  </label>
                  <hr style={{ width: '100%', marginTop: '-5px' }} />
                  <table className="table mt-0" >
                    <thead className="thead-light">
                      <tr>
                        <th
                          className="KhmerOSsiemreaptext small text-center"
                          scope="col"
                          style={{ width: "10rem" }}
                        >
                          <b>{t("598d42810b342196884f255912974d69")}</b>
                        </th>
                        <th
                          className="KhmerOSsiemreaptext small text-center"
                          style={{ width: "40rem" }}
                        >
                          <b>{t("62ae9fe7b10b8c20909c1cef5970d5ad")}</b>
                        </th>
                        <th
                          className="KhmerOSsiemreaptext small text-center"
                          style={{ width: "10rem" }}
                        >
                          <b>{t("4240abaacda205bcafd1df82fd1586de")}</b>
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {serviceDiteals.DocLinks.map((doclink, index) => {
                        return (
                          doclink.DocLink != null && (
                            <tr key={index}>
                              <td className="KhmerOSsiemreaptext text-center">{++slno_template}</td>
                              <td className="KhmerOSsiemreaptext text-center">{doclink.Mention}</td>
                              <td
                                className="text-center"
                                onClick={() =>
                                  handleTemplateDownload(doclink.Id)
                                }
                              >
                                <i
                                  style={{ color: "green", cursor: "pointer" }}
                                  class="fa fa-download"
                                  aria-hidden="true"
                                ></i>
                              </td>
                            </tr>
                          )
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              )}


              {requiredDocument.length > 0 && <div className="form-group col-md-12">
                <label className="KhmerOSsiemreaptext small mt-3" for="inputLocation" style={{ fontweight: "bold", color: "#0061f2" }}>
                  {t("1c0a9e6f20b9f1b59d22290d9f0f31a7")}{" "}
                  <span style={{ color: "red" }}>*</span>
                  <p
                    className="KhmerOSsiemreaptext mt-1"
                    style={{
                      fontSize: "11.5px",
                      fontStyle: "italic",
                      color: "red",
                    }}
                  >
                    {t("2ffe94058afaea3b875be2082941ec0b")}
                  </p>
                </label>
                <hr style={{ width: '100%', marginTop: '-15px' }} />



                <table className="table" style={{ marginTop: '-5px', marginBottom: '7px' }}>
                  <thead className="thead-light">
                    <tr>
                      <th
                        className="KhmerOSsiemreaptext small text-center"
                        scope="col"
                        style={{ width: "6rem" }}
                      >
                        <b>{t("598d42810b342196884f255912974d69")}</b>
                      </th>
                      <th
                        className="KhmerOSsiemreaptext small text-center"
                        style={{ width: "20rem" }}
                      >
                        <b>{t("25b2a90d6504b30bf67144df2a9db1c7")}</b>
                      </th>
                      <th
                        className="KhmerOSsiemreaptext small text-center"
                        style={{ width: "20rem" }}
                      >
                        <b>{t("523c9b00a728a0dad486e9fdcedc716c")}</b>
                      </th>
                      <th
                        className="KhmerOSsiemreaptext textAlign-center small"
                        style={{ width: "10rem" }}
                      >
                        <b> {t("004bf6c9a40003140292e97330236c53")}</b>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {requiredDocument.map((docklink, index) => {
                      {
                        // slno_document = slno_document + 1; // Increment serial number if condition is met
                        return (
                          <tr key={index}>
                            <td className="KhmerOSsiemreaptext text-center">{index + 1}</td>
                            <td className="KhmerOSsiemreaptext text-center">{docklink.item.Mention}</td>
                            <td className="KhmerOSsiemreaptext text-center">
                              <input
                                type="file"
                                ref={inputFile}
                                className="KhmerOSsiemreaptext text-center center-block file-upload"
                                key={inputKey}
                                // disabled={attachDocID == ""}
                                // disabled={disabledInputs[index]}
                                onChange={(event) => handleFileChange(event, index)}
                                name="uploadFile"
                                style={{ display: 'none' }} // Hide the input visually
                                id={`${index}`} // Associate label with input using htmlFor
                              />
                              <label className="KhmerOSsiemreaptext" htmlFor={`${index}`} style={{ cursor: !docklink.filesuploaded && 'pointer', padding: !docklink.filesuploaded && '5px', border: !docklink.filesuploaded && '1px solid #ccc', borderRadius: !docklink.filesuploaded && '2px', backgroundColor: !docklink.filesuploaded && '#e2dede', color: docklink.filesuploaded && 'grey' }} >
                                {docklink.filesuploaded != '' ? docklink.filesuploaded.name : `${t("e635032a5f71d809146d3872389f5b0c")}`}
                              </label> 
                            </td>
                            <td className="text-center">
                              <BsEyeFill
                                className="mx-1"
                                onClick={() => {
                                  handleDocumentView(index);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#0000ffdb",
                                }}
                                disable={icon}
                              />
                              <MdDelete
                                // className="mx-4"
                                onClick={() => {
                                  handelAttrachmentDelete(index);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#b00000b0",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </table>

              </div>}

              <div className="form-group col-md-12">
              <label className="KhmerOSsiemreaptext small mt-3" for="inputLocation" style={{fontweight: "bold",color: "red"}}>
              {t("f6e9033f4ba0d801e4f74a2790dee102")}* 
              </label>
              <div className="term_condition_checkbox">
                <input type="checkbox" onChange={(e) => setIsChecked(e.target.checked)} className="term_condition_checkbox_box"/>
                <label htmlFor="" className="term_condition_checkbox_text">ខ្ញុំសូមធានាអះអាងថា ព័ត៌មានដែលបានផ្ដល់ជូនខាងលើនេះជាព័ត៌មានដែលពិតប្រាកដ និងត្រឹមត្រូវ។ ក្នុងករណីមានព័ត៌មានណាមួយមិនត្រឹមត្រូវ ខ្ញុំបាទ/នាងខ្ញុំ សូមទទួលខុសត្រូវចំពោះមុខច្បាប់ជាធរមាន។ (សូមបញ្ជាក់ថា ត្រូវ ដាច់ខាត)</label>
              </div>
              </div>

              {/* map function for documentArr */}
              {/* {requiredDocument.map((item, index) => {
                        console.log(item)
                        return (
                          <tr key={index}>
                            <td className="text-center">{index + 1}</td>
                            <td className="text-center">
                              {item.Mention}
                            </td>
                            <td className="text-center">
                              {item.Mention}
                            </td>
                            <td className="text-center">
                              <BsEyeFill
                                className="mx-1"
                                onClick={() => {
                                  handleDocumentView(item);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#0000ffdb",
                                }}
                              />

                              <MdDelete
                                className="mx-4"
                                onClick={() => {
                                  handelAttrachmentDelete(index);
                                }}
                                style={{
                                  cursor: "pointer",
                                  color: "#b00000b0",
                                }}
                              />
                            </td>
                          </tr>
                        );
                      })} */}




              {/* {flagTemplate == 1 && (
                <div className="form-group col-md-6">
                  <label className="small mb-2" for="inputLocation">
                    {t("62ae9fe7b10b8c20909c1cef5970d5ad")}
                  </label>
                  <ol>
                    {serviceDiteals.DocLinks.map(
                      (doclink) =>
                        doclink.DocLink != null && (
                          <li
                            target="blank"
                            onClick={() => handleTemplateDownload(doclink.Id)}
                          >
                            <Link
                              style={{ textDecoration: "underline" }}
                              target="blank"
                              onClick={() => handleTemplateDownload(doclink.Id)}
                            >
                              {doclink.Mention}
                            </Link>
                            &nbsp;&nbsp;&nbsp;
                            <i
                              style={{ color: "green", cursor: "pointer" }}
                              class="fa fa-download"
                              aria-hidden="true"
                            ></i>
                          </li>
                        )
                    )}
                  </ol>
                </div>
              )} */}

              {/* <div className="form-group col-md-6">
              <label className="small mb-1" for="inputPhone">
                Date of Appliaction
              </label>

              <input
                className="form-control input-dark"
                disabled
                id="start"
                name="trip-start"
                value={currentDate}
              />
            </div> */}
            </div>
          </form>
        </div>
        <div className="col-12 fot-row row">
          <div className="d-flex justify-content-between col-6"></div>

          <div className="d-flex flex-row justify-content-end col-6">
            {/* <!-- Button trigger modal --> */}
            <button
              type="submit"
              className="KhmerOSsiemreaptext btn btn-primary"
              data-toggle="modal"
              data-target="#exampleModal"
              onClick={serviceSubmithandel}
            >
              {t("a4d3b161ce1309df1c4e25df28694b7b")}
            </button>

            {/* <!-- Modal --> */}
            <div className={`${modal && "loderdiv"}`}>
              <div
                className={`modal fade ${modal && "show modal-img"} `}
                id="exampleModal"
                tabindex="-1"
                role="dialog"
                aria-labelledby="exampleModalLabel"
                aria-hidden="true"
                style={{ display: `${modal && "block"}` }}
              >
                <div className="modal-dialog" role="document">
                  <div className="modal-content">
                    <div className="modal-header">
                      <h5
                        className="modal-title"
                        id="exampleModalLabel"
                        style={{ padding: "0" }}
                      ><b>
                          {t("674880d923c4e1086190af98851ec927")}
                        </b></h5>
                      <button
                        type="button"
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        {/* <span aria-hidden="true">&times;</span> */}
                      </button>
                    </div>
                    <div className="modal-body">
                      <div className="row justify-content-center">
                        <div className="col-3">
                          <img
                            src="https://img.icons8.com/color/96/000000/ok--v2.png"
                            className="fit-image"
                          />
                        </div>
                      </div>
                      <div className="KhmerOSsiemreaptext col-md-12 col-lg-12 text-center">
                        {t("1769908af5b99c90bf8f8fcea46ba6da")}
                      </div>
                      <br />
                      <div className="row" style={{ marginBottom: "80px" }}>
                        <div className="KhmerOSsiemreaptext col-md-6 md-6" style={{ paddingLeft: '40px' }}>
                          {t("9b4e480ebe53f5031ab51891acfec191")}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6">
                          :&nbsp;&nbsp;&nbsp;
                          {submitResponse.RegisterCode}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6 md-6" style={{ paddingLeft: '40px' }}>
                          {t("13427696ab995e729d57a9fee166f805")}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6">
                          :&nbsp;&nbsp;&nbsp;
                          {serviceDiteals.Service.Name}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6 md-6" style={{ paddingLeft: '40px' }}>
                          {t("b55afee3a3deb460e6149b486d8d9a49")}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6">
                          :&nbsp;&nbsp;&nbsp;{submitResponse.Status}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6 md-6" style={{ paddingLeft: '40px' }}>
                          {t("f18c4dab2674eae1e8c287c683b66729")}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-6">
                          :&nbsp;&nbsp;&nbsp;{currentDate}
                        </div>
                        <div className="KhmerOSsiemreaptext col-md-12 district_message">
                          ពាក្យស្នើសុំរបស់អ្នកត្រូវបានបញ្ជូនទៅការិយាល័យច្រកចេញចូលតែមួយក្រុង ស្រុក ខណ្ឌ ({guesthouseDistrictName})
                        </div>
                        {/* <div className="col-md-6 md-6">
                        Expected Date of Delivery
                      </div>
                      <div className="col-md-6">
                        :&nbsp;&nbsp;&nbsp;8/11/2023
                      </div> */}
                        {/* <div className="col-md-6 md-6">
                        Track your application status
                      </div>
                      <div className="col-md-6">
                        :&nbsp;&nbsp;&nbsp;
                        <img
                          width="40"
                          height="40"
                          src="https://img.icons8.com/ultraviolet/40/qr-code--v1.png"
                          alt="qr-code--v1"
                        />
                      </div> */}
                      </div>
                    </div>
                    <div className="modal-footer text-center">
                      <div className="col-12 fot-row row">
                        <div className="d-flex justify-content-between col-6">
                          <button
                            className="KhmerOSsiemreaptext btn btn-primary collapsed"
                            type="button"
                            onClick={getApplicationReceipt}
                          >
                            {t("4240abaacda205bcafd1df82fd1586de")} &nbsp;
                            &nbsp;
                            <img
                              width="25"
                              height="25"
                              src="https://img.icons8.com/dotty/80/download.png"
                              alt="download"
                            />
                          </button>
                        </div>
                        <div className="d-flex flex-row justify-content-end col-6">
                          <button
                            type="button"
                            className="KhmerOSsiemreaptext btn btn-secondary"
                            data-dismiss="modal"
                            onClick={() => Navigate("/dashboard")}
                          >
                            {t("d3d2e617335f08df83599665eef8a418")}
                          </button>
                          <a href="#" className=""></a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default TourismServiceDetails;

// to_convert

// Template Document Download
// Guesthouse Phone Number
// Guesthouse Name
// Service Duration (Days)
// Service Name
