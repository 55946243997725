import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import DatePicker from "react-datepicker";
import { useNavigate } from "react-router-dom";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { MdDelete } from "react-icons/md";
import { BsEyeFill } from "react-icons/bs"; 

import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";
import ModalImage from "../Error-Span-Modal/ModalImage";

function RegistrationProcessA(props) {
  const { t } = useTranslation();
  const isLoginpage = true;
  let navigate = useNavigate();
  const inputRefIDCardFrontSide = useRef(null);
  const inputRefIDCardBackSide = useRef(null);

  const provinceRef = useRef(null);
  const districtRef = useRef(null);
  const communeRef = useRef(null);
  const villageRef = useRef(null);

  const [id, setId] = useState("");
  const [firstName, setfirstName] = useState("");
  const [lastName, setlastName] = useState("");
  const [latinFirstName, setLatinFirstName] = useState("");
  const [latinLastName, setLatinLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateExpired, setDateExpired] = useState("");
  const [dobAddress, setDobAddress] = useState("");
  const [sex, setSex] = useState(0);
  const [capitalCode, setCapitalCode] = useState("");
  const [capitalId, setCaptialId] = useState("");
  const [districtCode, setDisctirctCode] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [communeCode, setCommuneCode] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [villageCode, setVillageCode] = useState("");
  const [villageId, setVillageId] = useState("");
  const [email, setEmail] = useState("");
  const [partner, setPartner] = useState("");
  const formData = new FormData();
  const [frontID, setFrontID] = useState();
  const [backID, setBackID] = useState();
  const [frontBase64, setFrontBase64] = useState("");
  const [backBase64, setBackBase64] = useState("");
  const [frontName, setFronName] = useState("");
  const [backName, setBackName] = useState("");
  const [token, setToken] = useState("");
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [frontError, setfronError] = useState("");
  const [backError, setbackError] = useState("");
  const [viewDocument,setViewDocumet]=useState(false)
  // const [startDate, setStartDate] = useState(new Date());
  const [upload, setUpload] = useState([]);
  //data coming from api
  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);
  const [apidob, setApiDob] = useState("");
  const [isValid, setIsValid] = useState(true);

  const Masterkey = process.env.REACT_APP_OWCS_MASTER_SERVICE;

  const years = range(1950, getYear(new Date()) + 50, 1);
  const currentYear = range(1850, getYear(new Date()) + 1, 1);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (props.isExistCustomer) {
      setId(props.customer.IdCard);
      setfirstName(props.customer.FirstName);
      setlastName(props.customer.LastName);
      setLatinFirstName(props.customer.LatinFirstName);
      setLatinLastName(props.customer.LatinLastName);
      setDobAddress(props.customer.DobAddress);
      setSex(props.customer.Sex);
      setEmail(props.customer.Email);
      // setDateOfBirth(props.customer.Dob)
      setCapitalCode(props.customer.CapitalCode);
      setCaptialId(props.customer.CapitalId);
      setDisctirctCode(props.customer.DistrictCode);
      setDistrictId(props.customer.DistrictId);
      setCommuneCode(props.customer.CommuneCode);
      setCommuneId(props.customer.CommuneId);
      setVillageCode(props.customer.VillageCode);
      setVillageId(props.customer.villageId);
      const dob = props.customer.Dob.substr(0, 10);
      setApiDob(dob);
    } else setId(props.id);
    if (sessionStorage.getItem("accessToken")) {
      const tokenID = sessionStorage.getItem("accessToken");
      setToken(tokenID);
    }
    // api for get capitals from database

    // let config = {
    //   headers: {
    //     Authorization:
    //     "BGMNysVcHLzzQyiMWiIIjIvJuQdHlFcsa4bdMw3dnA04gGgv2IW7D7Y2A93i6_9q9P9peo8aCeWcuFVSIJf4IevkEr9x51pjUWsm5xgt7Q0axcqw_4Q1r9_KUKRhMC7AW4Jq-x2_CGowhAHQiNmAt0Q5SyPg2bVOmdaNxUlV1aLGk0PnlWOH2ETAoDYWv_L0mpAx_JmrvvHpF-Wy2UPDZjmlt0td5uCVIKTSZEVbJlP-squG_5A1aqAdbspEUJqMjG9Egg-6XhcTqLomLz0_ooiQn6USXb3h_UU3He8BgZpYST9SKA4WCIgojK_3VWi0SRIXZfYp-ZZUlFPRZhii_A2Hkpd5IzEqGurm7ANPgsN7l3Y3MwwnikoCHSSV37mmIxH_NO6PRb11iMkSbfXroDXaxMKGiWHIfCfMejOxUf0tQzL56uCGv_0kzuYz2Ap5NWFxrH4G8DUMpCDu5rt316NOjTNSwt3cbq8YV2o6GIA",
    //   },
    // };
    axios
      .post(
        `${Masterkey}/getAllCapital`,
        { userCode: "1" }
        //config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get districts from database
    axios
      .post(
        `${Masterkey}/getAllDistrictByCapital`,
        {
          userCode: "string",
          capitalId: capitalId,
          online: true,
        }
        //config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get commune from database
    axios
      .post(
        `${Masterkey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: districtId,
        }
        //config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    /// api for get villages from database
    axios
      .post(
        `${Masterkey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: communeId,
        }
        // config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [capitalCode, districtCode, communeCode, villageCode]);

  useEffect(() => {
    if (!props.isExistCustomer) {
      setApiDob(dateOfBirth);
    }
  }, [dateOfBirth]);

  const IDcardFronthandel = (e) => {
    // formData.append("idCardFront", file);
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      inputRefIDCardFrontSide.current.value = '';
      setFrontID(null);
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
      setFronName("");
    } else {
      if (e.target.files[0].size >= 10 * 1024 * 1024) {
        inputRefIDCardFrontSide.current.value = '';
        setFrontID(null);
        setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
        errorHandel(0);
        return;
      }
      let file = e.target.files[0];
      setFronName(e.target.files[0].name);
      setFrontID(file);
      setfronError(" ");

      getBase64(file).then((res) => setFrontBase64(res));
    }
  };

  const IDcardBackhandel = (e) => {
    // formData.append("idCardBack", file);
    if (!e.target.files[0].name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      inputRefIDCardBackSide.current.value = '';
      setBackID(null);
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
      setBackName("");
    } else if (e.target.files[0].size >= 10 * 1024 * 1024) {
      inputRefIDCardBackSide.current.value = '';
      setBackID(null);
      setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
      errorHandel(0);
      return;
    } else {
      const file = e.target.files[0];
      setBackName(e.target.files[0].name);
      setBackID(file);
      setfronError(" ");

      getBase64(file).then((res) => setBackBase64(res));
    }
  };

  // function convet file to base64
  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(()=>{
    //   setErrorBlock(false)
    // },4000)
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  const nextregistrationProcessA = () => {
    if (
      id === "" ||
      firstName === "" ||
      lastName === "" ||
      latinFirstName === "" ||
      latinLastName === "" ||
      dobAddress === "" ||
      sex === "" ||
      // dateOfBirth === "" ||
      capitalCode === "" ||
      districtCode === "" ||
      communeCode === "" ||
      villageCode === "" ||
      dateExpired === "" ||
      // email === "" ||
      frontName === "" ||
      backName === "" ||
      apidob === ""
    ) {
      setError("2d13aa4a4d1a2a0dedf1a03323d294b8");
      errorHandel(0);
      return;
    }
    else if(!isValid && email !== ''){
      setError("d9d12ce1088807d8fb826c2d0dda4ba3");
      errorHandel(0);
      return;
    }
    else {
      if (new Date().getFullYear() - new Date(dateOfBirth).getFullYear() < 15) {
        setError("87c8900f4702fcd0dc1db9d96d356d7b");
        errorHandel(0);
        return;
      }

      const formatExpireDate = `${dateExpired.getFullYear()}-${
        dateExpired.getMonth() + 1 < 10
          ? `0${dateExpired.getMonth() + 1}`
          : dateExpired.getMonth() + 1
      }-${
        dateExpired.getDate() < 10
          ? `0${dateExpired.getDate()}`
          : dateExpired.getDate()
      }`;
      const formatDobDate = props.isExistCustomer
        ? ""
        : `${dateOfBirth.getFullYear()}-${
            dateOfBirth.getMonth() + 1 < 10
              ? `0${dateOfBirth.getMonth() + 1}`
              : dateOfBirth.getMonth() + 1
          }-${
            dateOfBirth.getDate() < 10
              ? `0${dateOfBirth.getDate()}`
              : dateOfBirth.getDate()
          }`;
      const registerData = {
        createUid: id,
        createDate: "",
        modifiedUid: id,
        modifiedDate: "",
        versionNo: 0,
        isSubmitted: "",
        isActive: "",
        isDeleted: "",
        assigneeId: "",

        idCardNumber: id,
        firstName: firstName,
        lastName: lastName,
        latinFirstName: latinFirstName,
        latinLastName: latinLastName,
        dobAddress: dobAddress,
        sex: sex,
        dob: `${props.isExistCustomer ? apidob : formatDobDate}`,
        capitalCode: capitalCode,
        districtCode: districtCode,
        communeCode: communeCode,
        villageCode: villageCode,
        dateExpired: formatExpireDate,
        email: email,
        partner: "ONLINE DMK",
        partnerRefno: "",
      };
      console.log(registerData)

      const fileData = {
        frontID: frontID,
        backID: backID,
      };
      props.handelform(fileData);
      props.handelData(registerData);
      props.handelclick(1);
    }
  };

  // const handleFileChange = (e) => {
  //   setUpload((prevData) => [...prevData, e.target.files[0]]);
  // };

  // const handleDocumentView = (selectedFile) => {
  //   if (selectedFile) {
  //     const reader = new FileReader();
  //     reader.onload = () => {
  //       const fileContent = reader.result;
  //       openPdfInNewTab(fileContent);
  //     };
  //     reader.readAsDataURL(selectedFile);
  //   }
  // };

  // const openPdfInNewTab = (fileContent) => {
  //   const newTab = window.open();
  //   newTab.document.write(
  //     '<iframe src="' + fileContent + '" width="100%" height="100%"></iframe>'
  //   );
  // };

  // const handleDocumentDelete = (index) => {
  //   const updatedUploadList = [...upload];
  //   updatedUploadList.splice(index, 1);
  //   setUpload(updatedUploadList);
  // };

  const capitalChangeHandel = (e) => {
    let capital = JSON.parse(e.target.value);
    districtRef.current.value = '0';
    communeRef.current.value = '0';
    villageRef.current.value = '0';
    setCapitalCode(capital.Code);
    setCaptialId(capital.Id);
    setDistrictId('');
    setCommuneId('');
  };

  const emailHandler = (e) => {
    setEmail(e.target.value);
    const time = setTimeout(() => {
      const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[cC][oO][mM]$/;
      if(e.target.value !== ''){
        setIsValid(emailRegex.test(e.target.value));
      }else{
        setIsValid(true);
      }
    },2500)

    return () => clearTimeout(time);
    
  };  

  const districtChangeHandel = (e) => {
    communeRef.current.value = '0';
    villageRef.current.value = '0';
    let district = JSON.parse(e.target.value);
    setDisctirctCode(district.Code);
    setDistrictId(district.Id);
    setCommuneId('')
    
  };

  const CommuneChnagehandel = (e) => {
    villageRef.current.value = '0';
    let commune = JSON.parse(e.target.value);
    setCommuneCode(commune.Code);
    setCommuneId(commune.Id);
  };

  const villageChangeHandel = (e) => {
    let village = JSON.parse(e.target.value);
    setVillageCode(village.Code);
    setVillageId(village.Id);
  };

  const [ImageName,setImageName]=useState("");
  const [ImageBase64,setImageBase64]=useState("");
  const handelDocumentView=(ImageName,ImageBase64)=>{
    if(ImageBase64!=""){
    setViewDocumet(true);
    setImageName(ImageName);
    setImageBase64(ImageBase64);
    }
  }
  const imageClose=(e)=>{
    setViewDocumet(e);
  }
  
  const handlePaste = (event) => {
    let pastedText = event.clipboardData.getData('text');
    let containsHTML = /<\/?[^>]+(>|$)/g.test(pastedText);
    console.log(containsHTML)
    
    if(containsHTML){
      event.preventDefault();
    }
  };
  const handleKeyPress = (event) => {
    const char = event.data;
    if (!/[\u1780-\u17FFa-zA-Z]/.test(char)) {
      event.preventDefault(); // Block characters that are not Khmer or English letters
    }
  }

  const handleKeyPressForEmail = (event) => {
    const char = event.data;
    if (!/[\u1780-\u17FFa-zA-Z0-9._%+-@]/.test(char)) {
      event.preventDefault(); // Block characters that are not Khmer or English letters
    }
  }

  const handleKeyPressForDOBAddress = (event) => {
    const char = event.data;
    if (!/[\u1780-\u17FFa-zA-Z0-9.,()\-\s]/.test(char)) {
      event.preventDefault(); // Block characters that are not Khmer or English letters
    }
  }

  
  return (
    <div>
      {errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
      {errorblock && errorClass == "errorMessage" && (
          <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
        )}

        {viewDocument &&(
          <ModalImage imageName={ImageName} imageBase64={ImageBase64} imageClose={imageClose}></ModalImage>
        )}
      <div className="card">
        <div id="headingOne" />
        <div
          id="collapseOne"
          className="collapse show"
          aria-labelledby="headingOne"
          data-parent="#accordionExample"
        >
          <div className="card-body">
            <div className="form-row">
              {/* Form Group (first name)*/}
              <div className="form-group col-md-12">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputFirstName">
                  <b>
                    {" "}
                    {t("dae903caf35016e727adebc1e3c18b2a")} :{" "}
                    {props.isExistCustomer ? props.customer.IdCard : props.id}{" "}
                  </b>
                </label>

                {/* <input
                  className="form-control input-dark"
                  id="inputUsername"
                  type="text"
                  placeholder={t("504c46e2c369d441b6eb6c581101fc85")}
                  disabled
                  value={
                    props.isExistCustomer ? props.customer.IdCard : props.id
                  }
                  onChange={(e) => setId(e.target.value)}
                /> */}
              </div>
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputOrgName">
                  {t("2c34abc1b734258621340369de150576")}
                  <span className="error">{frontError}</span>
                </label>
                <span className="mandatory">*</span>
                <div className="text-left">
                  
                  {frontName.match(/\.(pdf)$/i) ? (
                    <>
                    <iframe width={150} height={150} src={frontBase64}  ></iframe> 
                    <div className="iframe-modal" onClick={()=>handelDocumentView(frontName,frontBase64)}></div>
                    </>
                  ) : (
                    <img
                    className="img-cursor"
                      width={150}
                      height={150}
                      src={
                        frontID != null
                          ? frontBase64
                          : "images/6131727403668586221.jpg"
                      }
                      alt="file"
                      onClick={()=>handelDocumentView(frontName,frontBase64)}
                    />
                  )}
                  
                  <br />
                  <br />

                  <input
                    type="file"
                    className="KhmerOSsiemreaptext text-center center-block file-upload"
                    onChange={IDcardFronthandel}
                    ref={inputRefIDCardFrontSide}
                  />
                  <p
                    className="KhmerOSsiemreaptext mt-1"
                    style={{
                      fontSize: "10px",
                      fontStyle: "italic",
                      color: "red",
                    }}
                  >
                    {t("2ffe94058afaea3b875be2082941ec0b")}
                  </p>
                </div>
              </div>

              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputOrgName">
                  {t("9c22570c0feeb5cea41f628ffa7e8790")}
                  <span className="error">{backError}</span>
                </label>
                <span className="mandatory">*</span>
                <div className="text-left">
                  {backName.match(/\.(pdf)$/i) ? (
                    <>
                    <iframe width={150} height={150} src={backBase64} />
                    <div className="iframe-modal" onClick={()=>handelDocumentView(backName,backBase64)}></div>
                    </>
                  ) : (
                    <img
                    className="img-cursor"
                      width={150}
                      height={150}
                      src={
                        backID != null
                          ? backBase64
                          : "images/6134203920466166387.jpg"
                      }
                      alt="file"
                      onClick={()=>handelDocumentView(backName,backBase64)}
                    />
                  )}

                  <br />
                  <br />

                  <input
                    type="file"
                    ref={inputRefIDCardBackSide}
                    className="KhmerOSsiemreaptext text-center center-block file-upload"
                    onChange={IDcardBackhandel}
                  />
                  <p
                    className="KhmerOSsiemreaptext mt-1"
                    style={{
                      fontSize: "10px",
                      fontStyle: "italic",
                      color: "red",
                    }}
                  >
                    {t("2ffe94058afaea3b875be2082941ec0b")}
                  </p>
                </div>
              </div>

              {/* <div className="form-group col-md-6">
                <label className="small mb-1" for="inputOrgName">
                  {t("25b2a90d6504b30bf67144df2a9db1c7")}
                </label>
                <div className="text-left">
                  <select
                    className="form-control col-sm col-md-12"
                    data-val="true"
                    data-val-number="The field Select State/UT must be a number."
                    data-val-required="Select State/UT"
                    id="StateId"
                    name="StateId"
                  >
                    <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                    <option value="33">Document Front Side</option>
                    <option value="33">Document Back Side</option>
                    <option value="33">Others</option>
                  </select>
                  <div className="text-left">
                    <br />
                    <input
                      type="file"
                      className="text-center center-block file-upload"
                      onChange={handleFileChange}
                    />
                    <br />
                    <br />
                  </div>
                </div>
              </div>
              
              <div className="form-group col-md-6">
                <label className="small mb-1" for="inputLocation">
                  Document Subject
                </label>

                <input className="form-control" />
                <div className="text-left">
                  <br />
                  <button className="btn btn-primary" type="button">
                    Add Attachment
                  </button>
                </div>
              </div> */}

              {/* {upload.length != 0 ? (
                <div className="form-group col-md-12">
                  <table className="table">
                    <thead className="thead-light">
                      <tr>
                        <th scope="col">Sl. No.</th>
                        <th>Document Name</th>
                        <th>Subject Type</th>
                        <th className="textAlign-center">Action</th>
                        <th className="textAlign-center">Action</th>
                      </tr>
                    </thead>
                    <tbody>
                      {upload.map((item, index) => {
                        return (
                          <tr key={index}>
                            <td>{index + 1}</td>
                            <td>{item.name}</td>
                            <td>{item.type}</td>
                            <td
                              onClick={() => {
                                handleDocumentView(item);
                              }}
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <BsEyeFill />
                            </td>
                            <td
                              onClick={() => {
                                handleDocumentDelete(index);
                              }}
                              style={{
                                textAlign: "center",
                                cursor: "pointer",
                              }}
                            >
                              <MdDelete />
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </table>
                </div>
              ) : null} */}

              {/* date expired */}

              {/* <div className="form-group col-md-12"> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("0ec0352c0f61ad1c9a1c3babb7c370f1")}
                </label>
                <span className="mandatory">*</span>
                <DatePicker
                  showIcon
                  toggleCalendarOnIconClick
                  onKeyDown={(e) => e.preventDefault()}
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                      </button> */}
                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {years.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                      </button> */}
                    </div>
                  )}
                  className="KhmerOSsiemreaptext form-control width-100"
                  dateFormat="dd/MM/yyyy"
                  selected={dateExpired}
                  // value={startDate}
                  onChange={(date) => setDateExpired(date)}
                />
              </div>
              {/* </div> */}
              {/* <div className="form-group col-md-6" /> */}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                  {t("ee583624fabcb93a41504c81eaf561be")}{" "}
                </label>
                <span className="mandatory">*</span>
                <select
                  className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                  defaultValue={props.isExistCustomer ? props.customer.Sex : ""}
                  disabled={props.isExistCustomer && true}
                  onChange={(e) => setSex(e.target.value)}
                >
                  <option value="" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  <option value={1} selected="" className="KhmerOSsiemreaptext">
                    {t("63889cfb9d3cbe05d1bd2be5cc9953fd")}
                  </option>
                  <option value={2} selected="" className="KhmerOSsiemreaptext">
                    {t("b719ce180ec7bd9641fece2f920f4817")}
                  </option>
                </select>
              </div>
              {/* Form Group (last name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                  {t("d51f2f5018481db5780056d73b18babf")}
                </label>
                <span className="mandatory">*</span>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputPhone"
                  type="text"
                  maxLength={50}
                  onPaste={handlePaste}
                  onBeforeInput={handleKeyPress}
                  placeholder={t("dbcc71284f11a659aa72716396584a4c")}
                  defaultValue={
                    props.isExistCustomer ? props.customer.FirstName : ""
                  }
                  disabled={props.isExistCustomer && true}
                  onChange={(e) => {
                    setfirstName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                {t("8f1373fa0f2d32523259b56ce11e1975")}
                </label>
                <span className="mandatory">*</span>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputPhone"
                  type="text"
                  placeholder={t("8f1373fa0f2d32523259b56ce11e1975")}
                  onPaste={handlePaste}
                  onBeforeInput={handleKeyPress}
                  defaultValue={
                    props.isExistCustomer ? props.customer.LastName : ""
                  }
                  maxLength={50}
                  disabled={props.isExistCustomer && true}
                  onChange={(e) => {
                    setlastName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("bc910f8bdf70f29374f496f05be0330c")}
                </label>
                <span className="mandatory">*</span>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder={t("4ac58405bbbe783528ee33e7ac3fcc5c")}
                  value={
                    props.isExistCustomer
                      ? props.customer.LatinFirstName
                      : latinFirstName
                  }
                  maxLength={50}
                  onPaste={handlePaste}
                  onBeforeInput={handleKeyPress}
                  disabled={props.isExistCustomer && true}
                  onChange={(e) => {
                    setLatinFirstName(e.target.value);
                  }}
                />
              </div>
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                {t("9186d01018279f3b2ef5a022d71ba743")}
                </label>
                <span className="mandatory">*</span>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder={t("9186d01018279f3b2ef5a022d71ba743")}
                  value={
                    props.isExistCustomer ? props.customer.LatinLastName : latinLastName
                  }
                  maxLength={50}
                  onPaste={handlePaste}
                  onBeforeInput={handleKeyPress}
                  disabled={props.isExistCustomer && true}
                  onChange={(e) => {
                    setLatinLastName(e.target.value);
                  }}
                />
              </div>
              {/* Form Group (phone number)*/}
              

              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("8be5338b5414092f30160584e760bb01")}
                </label>
                {/* <span className="mandatory">*</span> */}
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder="abc@gmail.com"
                  defaultValue={
                    props.isExistCustomer ? props.customer.Email : ""
                  }
                  maxLength={320}
                  onPaste={handlePaste}
                  onBeforeInput={handleKeyPressForEmail}
                  disabled={props.isExistCustomer && true}
                  onChange={emailHandler}
                />
                {!isValid ? <p style={{ color: 'red' }}>{t("d9d12ce1088807d8fb826c2d0dda4ba3")}</p> : null}
              </div> 

              {/* Form Group (last name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("10803b83a68db8f7e7a33e3b41e184d0")}
                </label>
                <span className="mandatory">*</span>
                <DatePicker
                  maxDate={new Date()}
                  onKeyDown={(e) => e.preventDefault()}
                  showIcon
                  toggleCalendarOnIconClick
                  renderCustomHeader={({
                    date,
                    changeYear,
                    changeMonth,
                    decreaseMonth,
                    increaseMonth,
                    prevMonthButtonDisabled,
                    nextMonthButtonDisabled,
                  }) => (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        justifyContent: "center",
                      }}
                    >
                      {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                      </button> */}
                      <select
                        value={getYear(date)}
                        onChange={({ target: { value } }) => changeYear(value)}
                      >
                        {currentYear.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      <select
                        value={months[getMonth(date)]}
                        onChange={({ target: { value } }) =>
                          changeMonth(months.indexOf(value))
                        }
                      >
                        {months.map((option) => (
                          <option key={option} value={option}>
                            {option}
                          </option>
                        ))}
                      </select>

                      {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                      </button> */}
                    </div>
                  )}
                  // showIcon
                  // toggleCalendarOnIconClick
                  disabled={props.isExistCustomer && true}
                  className="KhmerOSsiemreaptext form-control width-100"
                  dateFormat="dd/MM/yyyy"
                  selected={
                    props.isExistCustomer ? props.customer.Dob : dateOfBirth
                  }
                  onChange={(e) => setDateOfBirth(e)}
                />
              </div>
              <div
                className="form-group col-md-12"
                // style={{ marginBottom: 80 }}
              >
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("f984cd4a2d763ce723eeaeb59d835bb9")}
                </label>
                <span className="mandatory">*</span>
                <textarea
                  className="KhmerOSsiemreaptext form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder={t("f984cd4a2d763ce723eeaeb59d835bb9")}
                  defaultValue={
                    props.isExistCustomer ? props.customer.DobAddress : ""
                  }
                  maxLength={200}
                  onPaste={handlePaste}
                  onBeforeInput={handleKeyPressForDOBAddress}
                  disabled={props.isExistCustomer && true}
                  onChange={(e) => {
                    setDobAddress(e.target.value);
                  }}
                />
              </div>

              {/* Sex */}
              
              {/* Form Group (phone number)*/}
              {/* <div className="form-group col-md-6">
                <label className="small mb-1" htmlFor="inputPhone">
                  Nationality{" "}
                </label>
                <select
                  className="form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                >
                  <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                  <option value={33} selected="">
                    Cambodia
                  </option>
                </select>
              </div> */}
              <div className="form-group col-md-6"></div>
              <h5 style={{ textAlign: "left" }} className="mt-5 col-12"><b>
                {t("861a00de3a9b1a4751e4e3190944227e")}
                </b></h5>
              <hr style={{width: "100%"}}/>
              {/* Form Group (last name)*/}
              {/* <div className="form-group col-md-6">
                <label className="small mb-1" htmlFor="inputLastName">
                  House Number
                </label>
                <input
                  className="form-control"
                  id="inputFirstName"
                  type="text"
                  placeholder="House Number"
                />
              </div> */}
              {/* Province */}
             
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}
                </label>
                <span className="mandatory">*</span>
                <select
                  className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                  // value={props.isExistCustomer ? props.customer.CapitalCode:capitalCode}
                  value={props.customer.CapitalId}
                  disabled={props.isExistCustomer && true}
                  // onChange={(e) => setCapitalCode(e.target.value)}
                  onChange={capitalChangeHandel}
                  ref={provinceRef}
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apicapital.map((capital) => (
                    <option
                      value={
                        props.isExistCustomer
                          ? capital.Id
                          : JSON.stringify(capital)
                      }
                      selected=""
                      className="KhmerOSsiemreaptext"
                    >
                      {capital.Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Form Group (last name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("e0bb2f66e5b61be3235bf8969979de19")}
                </label>
                <span className="mandatory">*</span>
                <select
                  className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                  // defaultValue={props.isExistCustomer ? props.customer.DistrictCode:districtCode}
                  value={props.customer.DistrictId}
                  disabled={props.isExistCustomer && true}
                  onChange={districtChangeHandel}
                  ref={districtRef}
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apidistricts.map((districts) => (
                    <option
                      value={
                        props.isExistCustomer
                          ? districts.Id
                          : JSON.stringify(districts)
                      }
                      selected=""
                      className="KhmerOSsiemreaptext"
                    >
                      {districts.Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Form Group (last name)*/}
              {/* <div className="form-group col-md-6">
                <label className="small mb-1" htmlFor="inputLastName">
                  Municipality
                </label>
                <select
                  className="form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                >
                  <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                  <option value={33} selected="" />
                </select>
              </div> */}
              {/* Form Group (last name)*/}

              <div className="form-group col-md-6" style={{ marginBottom: 80 }}>
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("04beeb76f7acc97bb1e309f654fdee7a")}
                </label>
                <span className="mandatory">*</span>
                <select
                  className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                  // defaultValue={props.isExistCustomer ? props.customer.CommuneCode:communeCode}
                  value={props.customer.CommuneId}
                  disabled={props.isExistCustomer && true}
                  onChange={CommuneChnagehandel}
                  ref={communeRef}
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apicommune.map((commune) => (
                    <option
                      value={
                        props.isExistCustomer
                          ? commune.Id
                          : JSON.stringify(commune)
                      }
                      selected=""
                      className="KhmerOSsiemreaptext"
                    >
                      {commune.Name}
                    </option>
                  ))}
                </select>
              </div>

              {/* Form Group (last name)*/}
              <div className="form-group col-md-6" style={{ marginBottom: 80 }}>
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("abc63490c815af81276f930216c8d92b")}
                </label>
                <span className="mandatory">*</span>
                <select
                  className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                  data-val="true"
                  name="StateId"
                  // defaultValue={props.isExistCustomer ? props.customer.VillageCode:villageCode}
                  value={props.customer.VillageId}
                  disabled={props.isExistCustomer && true}
                  onChange={villageChangeHandel}
                  ref={villageRef}
                >
                  <option value="0" className="KhmerOSsiemreaptext">
                    {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                  </option>
                  {apiVillage.map((village) => (
                    <option
                      value={
                        props.isExistCustomer
                          ? village.Id
                          : JSON.stringify(village)
                      }
                      selected=""
                      className="KhmerOSsiemreaptext"
                    >
                      {village.Name}
                    </option>
                  ))}
                </select>
              </div>

              <div className="form-group col-md-6" />
              {/* DOB Address */}
             
              
              
              <div
                className="col-12 fot-row row"
                style={{ "background-color": "#ffffff"}}
              >
                <div className="d-flex flex-row justify-content-end col-12" >
                  <button
                    className="KhmerOSsiemreaptext btn btn-primary next"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseTwo"
                    aria-expanded="false"
                    aria-controls="collapseTwo"
                    onClick={nextregistrationProcessA}
                  >
                    {t("d0cab90d8d20d57e2f2b9be52f7dd25d")}
                  </button>
                  <a href="#" className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationProcessA;

// to_convert
// all of whose match not found....
// Please upload jpg,jpeg,png or pdf file only
// {t("621b7de2db36267f3ca7e72abc4781c5")}

// File size exceeds the limit of 2MB.
// {t("ada3e831bf3d6e697a2ee9c2cbd3f0ef")}

// Please fill out all required fields.
// {t("2d13aa4a4d1a2a0dedf1a03323d294b8")}

// Upload ID Card (Back Side)
// {t("9c22570c0feeb5cea41f628ffa7e8790")}

// Upload ID Card (Front Side)
// {t("2c34abc1b734258621340369de150576")}

// Date Expired
// {t("0ec0352c0f61ad1c9a1c3babb7c370f1")}

// First Name (EN)
// {t("bc910f8bdf70f29374f496f05be0330c")}

// Last Name (EN)
// {t("bc910f8bdf70f29374f496f05be0330c")}

// First Name (KH)
// {t("d51f2f5018481db5780056d73b18babf")}

// Last Name (KH)
// {t("6910818fb23ef36a05abf826874f8a1c")}

// Male
// {t("63889cfb9d3cbe05d1bd2be5cc9953fd")}

// Female
// {t("b719ce180ec7bd9641fece2f920f4817")}

// select option
// {t("c6be2b6191e6660291b6b0c92bd2f0df")}

// DOB Address
// {t("f984cd4a2d763ce723eeaeb59d835bb9")}

// next
// {t("d0cab90d8d20d57e2f2b9be52f7dd25d")}

// *Note: Please select the Document type before upload a document (jpg,jpeg,png or pdf file only and size limit of 2MB)
// {t("{t("2ffe94058afaea3b875be2082941ec0b")}")}

// ask..
// Jan-Dec
// years
