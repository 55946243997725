import React, { useState,useEffect } from "react";
import "../../css/style.css";
import Footer from "../Footer";
import Navigation from "../NavigationPanel/Navigation";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";



function TrackApplicationwork() {
  const { t } = useTranslation();
  const isLoginpage = true;
  const isLogedin = true;
  const [formChange, setFormChange] = useState("ApplyforSelf");

  
  const [isdisabale,setIsdiabale]= useState(true);
  const [inputDark,setInputDark]=useState("input-dark");
  useEffect(()=>{
    if(formChange==="ApplyforChildren"){
      setIsdiabale(false)
      setInputDark("")
    }else{
      setIsdiabale(true)
      setInputDark("input-dark")
    }
  },[formChange])
  return (
    <div>
    <Navigation page={isLoginpage} login={isLogedin}></Navigation>

    <div id="layoutSidenav_content">
     <main>
       <header
         className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-5 pt-2">
         <div className="container-fluid">
           <div className="page-header-content padding-0">
             <div className="row d-flex">
               <div className="p-2 col-12 col-sm-12 col-md-12 col-lg-12">
                 <h1 className="page-header-title" style={{ fontSize: "20px" }}>
                   <div className="page-header-icon">
                     <i data-feather="activity"></i>
                   </div>
                   <Link to={"/dashboard"}>{t("2938c7f7e560ed972f8a4f68e80ff834")}</Link> &gt;{t("7a956a088ec40eeaf20d62c1b0ef5538")}

                 </h1>
               </div>

             </div>
           </div>
         </div>
       </header>
       {/* <!-- Main page content--> */}
       <div className="container-fluid mt-sm-n5">
         {/* <!-- Example Colored Cards for Dashboard Demo--> */}
         <div className="row">
           
           <div className="col-lg-12 col-md-12 col-sm-12">
             <div className="card card-lg card-other">
               <div className="row" style={{ marginBottom: "80px" }}>
                 <form>
                   <h5>Track Application Details</h5>
                   <hr />
                   {/* <!-- Form Row--> */}
                   <div className="form-row">
                     {/* <!-- Form Group (first name)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputFirstName">
                         Select Serive
                       </label>
                       <span className="mandatory">*</span>
                       <select
                         className="form-control col-sm col-md-12"
                         data-val="true"
                         data-val-number="The field Select State/UT must be a number."
                         data-val-required="Select State/UT"
                         id="StateId"
                         name="StateId"
                       >
                         <option value="">Select Option</option>
                         <option value="33">ID Card</option>
                         <option value="33">Family Book</option>
                         <option value="33">Residence Book</option>
                       </select>
                     </div>

                     {/* <!-- Form Group (last name)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputLastName">
                         Service Duration
                       </label>
                       <input
                         className="form-control input-dark"
                         id="inputFirstName"
                         type="text"
                         placeholder="3 Working Days (After acceptance of application)"
                         title="3 Working Days (After acceptance of application by front office )"
                       />
                     </div>
                     {/* <!-- Form Group (phone number)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputPhone">
                         Service Fee
                       </label>
                       <input
                         className="form-control input-dark"
                         id="inputPhone"
                         type="tel"
                         placeholder="0"
                       />
                     </div>

                     {/* <!-- Form Group (last name)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputLastName">
                         Document ID
                       </label>
                       <input
                         className="form-control"
                         id="inputFirstName"
                         type="text"
                         placeholder="Enter Document ID"
                       />
                     </div>

                     {/* <!-- Form Group (organization name)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputOrgName">
                         Upload Document Front Side
                       </label>
                       <div className="text-left">
                         <img
                           width="100"
                           height="100"
                           src="https://img.icons8.com/plasticine/100/file.png"
                           alt="file"
                         />
                         <br />
                         <br />
                         <input
                           type="file"
                           className="text-center center-block file-upload"
                         />
                       </div>
                     </div>
                     {/* <!-- Form Group (location)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputLocation">
                         Upload Document Back Side
                       </label>
                       <div className="text-left">
                         <img
                           width="100"
                           height="100"
                           src="https://img.icons8.com/plasticine/100/file.png"
                           alt="file"
                         />
                         <br />
                         <br />
                         <input
                           type="file"
                           className="text-center center-block file-upload"
                         />
                       </div>
                     </div>

                     {/* <!-- Form Group (phone number)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputPhone">
                         Number of Copies
                       </label>
                       <input
                         className="form-control"
                         id="inputPhone"
                         type="tel"
                         placeholder="Enter Number of Copies Required"
                       />
                     </div>

                     {/* <!-- Form Group (phone number)--> */}
                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputPhone">
                         Total Fee
                       </label>
                       <input
                         className="form-control"
                         id="inputPhone"
                         type="tel"
                         placeholder="0"
                       />
                     </div>

                     <div className="form-group col-md-6">
                       <label className="small mb-1" for="inputPhone">
                         Date of Appliaction
                       </label>

                       <input
                         className="form-control input-dark"
                         type="date"
                         id="start"
                         name="trip-start"
                         value="2018-11-05"
                         min="2018-11-05"
                         max="2018-12-05"
                       />
                     </div>
                   </div>
                 </form>
               </div>
               <div className="col-12 fot-row row">
                 <div className="d-flex justify-content-between col-6"></div>

                 <div className="d-flex flex-row justify-content-end col-6">
                   {/* <!-- Button trigger modal --> */}
                   <button
                     type="button"
                     className="btn btn-primary"
                     data-toggle="modal"
                     data-target="#exampleModal"
                   >
                     Submit
                   </button>

                   {/* <!-- Modal --> */}
                   <div
                     className="modal fade"
                     id="exampleModal"
                     tabindex="-1"
                     role="dialog"
                     aria-labelledby="exampleModalLabel"
                     aria-hidden="true"
                   >
                     <div className="modal-dialog" role="document">
                       <div className="modal-content">
                         <div className="modal-header">
                           <h5
                             className="modal-title"
                             id="exampleModalLabel"
                             style={{ padding: "0" }}
                           >
                             Application Receipt
                           </h5>
                           <button
                             type="button"
                             className="close"
                             data-dismiss="modal"
                             aria-label="Close"
                           >
                             <span aria-hidden="true">&times;</span>
                           </button>
                         </div>
                         <div className="modal-body">
                           <div className="row justify-content-center">
                             <div className="col-3">
                               <img
                                 src="https://img.icons8.com/color/96/000000/ok--v2.png"
                                 className="fit-image"
                               />
                             </div>
                           </div>
                           <div className="col-md-12 col-lg-12 text-center">
                             Service Request Submitted Successfully !
                           </div>
                           <br />
                           <div className="row" style={{ marginBottom: "80px" }}>
                             <div className="col-md-6 md-6">
                               Application Number :
                             </div>
                             <div className="col-md-6">
                               :&nbsp;&nbsp;&nbsp;1204 2300 9287
                             </div>
                             <div className="col-md-6 md-6">
                               Service Applied for :
                             </div>
                             <div className="col-md-6">
                               :&nbsp;&nbsp;&nbsp;Civil Registration Work
                             </div>
                             <div className="col-md-6 md-6">
                               Current Status :
                             </div>
                             <div className="col-md-6">
                               :&nbsp;&nbsp;&nbsp;Application under review
                             </div>
                             <div className="col-md-6 md-6">
                               Date of Appliaction :
                             </div>
                             <div className="col-md-6">
                               :&nbsp;&nbsp;&nbsp;5/11/2023
                             </div>
                             <div className="col-md-6 md-6">
                               Expected Date of Delivery
                             </div>
                             <div className="col-md-6">
                               :&nbsp;&nbsp;&nbsp;8/11/2023
                             </div>
                             <div className="col-md-6 md-6">
                               Track your application status
                             </div>
                             <div className="col-md-6">
                               :&nbsp;&nbsp;&nbsp;
                               <img
                                 width="40"
                                 height="40"
                                 src="https://img.icons8.com/ultraviolet/40/qr-code--v1.png"
                                 alt="qr-code--v1"
                               />
                             </div>
                           </div>
                         </div>
                         <div className="modal-footer text-center">
                           <div className="col-12 fot-row row">
                             <div className="d-flex justify-content-between col-6">
                               <button
                                 className="btn btn-primary collapsed"
                                 type="button"
                               >
                                 Download &nbsp; &nbsp;
                                 <img
                                   width="25"
                                   height="25"
                                   src="https://img.icons8.com/dotty/80/download.png"
                                   alt="download"
                                 />
                               </button>
                             </div>
                             <div className="d-flex flex-row justify-content-end col-6">
                               <button
                                 type="button"
                                 className="btn btn-secondary"
                                 data-dismiss="modal"
                               >
                                 Close
                               </button>
                               <a href="#" className=""></a>
                             </div>
                           </div>
                         </div>
                       </div>
                     </div>
                   </div>
                 </div>
               </div>
             </div>
           </div>

           <div style={{ margin: "30px", width: "100%" }}></div>
         </div>
       </div>
     </main>
   </div>

    <Footer></Footer>
 </div>
  )
}

export default TrackApplicationwork

// to_convert
// Application under review
// Track Application Details