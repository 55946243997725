import React, { useEffect, useState } from "react";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

function TopBar({ page }) {
  const { t, i18n } = useTranslation();
  const [LanguageChange, setLanguageChange] = useState(false);
  useEffect(() => {
    if (sessionStorage.getItem("LanguageChange")) {
      let language = sessionStorage.getItem("LanguageChange");
      if (language == "true") {
        setLanguageChange(true);
        i18n.changeLanguage("en");
      } else {
        setLanguageChange(false);
        i18n.changeLanguage("kh");
      }
    }
  }, []);

  const chnageLanguageHandeler = (lng) => {
    i18n.changeLanguage(lng);
  };
  return (
    <div className="header-top-line topStrip">
      <div className="container-fluid">
        <div className="row">
          <div className="col-10 col-md-4 col-lg-4 col-xl-4">
            {/* <ul>
              <li>
                <a href="#" data-drupal-link-system-path="sitemap">
                  Sitemap
                </a>
              </li>
              <li>
                <a href="#" data-drupal-link-system-path="faq">
                  {t("483a5da192f46e3cfe8391d3ef234fc4")}
                </a>
              </li>
              <li>
                <a
                  href="#"
                  data-drupal-link-system-path="node/add/feedback_form"
                >
                  {t("bea4c2c8eb82d05891ddd71584881b56")}
                </a>
              </li>
            </ul> */}
          </div>
          <div className="col-2 col-md-8 col-lg-8 col-xl-8 rightTopStrip d-flex justify-content-end">
            <ul className="smallFont">
              <li className="nav-item dropdown no-caret smallLI">
                <a
                  className="nav-link dropdown-toggle"
                  id="navbarDropdownDocs"
                  href="javascript:void(0);"
                  role="button"
                  data-bs-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <div className="KhmerOSsiemreaptext fw-500">
                    {t("eafb031af583d0e36b87e7c334000af3")}
                  </div>
                  <svg
                    className="svg-inline--fa fa-chevron-right dropdown-arrow"
                    aria-hidden="true"
                    focusable="false"
                    data-prefix="fas"
                    data-icon="chevron-right"
                    role="img"
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 320 512"
                    data-fa-i2svg=""
                  >
                    <path
                      fill="currentColor"
                      d="M310.6 233.4c12.5 12.5 12.5 32.8 0 45.3l-192 192c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3L242.7 256 73.4 86.6c-12.5-12.5-12.5-32.8 0-45.3s32.8-12.5 45.3 0l192 192z"
                    ></path>
                  </svg>
                  {/* <!-- <i className="fas fa-chevron-right dropdown-arrow"></i> Font Awesome fontawesome.com --> */}
                </a>
                <div
                  className="dropdown-menu dropdown-menu-end border-0 shadow animated--fade-in-up"
                  aria-labelledby="navbarDropdownUserImage"
                >
                  {LanguageChange ? (
                    <button
                      className="KhmerOSsiemreaptext dropdown-item top"
                      onClick={() => {
                        chnageLanguageHandeler("kh");
                        setLanguageChange(false);
                        sessionStorage.setItem("LanguageChange", false);
                      }}
                    >
                      ភាសាខ្មែរ
                    </button>
                  ) : (
                    <button
                      className="dropdown-item top"
                      onClick={() => {
                        chnageLanguageHandeler("en");
                        setLanguageChange(true);
                        sessionStorage.setItem("LanguageChange", true);
                      }}
                    >
                      English
                    </button>
                  )}
                </div>
              </li> 
              {/* {!page ? (
                <>
                  <li className="smallLI">
                    <NavLink to={"/login"} title="Login" className="KhmerOSmuollighttext btn-border">
                      {t("303b1b49212f7c122245bbcdbe085e1d")}
                    </NavLink>
                  </li>
                  <li className="smallLI">
                    <Link className="KhmerOSmuollighttext btn-border" to={"/registrationHolder"}>
                      {t("2fe4fe0a0ebea41ce43eb3dc3fce4ea3")}
                    </Link>
                  </li>
                </>
              ) : (
                <></>
              )} */}

              
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TopBar;

// to_convert


// Sitemap
