import React, { useState, useEffect, useContext } from "react";
import "../../css/style.css";
import Footer from "../Footer";
import Navigation from "../NavigationPanel/Navigation";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BsEyeFill } from "react-icons/bs";
import { DashboardContext } from "../../ContextApi";
import DatePicker from "react-datepicker";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode } from "primereact/api";
import { InputText } from "primereact/inputtext";
import "../../css/datatable.css";
import { useTranslation } from "react-i18next";
import { FaArrowRightFromBracket } from "react-icons/fa6";
import ModalImage from "../Error-Span-Modal/ModalImage";
import ModalError from "../Error-Span-Modal/ModalError";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function DashboardindexDetails() {
  const { t } = useTranslation();
  const { dashboardUrl } = useContext(DashboardContext);
  const { setRejectedServiceDetails } = useContext(DashboardContext);
  const { setServiceViewDetails } = useContext(DashboardContext);
  const isLoginpage = true;
  const isLogedin = true;
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const LegalizationKey = process.env.REACT_APP_LEGALIZATION_KEY;
  const [formChange, setFormChange] = useState("ApplyforSelf");
  const [dashboardData, setDashboardData] = useState([]);
  const [status, setStatus] = useState("");
  const [processStatus, setProcessStatus] = useState("");
  const [filters, setFilters] = useState({
    global: { value: null, matchMode: FilterMatchMode.CONTAINS },
  });
  let Navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      Navigate('/');
    }
  }, [isAuthenticated]);
  const [isPaymentApplicable, setPaymentApplicable] = useState("");
  const [paymentQRModal, setPaymentQRModal] = useState(false);
  const [paymentQRBase64, setPaymentQRBase64] = useState('');
  const [ImageQRBase64,setImageQRBase64]=useState("");
  const [ImageName,setImageName]=useState("");

  const [failedpaymentQRModal, setFailedpaymentQRModal] = useState(false);
  const [timeoutModal, setTimeoutModal] = useState(false)
  const [error, setError] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [applicationNumber, setApplicationNumber] = useState('');
  const [amount, setAmount] = useState(0);
  const [description, setDescription]= useState('');
  const [paymentErrorMsg, setPaymentErrorMsg] = useState('');
  const [isloading, setIsloading] = useState(false);
  function qrTimeout(){
    setPaymentQRModal(false);
    setTimeoutModal(true);
    setError("d9f21091f3007ccdff04d8ed57cf13dc");
    errorHandel(0);
  }




  const columns = [
    { field: "code", header: "Code" },
    { field: "name", header: "Name" },
    { field: "category", header: "Category" },
    { field: "quantity", header: "Quantity" },
  ];

  useEffect(() => {
    if (sessionStorage.getItem("customerId")) {
      const customerId = sessionStorage.getItem("customerId");

      const formData = {
        typeId: 3,
        customerId: customerId,
        status: dashboardUrl.status,
        processStatus: dashboardUrl.processStatus,
      };

      let config = {
        headers: {
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      axios
        .post(`${MasterKey}/getDashBoardDetails`, formData, config)
        .then((response) => {
          // setIsloading(false)
          if (response.data.status == 1) {
            let dashboardData = response.data.responseObject.Data.Transactions;
            setPaymentApplicable(response.data.responseObject.Data.isPaymentApplicable)
            setDashboardData(dashboardData.reverse());
          }
        })
        .catch((err) => {
          // setIsloading(false)
          console.log(err);
        });
    }
  }, []);

  useEffect(() => {
    if (localStorage.getItem("firstLoadDone") === null) {
      // If it's the first load, set the flag in local storage to true and reload the page
      localStorage.setItem("firstLoadDone", 1);
    } else {
      Navigate("/dashboard");
      localStorage.removeItem("firstLoadDone");
    }
  }, []);

  const getApplication = (requestCode, serviceName) => {
    let tokenss = sessionStorage.getItem("accessToken");
    let data = {
      serviceApplicationRegisterCode: requestCode,
      loggedinUserCode: sessionStorage.getItem("userId"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
      responseType: "blob",
      body: JSON.stringify(data),
    };
    fetch(
      `${LegalizationKey}/downloadLegalizationApplication`,
      requestOptions
    ).then((response) => {
      response.blob().then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${serviceName}_${requestCode}.pdf`);
        document.body.appendChild(link);
        link.click();
      });
      // if (response.status == 200) {
      //     swal({
      //         title: "Success",
      //         text: "Your pdf has been downloaded",
      //         icon: "success",
      //         closeOnClickOutside: false
      //     })
      // } else {
      //     swal({
      //         title: "Failure",
      //         text: "There is some issue while generating pdf, try again later",
      //         icon: "error",
      //         closeOnClickOutside: false
      //     })
      // }
    });
  };

  const getReceipt = (registerCode, serviceName) => {
    let tokenss = sessionStorage.getItem("accessToken");
    let data = {
      serviceApplicationCode: registerCode,
      loggedinUserCode: sessionStorage.getItem("userId"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
      responseType: "blob",
      body: JSON.stringify(data),
    };
    fetch(
      `${LegalizationKey}/downloadLegalizationReceipt`,
      requestOptions
    ).then((response) => {
      response.blob().then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${serviceName}_${registerCode}_Receipt.pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
      // if (response.status == 200) {
      //     swal({
      //         title: "Success",
      //         text: "Your pdf has been downloaded",
      //         icon: "success",
      //         closeOnClickOutside: false
      //     })
      // } else {
      //     swal({
      //         title: "Failure",
      //         text: "There is some issue while generating pdf, try again later",
      //         icon: "error",
      //         closeOnClickOutside: false
      //     })
      // }
    });
  };

  const getPayment = (registerCode, serviceName) => {
    let tokenss = sessionStorage.getItem("accessToken");
    let data = {
      serviceApplicationCode: registerCode,
      loggedinUserCode: sessionStorage.getItem("userId"),
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
      responseType: "blob",
      body: JSON.stringify(data),
    };
    fetch(
      `${LegalizationKey}/downloadPaymentReceipt`,
      // "http://localhost:9296/legalization/downloadPaymentReceipt",
      requestOptions
    ).then((response) => {
      response.blob().then((data) => {
        const url = window.URL.createObjectURL(new Blob([data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute(
          "download",
          `${serviceName}_${registerCode}_Payment_Receipt.pdf`
        );
        document.body.appendChild(link);
        link.click();
      });
      // if (response.status == 200) {
      //     swal({
      //         title: "Success",
      //         text: "Your pdf has been downloaded",
      //         icon: "success",
      //         closeOnClickOutside: false
      //     })
      // } else {
      //     swal({
      //         title: "Failure",
      //         text: "There is some issue while generating pdf, try again later",
      //         icon: "error",
      //         closeOnClickOutside: false
      //     })
      // }
    });
  };

  const completePayment = (registerCode, serviceName) => {
    // console.log('inside pay')
    setApplicationNumber(registerCode);
    setIsloading(true);
    if (sessionStorage.getItem("accessToken")) {
      let tokenss = sessionStorage.getItem("accessToken");
       
      var config = {
        headers: {
          // Authorization: tokenss,
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`
        },
      };
    }
    const reqBody = {
      "requestCode": registerCode
    }
    axios.post(`${LegalizationKey}/fetchQRForPayment`, reqBody, config)
    .then((response) => {
      // console.log(response.data.Data.QrCode); 
      setIsloading(false);
      console.log(response.data.responseObject.IsError === false);
      const qrbase64 = response.data.responseObject.Data.QrCode
      if(response.data.responseObject.IsError === false){
        console.log('inside if');
        const base64Image = `data:image/png;base64,${qrbase64}`
        setImageQRBase64(base64Image);
        setImageName('qr_payment');
        setPaymentQRModal(true);
        if(response.data.responseObject.Data.Receipt.Description !== null) setDescription(response.data.responseObject.Data.Receipt.Description)
        else setDescription('')
        setAmount(response.data.responseObject.Data.NBC.data.amount);
        
        
      }else{
        console.log('ffail')
        setFailedpaymentQRModal(true);
        setError(response.data.responseObject.ErrorMessage);
        errorHandel(0);
        return;
      }
      
        
      })
    .catch((error) => {
      setIsloading(false);

      console.log(error);
    })
    // console.log(ImageQRBase64)
    // console.log(paymentQRModal)

  }
  const registeredDate = (dashboard) => {
    return (
      // { dashboard.DateRegistered.substr(0, 10) }
      <DatePicker
        // showIcon
        // toggleCalendarOnIconClick
        disabled={true}
        className={`form-control transparentBg`}
        dateFormat="dd/MM/yyyy"
        selected={dashboard.DateRegistered.substr(0, 10)}
      />
    );
  };

  const downloadApplication = (dashboard) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {/* <button className="download"><BsEyeFill style={{color:"red"}}  /></button> */}
        <button
          className="download"
          onClick={() =>
            getApplication(dashboard.RequestCode, dashboard.ServiceName)
          }
        >
          <i
            style={{ color: "green" }}
            class="fa fa-download"
            aria-hidden="true"
          ></i>{" "}
        </button>
      </div>
    );
  };

  const downloadReciept = (dashboard) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {/* <button className="download"><BsEyeFill style={{color:"red"}}  /></button> */}
        <button
          className="download"
          onClick={() =>
            getReceipt(dashboard.RegisterCode, dashboard.ServiceName)
          }
        >
          <i
            style={{ color: "red" }}
            class="fa fa-download"
            aria-hidden="true"
          ></i>{" "}
        </button>
      </div>
    );
  };

  const downloadPayment = (dashboard) => {
    return (
      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {/* <button className="download"><BsEyeFill style={{color:"red"}}  /></button> */}
        <button
          className="download"
          onClick={() =>
            getPayment(dashboard.RequestCode, dashboard.ServiceName)
          }
        >
          <i
            style={{ color: "blue" }}
            class="fa fa-download"
            aria-hidden="true"
          ></i>{" "}
        </button>
      </div>
    );
  };

  const makePayment = (dashboard) => {
    return (

      <div style={{ display: "flex", justifyContent: "space-evenly" }}>
        {/* <button className="download"><BsEyeFill style={{color:"red"}}  /></button> */}
        <button
          className="download"
          onClick={() =>
            completePayment(dashboard.RequestCode, dashboard.ServiceName)
          }
          disabled={!dashboard.Amount > 0.0}
        >
          <i
            style={{ color: dashboard.Amount > 0.0 ? "blue" : "#cbb8b8d4" }}
            class="fas fa-wallet"
            aria-hidden="true"
          ></i>{" "}
        </button>
      </div>

    );
  }

  const viewApplication = (dashboard) => {
    return (
      <BsEyeFill
        style={{ cursor: "pointer" }}
        onClick={() => goToServiceView(dashboard)}
      />
    );
  };

  const redirectApplication = (dashboard) => {
    return (
      <FaArrowRightFromBracket
        style={{ cursor: "pointer" }}
        onClick={() => goToServiceRejection(dashboard)}
      />
    );
  };

  const goToServiceRejection = (dashboard) => {
    setRejectedServiceDetails(dashboard);
    // Navigate("/rejectionService");
    switch (dashboard.ServiceCode) {
      case 201021:
        Navigate("/rejectionService");
        break;
      case 201022:
        Navigate("/rejectionService");
        break;
      case 201023:
        Navigate("/rejectionService");
        break;
      case 215001:
        Navigate("/rejectionService");
        break;
      case 215002:
        Navigate("/rejectionService");
        break;
      case 215003:
        Navigate("/rejectionService");
        break;
      case 204001:
        Navigate("/rejectionTourismService");
        break;
      case 204002:
        Navigate("/rejectionTourismService");
        break;
      case 204003:
        Navigate("/rejectionTourismService");

      default:
        return null;
    }
  };

  const goToServiceView = (dashboard) => {
    console.log(dashboard);
    sessionStorage.setItem("isVisitFromNotification",false)
    setServiceViewDetails(dashboard);
    Navigate("/viewService")
    // switch (dashboard.ServiceCode) {
    //   case 204001:
    //     Navigate("/viewService");
    //     break;
    //   case 204002:
    //     Navigate("/viewService");
    //     break;
    //   case 204003:
    //     Navigate("/viewService");

    //   default:
    //     return null;
    // }
  };
 
  
  const imageClose=(e)=>{
      setPaymentQRModal(e);
      Navigate('/dashboard')
  }

  const errorHandel = (e) => {
    // setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
  };
 
  const errorClose = (e) => {
    setFailedpaymentQRModal(e);
    setTimeoutModal(e);
  };
  return (
    <div>

      
        {
          paymentQRModal && (
          <ModalImage  imageName={ImageName} imageBase64={ImageQRBase64} imageClose={imageClose} isQR={true} qrTimeout={qrTimeout} applicationNumber={applicationNumber} amount={amount} description={description}></ModalImage>
          )
        }

        {
          failedpaymentQRModal && (
            <ModalError error={error} errorClose={errorClose}></ModalError>
          )
        }

        {
          timeoutModal && (
            <ModalError error={error} errorClose={errorClose}></ModalError>
          )
        }
      
      <Navigation page={isLoginpage} login={isLogedin}></Navigation>

      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-5 pt-2">
            <div className="container">
              <div className="page-header-content padding-0">
                <div className="row d-flex">
                  <div className="p-2 col-12 col-sm-12 col-md-12 col-lg-12">
                    <h1
                      className="page-header-title KhmerOSmuollighttext"
                      style={{ fontSize: "20px"}}
                    >
                      <div className="page-header-icon">
                        <i data-feather="activity"></i>
                      </div>
                      {/* <Link to={"/dashboard"}>Dashboard</Link> &nbsp;&nbsp; &gt;
                      Details */}
                      {dashboardUrl.name}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>

          {/* <!-- Main page content--> */}
          <div className="container mt-sm-n5">
            {/* <!-- Example Colored Cards for Dashboard Demo--> */}
            <div className="row">
              <div className="col-lg-12 col-md-12 col-sm-12">
                <div
                  className="card card-lg mb-4"
                  style={{ backgroundColor: "rgb(17,126,191, 0.8)" }}
                >
                  {/* <div className="card-header" style={{ color: "#fff" }}>
                    {dashboardData.length == 0 ? (
                      <table classname="table table-dark table-striped col-md-12 col-lg-12">
                        <tbody>
                          <div
                            style={{
                              minHeight: "150px",
                              display: "flex",
                              alignItems: "center",
                              justifyContent: "center",
                              fontSize: "25px",
                            }}
                          >
                            No Record Found
                          </div>
                        </tbody>
                      </table>
                    ) : (
                      <table classname="table table-dark table-striped col-md-12 col-lg-12">
                        <thead>
                          <tr>
                            <th scope="col" style={{ width: "180px" }}>
                              Application Number
                            </th>
                            <th scope="col">Service Name</th>
                            <th scope="col" style={{ width: "180px" }}>
                              Registration Date
                            </th>
                            <th scope="col" style={{ width: "180px" }}>
                              Action
                            </th>
                          </tr>
                        </thead>
                        {dashboardData.map((dashboard) => (
                          <tbody key={dashboard.RequestCode}>
                            <tr>
                              <td scope="row">{dashboard.RequestCode}</td>
                              <td>{dashboard.ServiceName}</td>
                              <td>
                                <div>
                                  {dashboard.DateRegistered.substr(0, 10)}
                                  <DatePicker
                                    // showIcon
                                    // toggleCalendarOnIconClick

                                    disabled={true}
                                    className={`form-control transparentBg`}
                                    dateFormat="dd/MM/yyyy"
                                    selected={dashboard.DateRegistered}
                                  />
                                </div>
                              </td>
                              <td>
                                <a class="btn btn-datatable btn-icon btn-transparent-dark me-2" href="user-management-edit-user.html"><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" class="feather feather-edit"><path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path><path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path></svg></a>
                                                  <button className="btn btn-datatable btn-icon btn-transparent-dark">
                                                    <svg
                                                      className="svg-inline--fa fa-trash-can"
                                                      aria-hidden="true"
                                                      focusable="false"
                                                      data-prefix="far"
                                                      data-icon="trash-can"
                                                      role="img"
                                                      xmlns="http://www.w3.org/2000/svg"
                                                      viewBox="0 0 448 512"
                                                      data-fa-i2svg=""
                                                    >
                                                      <path
                                                        fill="currentColor"
                                                        d="M170.5 51.6L151.5 80h145l-19-28.4c-1.5-2.2-4-3.6-6.7-3.6H177.1c-2.7 0-5.2 1.3-6.7 3.6zm147-26.6L354.2 80H368h48 8c13.3 0 24 10.7 24 24s-10.7 24-24 24h-8V432c0 44.2-35.8 80-80 80H112c-44.2 0-80-35.8-80-80V128H24c-13.3 0-24-10.7-24-24S10.7 80 24 80h8H80 93.8l36.7-55.1C140.9 9.4 158.4 0 177.1 0h93.7c18.7 0 36.2 9.4 46.6 24.9zM80 128V432c0 17.7 14.3 32 32 32H336c17.7 0 32-14.3 32-32V128H80zm80 64V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16zm80 0V400c0 8.8-7.2 16-16 16s-16-7.2-16-16V192c0-8.8 7.2-16 16-16s16 7.2 16 16z"
                                                      />
                                                    </svg>
                                                    
                                                  </button>
                                <BsEyeFill onClick={() => getApplicationReceipt(dashboard.RequestCode, dashboard.ServiceName)} />
                              </td>
                            </tr>
                          </tbody>
                        ))}
                      </table>
                    )}
                  </div> */}
                  <div className="card-header" style={{ color: "#fff" }}>
                    {/* react data table */}
                    {/* {dashboardData.length == 0 ? (
          <div
            style={{
              minHeight: "150px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              fontSize: "25px",
            }}
          >
            No Record Found
          </div>
        ) : ( */}
                    <div>
                      <div
                        className="flex justify-content-end"
                        style={{ display: "flex", marginBottom: "5px" }}
                      >
                        <span
                          className="p-input-icon-left"
                          style={{ width: "500px" }}
                        >
                          <i class="fa fa-search" aria-hidden="true"></i>
                          <InputText
                            style={{ paddingLeft: "2rem" }}
                            className="form-control"
                            onInput={(e) => {
                              setFilters({
                                global: {
                                  value: e.target.value,
                                  matchMode: FilterMatchMode.CONTAINS,
                                },
                              });
                            }}
                          />
                        </span>
                      </div>
                      <DataTable
                        className="KhmerOSsiemreaptext"
                        value={dashboardData}
                        tableStyle={{ minWidth: "50rem" }}
                        sortMode="multiple"
                        filters={filters}
                        paginator
                        showGridlines
                        rows={5}
                        emptyMessage={t("087fb8756d4add87f2d162304ccd486b")}
                        rowsPerPageOptions={[5, 10, 25, 50, 100]}
                        stripedRows
                        removableSort
                      >
                        <Column
                          style={{ width: "120px" }}
                          field="RequestCode"
                          header={t("9fc6c130df428afee946043e4b852b88")}
                          sortable
                        ></Column>
                        <Column
                          style={{ width: "300px" }}
                          field="ServiceName"
                          header={t("46140fd4f90101f3beccfd3428bea873")}
                          sortable
                        ></Column>
                       { dashboardUrl.status === "PENDING" && <Column
                          style={{ width: "120px"}}
                          field="Location"
                          header='Location'
                          
                        ></Column>}
                        <Column
                          style={{ width: "100px", textAlign: "center" }}
                          field=""
                          header={t("97ce2ccb5de5849fd94203a375054378")}
                          body={registeredDate}
                        ></Column>
                        <Column
                          style={{ width: "50px", textAlign: "center" }}
                          field=""
                          header={t("b179b5b6920cdd2c6a6a06473f84987b")}
                          body={downloadApplication}
                        ></Column>
                        {dashboardUrl.status === "PENDING" && (
                          <Column
                            style={{ width: "100px", textAlign: "center" }}
                            field=""
                            header={t("c60450b18b452cd3050cd479da30e3e1")}
                            body={viewApplication}
                          ></Column>
                        )}
                        {dashboardUrl.status === "PAID" && (
                          <Column
                            style={{ width: "120px", textAlign: "center" }}
                            field=""
                            header={t("44c603850b867ef1bbd8f29ad3f84e80")}
                            body={downloadPayment}
                          ></Column>
                        )}
                        {dashboardUrl.status == "APPROVED" && isPaymentApplicable === "Y" && (
                          <Column
                            style={{ width: "120px", textAlign: "center" }}
                            field=""
                            header={t("3cfd82f426314baac679a85da388c8b7")}
                            body={makePayment}
                          ></Column>
                        )}
                        {dashboardUrl.status === "CLOSED" && (
                          <Column
                            style={{ width: "50px", textAlign: "center" }}
                            field=""
                            header={t("9302b43c215ea9f19d5c7d7d3a3e10b0")}
                            body={downloadReciept}
                          ></Column>
                        )}
                        {dashboardUrl.status === "RETURN" && (
                          <Column
                            style={{ width: "50px", textAlign: "center" }}
                            field=""
                            header={t("004bf6c9a40003140292e97330236c53")}
                            body={redirectApplication}
                          ></Column>
                        )}
                      </DataTable>
                    </div>
                  </div>
                </div>
              </div>
              <div style={{ margin: "30px", width: "100%" }}></div>
            </div>
          </div>
        </main>
      </div>

      <Footer></Footer>
      {isloading && (
          <div className="loderdiv">
            <ClipLoader
              color="hsla(203, 66%, 53%, 1)"
              size={80}
              cssOverride={override}
            />
          </div>
        )}
    </div>
  );
}

export default DashboardindexDetails;

// to_convert
// column headers needs to be translated**