import React, { useContext, useEffect, useState } from "react";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { ServiceSelectContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";

function ServiceSelectorNew() {
  const { t } = useTranslation();
  const { setServiceCallID } = useContext(ServiceSelectContext);
  const isLoginpage = true;
  const isLogedin = true;
  const [servicevalue, setService] = useState(0);
  const [path, setPath] = useState(0);
  const [isVisible, setIsVisible] = useState(false);
  const [apiSector, setApiSector] = useState([]);
  const [apiService, setApiService] = useState([]);
  const [serviceID, setserviceID] = useState();
  let navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      navigate('/');
    }
  }, [isAuthenticated]);
  const Masterkey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  // const sectorChoose = [
  //   {
  //     id: 0,
  //     sector: "Select Option",
  //     value: "",
  //     serviceType: [
  //       {
  //         id: 0,
  //         service: "Select Option",
  //         path: "",
  //       },
  //     ],
  //   },
  //   {
  //     id: 1,
  //     sector: "Legalization Work",
  //     value: "legal",
  //     serviceType: [
  //       {
  //         id: 0,
  //         service: "Select Option",
  //         path: "",
  //       },
  //       {
  //         id: 1,
  //         service: "Legalization Work",
  //         path: "/legalizationWork",
  //       },
  //       {
  //         id: 2,
  //         service: "legalization main",
  //         path: "/legalizationmain",
  //       },
  //     ],
  //   },
  //   {
  //     id: 2,
  //     sector: "Civil Registration Work",
  //     value: "civil",
  //     serviceType: [
  //       {
  //         id: 0,
  //         service: "Select Option",
  //         path: "",
  //       },
  //       {
  //         id: 1,
  //         service: "Civil Registration Work",
  //         path: "/civilRegistrationWork",
  //       },
  //     ],
  //   },
  //   {
  //     id: 3,
  //     sector: "Tourism",
  //     value: "Tourism",
  //     serviceType: [
  //       {
  //         id: 0,
  //         service: "Select Option",
  //         path: "",
  //       },
  //       {
  //         id: 1,
  //         service: "Guesthouse (New)",
  //         path: "/tourismLicenseWork",
  //       },
  //       {
  //         id: 2,
  //         service: "Guesthouse (Renewal)",
  //         path: "",
  //       },
  //       {
  //         id: 3,
  //         service: "Guesthouse (Change of Owner)",
  //         path: "",
  //       },
  //     ],
  //   },
  // ];
  useEffect(() => {
    if (sessionStorage.getItem("accessToken")) {
      let tokenss = sessionStorage.getItem("accessToken");

      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };
      // get sector
      axios
        .post(
          `${Masterkey}/getSectors`,
          {
            userCode: "string",
            serviceTypeId: 2,
            online: true,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            setApiSector(response.data.responseObject.Json.Sectors);
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
      //
      axios
        .post(
          `${Masterkey}/getServices`,
          {
            userCode: "string",
            sectorId: servicevalue,
            online: true,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            setApiService(response.data.responseObject.Json.Services);
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [servicevalue]);

  const handelsetService = (e) => {
    let service = JSON.parse(e.target.value);
    setPath(service.Code);
    setserviceID(service.Id);
  };

  const submitServicehandeler = () => {
    localStorage.removeItem("firstLoadDone");
    setServiceCallID(serviceID);
    switch (path) {
      case "201021":
        navigate("/legalizationWorkNew");
        break;
      case "201022":
        navigate("/legalizationWorkNew");
        break;
      case "201023": 
        navigate("/legalizationWorkNew");
        break;
      case "215001":
        navigate("/civilRegistrationWork");
        break;
      case "215002":
        navigate("/civilRegistrationWork");
        break;
      case "215003":
        navigate("/civilRegistrationWork");
        break;
      case "204001":
        navigate("/tourismLicenseWork");
        break;
      case "204002":
        navigate("/tourismLicenseWork");
        break;
      case "204003":
        navigate("/tourismLicenseWork");

      default:
        return null;
    }
  };

  return (
    <div>
      <Navigation page={isLoginpage} login={isLogedin}></Navigation>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-5 paddingTop-60 service">
            <div className="container-fluid d-flex justify-content-center col-md-4">
              <div
                className="card card-lg mb-4"
                style={{
                  backgroundColor: "rgb(17,126,191, 0.8)",
                  width: "380px",
                }}
              >
                <div className="KhmerOSmuollighttext card-header" style={{ color: "#fff" }}>
                  {t("992a0f0542384f1ee5ef51b7cf4ae6c4")}
                </div>

                <div
                  className="card-body"
                  style={{
                    padding_top: "0.35rem",
                    padding_bottom: "0.35rem",
                    text_align: "left",
                  }}
                >
                  <form>
                    {/* <!-- Form Group (username)--> */}
                    <div className="form-group col-md-12">
                      <label className="KhmerOSsiemreaptext small mb-1" for="inputUsername">
                        {t("be93366a7d060b55dc09e331b20e4605")}
                      </label>
                      <select
                        className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                        data-val="true"
                        data-val-number="The field Select State/UT must be a number."
                        data-val-required="Select State/UT"
                        id="StateId"
                        name="StateId"
                        onChange={(e) => {
                          setService(e.target.value);
                          setIsVisible(true);
                        }}
                      >
                        <option value="" className="KhmerOSsiemreaptext">
                          {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                        </option>
                        {apiSector.map((sector) => (
                          <option key={sector.Id} value={sector.Id} className="KhmerOSsiemreaptext">
                            {sector.Name}
                          </option>
                        ))}
                        {/* <option value="33">ID Card</option>
                                    <option value="33">Family Book</option>
                                    <option value="33">Residence Book</option> */}
                      </select>
                    </div>

                    <div
                      className={`form-group col-md-12 ${
                        !isVisible && "display-none-Nw"
                      }`}
                      style={{ marginBottom: 20 }}
                    >
                      <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                        {t("deb5f4f364f3c67e6a9fab83c06c6cd3")}
                      </label>

                      <select
                        className="KhmerOSsiemreaptext form-control col-sm col-md-12"
                        data-val="true"
                        data-val-number="The field Select State/UT must be a number."
                        data-val-required="Select State/UT"
                        id="StateId"
                        name="StateId"
                        onChange={handelsetService}
                      >
                        <option value="" className="KhmerOSsiemreaptext">
                          {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                        </option>
                        {/* {sectorChoose[servicevalue].serviceType.map( */}
                        {apiService.map((service) => (
                          <option
                            value={JSON.stringify(service)}
                            key={service.Id}
                            className="KhmerOSsiemreaptext"
                          >
                            {service.Name}
                          </option>
                        ))}
                        {/* <option value="33">ID Card</option>
                                    <option value="33">Family Book</option>
                                    <option value="33">Residence Book</option> */}
                      </select>
                    </div>

                    <div className="form-row">
                      {/* <!-- Form Group (last name)--> */}
                      <div className="d-flex justify-content-center form-group col-md-12">
                        <button
                          type="button"
                          className="KhmerOSsiemreaptext btn btn-primary boderR login-acc"
                          onClick={submitServicehandeler}
                        >
                          {" "}
                          {t("a4d3b161ce1309df1c4e25df28694b7b")}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>

                {/* <!-- Save changes button--> */}
              </div>
            </div>
          </header>
        </main>
      </div>
<div className="footer-service">
      <Footer ></Footer>
      </div>
    </div>
  );
}

export default ServiceSelectorNew;

// to_convert
//  done !