import React, { useEffect, useState, useContext } from "react";
import axios from "axios";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import SelfDetails from "../Self-Others-ServiceDiteals/SelfDetails";
import OthersDetails from "../Self-Others-ServiceDiteals/OthersDetails";
import ServiceDetails from "../Self-Others-ServiceDiteals/ServiceDetails";
import { ServiceSelectContext } from "../../ContextApi";
import { useNavigate } from "react-router-dom";
import { OthersComponentContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";

function CivilRegistrationWork() {
  const { t } = useTranslation();
  const isLoginpage = true;
  const isLogedin = true;

  const user_details_string = "9288098bf51c8184caf86bea0722543f";

  const [existingData, setExistingData] = useState({});
  const [otherData, setOtherData] = useState({});
  const [otherIDCard, setotherIdCard] = useState();
  const [serviceDiteals, setServiceDiteals] = useState({});
  const [Self, setSelf] = useState(false);
  const [others, setOthers] = useState(false);
  const [flag, setFlag] = useState(false);
  const [formChange, setFormChange] = useState("ApplyforSelf");
  const [check18, setCheck18] = useState(false);
  const [streetHome, setStreetHome] = useState({});
  const { serviceCallID } = useContext(ServiceSelectContext);
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const Navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      Navigate('/');
    }
  }, [isAuthenticated]);
  const [isApplyforOthersDataAvialble,setisApplyforOthersDataAvailable]=useState("");

  useEffect(() => {
    if (sessionStorage.getItem("userId")) {
      const id = sessionStorage.getItem("userId");
      // existing diteals check by id
      if (formChange === "ApplyforSelf") {
        const data = {
          idNumber: id,
          userCode: id,
        };

        let config = {
          headers: {
            AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
          },
        };

        axios
          .post(
            `${LoginKey}/registration/checkExistingIdCardOnRegistration`,
            data,
            config
          )
          .then((response) => {
            if (response.data.status == 1) {
              setExistingData(response.data.responseObject.Data.Customer);
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      //get service diteals by service ID
      if (sessionStorage.getItem("accessToken")) {
        let tokenss = sessionStorage.getItem("accessToken");
        let config = {
          headers: {
            Authorization: tokenss,
            AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
          },
        };
        const serviceData = {
          serviceId: serviceCallID,
          userCode: id,
        };

        axios
          .post(`${MasterKey}/getServiceDetailById`, serviceData, config)
          .then((response) => {
            if (response.data.status == 1) {
              setServiceDiteals(response.data.responseObject.Json);
              setCheck18(
                response.data.responseObject.Json.Service.IsCheckAge18
              );
              setSelf(
                response.data.responseObject.Json.Service.IsApplicableForSelf
              );
              setOthers(
                response.data.responseObject.Json.Service.IsApplicableForOthers
              );
              if (
                response.data.responseObject.Json.Service
                  .IsApplicableForSelf === true
              ) {
                setFormChange("ApplyforSelf");
              } else {
                setFormChange("ApplyforChildren");
              }
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }
    setTimeout(() => {
      setFlag(true);
    }, 1000);
  }, []);

  useEffect(() => {
    if (localStorage.getItem("firstLoadDone") === null) {
      // If it's the first load, set the flag in local storage to true and reload the page
      localStorage.setItem("firstLoadDone", 1);
    } else {
      Navigate("/serviceSelectorNew");
      localStorage.removeItem("firstLoadDone");
    }
  }, []);

  // const handelCustomerData=(e)=>{
  //   setOtherData(e);
  // }
  const handleDocumentOfOther = (e) => {
    setotherIdCard(e);
  };

  return (
    <div>
      <Navigation page={isLoginpage} login={isLogedin}></Navigation>

      <div id="layoutSidenav_content">
        <main>
          {/* Header of the Service Page */}

          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-5 pt-2">
            <div className="container-fluid">
              <div className="page-header-content padding-0">
                <div className="row d-flex">
              
                  <div className="p-2 col-4 col-sm-12 col-md-6 col-lg-12">
                 
                    <h1
                      className="page-header-title"
                      style={{ fontSize: "20px", float:"left" , width:"50%" }}
                    >
                      <div className="page-header-icon">
                        <i data-feather="activity"></i>
                      </div>
                      {t("325d21423ea977ca0f03ad45e1a1a96a")}
                    </h1>
                    <p className="KhmerOSsiemreaptext mt-1 red-text-mrk">
                       {t("117e4fcfaa8fa8f0e369843b8e5e8d3a")}
                  </p>
                  </div>
                  
                  <hr style={{ width: "100%", padding: 0, margin: 0 }}></hr>

                  {/* radio button */}
                  {Self && others && (
                    <div className="p-2 col-4 col-sm-12 col-md-6 col-lg-12 text-center" style={{ marginTop : '-15px'}}>
                      
                      <label className="KhmerOSmuollighttext label-txt" for="inputFirstName">
                        {t("13427696ab995e729d57a9fee166f805")}
                      </label>
                      &nbsp;&nbsp;&nbsp;
                      <input
                        type="radio"
                        id="applyforself"
                        name="fav_language"
                        value="ApplyforSelf"
                        checked={formChange === "ApplyforSelf"}
                        onClick={() => {
                          setFormChange("ApplyforSelf");
                        }}
                      />
                      <label for="applyforself" className="KhmerOSsiemreaptext lb label-txt cv">
                        {t("ad6e7652b1bdfb38783486c2c3d5e806")}
                      </label>
                      &nbsp;&nbsp;
                      <input
                        type="radio"
                        id="applyforchildren"
                        name="fav_language"
                        value="ApplyforChildren"
                        // checked={!Self && others}
                        onClick={() => {
                          setFormChange("ApplyforChildren"); 
                        }}
                      />
                      <label for="applyforchildren" className="KhmerOSsiemreaptext lb label-txt cv2">
                        {check18
                          ?  `${t("64e4aca4297806247f62a7b5f8cbd3df")}`
                          : `${t("52ef9633d88a7480b3a938ff9eaa2a25")}`}
                      </label>
                    </div>
                  )}


                </div>

               
              </div>
            </div>
          </header>
          <div className="container-fluid mt-sm-n5">
            {/* <!-- Example Colored Cards for Dashboard Demo--> */}
            <OthersComponentContext.Provider
              value={{ otherData, setOtherData, streetHome, setStreetHome,isApplyforOthersDataAvialble,setisApplyforOthersDataAvailable }}
            >
              <div className={`row`}>
                <div className="col-lg-5 col-md-5 col-sm-12">
                  <div className="card card-lg card-other drk">
                    <div className="row">
                      <form style={{ marginBottom: "80px" }}>
                        {/* <!-- Form Group (username)--> */}
                        <h5 style={{ color: "white" }}><b>
                          {formChange === "ApplyforSelf"
                            ? `${t("732cabde6bfc84822b5fd47a5288b789")}`
                            : `${t("df4ec6392769f58a7bb2361c7d136d2c")}`}
                        </b></h5>
                        <hr />

                        {/* Body of the Serive Page */}

                        {/* Self Page */}
                        {flag && Self && formChange === "ApplyforSelf" && (
                          <SelfDetails customer={existingData}></SelfDetails>
                        )}
                        {/* Others page */}
                        {flag &&
                          others &&
                          formChange === "ApplyforChildren" && (
                            <OthersDetails
                              serviceDiteals={serviceDiteals}
                              // handelCustomerData={handelCustomerData}
                              handleDocumentOfOther={handleDocumentOfOther}
                            ></OthersDetails>
                          )}
                      </form>
                    </div>
                  </div>
                </div>

                {/* Service Diteals Page */}
                {flag && (
                  <ServiceDetails
                    formChangeOfCustomer={formChange}
                    customer={
                      formChange === "ApplyforSelf" ? existingData : otherData
                    }
                    serviceDiteals={serviceDiteals}
                    otherIDCard={otherIDCard}
                  ></ServiceDetails>
                )}

                <div style={{ margin: "30px", width: "100%" }}></div>
              </div>
            </OthersComponentContext.Provider>
          </div>
        </main>
      </div>
      <Footer></Footer>
    </div>
  );
}

export default CivilRegistrationWork;

// to_convert
// Others
// Owner Details
