import React, { useEffect, useState, useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import axios from "axios";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import OthersDetailsWithoutData from "./OthersDetailsWithoutData";
import { OthersComponentContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";

const override = { 
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function OthersDetails(props) {
  const { t } = useTranslation();
  const [inputDark, setInputDark] = useState("input-dark");
  const [isdisabale, setIsdiabale] = useState(true);
  const [formChange, setFormChange] = useState("ApplyforSelf");
  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);
  const [identity, setIdentity] = useState("");
  const [identityName, setIdentityName] = useState("");
  const [error, setError] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [customerId, setCustomerId] = useState("");
  const [khamerName, setKhamerName] = useState("");
  const [latinName, setLatinName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [homeNO, setHomeNo] = useState("");
  const [streetNO, setStreetNO] = useState("");
  const [attachDocID, setAttachDocID] = useState("");
  const [provinceCode, setProvinceCode] = useState("");
  const [districtCode, setDistrictCode] = useState("");
  const [villageCode, setVillageCode] = useState("");
  const [communeCode, setCommuneCode] = useState("");
  const [phone, SetPhone] = useState("");
  const [address, setAddress] = useState("");
  const [capitalId, setCaptialId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [villageId, setVillageId] = useState("");
  const [existingOtherData, setExistingOtherData] = useState("");
  const [base64, setBase64] = useState("");
  const [imageName, setImageName] = useState("");
  const [uploaFile, setUploaFile] = useState();
  const [isloading, setIsloading] = useState(false);
  const { setStreetHome } = useContext(OthersComponentContext);
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const currentYear = range(1850, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const inputFile = useRef(null);
  const { setOtherData, setisApplyforOthersDataAvailable } = useContext(
    OthersComponentContext
  );
  useEffect(() => {
    // props.handleDocumentOfOther("");
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get capital
    axios
      .post(`${MasterKey}/getCapital`, { userCode: "1" }, config)
      .then((response) => {
        if (response.data.status === "1") {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get district
    axios
      .post(
        `${MasterKey}/getDistrictByCapital`,
        {
          userCode: "string",
          capitalId: capitalId,
          online: true,
        },
        config
      )
      .then((response) => {
        if (response.data.status === "1") {
          setApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get commune
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: districtId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: communeId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, [capitalId, districtId, communeId, villageId]);

  const selectIdentity = (e) => {
    let selectedIdentity = JSON.parse(e.target.value);
    console.log(selectedIdentity)
    setIdentity(selectedIdentity.identityTypeId);
    setIdentityName(selectedIdentity.identityTypeName);
  };

  const searchCustomerInfo = () => {
    if (identity != "" && customerId != "") {
      setIsloading(true);
      setBase64("");
      setImageName("");
      let config = {
        headers: {
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      const data = {
        identityType: identity,
        code: customerId,
      };

      axios
        .post(`${LoginKey}/login/getCustomerInfo`, data, config)
        .then((response) => {
          setIsloading(false);
          if (response.data.status == 1) {
            setExistingOtherData(response.data.responseObject.Data.Customer);
            setisApplyforOthersDataAvailable(
              response.data.responseObject.Data.Customer
            );
            setCaptialId(response.data.responseObject.Data.Customer.CapitalId);
            setDistrictId(
              response.data.responseObject.Data.Customer.DistrictId
            );
            setCommuneId(response.data.responseObject.Data.Customer.CommuneId);
            setVillageId(response.data.responseObject.Data.Customer.VillageId);
            // props.handelCustomerData(
            //   response.data.responseObject.Data.Customer
            // );
            setOtherData(response.data.responseObject.Data.Customer);
          } else {
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });

      // api for get capital
      // axios
      //   .post(`${MasterKey}master/getCapital`, { userCode: "1" })
      //   .then((response) => {
      //     if (response.data.status === "1") {
      //       setApiCaptial(response.data.responseObject.Data.Capitals);
      //     } else if (response.data.status === 0) {
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      // // api for get district
      // axios
      //   .post(`${MasterKey}master/getDistrictByCapital`, {
      //     userCode: "string",
      //     capitalId: capitalId,
      //     online: true,
      //   })
      //   .then((response) => {
      //     if (response.data.status === "1") {
      //       setApiDistricts(response.data.responseObject.Data.Districts);
      //     } else if (response.data.status === 0) {
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      // // api for get commune
      // axios
      //   .post(`${MasterKey}master/getCommuneByDistrict`, {
      //     userCode: "string",
      //     districtId: districtId,
      //   })
      //   .then((response) => {
      //     if (response.data.status == 1) {
      //       setApiCommune(response.data.responseObject.Data.Communes);
      //     } else if (response.data.status == 0) {
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });

      // // api for get villages
      // axios
      //   .post(`${MasterKey}master/getVillageByCommune`, {
      //     userCode: "string",
      //     communeId: communeId,
      //   })
      //   .then((response) => {
      //     if (response.data.status == 1) {
      //       setApiVillage(response.data.responseObject.Data.Villages);
      //     } else if (response.data.status == 0) {
      //     }
      //   })
      //   .catch((err) => {
      //     console.log(err);
      //   });
    } else {
      setError("f17a4099f21c248e8d09c2505f66f0b6");
      setErrorClass("errorMessage");
      setErrorBlock(true);
    }

    // setTimeout(() => {
    //   setErrorBlock(false);
    // }, 4000);
  };

  const handleFileChange = () => {
    if (!inputFile.current.files[0].name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
    } else {
      if (inputFile.current.files[0].size >= 2 * 1024 * 1024) {
        setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
        errorHandel(0);
        return;
      }
      setUploaFile(inputFile.current.files[0]);
      props.handleDocumentOfOther(inputFile.current.files[0]);


      setImageName(inputFile.current.files[0].name);
      getBase64(inputFile.current.files[0]).then((res) => setBase64(res));
    }
  };

  // function convet file to base64
  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(() => {
    //   setErrorBlock(false);
    // }, 4000);
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };
  const errorClose = (e) => {
    setErrorBlock(e);
  };

  // setTimeout(()=>{
  // const OthersDetailsWithoutData = (e) => {
  //   if(existingOtherData === null){
  //   props.handelCustomerData(e);
  //   }
  // };

  // },[2000])

  const handleDocumentOfOther = (e) => {
    if (existingOtherData === null) {
      props.handleDocumentOfOther(e);
    }
  };
  useEffect(() => {
    setExistingOtherData("");
  }, [identity]);

  useEffect(() => {
    const streetHome = {
      streetNO: streetNO,
      homeNO: homeNO,
    };
    setStreetHome(streetHome);
  }, [streetNO, homeNO]);

  return (
    <div className="form-row">
      {error != "" && errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
      {errorblock && errorClass == "errorMessage" && (
        <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
      )}
      {/* <!-- Form Group (first name)--> */}

      <div className="form-group col-md-6">
        <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
          {t("f47c269c2fbde3f5a353b4b442a25e24")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
          data-val="true"
          name="StateId"
          onChange={selectIdentity}
        >
          <option
            value={JSON.stringify({
              identityTypeId: "0",
              identityTypeName: "",
            })}
            className="KhmerOSsiemreaptext"
          >
            {t("c6be2b6191e6660291b6b0c92bd2f0df")}
          </option>
          {props.serviceDiteals &&
            props.serviceDiteals.Service &&
            props.serviceDiteals.Service.IdentityTypeList.map((item) => (
              <option value={JSON.stringify(item)} selected="" className="KhmerOSsiemreaptext">
                {item.identityTypeName}
              </option>
            ))}
        </select>
      </div>

      {identity != "" && identity != "0" ? ( 
        <div className="form-group col-md-6">
          <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
            {identityName} Number <span style={{ color: "red" }}>*</span>
          </label>
          <input
            className="KhmerOSsiemreaptext form-control"
            id="inputUsername"
            type="text"
            placeholder={identityName === 'អត្តសញ្ញាណប័ណ្ណ' ? `${t("504c46e2c369d441b6eb6c581101fc85")}` : `${t("fec629af7fc5bb8ccd3053ed3b6c20fc")}`}
            onChange={(e) => setCustomerId(e.target.value)}
          />
        </div>
      ) : null}

      <div className="form-group col-md-12">
        {/* <div className="text-center"> */}
          <button
            type="button"
            className="KhmerOSsiemreaptext btn btn-primary"
            style={{ height: "32px", marginBottom: "5px" }}
            onClick={searchCustomerInfo}
          >
            <b>{t("13348442cc6a27032d2b4aa28b75a5d3")}</b>
          </button>
        {/* </div> */}
      </div>
      

      {/* <div className="form-group col-md-6">
        <label className="small mb-1" for="inputOrgName">
          ID Card (Front Side) <span style={{ color: "red" }}>*</span>
        </label>
        <div className="text-left">
          <img src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVR4nO2by04UQRSGS0x0IbjyAvgG+BAgKwlG4yXGNyC6QBcgIa4UcOnlKYwrCK7HhRNXRrxtjSi606jgJZoonyk5kxQEenqqq6erp8+X9GrO+avqn6ozU91dxiiKoiiKoiiKoiiK0nbwRL8qQQ3MSKszSmfgFtTAHAwERoEPwHtgpFl8pWF7A61xDVaaxZcC4ARQA777Fv4kWjUwMN9kbKN5mTdLzpjN7Y2IiSvA8TYY6DKTx8yz/AImgL7A+v/JKz6lZh8wKWMk6EwEHoroRDDRyAx0tK+KfC2kqK0Plt5govEa2CvyayFFt+0w8Bio56UfKj7v/ngLAnXgkafmTeB3Q9vHQMFqzPn0oZl+vIJmU1kIwVoVDZzbOgM9sRqzpTSQQDUwNkpRA2OmFEs4ZtTAjGgNzIjWwIzoEu5UA4FD9lYR8EzuKdprCbgBHDSREGUNBM7ZHUPCH+JV4IyJgOhqIBvmrYvMPDAI7JNrCFiQz/4Cp9P2JwStjNebLIJsLNvGzJtMiJuSmK/AgTT9qYqBM5I+nyJ2UWKvmwKJqgYCzyV9MEU7xyR2yVTEwHqzGugs3+4U7fTkcXuqVWJbwl9aMHC/xH5O058s5DXe4ILAU0kfShE7LLFPKmEg6WrgNUlfSNHOA4mdNgUSWw08DHwSiamEuGmJ+Wj/+pgCiWoJW4BTwB+RWZRf2265hp2ZZ2NOmoKJzkCL3aY5u5HtWE+zCxGtYCTpm1Bk3QuzYd5yivEsV2orR5MaCOwC7jj9fQGMAwPOXvgocBl46cTdtrmmINpmYDOAu5JqX9gZA7oSYruAi87LPbdMlQ0EzjrmDbaQN+SYWMjtrcJrILAXeCdpYx7tXZLct8CenfqTBZ/xtq0GAhecmtfl0d5u4JVonO9YA3cCuC8p48YT4Ipo3PPVyNB24Qa+kZSBDG3aX2fLa1+NDG0XXgN/SkpPhjYbt7Z++GqUwcC6vhtTxDcSOYXXwLJTeA0sO1oDIzSw8WAo6AGbGAGOyFhXQ4raM2SJD8Y7BecBf9CDNvboKbLJtyd5+k2HAfSLeY0X38MeOnTeMKgCM0HN2zITa4HPeMRCvsddFUVRFEVRFEVRFEVRFJOdf+dxttTxQOFBAAAAAElFTkSuQmCC" />
        </div>
      </div> */}

      {existingOtherData && identity != "0" && (
        <>
         { false && <div className="form-group col-md-12">
            <label className="KhmerOSsiemreaptext small mb-1" for="inputOrgName">
              {t("794ac0f6ff77cb552878bf13fad600de")}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <div className="text-left">
              {imageName.match(/\.(pdf)$/i) ? (
                <iframe width={150} height={150} src={base64} />
              ) : (
                <img
                  width={150}
                  height={150}
                  src={
                    base64 != ""
                      ? base64
                      : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVR4nO2by04UQRSGS0x0IbjyAvgG+BAgKwlG4yXGNyC6QBcgIa4UcOnlKYwrCK7HhRNXRrxtjSi606jgJZoonyk5kxQEenqqq6erp8+X9GrO+avqn6ozU91dxiiKoiiKoiiKoiiK0nbwRL8qQQ3MSKszSmfgFtTAHAwERoEPwHtgpFl8pWF7A61xDVaaxZcC4ARQA777Fv4kWjUwMN9kbKN5mTdLzpjN7Y2IiSvA8TYY6DKTx8yz/AImgL7A+v/JKz6lZh8wKWMk6EwEHoroRDDRyAx0tK+KfC2kqK0Plt5govEa2CvyayFFt+0w8Bio56UfKj7v/ngLAnXgkafmTeB3Q9vHQMFqzPn0oZl+vIJmU1kIwVoVDZzbOgM9sRqzpTSQQDUwNkpRA2OmFEs4ZtTAjGgNzIjWwIzoEu5UA4FD9lYR8EzuKdprCbgBHDSREGUNBM7ZHUPCH+JV4IyJgOhqIBvmrYvMPDAI7JNrCFiQz/4Cp9P2JwStjNebLIJsLNvGzJtMiJuSmK/AgTT9qYqBM5I+nyJ2UWKvmwKJqgYCzyV9MEU7xyR2yVTEwHqzGugs3+4U7fTkcXuqVWJbwl9aMHC/xH5O058s5DXe4ILAU0kfShE7LLFPKmEg6WrgNUlfSNHOA4mdNgUSWw08DHwSiamEuGmJ+Wj/+pgCiWoJW4BTwB+RWZRf2265hp2ZZ2NOmoKJzkCL3aY5u5HtWE+zCxGtYCTpm1Bk3QuzYd5yivEsV2orR5MaCOwC7jj9fQGMAwPOXvgocBl46cTdtrmmINpmYDOAu5JqX9gZA7oSYruAi87LPbdMlQ0EzjrmDbaQN+SYWMjtrcJrILAXeCdpYx7tXZLct8CenfqTBZ/xtq0GAhecmtfl0d5u4JVonO9YA3cCuC8p48YT4Ipo3PPVyNB24Qa+kZSBDG3aX2fLa1+NDG0XXgN/SkpPhjYbt7Z++GqUwcC6vhtTxDcSOYXXwLJTeA0sO1oDIzSw8WAo6AGbGAGOyFhXQ4raM2SJD8Y7BecBf9CDNvboKbLJtyd5+k2HAfSLeY0X38MeOnTeMKgCM0HN2zITa4HPeMRCvsddFUVRFEVRFEVRFEVRFJOdf+dxttTxQOFBAAAAAElFTkSuQmCC"
                  }
                />
              )}
              <br />
              <br />
              <input
                type="file"
                ref={inputFile}
                className="text-center center-block file-upload"
                onChange={handleFileChange}
              />
              <p
                className="KhmerOSsiemreaptext mt-1"
                style={{
                  fontSize: "13px",
                  fontStyle: "italic",
                  color: "white",
                }}
              >
                {t("2ffe94058afaea3b875be2082941ec0b")}
              </p>
            </div>
          </div>}

          {/* <!-- Form Group (last name)--> */}
         
          {/* <!-- Form Group (phone number)--> */}
          <div className="form-group col-md-6">
            <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
              {t("dbcc71284f11a659aa72716396584a4c")}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className={`KhmerOSsiemreaptext form-control`}
              id="inputPhone"
              type="text"
              placeholder={t("dbcc71284f11a659aa72716396584a4c")}
              // onChange={(e) => setKhamerName(e.target.value)}
              value={existingOtherData.KhmerName}
              disabled
            />
          </div>

          <div className="form-group col-md-6">
            <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
              {t("4ac58405bbbe783528ee33e7ac3fcc5c")}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <input
              className={`KhmerOSsiemreaptext form-control`}
              id="inputFirstName"
              type="text"
              placeholder={t("4ac58405bbbe783528ee33e7ac3fcc5c")}
              // onChange={(e) => setLatinName(e.target.value)}
              value={existingOtherData.LatinName}
              disabled
            />
          </div>
          

          {/* <!-- Form Group (last name)--> */}
          {false && <div className="form-group col-md-6">
            <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
              {t("10803b83a68db8f7e7a33e3b41e184d0")}{" "}
              <span style={{ color: "red" }}>*</span>
            </label>
            <DatePicker
              renderCustomHeader={({
                date,
                changeYear,
                changeMonth,
                decreaseMonth,
                increaseMonth,
                prevMonthButtonDisabled,
                nextMonthButtonDisabled,
              }) => (
                <div
                  style={{
                    margin: 10,
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  <select
                    value={getYear(date)}
                    onChange={({ target: { value } }) => changeYear(value)}
                  >
                    {currentYear.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>

                  <select
                    value={months[getMonth(date)]}
                    onChange={({ target: { value } }) =>
                      changeMonth(months.indexOf(value))
                    }
                  >
                    {months.map((option) => (
                      <option key={option} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              // showIcon
              // toggleCalendarOnIconClick
              className={`KhmerOSsiemreaptext form-control`}
              dateFormat="dd/MM/yyyy"
              selected={existingOtherData.Dob}
              // onChange={(e) => setDateOfBirth(e)}
              disabled
            />
          </div>}

          {/* <!-- Form Group (phone number)--> */}
          {/* <div className="form-group col-md-6">
            <label className="small mb-1" for="inputPhone">
              Nationality <span style={{ color: "red" }}>*</span>
            </label>
            <select
              className={`form-control col-sm col-md-12`}
              data-val="true"
              name="StateId"
              value={33}
              disabled
            >
              <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
              <option value="33" selected="">
                Cambodia
              </option>
            </select>
          </div> */}
          <div className="form-group col-md-6"></div>
          <hr />
         { false&& <h5 style={{ textAlign: "left", color: "#fff", marginTop: "20px" }} className="col-12">
            {t("861a00de3a9b1a4751e4e3190944227e")}
          </h5>}
          <hr style={{ width: "100%" }} />

          {/* <!-- Form Group (last name)--> */}
          {false && <div className="form-group col-md-6">
            <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
              {t("c1a3626600b7d8a8b3e0b2474ab5c202")}
            </label>
            <input
              className={`KhmerOSsiemreaptext form-control`}
              id="inputFirstName"
              type="text"
              placeholder={t("c1a3626600b7d8a8b3e0b2474ab5c202")}
              onChange={(e) => setHomeNo(e.target.value)}
              // value={existingOtherData.HomeNo}
            />
          </div>}

          {false && <div className="form-group col-md-6">
            <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
              {t("d7dbce89503da95fa05b5e38b106adb4")}
            </label>
            <input
              className={`KhmerOSsiemreaptext form-control `}
              id="inputFirstName"
              type="text"
              placeholder={t("d7dbce89503da95fa05b5e38b106adb4")}
              onChange={(e) => setStreetNO(e.target.value)}
              // value={existingOtherData.StreetNo}
            />
          </div>}

          {/* <!-- Form Group (last name)--> */}
          { false && <>
                    <div className="form-group col-md-6">
                      <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                        {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                        data-val="true"
                        name="StateId"
                        value={existingOtherData.CapitalId}
                        disabled
                      >
                        <option value="" className="KhmerOSsiemreaptext">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                        {apicapital.map((capital) => (
                          <option value={capital.Id} selected="" className="KhmerOSsiemreaptext">
                            {capital.Name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* <!-- Form Group (last name)--> */}
                    <div className="form-group col-md-6">
                      <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                        {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                        data-val="true"
                        name="StateId"
                        value={existingOtherData.DistrictId}
                        disabled
                      >
                        <option value="" className="KhmerOSsiemreaptext">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                        {apidistricts.map((districts) => (
                          <option value={districts.Id} selected="" className="KhmerOSsiemreaptext">
                            {districts.Name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* <!-- Form Group (last name)--> */}
                    <div className="form-group col-md-6">
                      <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                        {t("04beeb76f7acc97bb1e309f654fdee7a")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                        data-val="true"
                        name="StateId"
                        value={existingOtherData.CommuneId}
                        disabled
                      >
                        <option value="" className="KhmerOSsiemreaptext">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                        {apicommune.map((commune) => (
                          <option value={commune.Id} selected="" className="KhmerOSsiemreaptext">
                            {commune.Name}
                          </option>
                        ))}
                      </select>
                    </div>

                    {/* <!-- Form Group (last name)--> */}
                    <div className="form-group col-md-6">
                      <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                        {t("abc63490c815af81276f930216c8d92b")}{" "}
                        <span style={{ color: "red" }}>*</span>
                      </label>
                      <select
                        className={`KhmerOSsiemreaptext form-control col-sm col-md-12 `}
                        data-val="true"
                        name="StateId"
                        value={existingOtherData.VillageId}
                        disabled
                      >
                        <option value="" className="KhmerOSsiemreaptext">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
                        {apiVillage.map((village) => (
                          <option value={village.Id} selected="" className="KhmerOSsiemreaptext">
                            {village.Name}
                          </option>
                        ))}
                      </select>
                    </div>
          </>}
        </>
      )}

      {existingOtherData === null && identity != "0" && (
        <OthersDetailsWithoutData
          IdCard={customerId}
          identityName={identityName}
          // OthersDetailsWithoutData={OthersDetailsWithoutData}
          handleDocumentOfOther={handleDocumentOfOther}
        ></OthersDetailsWithoutData>
      )}

      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default OthersDetails;

// to_convert
// Please fill Identity type and number to search the details.
