import "./App.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import ForgetPassword from "./Components/Forget-Password/ForgetPassword";
import Index_landing from "./Components/index_landing";
import Login from "./Components/Login";
import Registration from "./Components/registration/Registration";
import RegistrationProcessA from "./Components/registration-process/RegistrationProcessA";
import RegistrationProcessB from "./Components/registration-process/RegistrationProcessB";
import RegistrationProcessC from "./Components/registration-process/RegistrationProcessC";
import RegistrationProcessD from "./Components/registration-process/RegistrationProcessD";
import ExistingUser from "./Components/Existing-user/ExistingUser";
import PersonalInformation from "./Components/Existing-user/PersonalInformation";
import SetPassword from "./Components/Existing-user/SetPassword";
import SubmittedSuccessfully from "./Components/Existing-user/SubmittedSuccessfully";
import Dashboard from "./Components/Dashboard";


import CivilRegistrationWork from "./Components/CivilRegistrationWork/CivilRegistrationWork";
import TrackApplicationwork from "./Components/TrackApplicationwork/TrackApplicationwork";
import RejectionService from "./Components/RejectionService/RejectionService";
import RejectionTourismService from "./Components/RejectionService/RejectionTourismService";
import RegistrationHolder from "./Components/registration-process/RegistrationHolder";
import PasswordChangeHolder from "./Components/Forget-Password/PasswordChangeHolder";
import ServiceSerlector from "./Components/ServiceSelector/ServiceSerlector";
import TourismGuesthouseNew from "./Components/TourismLicenseWork/TourismGuesthouseNew";
import ServiceSelectorNew from "./Components/ServiceselectorNew/ServiceSelectorNew";
import DashboardindexDetails from "./Components/DashboardDetails/DashboardindexDetails";
import "react-datepicker/dist/react-datepicker.css";
import {
  DashboardContext,
  RejectionResponseContext,
  ServiceSelectContext,
} from "./ContextApi";
import { useState } from "react";
import LegalizationWorkNew from "./Components/LegalizationWorkNew/LegalizationWorkNew";
import RejectionApplication from "./Components/RejectionApplication/RejectionApplication";
import TourismLicenseWork from "./Components/TourismLicenseWork/TourismLicenseWork";
import ViewService from "./Components/ViewService/ViewService";
// import ViewProfile from "./Components/ViewProfile";
import ChangePassword from "./Components/ChangePassword";


function App() {
  const [dashboardUrl, setDashboardUrl] = useState("");
  const [serviceCallID, setServiceCallID] = useState("");
  const [rejectionResponse, setRejectionResponse] = useState("");
  const [rejectedServiceDetails, setRejectedServiceDetails] = useState("");
  const [serviceViewDetails,setServiceViewDetails]=useState("")
 
  return (
    <div className="App">
      <BrowserRouter>
        {/* Routes for Landing and Login ForgotPassword */}
        <Routes>
          <Route path="/" element={<Index_landing></Index_landing>}></Route>

          {/* Nested Route for password Change process */}
          <Route
            path="/forgetPasswordHolder"
            element={<PasswordChangeHolder />}
          />
          {/* <Route path="/profile" element={<ViewProfile />}  /> */}
          <Route path="/changepassword"  element={<ChangePassword/>} />

          {/* Nested Route for registration process */}
          <Route path="/registrationHolder" element={<RegistrationHolder />} />
          {/* <Route path="/ExistingUser"element={<ExistingUser/>} />
          <Route path="/PersonalInformation" element={<PersonalInformation/>} />
          <Route path="/SetPassword" element={<SetPassword/>}/>
          <Route path="/SubmittedSuccessfully" element={<SubmittedSuccessfully/>} ></Route> */}
          {/* <Route path="/legalizationWork" element={<LegalizationWork />} /> */}
          <Route
            path="/trackApplicationwork"
            element={<TrackApplicationwork />}
          />
          {/* <Route path="/selectService" element={<ServiceSerlector />} /> */}
        </Routes>

        {/* Services */}
        <ServiceSelectContext.Provider
          value={{ serviceCallID, setServiceCallID }}
        >
          <Routes>
            <Route
              path="/legalizationWorkNew"
              element={<LegalizationWorkNew />}
            />
            {/* <Route path="/tourismGuesthouseNew"  element={<TourismGuesthouseNew />} /> */}
            <Route
              path="/tourismLicenseWork"
              element={<TourismLicenseWork />}
            />
            <Route
              path="/civilRegistrationWork"
              element={<CivilRegistrationWork />}
            /> 
            <Route
              path="/serviceSelectorNew"
              element={<ServiceSelectorNew />}
            />
          </Routes>
        </ServiceSelectContext.Provider>

        {/* Dashboard */}
        <DashboardContext.Provider
          value={{
            dashboardUrl,
            setDashboardUrl, 
            rejectedServiceDetails,
            setRejectedServiceDetails,
            serviceViewDetails,
            setServiceViewDetails 
          }}
        >
          <Routes>
            <Route path="/dashboard" element={<Dashboard />} />
            <Route path="/viewService" element={<ViewService />} />
            <Route
              path="/dashboardindexDetails"
              element={<DashboardindexDetails />}
            />
            <Route path="/rejectionService" element={<RejectionService />} />
            <Route path="/rejectionTourismService" element={<RejectionTourismService />} />
            
          </Routes>
        </DashboardContext.Provider>

        <RejectionResponseContext.Provider
          value={{ rejectionResponse, setRejectionResponse }}
        >
          <Routes>
            <Route
              path="/rejectionApplication"
              element={<RejectionApplication />}
            />
            <Route path="/login" element={<Login></Login>}></Route>
          </Routes>
        </RejectionResponseContext.Provider>
      </BrowserRouter>
    </div>
  );
}

export default App;
