import React from "react";
import { useTranslation } from "react-i18next";
function ModalUnAuthorised({ error, errorClose, buttonText }) {
  const { t } = useTranslation();
  return (
    <div className={`  loderdiv`}>
      <div
        className={`modal fade  show modal-img`}
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: ` block` }}
      >
        <div className="modal-dialog mt-15" role="document">
          <div className="modal-content">

            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ padding: "0" }}
              >
                {/* {t("674880d923c4e1086190af98851ec927")} */}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>

            <div className="modal-body">
              <div className="row justify-content-center ">
                <div className="col-2">
                  <img width="48" height="48" src="https://img.icons8.com/color/48/cancel--v1.png" alt="cancel--v1" />
                </div>
              </div>
              <div className="KhmerOSsiemreaptext col-md-12 col-lg-12 mt-2 text-center text-black">
                {/* {t("1769908af5b99c90bf8f8fcea46ba6da")} */}
                {t(`${error}`)}
              </div>
              <br />
              <div className="row" style={{ marginBottom: "30px" }}></div>
            </div>

            <div className="modal-footer text-center">
              <div className="col-12 fot-row row">
                <div className="d-flex flex-row justify-content-end col-12">
                <button
                    type="button"
                    className="KhmerOSsiemreaptext btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => errorClose(true)}
                    hidden={!buttonText}
                  >
                   {`${t('e0aa021e21dddbd6d8cecec71e9cf564')}`}
                  </button>
                  <button
                    type="button"
                    className="KhmerOSsiemreaptext btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => errorClose(false)}
                    hidden={buttonText}
                  >
                   {`${t('e673f653b6898ccbb47b8c1ff69f9034')}`}
                  </button>
                  <a href="#" className=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalUnAuthorised;
