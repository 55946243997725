import React, { useEffect, useState } from "react";
// import "../css/style.css";
import { NavLink, Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import TopBar from "./TopBar";
import MidBar from "./MidBar";
import EndBar from "./EndBar";
// import "../css/animate.css"
// import "../css/bootstrap.css"
// import "../css/waves.css"
// import logo from "../images/logo.png"

function Navigation({ page, login, rejectionlogin }) {
  const [LanguageChange, setLanguageChange] = useState(false);

  return (
    <section className="header">
      <TopBar page={page}></TopBar>

      <MidBar login={login} rejectionlogin={rejectionlogin}></MidBar>

      <EndBar login={login}></EndBar>
      {/* other navigation end */}
    </section>
  );
}

export default Navigation;
