import axios from "axios";
import React, { useState, useEffect, useCallback } from "react";

import { useTranslation } from "react-i18next";

import { useNavigate } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import ModalError from "../Error-Span-Modal/ModalError";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function RegistrationProcessB(props) {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [otpText, setOtpText] = useState("4f2df4c330b8df125a3d75f2d69a9b5e");
  const [disable, setDisable] = useState(false);
  const [submitdisable, setSubmitDisable] = useState(true);
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const { t } = useTranslation();
  const [errorClass, setErrorClass] = useState("");
  const [otpToken, setOtpToken] = useState("");
  const [otp, setOTP] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [isValid, setIsValid] = useState(false);
  // const isLoginpage = true;
  // let navigate = useNavigate();
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

  const [minutes, setMinutes] = useState(0);
  const [seconds, setSeconds] = useState(0);
  useEffect(() => {
    if (props.isExistCustomer) {
      setPhoneNumber(props.customer.Phone);
    }
    const interval = setInterval(() => {
      if (seconds > 0) {
        setSeconds(seconds - 1);
      }

      if (seconds === 0) {
        if (minutes === 0) {
          clearInterval(interval);
          setDisable(false);
          setSubmitDisable(true);
          setError("");
          setErrorBlock(false);

        } else {
          setSeconds(59);
          setMinutes(minutes - 1);
        }
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [seconds]);
 
  const resendOTP = () => {
    if (phoneNumber == "") {
      setError("8c4ed5656b26e589227db99ed6d8e5b9");
      errorHandel(0);
      return;
    }
    const phoneRegex = /^\d{8,10}$/;
    const isValidPhone = phoneRegex.test(phoneNumber);

    if (!isValidPhone) {
      setError("613ef4baa62da2b5aa61e3cbfcd03cce");
      errorHandel(1);
      return;
    }

    const sendOTP = {
      user_id: props.registerDataA.idCardNumber,
      // user_id: props.customer.IdCard,
      mobileno: phoneNumber,
    };
    // api call for otp send
    axios
      .post(`${LoginKey}/registration/sendOTP`, sendOTP)
      .then((response) => {
        // setIsloading(false);
        if (response.data.status == 1) {
          console.log(response.data.message)
          setError("a6968077c45b80366958453abe562984"); 
          setOtpToken(response.data.responseObject.otp_token);
          setOtpText("e0cf58fa3413df99ae83109ebb9a41a3");
          errorHandel(2);
          setDisable(true);
          setMinutes(2);
          setSeconds(59);
          setSubmitDisable(false);
        } else if (response.data.status == 0) {
          setError("ab1c0422d06e8d88fa2b627ab9c4db69");
          errorHandel(0);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const backProcessA = () => {
    props.handelclick(0);
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else if (e == 1) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(()=>{
    //   setErrorBlock(false)
    // },4000)
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  const verifyOTp = () => {
    // api call for verify otp
    setIsloading(true);

    const registerData = {
      ...props.registerDataA,
      mobile: phoneNumber,
    };
    const submitOTP = {
      otp: otp,
      otp_token: otpToken,
    };
    axios
      .post(`${LoginKey}/registration/validateOTP`, submitOTP)
      .then((response) => {
        setIsloading(false);
        if (response.data.status == 1) {
          props.handelData(registerData);
          props.handelclick(2);
        } else if (response.data.status == 0) {
          setError("a558ffd7c2756724f0369642825c1014");
          errorHandel(0);
        }
      })
      .catch((err) => {
        setIsloading(false);
        console.log(err);
      });
  };

  return (
    <div>
      {errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}

      {errorblock && errorClass == "errorMessage" && (
        <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
      )}
      <div className="card">
        <div id="headingTwo" />
        <div
          id="collapseTwo"
          className="collapse show"
          aria-labelledby="headingTwo"
          data-parent="#accordionExample"
          style={{}}
        >
          <div className="card-body" style={{ width: "648px!important" }}>
            <div className="form-row">
              {/* Form Group (first name)*/}
              <div className="form-group col-md-6">
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputFirstName">
                  {t("7385b9abf62f68950925436c288c6746")}
                  {/* <span
                    style={{
                      paddingLeft: "15px",
                      fontSize: "12px",
                      color: "#b10909",
                    }}
                  >
                    {error}
                  </span> */}
                  <span style={{ float: "right" }}>
                    {disable &&
                      `${minutes < 10 ? `0${minutes}` : minutes}:${
                        seconds < 10 ? `0${seconds}` : seconds
                      }`}
                  </span>
                </label>
                <span className="mandatory">*</span>
                <div className="input-group mb-3">
                  <input
                    type="number"
                    className="KhmerOSsiemreaptext form-control"
                    placeholder={t("7385b9abf62f68950925436c288c6746")}
                    aria-label="Recipient's username"
                    aria-describedby="button-addon2"
                    // disabled={props.isExistCustomer?true:disable}
                    disabled={disable}
                    defaultValue={props.customer.Phone}
                    onChange={(e) => {
                      setPhoneNumber(e.target.value);
                    }}
                  />
                  <button
                    className="KhmerOSsiemreaptext btn btn-primary next"
                    type="button"
                    id="button-addon2"
                    disabled={disable}
                    onClick={resendOTP}
                  >
                    {t(`${otpText}`)}
                  </button>
                </div>
              </div>

              {/* Form Group (last name)*/}
              <div className="form-group col-md-6" style={{ marginBottom: 80 }}>
                <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputLastName">
                  {t("2f96a82a2d4bb7206c760a26cdcb2cc8")}
                </label>
                <span className="mandatory">*</span>
                <input
                  className="KhmerOSsiemreaptext form-control"
                  id="inputFirstName"
                  type="number"
                  placeholder={t("2f96a82a2d4bb7206c760a26cdcb2cc8")}
                  onChange={(e) => setOTP(e.target.value)}
                  disabled={submitdisable}
                />
              </div>
              <hr />
              <div className="col-12 fot-row row">
                <div className="d-flex justify-content-between col-6">
                  <button
                    className="KhmerOSsiemreaptext btn btn-primary next"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseOne"
                    aria-expanded="false"
                    aria-controls="collapseOne"
                    // style={{
                    //   backgroundColor: "#a1a1a1",
                    //   borderColor: "#7a7a7a",
                    // }}
                    onClick={backProcessA}
                  >
                    {t("0557fa923dcee4d0f86b1409f5c2167f")}
                  </button>
                </div>

                <div className="d-flex flex-row justify-content-end col-6">
                  <button
                    className="KhmerOSsiemreaptext btn btn-primary next"
                    type="button"
                    data-toggle="collapse"
                    data-target="#collapseThree"
                    aria-expanded="false"
                    aria-controls="collapseThree"
                    style={{ backgroundColor: submitdisable && "#a1a1a1", borderColor: submitdisable && "#7a7a7a" }}
                    onClick={verifyOTp}
                    disabled={submitdisable}
                  >
                    {t("60bf4ca5718f38278204d94c03113615")}
                  </button>
                  <a href="#" className="" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default RegistrationProcessB;

// to_convert
// match not found...
// Please Enter Your Phone Number !
// Please Enter a Valid Phone number !
// Resend OTP
// Not Correct Number
// The entered OTP is incorrect. Please try again
