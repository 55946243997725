import React, { useEffect, useState, CSSProperties, useContext } from "react";
import "../css/style.css";
import Footer from "./Footer";
import Navigation from "./NavigationPanel/Navigation";
import { Link, useNavigate } from "react-router-dom";
import bcrypt from "bcryptjs";
import { useTranslation } from "react-i18next";
import axios from "axios";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { RejectionResponseContext } from "../ContextApi";
import ModalError from "./Error-Span-Modal/ModalError";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

// const loaderdiv ={
//   position: "absolute",
//   top: 0,
//   left: 0,
//   bottom: 50,
//   width: "100%",
//   height: "100%",
//  minHeight:"100%",
//   backgroundColor: "rgba(0, 0, 0, 0.6)",
//   backdropFilter: "blur(3px)",
//   zIndex: "15",
// }

function Login() {
  const isLoginpage = true;
  let navigate = useNavigate();
  const { t } = useTranslation();
  //Declearation of fields
  const [loginId, setLoginID] = useState("");
  const [password, setPassword] = useState("");
  const [captcha, setCaptcha] = useState("");
  const [loadCaptcha, setLoadCaptcha] = useState(" ");
  const [styleCaptcha,setStyleCaptcha]=useState("");
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [modalError, setModalError] = useState(false);
  const PasswordKey = process.env.REACT_APP_PAASSWORD_ENCRTPTKEY;
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;
  const salt = bcrypt.genSaltSync(10);

  const [isloading, setIsloading] = useState(false);

  const { setRejectionResponse } = useContext(RejectionResponseContext);

  useEffect(() => {
    captchaReload();
  }, []);

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(()=>{
    //   setErrorBlock(false)
    // },4000)
  };
  //function for reload captcha
  const captchaReload = () => {
    var alpha = ["0", "1", "2", "3", "4", "5", "6", "7", "8", "9"];
    var i;
    for (i = 0; i < 6; i++) {
      var a = alpha[Math.floor(Math.random() * alpha.length)];
      var b = alpha[Math.floor(Math.random() * alpha.length)];
      var c = alpha[Math.floor(Math.random() * alpha.length)];
      var d = alpha[Math.floor(Math.random() * alpha.length)];
      var e = alpha[Math.floor(Math.random() * alpha.length)];
      var f = alpha[Math.floor(Math.random() * alpha.length)];
      var g = alpha[Math.floor(Math.random() * alpha.length)];
    }
    var code = a + b + c + d + e + f;
    var styleCode=a+" "+b+" "+c+" "+d+" "+e+" "+f
    setLoadCaptcha(code);
    setStyleCaptcha(styleCode);
  };

  //Login handel function
  const loginHandel = (e) => {
    e.preventDefault();
    // const hashedPassword=bcrypt.hashSync(password, PasswordKey);

    const loginData = {
      userCode: loginId,
      password: password,
    };
 
    //comment this
    // setIsloading(true);
    // setTimeout(() => {
    //   setIsloading(false);
    //   navigate("/dashboard");
    // }, 2000);

    //login service implementation
    if (loginId === "" || password === "" || captcha === "") {
      setError("2d13aa4a4d1a2a0dedf1a03323d294b8");
      errorHandel(0);
    } else {
      if (loadCaptcha === captcha) {
        setIsloading(true);
        axios
          .post(`${LoginKey}/login/citizenLogin`, loginData)
          .then((response) => {
            setIsloading(false);
            // if (response.data.status == 1) {
            if (
              response.data.responseObject.citizenLoginResponse != undefined
            ) {
              if (
                response.data.responseObject.citizenLoginResponse
                  .citizenLoginDetailsResponseData.result.status == 1
              ) {
                const accessToken = response.data.responseObject.accessToken;
                sessionStorage.setItem("accessToken", accessToken);
                const authToken = response.data.responseObject.authToken;
                sessionStorage.setItem("authToken", authToken);
                const refreshToken = response.data.responseObject.refreshToken;
                sessionStorage.setItem("refreshToken", refreshToken);
                const customerId =
                  response.data.responseObject.citizenLoginResponse
                    .citizenLoginDetailsResponseData.result.customerId;
                 sessionStorage.setItem("customerId", customerId);
                const userID =
                  response.data.responseObject.citizenLoginResponse
                    .citizenLoginDetailsResponseData.result.idCard;
                sessionStorage.setItem("userId", userID);
                navigate("/dashboard");
              } else if (
                response.data.responseObject.citizenLoginResponse
                  .citizenLoginDetailsResponseData.result.status == 0
              ) {
                setError("9cabed03d7bc7aa396bef73dab7fe030");
                errorHandel(0);
                captchaReload();
                e.target.reset();
                setLoginID("");
                setPassword("");
              } else if (
                response.data.responseObject.citizenLoginResponse
                  .citizenLoginDetailsResponseData.result.status == 2
              ) {
                captchaReload();
                setError("339234bc46cf4438261567e1eed8d393");
                errorHandel(0);
                setLoginID("");
                setPassword("");
                const accessToken = response.data.responseObject.accessToken;
                sessionStorage.setItem("accessToken", accessToken);
                const authToken = response.data.responseObject.authToken;
                sessionStorage.setItem("authToken", authToken);
                const refreshToken = response.data.responseObject.refreshToken;
                sessionStorage.setItem("refreshToken", refreshToken);
                const customerId =
                  response.data.responseObject.citizenLoginResponse
                    .citizenLoginDetailsResponseData.result.customerId;
                 sessionStorage.setItem("customerId", customerId);
                const userID =
                  response.data.responseObject.citizenLoginResponse
                    .citizenLoginDetailsResponseData.result.idCard;
                sessionStorage.setItem("userId", userID);
                const rejectRespone = {
                  idCard: loginId,
                  accessToken: response.data.responseObject.accessToken,
                  authToken:  response.data.responseObject.authToken
                };
                setRejectionResponse(rejectRespone);
                navigate("/rejectionApplication");
              } else if (
                response.data.responseObject.citizenLoginResponse
                  .citizenLoginDetailsResponseData.result.status == 3
              ) {
                captchaReload();
                setError("e140917ef195a4d3b75f79e90fd1a3eb");
                errorHandel(0);
                setLoginID("");
                setPassword("");
              }
            } else {
              captchaReload();
              setError("efa2ddab1adbb740ca25d353c13bf516");
              errorHandel(0);
            }
          })
          .catch((err) => {
            setIsloading(false);
            console.log(err);
            setError("4b2b25d11213a09ce090cda61c0ea175");
            errorHandel(0);
          });

        // fetch(`http://localhost:8765/owcs-login-service/login/citizenLogin`, {

        //   method: 'POST',
        //   body: JSON.stringify({
        //     // Add parameters here
        //     userCode:loginId,
        //   password:password,
        //   }),
        //   headers: {
        //     'Content-type': 'application/json; charset=UTF-8',
        //   },
        // })
        //    .then((response) => {
        //     if(response.responseObject.validationCode==1){
        //       setIsloading(false);
        //       navigate("/dashboard")
        //     }
        //    })

        //    .then((data) => {
        //       // Handle data

        //    })
        //    .catch((err) => {
        //    });
      } else {
        // setIsloading(false);
        captchaReload();
        setError("f27b4e981b3b196187360b4dfe856c47");
        errorHandel(0);
      }
    }

    // setLoginID("");
    // setPassword("");
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };
  const errorClose = (e) => {
    setErrorBlock(e);
  };
  return (
    <>
      <div>
        {/* {errorblock && (
          <div
            className={`popup ${errorClass}`}
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-evenly",
            }}
          >
            <div>{error}</div>
            <div>
              <button
                onClick={erroBlockHandel}
                className={`download ${errorClass}`}
              >
                <i class="fa fa-times" aria-hidden="true"></i>
              </button>
            </div>
          </div>
        )} */}
        {errorblock && (
          <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
        )}
        <Navigation page={isLoginpage}></Navigation>

        <div id="layoutSidenav_content">
          <main>
            <header className="page-header page-header-dark bg-gradient-primary-to-secondary pb-10 lgn">
              <div className="container-fluid d-flex justify-content-center col-lg-4 col-sm-12 col-md-6">
                <div
                  className="card card-lg mb-4"
                  style={{ backgroundColor: "rgb(17,126,191, 0.8)" }}
                >
                  <div className="KhmerOSmuollighttext card-header" style={{ color: "#fff" }}>
                    {t("fd9242b35808986c4f749114e90875ec")}
                  </div>
                  <div
                    className="card-body"
                    style={{
                      padding_top: "0.35rem",
                      padding_bottom: "0.35rem",
                      text_align: "left",
                    }}
                  >
                    <form onSubmit={loginHandel}>
                      {/* <!-- Form Group (username)--> */}
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputUsername">
                            {t("8e233234a83a277872b8ce305dceb914")}{" "}
                            <span  style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control"
                            id="inputUsername"
                            type="number"
                            
                            // onBeforeInput={handleKeyPress}
                            placeholder={t("504c46e2c369d441b6eb6c581101fc85")}
                            onChange={(e) => {
                              setLoginID(e.target.value);
                            }}
                            autocomplete="off"
                          />
                        </div>
                      </div>
                      {/* <!-- Form Row--> */}
                      <div className="form-row">
                        {/* <!-- Form Group (last name)--> */}
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("dc647eb65e6711e155375218212b3964")}{' '}
                            <span  style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control"
                            id="inputLastName"
                            type="password"
                            placeholder={t("d351d57d7bafd005cdaabd11a85da85d")}
                            onChange={(e) => {
                              setPassword(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        {/* <!-- Form Group (last name)--> */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("45d048c35e3cfd449dc0a1b503cd103a")}{' '}
                            <span  style={{ color: "red" }}>
                              *
                            </span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control col-10"
                            id="inputLastName"
                            type="text"
                            placeholder="234GTY"
                            value={styleCaptcha}
                            disabled
                            onCopy={(e) => {
                              e.preventDefault();
                              return false;
                            }}
                            style={{'backgroundColor' : '#ded7d7'}}
                          />
                          <button className="re-btn col-2" type="button">
                            <img
                              width="20"
                              height="20"
                              src="https://img.icons8.com/windows/32/refresh.png"
                              alt="refresh"
                              onClick={captchaReload}
                            />
                          </button>
                        </div>

                        <div className="form-group col-md-6">
                          <label
                            className="small mb-1"
                            for="inputLastName"
                          ></label>
                          <input
                            className="KhmerOSsiemreaptext form-control"
                            id="inputLastName"
                            type="number"
                            placeholder={t("394fb72441549c3175d7784c3aa8a458")} 
                            autocomplete="off"
                            style={{ margin_top: "3px!important" }}
                            onChange={(e) => {
                              setCaptcha(e.target.value);
                            }}
                          />
                        </div>
                      </div>

                      <div className="form-row">
                        {/* <!-- Form Group (last name)--> */}
                        <div className="d-flex justify-content-center form-group col-md-12">
                          <button
                            className="KhmerOSsiemreaptext btn btn-primary boderR login-acc"
                            type="submit"
                          >
                            {t("fd9242b35808986c4f749114e90875ec")} &nbsp;
                            &nbsp;
                            <img
                              width="20px"
                              height="20px"
                              src="https://img.icons8.com/windows/32/password-window.png"
                              alt="password-window"
                            />
                          </button>
                        </div>
                      </div>

                      <div className="form-group row">
                        <label
                          for="remember-me"
                          className="KhmerOSsiemreaptext text-info col-md-6 col-lg-6"
                        >
                          <Link to={"/forgetPasswordHolder"}>
                            {t("6d909c2ed76c42670f090b269a478b7f")}
                          </Link>
                        </label>

                        <label
                          for="remember-me"
                          className="KhmerOSsiemreaptext text-info col-md-6 col-lg-6"
                          style={{ text_align: "right;" }}
                        >
                          <Link to={"/registrationHolder"}>
                            {t("2fe4fe0a0ebea41ce43eb3dc3fce4ea3")}
                          </Link>
                        </label>
                      </div>
                    </form>
                  </div>

                  {/* <!-- Save changes button--> */}
                </div>
              </div>
            </header>
          </main>
        </div>

        <Footer></Footer>
        {isloading && (
          <div className="loderdiv">
            <ClipLoader
              color="hsla(203, 66%, 53%, 1)"
              size={80}
              cssOverride={override}
            />
          </div>
        )}
      </div>
    </>
  );
}

export default Login;

// to_convert
// Captcha does not match
// Invalid Credentials.
// This account has been blocked/
// Your account submission has been rejected
// Your account is currently pending approval.
// Please fill out all required fields
