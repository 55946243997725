import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useSSR, useTranslation } from "react-i18next";

function RegistrationNav({ steps }) {
  
  const { t } = useTranslation();
  let navigate = useNavigate();
  
  // const [currentStep, setCurrentStep] = useState(1);
  const [isCompleted, setIsCompleted] = useState(false);
  const stepRef = useRef([]);
  const [margins, setMargins] = useState({
    marginLeft: 0,
    marginRight: 0
  })

  const calculateWidth = () =>{
    return (((steps-1)/3)*100); 
  }

  useEffect(() => {
    setMargins({
      marginLeft: stepRef.current[0].offsetWidth/2,
      marginRight: stepRef.current[3].offsetWidth/2
    })
  },[stepRef])


  const processNav = [
    {
      id: 1,
      information: "61f1667518c8516a8a4fe18686a4e6fc",
    },
    {
      id: 2,
      information: "79e5e863727b998ec0c5043348e6bbea",
    },
    {
      id: 3,
      information: "af214972865d03cc4eb63ed9f0b75554",
    },
    {
      id: 4,
      information: "a4d3b161ce1309df1c4e25df28694b7b",
    },
  ];
  return ( 
    <div>
      <div className="stepper">
        {/* <progress id="progress" value={steps} max={100} /> */}

        {processNav.map((navdata, index) => (
          <div className={`step ${steps > index + 1 || isCompleted || steps === 4? "complete" : ''} ${steps === index + 1 ? 'active_stepper' : ''}`} key={navdata.id} ref={(el) => (stepRef.current[index] = el)}>
            <button
              className="KhmerOSsiemreaptext step_number text-center"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
            >
              {
                steps > index + 1 || isCompleted || steps === 4 ? (
                  <span>&#10003;</span>
                ) : 
                  `${navdata.id}` 
                
              }
                
              
              
            </button>
            <div className="KhmerOSsiemreaptext step-title">{t(`${navdata.information}`)}</div>
          </div>
        ))}

        <div className="progress_bar" style={{width: `calc(100% - ${margins.marginLeft+margins.marginRight}px)`, marginLeft: margins.marginLeft, marginRight: margins.marginRight}}>
          <div className="progress" style={{width: `${calculateWidth()}%`}}></div>
        </div>

        {/* <div className="step-item">
                        <button
                          className="step-button text-center collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseTwo"
                          aria-expanded="false"
                          aria-controls="collapseTwo"
                        >
                          2
                        </button>
                        <div className="step-title">
                          Phone Number Verification
                        </div>
                      </div>
                      <div className="step-item">
                        <button
                          className="step-button text-center collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseThree"
                          aria-expanded="false"
                          aria-controls="collapseThree"
                        >
                          3
                        </button>
                        <div className="step-title">Set Password</div>
                      </div>
                      <div className="step-item">
                        <button
                          className="step-button text-center collapsed"
                          type="button"
                          data-toggle="collapse"
                          data-target="#collapseFour"
                          aria-expanded="false"
                          aria-controls="collapseFour"
                        >
                          4
                        </button>
                        <div className="step-title">Submitted Successfully</div>
                      </div> */}
      </div>

    </div>
  );
}

export default RegistrationNav;

// to_convert
// completed...
// done !
