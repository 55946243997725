import React, { useEffect, useState } from 'react'
import { useTranslation } from "react-i18next";
function ModalImage({ imageName, imageBase64, imageClose, isQR,qrTimeout, applicationNumber, amount, description }) {
  const { t } = useTranslation();

  const [minutes, setMinutes] = useState(2);
  const [seconds, setSeconds] = useState(59);
  const [isLessThan10Seconds, setIsLessThan10Seconds] = useState(false);
  
  // useEffect(() => {
    
  //   const clearQRTimeout = setTimeout(() => {
  //     qrTimeout();
  //   }, 180000);

  //   return () => {
  //     clearTimeout(clearQRTimeout);
  //   }
  // },[])
  useEffect(() => {
    const interval = setInterval(() => {
      if(seconds > 0){
        if(seconds > 10){
          setIsLessThan10Seconds(false);
        }
        if(seconds <= 10) setIsLessThan10Seconds(true);

        setSeconds(seconds - 1);

      }
      else if(seconds == 0){
        if(minutes == 0){
          // setIdleMessage(false)
          qrTimeout();
        }
        else{
          setSeconds(59)
          setMinutes(minutes - 1);
        }
    }
    }, (1000));

    return () => {
      clearInterval(interval)
    }
  },[seconds])

 
  const downloadImage = () => {
    console.log(imageName)
    let documentType = imageBase64.split(";")
    documentType = documentType[0].split("/");
    const downloadLink = document.createElement("a");
    downloadLink.href = imageBase64;
    downloadLink.download = `${imageName.split(".")[0]}.${documentType[1]}`
    downloadLink.click();
  }
  return (
    <div className={`  loderdiv`}>
      <>
      
      <div
        className={`modal fade show modal-img`}
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: ` block` }}
      >
        <div className="modal-dialog " role="document">
        
          <div className="modal-content">

            <div className={ isQR? "QR-header": "modal-header"}>
             { isQR && <img
                className="img-fluid logo"
                src={"/images/new_logo.png"}
                style={{ width: "70%", margin: "10px auto", height: '50px' }}
              ></img>}
              { isQR && <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{padding: "0" }}
              >
                {t("9fc6c130df428afee946043e4b852b88")}: {applicationNumber}
              </h5>}
              {isQR && <p style={{ margin: "13px 16px"}}>{description}</p>}

              {isQR && <div className='last_row_header'>
                <span className="item1">{t("eb7297e94e2cb86e90a6ead067666717")}</span>
                <span className="item2">{amount} {t("7f80fcc452c2f1ed2bb51b39d0864df1")}</span>
              </div>}
              {isQR && <hr class="styled-hr"></hr>}
            </div>

            <div className="modal-body">
              <div className="row justify-content-center ">
                <div className="qr_outer_container">

                 { isQR && 
                  <div className="bank_details">
                    <img src="/images/bank_logo.png" style={{width: '60px', height: '40px'}} alt="bank_logo" className='bank_logo'/>
                    
                    <div className='bank_text_container'>
                      <span className="bank_text_1"><b>Bakong KHQR</b></span>
                      <span className="bank_text_2">អាចបង់ប្រាក់បានគ្រប់ធនាគារ Bakong</span>
                    </div>

                  </div>
                  }
                  {imageName.match(/\.(pdf)$/i) ? (
                    <iframe width={480} height={450} src={imageBase64} />
                  ) : (
                    <div className={isQR ? "qr-container" : ''}>
                     <img
                      width={ isQR ? 220 : 480}
                      height={ isQR ? 200 : 450}
                      src={
                        imageBase64
                      }
                      alt="file"
                    />
                      { isQR && <><div class="corner top-left"></div>
                      <div class="corner top-right"></div>
                      <div class="corner bottom-left"></div>
                      <div class="corner bottom-right"></div> </> }

                      

                    </div>
                  
                    
                    
                  )}
                                <div className="row" style={{ marginTop: "10px" }}></div>

                  {isQR && <div className="last_row_payment">
                    <div className="last_row_payment_first_column">
                      <span className="last_row_payment_first_column_row_1"><b>{t("0087beb79ffa1507d7e20e832030dbb7")}</b></span>
                      <span className="last_row_payment_first_column_row_2">{minutes}:{isLessThan10Seconds ? `0${seconds}` : seconds}</span>
                      
                    </div>
                      <button
                    type="button"
                    className="KhmerOSsiemreaptext btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => imageClose(false)}
                  >
                    {t("ea4788705e6873b424c65e91c2846b19")}
                  </button>
                  </div>}
                </div>
                
              </div>
              <br />
              { !isQR && <div className="row" style={{ marginBottom: "30px" }}></div>}
            </div>

           {  !isQR && <div className="modal-footer text-center">
              <div className="col-12 fot-row row">
                { !isQR && <div className="d-flex justify-content-between col-6">
                  <button
                    className="KhmerOSsiemreaptext btn btn-primary collapsed"
                    type="button"
                    onClick={downloadImage}
                  >
                    {t("4240abaacda205bcafd1df82fd1586de")} &nbsp;
                    &nbsp;
                    <img
                      width="25"
                      height="25"
                      src="https://img.icons8.com/dotty/80/download.png"
                      alt="download"
                    />
                  </button>
                </div>}
                <div className="d-flex flex-row justify-content-end col-6">
                  <button
                    type="button"
                    className="KhmerOSsiemreaptext btn btn-secondary"
                    data-dismiss="modal"
                    onClick={() => imageClose(false)}
                  >
                    {t("d3d2e617335f08df83599665eef8a418")}
                  </button>
                  <a href="#" className=""></a>
                </div>
              </div>
            </div>}
          </div>
        </div>
      </div>
      </>
    </div>
  )
}

export default ModalImage