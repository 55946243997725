import React from "react";
import { useTranslation } from "react-i18next";
function ModalDeleteConfirm({ error, deleteCancel, deleteConfirm }) {
  const { t } = useTranslation();
  return (
    <div className={`  loderdiv`}>
      <div
        className={`modal fade  show `}
        id="exampleModal"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalLabel"
        aria-hidden="true"
        style={{ display: ` block` }}
      >
        <div className="modal-dialog mt-15" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h5
                className="modal-title"
                id="exampleModalLabel"
                style={{ padding: "0" }}
              >
                {/* {t("674880d923c4e1086190af98851ec927")} */}
                {t("a6b0cb5745e7cc8313d8eb2f719d69ae")}
              </h5>
              <button
                type="button"
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                {/* <span aria-hidden="true">&times;</span> */}
              </button>
            </div>
            <div className="modal-body">
              <div className="row justify-content-center ">
                <div className="col-2">
                  <img
                    width="48"
                    height="48"
                    src="https://img.icons8.com/color/48/delete-forever.png"
                    alt="delete-forever"
                  />
                </div>
              </div>
              <div className="KhmerOSsiemreaptext col-md-12 col-lg-12 mt-2 text-center text-black">
                {/* {t("1769908af5b99c90bf8f8fcea46ba6da")} */}
                {t("288ad6f9b3575ce5f4bb348538e9d87a")}
              </div>
              <br />
              <div className="row" style={{ marginBottom: "30px" }}></div>
            </div>
            <div className="modal-footer text-center">
              <div className="col-12 fot-row row">
                {/* <div className="d-flex justify-content-between col-6">
                  <button
                    className="btn btn-primary collapsed"
                    type="button"
                    onClick={()=>deleteConfirm()}
                  >
                    {t("4240abaacda205bcafd1df82fd1586de")} 
                    Yes
                  </button>
                </div> */}

                {/* <div className="d-flex flex-row justify-content-end col-md-12"> */}
                <div className="col-md-12">
                  <div className="text-center">
                    <button
                      className="KhmerOSsiemreaptext btn btn-primary collapsed"
                      type="button"
                      onClick={() => deleteConfirm()}
                    >
                      {/* {t("4240abaacda205bcafd1df82fd1586de")}  */}
                      {t("a6105c0a611b41b08f1209506350279e")}
                    </button>
                    &nbsp;&nbsp;&nbsp;
                    <button
                      type="button"
                      className="KhmerOSsiemreaptext btn btn-secondary"
                      data-dismiss="modal"
                      onClick={() => deleteCancel(false)}
                    >
                      {/* {t("d3d2e617335f08df83599665eef8a418")} */}
                      {t("7fa3b767c460b54a2be4d49030b349c7")}
                    </button>
                  </div>
                  <a href="#" className=""></a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalDeleteConfirm;
