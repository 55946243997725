import React, { useEffect, useState, useRef, useContext } from "react";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import axios from "axios";
import { OthersComponentContext } from "../../ContextApi";
import { useTranslation } from "react-i18next";
import ModalError from "../Error-Span-Modal/ModalError";
 
function OthersDetails(props) {
  const { t } = useTranslation();

  const communeRef = useRef(null);
  const districtRef = useRef(null);
  const provinceRef = useRef(null);
  const villageRef = useRef(null);

  const [khamerName, setKhamerName] = useState("");
  const [latinName, setLatinName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(""); 
  const [homeNO, setHomeNo] = useState("");
  const [strretNO, setStreetNO] = useState("");
  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);
  const [nationality, setNationality] = useState("");
  const [capitalCode, setCapitalCode] = useState("");
  const [districtCode, setDisctirctCode] = useState("");
  const [communeCode, setCommuneCode] = useState("");
  const [villageCode, setVillageCode] = useState("");
  const [error, setError] = useState("");
  const [errorClass, setErrorClass] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [sex, setSex] = useState(0);
  const [dobAddress, setDobAddress] = useState("");
  const [base64, setBase64] = useState("");
  const [imageName, setImageName] = useState("");
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const inputFile = useRef(null);
  const currentYear = range(1850, getYear(new Date()) + 1, 1);
  const { setStreetHome } = useContext(OthersComponentContext);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const { setOtherData } = useContext(OthersComponentContext);
  useEffect(() => {
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get capital
    axios
      .post(`${MasterKey}/getCapital`, { userCode: "1" }, config)
      .then((response) => {
        if (response.data.status === "1") {
          setApiCaptial(response.data.responseObject.Data.Capitals);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);
  useEffect(() => {
    const streetHome = {
      streetNO: strretNO,
      homeNO: homeNO,
    };
    setStreetHome(streetHome);
  }, [strretNO, homeNO]);

  const handleFileChange = () => {
    if (!inputFile.current.files[0].name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      console.log(inputFile.value)
      inputFile.current.value = '';
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
    } else {
      if (inputFile.current.files[0].size >= 2 * 1024 * 1024) {
        inputFile.current.value = '';
        setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
        errorHandel(0);
        return;
      }
      props.handleDocumentOfOther(inputFile.current.files[0]);
      setImageName(inputFile.current.files[0].name);
      getBase64(inputFile.current.files[0]).then((res) => setBase64(res));
    }
  };

  // function convet file to base64
  const getBase64 = async (file) => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        resolve(reader.result);
      };
      reader.onerror = reject;
    });
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(() => {
    //   setErrorBlock(false);
    // }, 4000);
  };
  const erroBlockHandel = () => {
    setErrorBlock(false);
  };
  const errorClose = (e) => {
    setErrorBlock(e);
  };

  const setProvinceData = (e) => {
    let capital = JSON.parse(e.target.value);

    setCapitalCode(capital.Code);

    let config = {
      headers: {
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get district
    axios
      .post(
        `${MasterKey}/getDistrictByCapital`,
        {
          userCode: "string",
          capitalId: capital.Id,
          online: true,
        },
        config
      )
      .then((response) => {
        if(response.data.status == 1 ){
          districtRef.current.value = "0";
          setApiDistricts(response.data.responseObject.Data.Districts);
          
          setApiCommune([]);
          
          setApiVillage([]);
          
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDistrictData = (e) => {
    let district = JSON.parse(e.target.value);
    setDisctirctCode(district.Code);

    let config = {
      headers: {
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get commune
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: district.Id,
        },
        config
      )
      .then((response) => {
        if(response.data.status == 1 ){
          communeRef.current.value = "0";
          setApiCommune(response.data.responseObject.Data.Communes);
          
          setApiVillage([]);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setCommuneData = (e) => {
    let commune = JSON.parse(e.target.value);
    setCommuneCode(commune.Code);

    let config = {
      headers: {
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: commune.Id,
        },
        config
      )
      .then((response) => {
        if(response.data.status == 1 ){
          villageRef.current.value = "0";
          
          setApiVillage(response.data.responseObject.Data.Villages);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setVillageData = (e) => {
    let village = JSON.parse(e.target.value);
    setVillageCode(village.Code);
  };

  const SubmitHandle = () => {
    if (
      latinName === "" ||
      khamerName === "" ||
      dateOfBirth === "" ||
      nationality === "" ||
      // homeNO === "" ||
      // strretNO === "" ||
      capitalCode === "" ||
      districtCode === "" ||
      communeCode === "" ||
      villageCode === ""
    ) { 
      // setTimeout(() => {
      //   let customer = {validation: 'Please fill'};
      //   setOtherData(customer);
      // },1000)
      return
      
    } else {
      setTimeout(() => {
        let KhamerName = khamerName.split(" ");
        let LatinName = latinName.split(" ");
      
        let customer = {
          IdCard: props.IdCard,
          KhmerName: khamerName,
          LatinName:latinName,
          FirstName: KhamerName[0],
          LastName: KhamerName[1],
          LatinFirstName: LatinName[0],
          LatinLastName: LatinName[1],
          Address: dobAddress,
          Sex: sex,
          Dob: dateOfBirth,
          streetNo: strretNO,
          homeNo: homeNO,
          CapitalCode: capitalCode,
          DistrictCode: districtCode,
          CommuneCode: communeCode,
          VillageCode: villageCode,
        };
        // props.OthersDetailsWithoutData(customer);
        setOtherData(customer);
      }, 1000);
    }
  };

  return (
    <>
      {error != "" && errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`{error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
      {errorblock && errorClass == "errorMessage" && (
        <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
      )}

      { props.identityName !== "សំបុត្រកំណើត" ? <div className="form-group col-md-12">
        <label className="small mb-1" for="inputOrgName">
          {props.identityName === "ID Card"
            ? `${props.identityName} (back side)`
            : props.identityName}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <div className="text-left">
          {imageName.match(/\.(pdf)$/i) ? (
            <iframe width={150} height={150} src={base64} />
          ) : (
            <img
              width={150}
              height={150}
              src={
                base64 != ""
                  ? base64
                  : "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVR4nO2by04UQRSGS0x0IbjyAvgG+BAgKwlG4yXGNyC6QBcgIa4UcOnlKYwrCK7HhRNXRrxtjSi606jgJZoonyk5kxQEenqqq6erp8+X9GrO+avqn6ozU91dxiiKoiiKoiiKoiiK0nbwRL8qQQ3MSKszSmfgFtTAHAwERoEPwHtgpFl8pWF7A61xDVaaxZcC4ARQA777Fv4kWjUwMN9kbKN5mTdLzpjN7Y2IiSvA8TYY6DKTx8yz/AImgL7A+v/JKz6lZh8wKWMk6EwEHoroRDDRyAx0tK+KfC2kqK0Plt5govEa2CvyayFFt+0w8Bio56UfKj7v/ngLAnXgkafmTeB3Q9vHQMFqzPn0oZl+vIJmU1kIwVoVDZzbOgM9sRqzpTSQQDUwNkpRA2OmFEs4ZtTAjGgNzIjWwIzoEu5UA4FD9lYR8EzuKdprCbgBHDSREGUNBM7ZHUPCH+JV4IyJgOhqIBvmrYvMPDAI7JNrCFiQz/4Cp9P2JwStjNebLIJsLNvGzJtMiJuSmK/AgTT9qYqBM5I+nyJ2UWKvmwKJqgYCzyV9MEU7xyR2yVTEwHqzGugs3+4U7fTkcXuqVWJbwl9aMHC/xH5O058s5DXe4ILAU0kfShE7LLFPKmEg6WrgNUlfSNHOA4mdNgUSWw08DHwSiamEuGmJ+Wj/+pgCiWoJW4BTwB+RWZRf2265hp2ZZ2NOmoKJzkCL3aY5u5HtWE+zCxGtYCTpm1Bk3QuzYd5yivEsV2orR5MaCOwC7jj9fQGMAwPOXvgocBl46cTdtrmmINpmYDOAu5JqX9gZA7oSYruAi87LPbdMlQ0EzjrmDbaQN+SYWMjtrcJrILAXeCdpYx7tXZLct8CenfqTBZ/xtq0GAhecmtfl0d5u4JVonO9YA3cCuC8p48YT4Ipo3PPVyNB24Qa+kZSBDG3aX2fLa1+NDG0XXgN/SkpPhjYbt7Z++GqUwcC6vhtTxDcSOYXXwLJTeA0sO1oDIzSw8WAo6AGbGAGOyFhXQ4raM2SJD8Y7BecBf9CDNvboKbLJtyd5+k2HAfSLeY0X38MeOnTeMKgCM0HN2zITa4HPeMRCvsddFUVRFEVRFEVRFEVRFJOdf+dxttTxQOFBAAAAAElFTkSuQmCC"
              }
            />
          )}
          <br />
          <br />
          <input
            type="file"
            ref={inputFile}
            className="text-center center-block file-upload"
            onChange={handleFileChange}
            style={{display: 'none'}}
            id="chooseImage"
          />
          <label htmlFor="chooseImage" style={{ cursor:'pointer', padding: '5px', border: '1px solid #ccc', borderRadius: '4px', backgroundColor: '#e2dede', color: 'black'}}>{t("e635032a5f71d809146d3872389f5b0c")}</label>
          <span style={{ color: 'white', paddingLeft: '10px', fontSize: '18px'}}>{imageName}</span>
          <p
            className="mt-1"
            style={{
              fontSize: "13px",
              fontStyle: "italic",
              color: "white",
            }}
          >
            {t("2ffe94058afaea3b875be2082941ec0b")}
          </p>
        </div>
      </div> : <></>} 

      {/* <!-- Form Group (last name)--> */}
      <br />
      <br />
      

      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputPhone">
          {t("dbcc71284f11a659aa72716396584a4c")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className={`form-control`}
          id="inputPhone"
          type="text"
          placeholder={t("dbcc71284f11a659aa72716396584a4c")}
          onChange={(e) =>
            setKhamerName(e.target.value)
          }
          //   value={existingOtherData.KhmerName}
          //   disabled
        />
      </div>

      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("4ac58405bbbe783528ee33e7ac3fcc5c")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <input
          className={`form-control`}
          id="inputFirstName"
          type="text"
          placeholder={t("4ac58405bbbe783528ee33e7ac3fcc5c")}
          onChange={(e) =>
            setLatinName(e.target.value)
          }
          //   value={existingOtherData.LatinName}
          //   disabled
        />
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("10803b83a68db8f7e7a33e3b41e184d0")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <DatePicker
          maxDate={new Date()}
          onKeyDown={(e) => e.preventDefault()}
          renderCustomHeader={({
            date,
            changeYear,
            changeMonth,
            decreaseMonth,
            increaseMonth,
            prevMonthButtonDisabled,
            nextMonthButtonDisabled,
          }) => (
            <div
              style={{
                margin: 10,
                display: "flex",
                justifyContent: "center",
              }}
            >
              <select
                value={getYear(date)}
                onChange={({ target: { value } }) => changeYear(value)}
              >
                {currentYear.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>

              <select
                value={months[getMonth(date)]}
                onChange={({ target: { value } }) =>
                  changeMonth(months.indexOf(value))
                }
              >
                {months.map((option) => (
                  <option key={option} value={option}>
                    {option}
                  </option>
                ))}
              </select>
            </div>
          )}
          showIcon
          toggleCalendarOnIconClick
          className={`form-control`}
          dateFormat="dd/MM/yyyy" 
          onChange={(e) => setDateOfBirth(e)}
          selected={dateOfBirth}
          //   disabled
        />
      </div>

      {/* <!-- Form Group (phone number)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputPhone">
          {t("14606eeaca7845ed80684e9e20a4c983")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`form-control col-sm col-md-12`}
          data-val="true"
          name="StateId"
          onChange={(e) => setNationality(e.target.value)}
          // value={33}
        >
          <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          <option value="33" selected="">
            {t("06c7a3c3a3344a62864b951aec4df830")}
          </option>
        </select>
      </div>

      <div className="form-group col-md-6">
        <label className="small mb-1" htmlFor="inputPhone">
          {t("ee583624fabcb93a41504c81eaf561be")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className="form-control col-sm col-md-12"
          data-val="true"
          name="StateId"
          onChange={(e) => setSex(e.target.value)}
        >
          <option value="">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          <option value={1} selected="">
            {t("63889cfb9d3cbe05d1bd2be5cc9953fd")}
          </option>
          <option value={2} selected="">
            {t("b719ce180ec7bd9641fece2f920f4817")}
          </option>
        </select>
      </div>

      <div className="form-group col-md-6"></div>
      <hr />
      <h5 style={{ textAlign: "left", color: "#fff", marginTop: "20px" }} className="col-12">
        <b>{t("861a00de3a9b1a4751e4e3190944227e")}</b>
      </h5>
      <hr style={{ width: "100%" }} />

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("c1a3626600b7d8a8b3e0b2474ab5c202")}
        </label>
        <input
          className={`form-control`}
          id="inputFirstName"
          type="text"
          placeholder={t("c1a3626600b7d8a8b3e0b2474ab5c202")}
          onChange={(e) => setHomeNo(e.target.value)}
          //   value={existingOtherData.HomeNo}
          //   disabled
        />
      </div>

      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("d7dbce89503da95fa05b5e38b106adb4")}
        </label>
        <input
          className={`form-control `}
          id="inputFirstName"
          type="text"
          placeholder={t("d7dbce89503da95fa05b5e38b106adb4")}
          onChange={(e) => setStreetNO(e.target.value)}
          //   value={existingOtherData.StreetNo}
          //   disabled
        />
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`form-control col-sm col-md-12`}
          data-val="true"
          name="StateId"
          onChange={setProvinceData}
          ref={provinceRef}
          //   value={existingOtherData.CapitalId}
          //   disabled
        >
          <option value="0">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apicapital.map((capital) => (
            <option value={JSON.stringify(capital)} selected="">
              {capital.Name}
            </option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`form-control col-sm col-md-12`}
          data-val="true"
          name="StateId"
          onChange={setDistrictData}
          ref={districtRef}
          //   value={existingOtherData.DistrictId}
          //   disabled
        >
          <option value="0">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apidistricts.map((districts) => (
            <option value={JSON.stringify(districts)} selected="">
              {districts.Name}
            </option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("04beeb76f7acc97bb1e309f654fdee7a")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`form-control col-sm col-md-12`}
          data-val="true"
          name="StateId"
          onChange={setCommuneData}
          ref={communeRef}
          //   value={existingOtherData.CommuneId}
          //   disabled
        >
          <option value="0">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apicommune.map((commune) => (
            <option value={JSON.stringify(commune)} selected="">
              {commune.Name}
            </option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (last name)--> */}
      <div className="form-group col-md-6">
        <label className="small mb-1" for="inputLastName">
          {t("abc63490c815af81276f930216c8d92b")}{' '}
          <span style={{ color: "red" }}>*</span>
        </label>
        <select
          className={`form-control col-sm col-md-12 `}
          data-val="true"
          name="StateId"
          onChange={setVillageData}
          ref={villageRef}
          //   value={existingOtherData.VillageId}
          //   disabled
        >
          <option value="0">{t("c6be2b6191e6660291b6b0c92bd2f0df")}</option>
          {apiVillage.map((village) => (
            <option value={JSON.stringify(village)} selected="">
              {village.Name}
            </option>
          ))}
        </select>
      </div>

      {/* <!-- Form Group (phone number)--> */}
      <div className="form-group col-md-12">
        <label className="small mb-1" htmlFor="inputLastName">
          {t("f984cd4a2d763ce723eeaeb59d835bb9")}{" "}
          <span style={{ color: "red" }}>*</span>
        </label>
        <textarea
          className="form-control"
          id="inputFirstName"
          type="text"
          placeholder={t("f984cd4a2d763ce723eeaeb59d835bb9")}
          onChange={(e) => {
            setDobAddress(e.target.value);
          }}
        />
      </div>

      <SubmitHandle></SubmitHandle>
    </>
  );
}

export default OthersDetails;

// to_convert
// checked
