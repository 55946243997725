import React, { useContext, useEffect, useState, useRef, useSyncExternalStore } from "react";
import "../../css/style.css";
import Footer from "../Footer";
import Navigation from "../NavigationPanel/Navigation";
import { BrowserRouter, Link, useNavigate } from "react-router-dom";
import moment from "moment";
import { MdDelete } from "react-icons/md";
import { BsEyeFill } from "react-icons/bs";
import axios from "axios";
import DatePicker from "react-datepicker";
import { getMonth, getYear } from "date-fns";
import range from "lodash/range";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { DashboardContext } from "../../ContextApi";
import SpanError from "../Error-Span-Modal/SpanError";
import { useTranslation } from "react-i18next";
import ModalDeleteConfirm from "../Error-Span-Modal/ModalDeleteConfirm";
import ModalError from "../Error-Span-Modal/ModalError";


const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function RejectionTourismService() {
  const { rejectedServiceDetails } = useContext(DashboardContext);
  const isLoginpage = true;
  const isLogedin = true;
  const { t } = useTranslation();
  let Navigate = useNavigate();
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      Navigate('/');
    }
  }, [isAuthenticated]);
  const [inputKey, setInputKey] = useState(0);
  const [isChecked, setIsChecked] = useState(false);


  const [errorBusinessData, setErrorBusinessData] = useState({});
  const [errorOwnerData, setErrorOwnerData] = useState({});
  const [formChange, setFormChange] = useState("ApplyforSelf");
  const [inputDark, setInputDark] = useState("input-dark");
  const [currentDate, setCurrentDate] = useState("");
  const [cardDisplay, setCardDisplay] = useState(true);
  const [modal, setModal] = useState(false);
  const [newUpload, setNewUpload] = useState();
  const [serviceDetails, setserviceDetails] = useState({});
  const [submitResponse, setSubmitResponse] = useState({});
  const [customerId, setCustomerId] = useState("");
  const [khamerName, setKhamerName] = useState("");
  const [latinName, setLatinName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState(new Date());
  const [homeNo, setHomeNo] = useState("");
  const [streetNo, setStreetNo] = useState("");
  const [provinceCode, setProvinceCode] = useState(0);
  const [districtCode, setDistrictCode] = useState(0);
  const [villageCode, setVillageCode] = useState(0);
  const [communeCode, setCommuneCode] = useState(0);
  const [capitalId, setCapitalId] = useState("");
  const [districtId, setDistrictId] = useState("");
  const [communeId, setCommuneId] = useState("");
  const [villageId, setVillageId] = useState("");

  const [businessProvinceCode, setBusinessProvinceCode] = useState(0);
  const [businessDistrictCode, setBusinessDistrictCode] = useState(0);
  const [businessVillageCode, setBusinessVillageCode] = useState(0);
  const [businessCommuneCode, setBusinessCommuneCode] = useState(0);
  const [businessCapitalId, setBusinessCapitalId] = useState("");
  const [businessDistrictId, setBusinessDistrictId] = useState("");
  const [businessCommuneId, setBusinessCommuneId] = useState("");
  const [businessVillageId, setBusinessVillageId] = useState("");

  const [apicapital, setApiCaptial] = useState([]);
  const [apidistricts, setApiDistricts] = useState([]);
  const [apicommune, setApiCommune] = useState([]);
  const [apiVillage, setApiVillage] = useState([]);

  const [businessApicapital, setBusinessApiCaptial] = useState([]);
  const [businessApidistricts, setBusinessApiDistricts] = useState([]);
  const [businessApicommune, setBusinessApiCommune] = useState([]);
  const [businessApiVillage, setBusinessApiVillage] = useState([]);

  const [quantity, setQuantity] = useState(1);
  const [flag, setFlag] = useState(true);
  const [isloading, setIsloading] = useState(false);
  const [serviceName, setServiceName] = useState("");
  const [serviceDuration, setServiceDuration] = useState("");
  const [servicePrice, setServicePrice] = useState("");
  const [documentType, setDocumentType] = useState([]);
  const [requiredDocument, setRequiredDocument] = useState([]);
  const [tableflag, setTableflag] = useState(false);
  const [guesthouseName, setGuesthouseName] = useState("");
  const [guesthouseLatinName, setGuesthouseLatinName] = useState("");
  const [guesthousePhoneNo, setGuesthousePhoneNo] = useState("");
  const [guesthouseHomeNo, setGuesthouseHomeNo] = useState("");
  const [guesthouseStreetNo, setGuesthouseStreetNo] = useState("");
  const [guesthouseAddress, setGuesthouseAddress] = useState("");
  const [newguesthouseAddress, setNewGuesthouseAddress] = useState("");
  const [isValid, setIsValid] = useState(false);
  const [nationality, setNationality] = useState("");
  const [sex, setSex] = useState(0);
  const [dobAddress, setDobAddress] = useState("");
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [deleteMessage, setDeleteMessage] = useState(false);
  const [documentIndex, setDocumentIndex] = useState();
  const [inputfilevalue, setInputfilevalue] = useState("");
  const [doclinkLength, setDoclinkLength] = useState(0);
  let [countofDocuments, setCountofDocuments] = useState(0);
  const [selectedFiles, setSelectedFiles] = useState([]);

  const [customeruniqueId, setCustomeruniqueId] = useState('');
  const [owneruniqueId, setOwneruniqueId] = useState('');
  const [payloadServiceID, setPayloadServiceID ] = useState('');

  const communeRef = useRef(null);
  const districtRef = useRef(null);
  const provinceRef = useRef(null);
  const villageRef = useRef(null);

  
  const [guesthuouseProvinceName, setGuesthouseProvinceName] = useState("");
  const [guesthouseDistrictName, setGuesthouseDistrictName] = useState("");
  const [guesthouseVillageName, setguesthouseVillageName] = useState("");
  const [guesthouseCommuneName, setGuesthouseCommuneName] = useState("");

  const [ownerAccountVerified, setOwnerAccountVerified] = useState(true);
  const [customerIdentityType, setCustomerIdentityType] = useState('');




  const inputFile = useRef(null);
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const LegalKey = process.env.REACT_APP_LEGALIZATION_KEY;
  const Attachments = [];
  const currentYear = range(1850, getYear(new Date()) + 1, 1);
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  useEffect(() => {
    if (flag === true) {
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      // api for get capital
      axios
        .post(`${MasterKey}/getAllCapital`, { userCode: "1" }, config)
        .then((response) => {
          if (response.data.status === "1") {
            setApiCaptial(response.data.responseObject.Data.Capitals);
          } else if (response.data.status === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // api for get district
      axios
        .post(
          `${MasterKey}/getAllDistrictByCapital`,
          {
            userCode: "string",
            capitalId: capitalId,
            online: true,
          },
          config
        )
        .then((response) => {
          if (response.data.status === "1") {
            setApiDistricts(response.data.responseObject.Data.Districts);
          } else if (response.data.status === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // api for get commune
      axios
        .post(
          `${MasterKey}/getCommuneByDistrict`,
          {
            userCode: "string",
            districtId: districtId,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            setApiCommune(response.data.responseObject.Data.Communes);
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // api for get villages
      axios
        .post(
          `${MasterKey}/getVillageByCommune`,
          {
            userCode: "string",
            communeId: communeId,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            setApiVillage(response.data.responseObject.Data.Villages);
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });


      // business address api's 
      // api for get capital
      axios
        .post(`${MasterKey}/getCapital`, { userCode: "1" }, config)
        .then((response) => {
          if (response.data.status === "1") {
            setBusinessApiCaptial(response.data.responseObject.Data.Capitals);
          } else if (response.data.status === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // api for get district
      axios
        .post(
          `${MasterKey}/getDistrictByCapital`,
          {
            userCode: "string",
            capitalId: businessCapitalId,
            online: true,
          },
          config
        )
        .then((response) => {
          if (response.data.status === "1") {
            setBusinessApiDistricts(response.data.responseObject.Data.Districts);
          } else if (response.data.status === 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // api for get commune
      axios
        .post(
          `${MasterKey}/getCommuneByDistrict`,
          {
            userCode: "string",
            districtId: businessDistrictId,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            setBusinessApiCommune(response.data.responseObject.Data.Communes);
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      // api for get villages
      axios
        .post(
          `${MasterKey}/getVillageByCommune`,
          {
            userCode: "string",
            communeId: businessCommuneId,
          },
          config
        )
        .then((response) => {
          if (response.data.status == 1) {
            setBusinessApiVillage(response.data.responseObject.Data.Villages);
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [capitalId, districtId, communeId, villageId, businessCapitalId, businessDistrictId, businessVillageId, businessCommuneId]);

  useEffect(() => {
    if (guesthouseVillageName === "" || guesthouseCommuneName === undefined || guesthouseDistrictName === undefined || guesthuouseProvinceName === undefined || guesthuouseProvinceName === undefined) {
      setNewGuesthouseAddress("");
      return
    }
    let address = `ភូមិ ${guesthouseVillageName} ឃុំ-សង្កាត់ ${guesthouseCommuneName} ក្រុង-ស្រុក-ខណ្ឌ  ${guesthouseDistrictName} រាជធានី-ខេត្ត ${guesthuouseProvinceName}`
    if(guesthouseVillageName && guesthouseCommuneName && guesthouseDistrictName && guesthuouseProvinceName){
      setNewGuesthouseAddress(address)
    }else{
      setNewGuesthouseAddress("");
    }
  }, [guesthouseVillageName, guesthouseCommuneName, guesthouseDistrictName, guesthuouseProvinceName])

console.log(guesthuouseProvinceName)
console.log(guesthouseDistrictName)
console.log(guesthouseCommuneName)
console.log(guesthouseVillageName)
  useEffect(() => {
    var date = moment();
    setCurrentDate(date.format("DD/MM/YYYY"));

    if (sessionStorage.getItem("userId")) {
      const id = sessionStorage.getItem("userId");

      // existing diteals check by id
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };
      const reqObj = {
        serviceId: rejectedServiceDetails.requestId,
        // serviceId: "65ba2c6f-7a97-435b-bc90-93790eec1628",
        // serviceId: "5ba8894d-c966-4ca1-890a-c15772411057",
        loggedInUserCode: id,
      };
      setPayloadServiceID(reqObj.serviceId);

      axios
        .post(`${LegalKey}/fetchLegalizationServiceDetails`, reqObj, config)
        .then((response) => {
          // console.log(reqObj.serviceId)
          // console.log(typeof(reqObj.serviceId));
          setCustomeruniqueId(response.data.responseObject.Json.Register.CustomerId);
          setOwneruniqueId(response.data.responseObject.Json.Register.OwnerId);
          if (response.data.status == 1) {
            response.data.responseObject.Json.Feedbacks.map((item) => {
              if (item.TypeId === "2") {
                for (let i = 0; i < item.mentionHtmlList.length; i++) {
                  let error = {};
                  error.errorReason = item.mentionHtmlList[i].Msg;
                  error.errorStatus = item.mentionHtmlList[i].Status;
                  let field = item.mentionHtmlList[i].Field;
                  errorBusinessData[field] = error;
                }
              }
            });

            response.data.responseObject.Json.Feedbacks.map((item) => {
              if (item.TypeId === "5") {
                for (let i = 0; i < item.mentionHtmlList.length; i++) {
                  let error = {};
                  error.errorReason = item.mentionHtmlList[i].Msg;
                  error.errorStatus = item.mentionHtmlList[i].Status;
                  let field = item.mentionHtmlList[i].Field;
                  errorOwnerData[field] = error;
                }
              }
            });

            var count = 0;
            let newRequiredDocument = [...requiredDocument];
            response.data.responseObject.Json.Attachments.map((item) => {
              if (item.Status === "2") {
                var id = item.Id;
                var title = item.Title;
                response.data.responseObject.Json.Feedbacks.map(
                  (feedbackItem) => {
                    if (
                      id === feedbackItem.AttachmentId &&
                      feedbackItem.TypeId === "3"
                    ) {
                      count = count + 1;
                      newRequiredDocument.push({
                        item: item,
                        filesuploaded: "",
                      });
                    }
                  }
                );
              }
            });

            if (!tableflag) {
              setDoclinkLength(count);
              setRequiredDocument(newRequiredDocument);
              setTableflag(true);
            }

            setCustomerId(response.data.responseObject.Json.Owner.IdCard);
            setCustomerIdentityType(response.data.responseObject.Json.Owner.IdentityType);
            setLatinName(response.data.responseObject.Json.Owner.LatinName);
            setKhamerName(response.data.responseObject.Json.Owner.KhmerName);
            setDateOfBirth(response.data.responseObject.Json.Owner.Dob);
            setHomeNo(response.data.responseObject.Json.Owner.HomeNo);
            setStreetNo(response.data.responseObject.Json.Owner.StreetNo);
            setSex(response.data.responseObject.Json.Owner.Sex);
            setDobAddress(response.data.responseObject.Json.Owner.Address)
            setGuesthouseName(response.data.responseObject.Json.Business.Name);
            setGuesthouseLatinName(response.data.responseObject.Json.Business.LatinName);
            setGuesthousePhoneNo(
              response.data.responseObject.Json.Business.Phone
            );
            setGuesthouseHomeNo(
              response.data.responseObject.Json.Business.HomeNo
            );
            setGuesthouseStreetNo(
              response.data.responseObject.Json.Business.StreetNo
            );
            setCapitalId(response.data.responseObject.Json.Owner.CapitalId);
            setDistrictId(
              response.data.responseObject.Json.Owner.DistrictId
            );
            setCommuneId(response.data.responseObject.Json.Owner.CommuneId);
            setVillageId(response.data.responseObject.Json.Owner.VillageId);
            setProvinceCode(response.data.responseObject.Json.Owner.Capital);
            setDistrictCode(
              response.data.responseObject.Json.Owner.District
            );
            setCommuneCode(response.data.responseObject.Json.Owner.Commune);
            setVillageCode(response.data.responseObject.Json.Owner.Village);


            setBusinessCapitalId(response.data.responseObject.Json.Business.CapitalId);
            setBusinessDistrictId(
              response.data.responseObject.Json.Business.DistrictId
            );
            setBusinessCommuneId(response.data.responseObject.Json.Business.CommuneId);
            setBusinessVillageId(response.data.responseObject.Json.Business.VillageId);
            setBusinessProvinceCode(response.data.responseObject.Json.Business.Capital);
            setBusinessDistrictCode(
              response.data.responseObject.Json.Business.District
            );
            setBusinessCommuneCode(response.data.responseObject.Json.Business.Commune);
            setBusinessVillageCode(response.data.responseObject.Json.Business.Village);
            setGuesthouseAddress(response.data.responseObject.Json.Business.Address)
          } else {
          }
        })
        .catch((err) => {
          console.log(err);
        });

      //get service diteals by service ID
      if (sessionStorage.getItem("accessToken")) {
        let tokenss = sessionStorage.getItem("accessToken");
        let config = {
          headers: {
            Authorization: tokenss,
            AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
          },
        };
        const serviceData = {
          serviceId: rejectedServiceDetails.ServiceId,
          userCode: id,
        };
        axios
          .post(`${MasterKey}/getServiceDetailById`, serviceData, config)
          .then((response) => {
            if (response.data.status == 1) {
              setServiceName(response.data.responseObject.Json.Service.Name);
              setServiceDuration(
                response.data.responseObject.Json.Service.ProcessDay
              );
              setServicePrice(response.data.responseObject.Json.Service.Price);
              setDocumentType(response.data.responseObject.Json.DocLinks);
              setserviceDetails(response.data.responseObject.Json);
            } else {
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }

      // console.log()
    }
  }, []);
  console.log(errorBusinessData);

  useEffect(() => {
    let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      const data = {
        identityType: customerIdentityType,
        code: customerId,
      };

      axios
        .post(`${LoginKey}/login/getCustomerInfo`, data, config)
        .then((response) => {
          setIsloading(false);
          if (response.data.status == 1) {
            console.log('here in api')
            console.log(response.responseObject);
            setOwnerAccountVerified(response.data.responseObject.Data.Customer.verify)
            // setExistingOtherData(response.data.responseObject.Data.Customer);
            // setCaptialId(response.data.responseObject.Data.Customer.CapitalId);
            // setDistrictId(
            //   response.data.responseObject.Data.Customer.DistrictId
            // );
            // setCommuneId(response.data.responseObject.Data.Customer.CommuneId);
            // setVillageId(response.data.responseObject.Data.Customer.VillageId);
            // props.handelCustomerData(
            //   response.data.responseObject.Data.Customer
            // );
            console.log('inside success !')
          } else {
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
  },[customerIdentityType])


  useEffect(() => {
    if (localStorage.getItem("firstLoadDone") === null) {
      // If it's the first load, set the flag in local storage to true and reload the page
      localStorage.setItem("firstLoadDone", 1);
    } else {
      Navigate("/rejectionTourismService");
      localStorage.removeItem("firstLoadDone");
    }
  }, []);

  console.log(errorOwnerData)

  const handleKeyPressBusinessName = (event) => {
    const char = event.data;
    if (/[<>/]/.test(char)) {
      event.preventDefault(); // Block '<', '>', '/' along with non-Khmer, non-English letters
    }
  }

  const handlePaste = (event) => {
    let pastedText = event.clipboardData.getData('text');
    let containsHTML = /<\/?[^>]+(>|$)/g.test(pastedText);
    console.log(containsHTML)
    
    if(containsHTML){
      event.preventDefault();
    }
  };

  const handleKeyPressForHomeAndStreetAddress = (event) => {
    const char = event.data;
    if (!/[\u1780-\u17FFa-zA-Z0-9.,()\-\s]/.test(char)) {
      event.preventDefault(); // Block characters that are not Khmer or English letters
    }
  }

  const getApplicationReceipt = () => {
    let tokenss = sessionStorage.getItem("accessToken");
    let data = {
      serviceApplicationCode: "1223035995",
      loggedinUserCode: "1234",
    };
    const requestOptions = {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
      responseType: "blob",
      body: JSON.stringify(data),
    };
    axios
      .post(`${LegalKey}/downloadLegalizationReceipt`, requestOptions)
      .then((response) => {
        response.blob().then((data) => {
          const url = window.URL.createObjectURL(new Blob([data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", "Legalization_Receipt.pdf");
          document.body.appendChild(link);
          link.click();
        });
      });
  };

  const handelAttach = () => {
    // let title = "";
    // for (let i = 0; i < serviceDiteals.DocLinks.length; i++) {
    //   if (attachDocID === serviceDiteals.DocLinks[i].ServiceRequireDocId) {
    //     title = serviceDiteals.DocLinks[i].Mention;
    //   }
    // }
    console.log(requiredDocument.length);
    let attachmentDiteals = {};
    for (let i = 0; i < requiredDocument.length; i++) {
      attachmentDiteals = {
        Id: requiredDocument[i].item.Id,
        Title: requiredDocument[i].item.Title,
        FileType: requiredDocument[i].filesuploaded.name.split(".").pop(),
        ServiceRequireDocId: requiredDocument[i].item.ServiceRequireDocId,
        Content: "",
      };
      Attachments.push(attachmentDiteals);
    }
  };

  const serviceSubmithandel = () => {

    if(!isChecked){
      setError("a619141aea5f7ad57c083dbfb5db655f");
      errorHandel(0);

      return
    }

    console.log("requiredDocument", requiredDocument);
    // const newSelectedFiles = [];
    for (let i = 0; i < requiredDocument.length; i++) {
      if (requiredDocument[i].filesuploaded != "") {
        countofDocuments += 1;
        // newSelectedFiles.push(requiredDocument[i].filesuploaded);
      }
    }
    setCountofDocuments(countofDocuments);
    // setSelectedFiles(newSelectedFiles);

    // if (requiredDocument.length > 0 && countofDocuments == 0) {
    //   console.log(countofDocuments)
    //   setError("9e4e9d4664a53b9c0683f8215a6d8f20");
    //   errorHandel(0);
    //   setCountofDocuments(0);

    //   return;
    // }
    // if (requiredDocument.length > 0 && countofDocuments < doclinkLength) {

    //   // console.log(countofDocuments);
    //   // console.log(doclinkLength)
    //   setError("85cd03dff046c778472e474b87769c66");
    //   errorHandel(0);
    //   setCountofDocuments(0);

    //   return;
    // }
    const newSelectedFiles = [];

    for (let i = 0; i < requiredDocument.length; i++) {
      if (requiredDocument[i].filesuploaded != '') {
        // countofDocuments += 1;
        // console.log(requiredDocument[i].filesuploaded)
        newSelectedFiles.push(requiredDocument[i].filesuploaded)
        // console.log(newSelectedFiles)
      }
    }
    setSelectedFiles(newSelectedFiles);

    setIsloading(true);
    handelAttach();
    // const formatDobDate = `${dateOfBirth.getFullYear()}/${
    //   dateOfBirth.getMonth() + 1 < 10
    //     ? `0${dateOfBirth.getMonth() + 1}`
    //     : dateOfBirth.getMonth() + 1
    // }/${
    //   dateOfBirth.getDate() < 10
    //     ? `0${dateOfBirth.getDate()}`
    //     : dateOfBirth.getDate()
    // }`;
    const Sid = sessionStorage.getItem("customerId");
    let KhamerName = khamerName && khamerName.split(" ");
    let LatinName = latinName && latinName.split(" ");
    const submitData = {
      createUid: sessionStorage.getItem("userId"),
      createDate: "",
      modifiedUid: sessionStorage.getItem("userId"),
      modifiedDate: "",
      versionNo: 0,
      isSubmitted: "",
      isActive: "",
      isDeleted: "",
      assigneeId: "",
      Register: {
        MstServiceId: "2428",
        CustomerId: Sid,
        ProCode: businessProvinceCode,
        DistrictCode: businessDistrictCode,
        TypeId: 3, //district level
        Quantity: quantity, 
        OwnerId: Sid,
        // Partner: "ONLINE DMK",
        // PartnerRefNo: "",
        Id: payloadServiceID,
      },
      Business: {
        Name:  guesthouseLatinName,
        LatinName: guesthouseName,
        
        Phone: guesthousePhoneNo,
        HomeNo: homeNo,
        StreetNo: streetNo,
        Address: newguesthouseAddress == '' ? guesthouseAddress : newguesthouseAddress,
        Capital: businessProvinceCode,
        District: businessDistrictCode,
        Commune: businessCommuneCode,
        Village: businessVillageCode,
      },
      Customer: {
        IdCard: sessionStorage.getItem("userId"),
        FirstName: KhamerName[0],
        LastName: KhamerName[1],
        LatinFirstName: LatinName[0],
        LatinLastName: LatinName[1],
        Address: dobAddress,
        Sex: sex,
        Dob: dateOfBirth,
        StreetNo: streetNo,
        HomeNo: homeNo,
        ProCode: provinceCode,
        IdentityType: "",
        ContentBack: "",
      },
      Attachments: Attachments,
    };
    let reUpload;
    if (formChange === "ApplyforSelf") {
      reUpload = null;
    } else {
      reUpload = newUpload;
    }
    const form = new FormData();
    form.append("SubmitLegalizationRequest", JSON.stringify(submitData));
    // form.append("attachments", upload);
    newSelectedFiles.forEach((item) => form.append("attachments", item));
    form.append("fileContentBack", reUpload);
    if (sessionStorage.getItem("accessToken")) {
      let tokenss = sessionStorage.getItem("accessToken");
      let config = {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: tokenss,
          AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
        },
      };

      axios
        .post(
          `${LegalKey}/updateRegisterLegalization`,
          // "http://localhost:9296/legalization/updateRegisterLegalization",
          form,
          config
        )
        .then((response) => {
          setIsloading(false);
          if (response.data.status == 1) {
            console.log('here')
            // setSubmitResponse(response.data.responseObject.Json.Register);
            setModal(true);
            // Navigate('/dashboard')
          } else if (response.data.status == 0) {
          }
        })
        .catch((err) => {
          setIsloading(false);
          console.log(err);
        });
    }
  };

  const handleFileChange = (event, index) => {
    // setUpload((prevData) => [...prevData, e.target.files[0]]);
    // const uploadList = [...upload];
    // uploadList.push(e.target.files[0]);
    // setUpload(uploadList);
    // setNewUpload(e.target.files[0]);

    let file = event.target.files[0];
    if (!file.name.match(/\.(jpg|jpeg|png|pdf)$/i)) {
      setError("621b7de2db36267f3ca7e72abc4781c5");
      errorHandel(0);
      return;
    } else if (file.size >= 10 * 1024 * 1024) {
      setError("ada3e831bf3d6e697a2ee9c2cbd3f0ef");
      errorHandel(0);
      return;
    } else {
      let newRequiredDocument = [...requiredDocument];
      newRequiredDocument[index].filesuploaded = file;
      setRequiredDocument(newRequiredDocument);
    }
  };

  const handleDocumentView = (index) => {
    if (requiredDocument[index].filesuploaded) {
      const reader = new FileReader();
      reader.onload = () => {
        const fileContent = reader.result;
        openPdfInNewTab(fileContent);
      };
      reader.readAsDataURL(requiredDocument[index].filesuploaded);
    } else {
      setError("e518f09e0a344d4a4dc9c0b01d3cb382");
      errorHandel(0);
    }
  };

  const openPdfInNewTab = (fileContent) => {
    const newTab = window.open();
    newTab.document.write(
      '<iframe src="' + fileContent + '" width="100%" height="100%"></iframe>'
    );
  };

  const handleAttachmentDelete = (index) => {
    // const updatedUploadList = [...upload];
    // updatedUploadList.splice(index, 1);
    // setUpload(updatedUploadList);
    if (requiredDocument[index].filesuploaded) {
      setDeleteMessage(true);
      setDocumentIndex(index);
    } else {
      setError("e518f09e0a344d4a4dc9c0b01d3cb382");
      errorHandel(0);
    }
  };

  const deleteConfirm = () => {
    handleDocumentDelete(documentIndex);
    setDeleteMessage(false);
  };

  const deleteCancel = (e) => {
    setDocumentIndex("");
    setDeleteMessage(e);
  };

  const handleDocumentDelete = (index) => {
    const newRequiredDocument = [...requiredDocument];
    newRequiredDocument[index].filesuploaded = "";
    setRequiredDocument(newRequiredDocument);
    const inputElement = document.getElementById(`${index}`);
    if (inputElement) {
      inputElement.value = "";
    }
    setInputfilevalue("");
  };

  const setProvinceData = (e) => {
    setFlag(false);
    let capital = JSON.parse(e.target.value);
    setCapitalId(e.target.value);
    setProvinceCode(capital.Code);
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    axios
      .post(
        `${MasterKey}/getDistrictByCapital`,
        {
          userCode: "string",
          capitalId: e.target.value,
          online: true,
        },
        config
      )
      .then((response) => {
        if (response.data.status === "1") {
          setApiDistricts(response.data.responseObject.Data.Districts);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setDistrictData = (e) => {
    setFlag(false);
    let district = JSON.parse(e.target.value);
    setDistrictId(e.target.value);
    setDistrictCode(district.Code);
    let tokenss = sessionStorage.getItem("accessToken");

    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get commune
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: e.target.value,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiCommune(response.data.responseObject.Data.Communes);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setCommuneData = (e) => {
    setFlag(false);
    let commune = JSON.parse(e.target.value);
    setCommuneId(e.target.value);
    setCommuneCode(commune.Code);
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: e.target.value,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          setApiVillage(response.data.responseObject.Data.Villages);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setVillageData = (e) => {
    setFlag(false);
    let village = JSON.parse(e.target.value);
    setVillageId(e.target.value);
    setVillageCode(village.Code);
  };


  // function for buisness address
  const setBusinessProvinceData = (e) => {
    setFlag(false);
    console.log(e.target.value);
    let capital = JSON.parse(e.target.value);
    setBusinessCapitalId(capital.Id);
    setBusinessProvinceCode(capital.Code);
    setGuesthouseProvinceName(capital.Name);

    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    axios
      .post(
        `${MasterKey}/getDistrictByCapital`,
        {
          userCode: "string",
          capitalId: businessCapitalId,
          online: true,
        },
        config
      )
      .then((response) => {
        if (response.data.status === "1") {
          districtRef.current.value = "0";
          setBusinessApiDistricts(response.data.responseObject.Data.Districts);
          setGuesthouseDistrictName('');
          setGuesthouseCommuneName('');
          setguesthouseVillageName('');
          setBusinessApiCommune([]);
          setBusinessApiVillage([]);
        } else if (response.data.status === 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setBusinessDistrictData = (e) => {
    setFlag(false);
    let district = JSON.parse(e.target.value);
    setBusinessDistrictId(district.Id);
    setBusinessDistrictCode(district.Code);
    console.log(district.Name);
    setGuesthouseDistrictName(district.Name);
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get commune
    axios
      .post(
        `${MasterKey}/getCommuneByDistrict`,
        {
          userCode: "string",
          districtId: businessDistrictId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          communeRef.current.value = "0";
          setBusinessApiCommune(response.data.responseObject.Data.Communes);
          setGuesthouseCommuneName('');
          setguesthouseVillageName('');
          setBusinessApiVillage([]);
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setBusinessCommuneData = (e) => {
    setFlag(false);
    let commune = JSON.parse(e.target.value);
    console.log(commune)
    setBusinessCommuneId(commune.Id);
    setBusinessCommuneCode(commune.Code);
    setGuesthouseCommuneName(commune.Name)
    
    let tokenss = sessionStorage.getItem("accessToken");
    let config = {
      headers: {
        Authorization: tokenss,
        AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
      },
    };

    // api for get villages
    axios
      .post(
        `${MasterKey}/getVillageByCommune`,
        {
          userCode: "string",
          communeId: businessCommuneId,
        },
        config
      )
      .then((response) => {
        if (response.data.status == 1) {
          villageRef.current.value = "0";
          setBusinessApiVillage(response.data.responseObject.Data.Villages);  
          setguesthouseVillageName('');
        } else if (response.data.status == 0) {
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const setBusinessVillageData = (e) => {
    setFlag(false);
    let village = JSON.parse(e.target.value);
    console.log(village)
    setBusinessVillageId(village.Id);
    setBusinessVillageCode(village.Code);
    setguesthouseVillageName(village.Name);
  };


  const handlePhoneNumber = (e) => {
    const inputValue = e.target.value;
    const phoneRegex = /^\d{8,10}$/;
    const isValidPhone = phoneRegex.test(inputValue);
    setGuesthousePhoneNo(inputValue);
    // setIsValid(isValidPhone);
    if (
      inputValue.length === 0 ||
      inputValue.length === 8 ||
      inputValue.length === 9 ||
      inputValue.length === 10
    ) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  };

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
  };

  const erroBlockHandel = () => {
    setErrorBlock(false);
  };
  const errorClose = (e) => {
    setErrorBlock(e);
  };
 console.log(guesthuouseProvinceName);
 console.log(guesthouseDistrictName);
 console.log(guesthouseCommuneName);
 console.log(guesthouseVillageName);
 console.log(guesthouseAddress);

 console.log(ownerAccountVerified)

  return (
    <div>
      <Navigation page={isLoginpage} login={isLogedin}></Navigation>
      {errorblock && errorClass == "successMessage" && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )}
      {errorblock && errorClass == "errorMessage" && (
        <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
      )}
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-5 pt-2">
            <div className="container-fluid">
              <div className="page-header-content padding-0">
                <div className="row d-flex">
                  <div className="p-2 col-4 col-sm-12 col-md-6 col-lg-12">
                    <h1
                      className="page-header-title"
                      style={{ fontSize: "20px" }}
                    >
                      <div className="page-header-icon">
                        <i data-feather="activity"></i>
                      </div>
                      {t("3da5a32741f328496796a4e36cc89303")}
                    </h1>
                  </div>

                  <hr style={{ width: "100%", padding: 0, margin: 0 }}></hr>
                </div>
              </div>
            </div>
          </header>

          <div className="container-fluid mt-sm-n5">
            <div className={`row ${!cardDisplay && "display-none"}`}>
              <div className="col-lg-5 col-md-5 col-sm-12">
                {deleteMessage && (
                  <ModalDeleteConfirm
                    deleteConfirm={deleteConfirm}
                    deleteCancel={deleteCancel}
                  ></ModalDeleteConfirm>
                )}
                <div className="card card-lg card-other drk">
                  <div className="row">
                    <form style={{ marginBottom: "80px" }}>
                      <h5 style={{ color: "white" }}><b>
                        {t("732cabde6bfc84822b5fd47a5288b789")}
                        </b></h5>
                      <hr />
                      <div className="form-row">
                        {/* {errorOwnerData.IdNumber &&
                          errorOwnerData.IdNumber.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
                            {t("dae903caf35016e727adebc1e3c18b2a")}
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control "
                            id="inputUsername"
                            type="text"
                            style={{
                              border: `${errorOwnerData?.IdNumber?.errorStatus === 2 && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "504c46e2c369d441b6eb6c581101fc85"
                            )}
                            value={customerId}
                            onChange={(e) => setCustomerId(e.target.value)}
                            disabled={errorOwnerData.IdNumber?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={
                              errorOwnerData.IdNumber?.errorStatus === "2" ? errorOwnerData?.IdNumber?.errorReason : ''
                            }
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {errorOwnerData.IdCard &&
                          errorOwnerData.IdCard.errorStatus === "2" ? (
                          <div className="form-group col-md-12 ">
                            <label className="KhmerOSsiemreaptext small mb-1" for="inputOrgName">
                              {t("7310da7e7015ec7cd1e83f324c7c030d")}{' '}
                              <span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="text-left">
                              <img
                                style={{
                                  border: `${errorOwnerData?.IdCard?.errorStatus === '2' && "1px solid red"
                                    }`,
                                }}
                                src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAFAAAABQCAYAAACOEfKtAAAACXBIWXMAAAsTAAALEwEAmpwYAAADRUlEQVR4nO2by04UQRSGS0x0IbjyAvgG+BAgKwlG4yXGNyC6QBcgIa4UcOnlKYwrCK7HhRNXRrxtjSi606jgJZoonyk5kxQEenqqq6erp8+X9GrO+avqn6ozU91dxiiKoiiKoiiKoiiK0nbwRL8qQQ3MSKszSmfgFtTAHAwERoEPwHtgpFl8pWF7A61xDVaaxZcC4ARQA777Fv4kWjUwMN9kbKN5mTdLzpjN7Y2IiSvA8TYY6DKTx8yz/AImgL7A+v/JKz6lZh8wKWMk6EwEHoroRDDRyAx0tK+KfC2kqK0Plt5govEa2CvyayFFt+0w8Bio56UfKj7v/ngLAnXgkafmTeB3Q9vHQMFqzPn0oZl+vIJmU1kIwVoVDZzbOgM9sRqzpTSQQDUwNkpRA2OmFEs4ZtTAjGgNzIjWwIzoEu5UA4FD9lYR8EzuKdprCbgBHDSREGUNBM7ZHUPCH+JV4IyJgOhqIBvmrYvMPDAI7JNrCFiQz/4Cp9P2JwStjNebLIJsLNvGzJtMiJuSmK/AgTT9qYqBM5I+nyJ2UWKvmwKJqgYCzyV9MEU7xyR2yVTEwHqzGugs3+4U7fTkcXuqVWJbwl9aMHC/xH5O058s5DXe4ILAU0kfShE7LLFPKmEg6WrgNUlfSNHOA4mdNgUSWw08DHwSiamEuGmJ+Wj/+pgCiWoJW4BTwB+RWZRf2265hp2ZZ2NOmoKJzkCL3aY5u5HtWE+zCxGtYCTpm1Bk3QuzYd5yivEsV2orR5MaCOwC7jj9fQGMAwPOXvgocBl46cTdtrmmINpmYDOAu5JqX9gZA7oSYruAi87LPbdMlQ0EzjrmDbaQN+SYWMjtrcJrILAXeCdpYx7tXZLct8CenfqTBZ/xtq0GAhecmtfl0d5u4JVonO9YA3cCuC8p48YT4Ipo3PPVyNB24Qa+kZSBDG3aX2fLa1+NDG0XXgN/SkpPhjYbt7Z++GqUwcC6vhtTxDcSOYXXwLJTeA0sO1oDIzSw8WAo6AGbGAGOyFhXQ4raM2SJD8Y7BecBf9CDNvboKbLJtyd5+k2HAfSLeY0X38MeOnTeMKgCM0HN2zITa4HPeMRCvsddFUVRFEVRFEVRFEVRFJOdf+dxttTxQOFBAAAAAElFTkSuQmCC"
                              />
                            </div>
                            <SpanError
                             errorReason={
                              errorOwnerData.IdCard?.errorStatus === "2" ? errorOwnerData?.IdCard?.errorReason : ''
                            }
                            />
                            <input
                              type="file"
                              className="text-center center-block file-upload"
                              // onChange={handleFileChange}
                              disabled={errorOwnerData.IdCard?.errorStatus != "2"}
                            />
                          </div>
                        ) : (
                          <></>
                        )}

                        {/* {errorOwnerData.LatinName &&
                          errorOwnerData.LatinName.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("4ac58405bbbe783528ee33e7ac3fcc5c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={ errorOwnerData.LatinName?.errorStatus === "2" ? `KhmerOSsiemreaptext form-control` : `KhmerOSsiemreaptext form-control ${inputDark}`}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorOwnerData?.LatinName?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "4ac58405bbbe783528ee33e7ac3fcc5c"
                            )}
                            onChange={(e) => setLatinName(e.target.value)}
                            value={latinName}
                            disabled={errorOwnerData.LatinName?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={
                              errorOwnerData.LatinName?.errorStatus === "2" ?  errorOwnerData?.LatinName?.errorReason : ''
                            }
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.KhamerName &&
                          errorOwnerData.KhamerName.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("dbcc71284f11a659aa72716396584a4c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={ errorOwnerData.KhamerName?.errorStatus === "2" ? `KhmerOSsiemreaptext form-control` : `KhmerOSsiemreaptext form-control ${inputDark}`}
                            id="inputPhone"
                            type="text"
                            style={{
                              border: `${errorOwnerData?.KhamerName?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "dbcc71284f11a659aa72716396584a4c"
                            )}
                            onChange={(e) => setKhamerName(e.target.value)}
                            value={khamerName}
                            disabled={errorOwnerData.KhamerName?.errorStatus !== "2"}
                          />
                          <SpanError
                            errorReason={
                              errorOwnerData.KhamerName?.errorStatus !== "2" ? errorOwnerData?.KhamerName?.errorReason  : ''
                            }
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.Dob &&
                          errorOwnerData.Dob.errorStatus === "2" ? ( */}
                       { ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("10803b83a68db8f7e7a33e3b41e184d0")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <div
                            style={{
                              border: `${errorOwnerData?.Dob?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                          >
                            <DatePicker
                              // showIcon
                              // toggleCalendarOnIconClick
                              renderCustomHeader={({
                                date,
                                changeYear,
                                changeMonth,
                                decreaseMonth,
                                increaseMonth,
                                prevMonthButtonDisabled,
                                nextMonthButtonDisabled,
                              }) => (
                                <div
                                  style={{
                                    margin: 10,
                                    display: "flex",
                                    justifyContent: "center",
                                  }}
                                >
                                  {/* <button onClick={decreaseMonth} disabled={prevMonthButtonDisabled}>
                        {"<"}
                      </button> */}
                                  <select
                                    value={getYear(date)}
                                    onChange={({ target: { value } }) =>
                                      changeYear(value)
                                    }
                                  >
                                    {currentYear.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  <select
                                    value={months[getMonth(date)]}
                                    onChange={({ target: { value } }) =>
                                      changeMonth(months.indexOf(value))
                                    }
                                  >
                                    {months.map((option) => (
                                      <option key={option} value={option}>
                                        {option}
                                      </option>
                                    ))}
                                  </select>

                                  {/* <button onClick={increaseMonth} disabled={nextMonthButtonDisabled}>
                        {">"}
                      </button> */}
                                </div>
                              )}
                              showIcon
                              // toggleCalendarOnIconClick
                              className={ errorOwnerData.Dob?.errorStatus === "2" ? `KhmerOSsiemreaptext form-control` : `KhmerOSsiemreaptext form-control ${inputDark}` }
                              dateFormat="dd/MM/yyyy"
                              selected={dateOfBirth}
                              onChange={(e) => setDateOfBirth(e)}
                              disabled={errorOwnerData.Dob?.errorStatus !== "2"}
                            />
                          </div>
                          <SpanError
                            errorReason={errorOwnerData.Dob?.errorStatus === "2" ?  errorOwnerData?.Dob?.errorReason : ''}
                          />
                        </div> }
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.Nationality &&
                          errorOwnerData.Nationality.errorStatus === "2" ? ( */}
                        {ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("14606eeaca7845ed80684e9e20a4c983")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={ errorOwnerData.Nationality?.errorStatus === "2" ? `KhmerOSsiemreaptext form-control` : `KhmerOSsiemreaptext form-control ${inputDark}`}
                            data-val="true"
                            name="StateId"
                            style={{
                              border: `${errorOwnerData?.Nationality?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            onChange={(e) => setNationality(e.target.value)}
                            value={33}
                            disabled={errorOwnerData.Nationality?.errorStatus != "2"}
                          >
                            <option value="" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            <option value="33" selected="" className="KhmerOSsiemreaptext">
                              {t("06c7a3c3a3344a62864b951aec4df830")}
                            </option>
                          </select>
                          <SpanError
                            errorReason={
                              errorOwnerData.Nationality?.errorStatus === "2" ? errorOwnerData?.Nationality?.errorReason : ''
                            }
                          />
                        </div> }
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.Sex &&
                          errorOwnerData.Sex.errorStatus === "2" ? ( */}
                        {ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" htmlFor="inputPhone">
                            {t("ee583624fabcb93a41504c81eaf561be")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={ errorOwnerData.Sex?.errorStatus === "2" ? `KhmerOSsiemreaptext form-control` : `KhmerOSsiemreaptext form-control ${inputDark}`}
                            data-val="true"
                            name="StateId"
                            value={sex}
                            style={{
                              border: `${errorOwnerData?.Sex?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            onChange={(e) => setSex(e.target.value)}
                            disabled={errorOwnerData.Sex?.errorStatus != "2"}
                          >
                            <option value="" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            <option value={1} selected="" className="KhmerOSsiemreaptext">
                              {t("63889cfb9d3cbe05d1bd2be5cc9953fd")}
                            </option>
                            <option value={2} selected="" className="KhmerOSsiemreaptext">
                              {t("b719ce180ec7bd9641fece2f920f4817")}
                            </option>
                          </select>
                          <SpanError
                            errorReason={errorOwnerData.Sex?.errorStatus === "2"  ? errorOwnerData?.Sex?.errorReason : ''}
                          />
                        </div> }
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {(errorOwnerData.houseNumber &&
                          errorOwnerData.houseNumber.errorStatus === "2") ||
                          (errorOwnerData.houseNumber &&
                            errorOwnerData.streetNumber.errorStatus === "2") ||
                          (errorOwnerData.ownerAddress &&
                            errorOwnerData.ownerAddress.errorStatus === "2") ? ( */}

                        { ownerAccountVerified && <h5
                          style={{ color: "#fff" }}
                          className="col-12"
                        ><b>
                          {t("861a00de3a9b1a4751e4e3190944227e")}
                          </b>
                        </h5>}
                        <hr style={{width: "100%"}}/>

                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.houseNumber &&
                          errorOwnerData.houseNumber.errorStatus === "2" ? ( */}
                        {ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("c1a3626600b7d8a8b3e0b2474ab5c202")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control`}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorOwnerData?.houseNumber?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "c1a3626600b7d8a8b3e0b2474ab5c202"
                            )}
                            value={homeNo}
                            onChange={(e) => setHomeNo(e.target.value)}
                            disabled={errorOwnerData.houseNumber?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={
                              errorOwnerData.houseNumber?.errorStatus === "2" ? errorOwnerData?.houseNumber?.errorReason : ''
                            }
                          />
                        </div>}
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.streetNumber &&
                          errorOwnerData.streetNumber.errorStatus === "2" ? ( */}
                       { ownerAccountVerified &&  <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("d7dbce89503da95fa05b5e38b106adb4")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control `}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorOwnerData?.streetNumber?.errorStatus == "2" &&
                                "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "d7dbce89503da95fa05b5e38b106adb4"
                            )}
                            value={streetNo}
                            onChange={(e) => setStreetNo(e.target.value)}
                            disabled={errorOwnerData.streetNumber?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={
                              errorOwnerData.streetNumber?.errorStatus != "2" ? errorOwnerData?.streetNumber?.errorReason : ''
                            }
                          />
                        </div>}
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.ownerAddress &&
                          errorOwnerData.ownerAddress.errorStatus === "2" ? ( */}
                        {ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12  ${inputDark}`}
                            data-val="true"
                            name="StateId"
                            value={capitalId}
                            onChange={setProvinceData}
                            disabled={errorOwnerData.ownerAddress?.errorStatus != "2"}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apicapital.map((capital) => (
                              <option value={capital.Id} selected="" className="KhmerOSsiemreaptext">
                                {capital.Name}
                              </option>
                            ))}
                          </select>
                        </div>}
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.ownerAddress &&
                          errorOwnerData.ownerAddress.errorStatus === "2" ? ( */}
                        {ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12  ${inputDark}`}
                            data-val="true"
                            name="StateId"
                            value={districtId}
                            onChange={setDistrictData}
                            disabled={errorOwnerData.ownerAddress?.errorStatus != "2"}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apidistricts.map((districts) => (
                              <option value={districts.Id} selected="" className="KhmerOSsiemreaptext">
                                {districts.Name}
                              </option>
                            ))}
                          </select>
                        </div>}
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.ownerAddress &&
                          errorOwnerData.ownerAddress.errorStatus === "2" ? ( */}
                        {ownerAccountVerified &&  <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("04beeb76f7acc97bb1e309f654fdee7a")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12  ${inputDark}`}
                            data-val="true"
                            name="StateId"
                            value={communeId}
                            onChange={setCommuneData}
                            disabled={errorOwnerData.ownerAddress?.errorStatus != "2"}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apicommune.map((commune) => (
                              <option value={commune.Id} selected="" className="KhmerOSsiemreaptext">
                                {commune.Name}
                              </option>
                            ))}
                          </select>
                        </div>}
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.ownerAddress &&
                          errorOwnerData.ownerAddress.errorStatus === "2" ? ( */}
                        { ownerAccountVerified && <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("abc63490c815af81276f930216c8d92b")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12  ${inputDark}`}
                            data-val="true"
                            name="StateId"
                            value={villageId}
                            onChange={setVillageData}
                            disabled={errorOwnerData.ownerAddress?.errorStatus != "2"}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">Select Option</option>
                            {apiVillage.map((village) => (
                              <option value={village.Id} selected="" className="KhmerOSsiemreaptext">
                                {village.Name}
                              </option>
                            ))}
                          </select>
                        </div> }
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorOwnerData.Address &&
                          errorOwnerData.Address.errorStatus === "2" ? ( */}
                        {ownerAccountVerified && <div className="form-group col-md-12">
                          <label
                            className="KhmerOSsiemreaptext small mb-1"
                            htmlFor="inputLastName"
                          >
                            {t("f984cd4a2d763ce723eeaeb59d835bb9")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            className="KhmerOSsiemreaptext form-control"
                            id="inputFirstName"
                            type="text"
                            value={dobAddress}
                            style={{
                              border: `${errorOwnerData?.Address?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "f984cd4a2d763ce723eeaeb59d835bb9"
                            )}
                            onChange={(e) => {
                              setDobAddress(e.target.value);
                            }}
                            disabled={errorOwnerData.Address?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={errorOwnerData?.Address?.errorReason}
                          />
                        </div> }
                        {/* ) : (
                          <></>
                        )} */}
                      </div>
                    </form>
                  </div>
                </div>
              </div>

              <div className="col-lg-7 col-md-7 col-sm-12">
                <div className="card card-lg card-other">
                  <div className="row" style={{ marginBottom: "80px" }}>
                    <form>
                      <h5 style={{color: "#0061f2"}}><b>{t("026eef87021de60dcac96ee4acd8f518")}</b></h5>
                      <hr />
                      {/* <!-- Form Row--> */}
                      <div className="form-row">
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputFirstName">
                            {t("46140fd4f90101f3beccfd3428bea873")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputFirstName"
                            type="text"
                            placeholder={t("6b3c4fac8f71637289b968e251d4429c")}
                            value={serviceName}
                            disabled
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("5139a143878751e4282ab73759c95079")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputFirstName"
                            type="text"
                            placeholder="3 Working Days (After acceptance of application)"
                            title="3 Working Days (After acceptance of application by front office )"
                            value={serviceDuration}
                            disabled
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("694e8d1f2ee056f98ee488bdc4982d73")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputPhone"
                            type="number"
                            placeholder={t("4bb55d367528dab71bd30ba3689b27b7")}
                            defaultValue={1}
                            disabled
                            onChange={(e) => setQuantity(e.target.value)}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("a90dc8c8855d760675981f47db71a98e")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputPhone"
                            type="tel"
                            placeholder="0"
                            disabled
                            value={servicePrice}
                          />
                        </div>

                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputPhone">
                            {t("70b626c98cf6be5204a2e76bc47f39bb")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className="KhmerOSsiemreaptext form-control input-dark"
                            id="inputPhone"
                            type="tel"
                            placeholder="0"
                            value={servicePrice * quantity}
                            disabled
                          />
                        </div>

                        {/* Business Section*/}
                        {/* {((
                          errorBusinessData.Name?.errorStatus === "2") ||
                          (
                            errorBusinessData.LatinName?.errorStatus === "2") ||
                          (
                            errorBusinessData.Phone?.errorStatus === "2") ||
                          (
                            errorBusinessData.HomeNo?.errorStatus === "2") ||
                          (
                            errorBusinessData.StreetNo?.errorStatus === "2") ||
                          (
                            errorBusinessData.BusinessAddress?.errorStatus ===
                            "2") ||
                          (
                            errorBusinessData.Address?.errorStatus === "2")) ? ( */}
                        <>
                          <hr />
                          <h5
                            style={{
                              textAlign: "left",
                              fontweight: "bold",
                              color: "#0061f2",
                            }}
                            className="col-12 mb-0 mt-2"
                          ><b>
                            {t("d6e6cb19e3b9c02f89d6cd54cfa7c613")}
                            </b></h5>
                          <hr style={{ width: "100%" }} />
                        </>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.Name &&
                          errorBusinessData.Name.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("a55aee8acc3abf18159a13674136052a")} (EN) {" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control `}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorBusinessData?.Name?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "a55aee8acc3abf18159a13674136052a"
                            )}
                            value={guesthouseLatinName}
                            maxLength={200}
                            onPaste={handlePaste}
                            onBeforeInput={handleKeyPressBusinessName}
                            onChange={(e) =>
                              setGuesthouseName(
                                e.target.value
                              )
                            }
                            disabled={errorBusinessData.Name?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={errorBusinessData?.Name?.errorStatus == "2" ? errorBusinessData?.Name?.errorReason : ''}
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.LatinName &&
                          errorBusinessData.LatinName.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                          {t("a55aee8acc3abf18159a13674136052a")} (KH)
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control `}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorBusinessData?.LatinName?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder="Enter Business Latin Name"
                            value={guesthouseName}
                            maxLength={200}
                            onPaste={handlePaste}
                            onBeforeInput={handleKeyPressBusinessName}
                            onChange={(e) =>
                              setGuesthouseLatinName(
                                e.target.value
                              )
                            }
                            disabled={errorBusinessData.LatinName?.errorStatus != "2"}
                          />
                          <SpanError
                           errorReason={errorBusinessData?.LatinName?.errorStatus == "2" ? errorBusinessData?.LatinName?.errorReason : ''}
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.Phone &&
                          errorBusinessData.Phone.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("464972ecbc84025453afcb389470c928")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control`}
                            id="inputFirstName"
                            type="number"
                            style={{
                              border: `${errorBusinessData?.Phone?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "464972ecbc84025453afcb389470c928"
                            )}
                            value={guesthousePhoneNo}
                            onKeyDown={(e) => {
                              const exceptionKeys = ['Backspace', 'Delete']
                              if(e.target.value.length > 9 && !exceptionKeys.includes(e.key)) e.preventDefault();
                            }}
                            onChange={handlePhoneNumber}
                            // {(e) => setGuesthousePhone(e.target.value)}
                            disabled={errorBusinessData.Phone?.errorStatus != "2"}
                          />
                          {isValid ? (
                            <p style={{ color: "red" }}>
                              {t("613ef4baa62da2b5aa61e3cbfcd03cce")}
                            </p>
                          ) : null}
                          <SpanError
                           errorReason={errorBusinessData?.Phone?.errorStatus == "2" ? errorBusinessData?.Phone?.errorReason : ''}
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.HomeNo &&
                          errorBusinessData.HomeNo.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("c1a3626600b7d8a8b3e0b2474ab5c202")}
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control `}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorBusinessData?.HomeNo?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "c1a3626600b7d8a8b3e0b2474ab5c202"
                            )}
                            value={guesthouseHomeNo}
                            maxLength={20}
                            onPaste={handlePaste}
                            onBeforeInput={handleKeyPressForHomeAndStreetAddress}
                            onChange={(e) =>
                              setGuesthouseHomeNo(e.target.value)
                            }
                            disabled={errorBusinessData.HomeNo?.errorStatus != "2"}
                          />
                          <SpanError
                             errorReason={errorBusinessData?.HomeNo?.errorStatus == "2" ? errorBusinessData?.HomeNo?.errorReason : ''}
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.StreetNo &&
                          errorBusinessData.StreetNo.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("070a6f943470aec6d12c7090f290e10f")}
                          </label>
                          <input
                            className={`KhmerOSsiemreaptext form-control  `}
                            id="inputFirstName"
                            type="text"
                            style={{
                              border: `${errorBusinessData?.StreetNo?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            placeholder={t(
                              "070a6f943470aec6d12c7090f290e10f"
                            )}
                            value={guesthouseStreetNo}
                            maxLength={100}
                            onPaste={handlePaste}
                            onBeforeInput={handleKeyPressForHomeAndStreetAddress}
                            onChange={(e) =>
                              setGuesthouseStreetNo(e.target.value)
                            }
                            disabled={errorBusinessData.StreetNo?.errorStatus != "2"}
                          />
                          <SpanError
                            errorReason={errorBusinessData?.StreetNo?.errorStatus == "2" ? errorBusinessData?.StreetNo?.errorReason : ''}
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.BusinessAddress &&
                          errorBusinessData.BusinessAddress.errorStatus ===
                          "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("c6b5d0bb9a0c3f077863fdaef9773f7c")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12 `}
                            data-val="true"
                            name="StateId"
                            value={
                              errorBusinessData.Address?.errorStatus !== "2" ? businessCapitalId:null
                            }
                            onChange={setBusinessProvinceData}
                            disabled={errorBusinessData.Address?.errorStatus != "2"}
                            ref={provinceRef}
                            style={{
                              border: `${errorBusinessData.Address?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            {businessApicapital.map((capital) => (
                              <option value={
                                errorBusinessData.Address?.errorStatus !== "2"
                                  ? capital.Id
                                  : JSON.stringify(capital)
                              }  selected="" className="KhmerOSsiemreaptext">
                                {capital.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.BusinessAddress &&
                          errorBusinessData.BusinessAddress.errorStatus ===
                          "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("e0bb2f66e5b61be3235bf8969979de19")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12 `}
                            data-val="true"
                            name="StateId"
                            onChange={setBusinessDistrictData}
                            value={
                              errorBusinessData.Address?.errorStatus !== "2" ? businessDistrictId:null
                            }
                            disabled={errorBusinessData.Address?.errorStatus != "2"}
                            ref={districtRef}
                            style={{
                              border: `${errorBusinessData.Address?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            {businessApidistricts.map((districts) => (
                              <option value={
                                errorBusinessData?.LivingAddress?.errorStatus !== '2'
                                  ? districts.Id
                                  : JSON.stringify(districts)
                              } selected="" className="KhmerOSsiemreaptext">
                                {districts.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.BusinessAddress &&
                          errorBusinessData.BusinessAddress.errorStatus ===
                          "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("04beeb76f7acc97bb1e309f654fdee7a")}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12 `}
                            data-val="true"
                            name="StateId"
                            onChange={setBusinessCommuneData}
                            value={
                              errorBusinessData.Address?.errorStatus !== "2" ? businessCommuneId:null
                            }
                            disabled={errorBusinessData.Address?.errorStatus != "2"}
                            ref={communeRef}
                            style={{
                              border: `${errorBusinessData.Address?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            {businessApicommune.map((commune) => (
                              <option value={
                                errorBusinessData?.LivingAddress?.errorStatus !== '2'
                                  ? commune.Id
                                  : JSON.stringify(commune)
                              } selected="" className="KhmerOSsiemreaptext">
                                {commune.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.BusinessAddress &&
                          errorBusinessData.BusinessAddress.errorStatus ===
                          "2" ? ( */}
                        <div className="form-group col-md-6">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("abc63490c815af81276f930216c8d92b")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                         
                          <select
                            className={`KhmerOSsiemreaptext form-control col-sm col-md-12`}
                            data-val="true"
                            name="StateId"
                            onChange={setBusinessVillageData}
                            value={
                              errorBusinessData?.LivingAddress?.errorStatus !== '2' ? businessVillageId:null
                            }
                            disabled={errorBusinessData.Address?.errorStatus !== "2"}
                            ref={villageRef}

                            style={{
                              border: `${errorBusinessData.Address?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                          >
                            <option value="0" className="KhmerOSsiemreaptext">
                              {t("c6be2b6191e6660291b6b0c92bd2f0df")}
                            </option>
                            {businessApiVillage.map((village) => (
                              <option value={
                                errorBusinessData?.LivingAddress?.errorStatus !== '2'
                                  ? village.Id
                                  : JSON.stringify(village)
                              } selected="" className="KhmerOSsiemreaptext">
                                {village.Name}
                              </option>
                            ))}
                          </select>
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {/* {errorBusinessData.Address &&
                          errorBusinessData.Address.errorStatus === "2" ? ( */}
                        <div className="form-group col-md-12">
                          <label className="KhmerOSsiemreaptext small mb-1" for="inputLastName">
                            {t("861a00de3a9b1a4751e4e3190944227e")}{" "}
                            <span style={{ color: "red" }}>*</span>
                          </label>
                          <textarea
                            className={`KhmerOSsiemreaptext form-control `}
                            id="inputFirstName"
                            type="text"
                            placeholder={t(
                              "861a00de3a9b1a4751e4e3190944227e"
                            )}
                            style={{
                              border: `${errorBusinessData.Address?.errorStatus == "2" && "1px solid red"
                                }`,
                            }}
                            value={errorBusinessData.Address?.errorStatus !== "2" ?guesthouseAddress:newguesthouseAddress}
                            onChange={(e) =>
                              setNewGuesthouseAddress(e.target.value)
                            }
                            disabled={errorBusinessData.Address?.errorStatus !== "2"}
                          />
                          <SpanError
                            errorReason={errorBusinessData?.Address?.errorStatus == "2" ? errorBusinessData?.Address?.errorReason : ''}
                          />
                        </div>
                        {/* ) : (
                          <></>
                        )} */}

                        {requiredDocument.length != 0 ? (
                          <div className="form-group col-md-12">
                            <label
                              className="KhmerOSsiemreaptext small mt-3"
                              for="inputLocation"
                              style={{ fontweight: "bold", color: "#0061f2" }}
                            >
                              {t("1c0a9e6f20b9f1b59d22290d9f0f31a7")}{" "}
                              <span style={{ color: "red" }}>*</span>
                              <p
                                className="KhmerOSsiemreaptext mt-1"
                                style={{
                                  fontSize: "11.5px",
                                  fontStyle: "italic",
                                  color: "red",
                                }}
                              >
                                {t("2ffe94058afaea3b875be2082941ec0b")}
                              </p>
                            </label>
                            <table
                              className="table"
                              style={{ marginTop: "-5px", marginBottom: "7px" }}
                            >
                              <thead className="thead-light">
                                <tr>
                                  <th
                                    className="KhmerOSsiemreaptext small text-center"
                                    scope="col"
                                    style={{ width: "3rem" }}
                                  >
                                    <b>
                                      {t("598d42810b342196884f255912974d69")}
                                    </b>
                                  </th>
                                  <th
                                    className="KhmerOSsiemreaptext small text-center"
                                    style={{ width: "20rem" }}
                                  >
                                    <b>{t("25b2a90d6504b30bf67144df2a9db1c7")}</b>
                                  </th>
                                  <th
                                    className="KhmerOSsiemreaptext small text-center"
                                    style={{ width: "20rem" }}
                                  >
                                    <b>{t("523c9b00a728a0dad486e9fdcedc716c")}</b>
                                  </th>
                                  <th
                                    className="KhmerOSsiemreaptext textAlign-center small"
                                    style={{ width: "10rem" }}
                                  >
                                    <b>
                                      {" "}
                                      {t("004bf6c9a40003140292e97330236c53")}
                                    </b>
                                  </th>
                                </tr>
                              </thead>
                              <tbody>
                                {requiredDocument.map((docklink, index) => {
                                  {
                                    // slno_document = slno_document + 1; // Increment serial number if condition is met
                                    return (
                                      <tr key={index}
                                        // style={{
                                        //   border: `${docklink.item.status == "2" && "2px solid red"
                                        //     }`,
                                        // }}
                                        style={{ border: "2px solid red " }}
                                      >
                                        <td className="KhmerOSsiemreaptext text-center" >
                                          {index + 1}
                                        </td>
                                        <td className="KhmerOSsiemreaptext text-center">
                                          {docklink.item.Title}
                                        </td>
                                        <td className="KhmerOSsiemreaptext text-center">
                                          <input
                                            type="file"
                                            // ref={inputFile}
                                            className="text-center center-block file-upload"
                                            // disabled={attachDocID == ""}
                                            //   disabled={disabledInputs[index]}
                                            key={inputKey}
                                            onChange={(event) =>
                                              handleFileChange(event, index)
                                            }
                                            name="uploadFile"
                                            style={{ display: "none" }} // Hide the input visually
                                            id={`${index}`} // Associate label with input using htmlFor
                                          />
                                          <label
                                            htmlFor={`${index}`}
                                            style={{
                                              cursor:
                                                !docklink.filesuploaded &&
                                                "pointer",
                                              padding:
                                                !docklink.filesuploaded &&
                                                "5px",
                                              border:
                                                !docklink.filesuploaded &&
                                                "1px solid #ccc",
                                              borderRadius:
                                                !docklink.filesuploaded &&
                                                "2px",
                                              backgroundColor:
                                                !docklink.filesuploaded &&
                                                "#e2dede",
                                              color:
                                                docklink.filesuploaded &&
                                                "grey",
                                            }}
                                          >
                                            {docklink.filesuploaded
                                              ? docklink.filesuploaded.name
                                              : `${t("e635032a5f71d809146d3872389f5b0c")}`}
                                          </label>
                                        </td>
                                        <td className="text-center">
                                          <BsEyeFill
                                            className="mx-1"
                                            onClick={() => {
                                              handleDocumentView(index);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: "#0000ffdb",
                                            }}
                                          //   disable={icon}
                                          />
                                          <MdDelete
                                            // className="mx-4"
                                            onClick={() => {
                                              handleAttachmentDelete(index);
                                            }}
                                            style={{
                                              cursor: "pointer",
                                              color: "#b00000b0",
                                            }}
                                          />
                                        </td>
                                      </tr>
                                    );
                                  }
                                })}
                              </tbody>
                            </table>
                          </div>
                        ) : (
                          <></>
                        )}

<div className="form-group col-md-12">
                            <label className="KhmerOSsiemreaptext small mt-3" for="inputLocation" style={{fontweight: "bold",color: "red"}}>
                            {t("f6e9033f4ba0d801e4f74a2790dee102")}*
                            </label>
                            <div className="term_condition_checkbox">
                              <input type="checkbox" onChange={(e) => setIsChecked(e.target.checked)} className="term_condition_checkbox_box"/>
                              <label htmlFor="" className="term_condition_checkbox_text">ខ្ញុំសូមធានាអះអាងថា ព័ត៌មានដែលបានផ្ដល់ជូនខាងលើនេះជាព័ត៌មានដែលពិតប្រាកដ និងត្រឹមត្រូវ។ ក្នុងករណីមានព័ត៌មានណាមួយមិនត្រឹមត្រូវ ខ្ញុំបាទ/នាងខ្ញុំ សូមទទួលខុសត្រូវចំពោះមុខច្បាប់ជាធរមាន។ (សូមបញ្ជាក់ថា ត្រូវ ដាច់ខាត)</label>
                            </div>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-12 fot-row row">
                    <div className="d-flex justify-content-between col-6"></div>

                    <div className="d-flex flex-row justify-content-end col-6">
                      {/* <!-- Button trigger modal --> */}
                      <button
                        type="submit"
                        className="KhmerOSsiemreaptext btn btn-primary"
                        data-toggle="modal"
                        data-target="#exampleModal"
                        onClick={serviceSubmithandel}
                      >
                        {t("a4d3b161ce1309df1c4e25df28694b7b")}
                      </button>

                      {/* <!-- Modal --> */}
                      <div className={`${modal && "loderdiv"}`}>
                        <div
                          className={`modal fade ${modal && "show"} `}
                          id="exampleModal"
                          tabindex="-1"
                          role="dialog"
                          aria-labelledby="exampleModalLabel"
                          aria-hidden="true"
                          style={{ display: `${modal && "block"}` }}
                        >
                          <div className="modal-dialog" role="document">
                            <div className="modal-content">
                              <div className="modal-header">
                                <h5
                                  className="modal-title"
                                  id="exampleModalLabel"
                                  style={{ padding: "0" }}
                                ><b>
                                  {t("674880d923c4e1086190af98851ec927")}
                                  </b></h5>
                                <button
                                  type="button"
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <div className="modal-body">
                                <div className="row justify-content-center">
                                  <div className="col-3">
                                    <img
                                      src="https://img.icons8.com/color/96/000000/ok--v2.png"
                                      className="fit-image"
                                    />
                                  </div>
                                </div>
                                <div className="KhmerOSsiemreaptext col-md-12 col-lg-12 text-center">
                                  {t("1769908af5b99c90bf8f8fcea46ba6da")}
                                </div>
                                <br />
                                <div
                                  className="row"
                                  style={{ marginBottom: "80px" }}
                                >
                                  {/* <div className="col-md-6 md-6">
                                    {t("9b4e480ebe53f5031ab51891acfec191")}
                                  </div> */}
                                  {/* <div className="col-md-6">
                                    :&nbsp;&nbsp;&nbsp;
                                    {submitResponse.RegisterCode}
                                  </div> */}
                                  <div className="KhmerOSsiemreaptext col-md-6 md-6">
                                    {t("13427696ab995e729d57a9fee166f805")}
                                  </div>
                                  <div className="KhmerOSsiemreaptext col-md-6">
                                    :&nbsp;&nbsp;&nbsp;
                                    {serviceName}
                                  </div>
                                  {/* <div className="KhmerOSsiemreaptext col-md-6 md-6">
                                    {t("b55afee3a3deb460e6149b486d8d9a49")}
                                  </div>
                                  <div className="KhmerOSsiemreaptext col-md-6">
                                    :&nbsp;&nbsp;&nbsp;{submitResponse.Status}
                                  </div> */}
                                  <div className="KhmerOSsiemreaptext col-md-6 md-6">
                                    {t("f18c4dab2674eae1e8c287c683b66729")}
                                  </div>
                                  <div className="KhmerOSsiemreaptext col-md-6">
                                    :&nbsp;&nbsp;&nbsp;{currentDate}
                                  </div>
                                  {/* <div className="KhmerOSsiemreaptext col-md-6 md-6">
                                      Expected Date of Delivery
                                    </div>
                                    <div className="KhmerOSsiemreaptext col-md-6">
                                      :&nbsp;&nbsp;&nbsp;8/11/2023
                                    </div> */}
                                  {/* <div className="col-md-6 md-6">
                                      Track your application status
                                    </div>
                                    <div className="col-md-6">
                                      :&nbsp;&nbsp;&nbsp;
                                      <img
                                        width="40"
                                        height="40"
                                        src="https://img.icons8.com/ultraviolet/40/qr-code--v1.png"
                                        alt="qr-code--v1"
                                      />
                                    </div> */}
                                </div>
                              </div>
                              <div className="modal-footer text-center">
                                <div className="col-12 fot-row row">
                                  {/* <div className="d-flex justify-content-between col-6">
                                    <button
                                      className="btn btn-primary collapsed"
                                      type="button"
                                      onClick={getApplicationReceipt}
                                    >
                                      {t("4240abaacda205bcafd1df82fd1586de")}{" "}
                                      &nbsp; &nbsp;
                                      <img
                                        width="25"
                                        height="25"
                                        src="https://img.icons8.com/dotty/80/download.png"
                                        alt="download"
                                      />
                                    </button>
                                  </div> */}
                                  <div className="d-flex flex-row justify-content-end col-12">
                                    <button
                                      type="button"
                                      className="KhmerOSsiemreaptext btn btn-secondary"
                                      data-dismiss="modal"
                                      onClick={() => Navigate("/dashboard")}
                                    >
                                      {t("d3d2e617335f08df83599665eef8a418")}
                                    </button>
                                    <a href="#" className=""></a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div style={{ margin: "30px", width: "100%" }}></div>
            </div>
          </div>
          {/* ) : (
            <div className="blankDiV"></div>
          )} */}
        </main>
      </div>
      <Footer></Footer>
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default RejectionTourismService;
