import React, { useContext, useEffect, useState } from "react";
import "../css/style.css";
import Footer from "./Footer";
import Navigation from "./NavigationPanel/Navigation";
import axios from "axios";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import { useNavigate } from "react-router-dom";
import { DashboardContext } from "../ContextApi";
import { useTranslation } from "react-i18next";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};
function Dashboard() {
  const { t } = useTranslation();
  const { setDashboardUrl } = useContext(DashboardContext);
  const isLoginpage = true;
  const isLogedin = true;
  const MasterKey = process.env.REACT_APP_OWCS_MASTER_SERVICE;
  const [dashboardData, setDashboardData] = useState([]);
  const [isloading, setIsloading] = useState(true);
  const navigate = useNavigate();
  const [isAuthenticated, setIsAuthenticated] = useState(true);
  useEffect(() => {
    if(sessionStorage.getItem('accessToken') !== null && sessionStorage.getItem('authToken') !== null){
      console.log('accesstoken', sessionStorage.getItem('accessToken'))
      console.log('authtyoken', sessionStorage.getItem('authToken'))
      setIsAuthenticated(true);
    }
    else{
      console.log('not verified')
      setIsAuthenticated(false);
    }
  },[])
 
  
  
  useEffect(() => {
    if (!isAuthenticated) {
      console.log('here to navigate')
      navigate('/');
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (sessionStorage.getItem("customerId")) {
      const customerId = sessionStorage.getItem("customerId");
      if (sessionStorage.getItem("accessToken")) {
        let tokenss = sessionStorage.getItem("accessToken");
        let config = {
          headers: {
            Authorization: tokenss,
            AuthToken: "Bearer " + `${sessionStorage.getItem("authToken")}`,
          },
        };
        const customerData = {
          customerid: customerId,
        };
        axios
          .post(`${MasterKey}/getDashBoardInfo`, customerData, config)
          .then((response) => {
            setIsloading(false);
            if (response.data.status == 1) {
              setDashboardData(response.data.responseObject.Data.Cards);
            }
          })
          .catch((err) => {
            setIsloading(false);
            console.log(err);
          });
      }
    }
  }, []);

  const detailsHandle = (e, i) => {
    let apiBody = {};
    if (e === "/pending") {
      apiBody = {
        status: "PENDING",
        processStatus: 0,
        name: i,
      };
    } else if (e === "/approved") {
      apiBody = {
        status: "APPROVED",
        processStatus: 0,
        name: i,
      };
    } else if (e === "/paid") {
      apiBody = {
        status: "PAID",
        processStatus: 0,
        name: i,
      };
    } else if (e === "/closed") {
      apiBody = {
        status: "CLOSED",
        processStatus: 0,
        name: i,
      };
    } else if (e === "/reject") {
      apiBody = {
        status: "RETURN",
        processStatus: 0,
        name: i,
      };
    } else if (e === "/completed") {
      apiBody = {
        status: "CLOSED",
        processStatus: 7,
        name: i,
      };
    } else if (e === "/received") {
      apiBody = {
        status: "CLOSED",
        processStatus: 8,
        name: i,
      };
    }
    setDashboardUrl(apiBody);
    navigate("/dashboardindexDetails");
    localStorage.removeItem("firstLoadDone");
  };
  return (
    <div>
      <Navigation page={isLoginpage} login={isLogedin}></Navigation>
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 ">
            <div className="container">
              <div className="page-header-content">
                <div className="row align-items-center justify-content-between">
                  <div className="col-auto">
                    <h1
                      className="page-header-title"
                      style={{ fontSize: "20px" }}
                    >
                      <div className="page-header-icon">
                        <i data-feather="activity"></i>
                      </div>
                      {t("2938c7f7e560ed972f8a4f68e80ff834")}
                    </h1>
                  </div>
                </div>
              </div>
            </div>
          </header>
          {/* <!-- Main page content--> */}
          <div className="container mt-n6">
            {/* <!-- Example Colored Cards for Dashboard Demo--> */}

            <div className="row">
              {dashboardData.map((dashboard) => (
                <div
                  className="col-md-4 col-sm-12 col-12"
                  key={dashboard.id}
                  onClick={() => detailsHandle(dashboard.Url, dashboard.Name)}
                >
                  <div className="serv-section-2" style={{ height: "200px" }}>
                    <div className="serv-section-2-icon serv-section-2-icon-act">
                      <i
                        className={`${dashboard.Icon}`}
                        style={{
                          fontSize: "50px",
                          color: `${dashboard.Color}`,
                        }}
                      ></i>
                    </div>
                    <div
                      className="serv-section-desc "
                      style={{ height: "150px" }}
                    >
                      <h4>{dashboard.Count}</h4>
                      <label className="KhmerOSsiemreaptext">
                        {/* Services <br />
                      Requested */}
                        <b>{dashboard.Name}</b>
                      </label>
                    </div>
                    <div
                      className={`section-heading-line-left  `}
                      style={{ backgroundColor: `${dashboard.Color}` }}
                    ></div>
                  </div>
                </div>
              ))}
              {/* <div className="col-md-4 col-sm-12 col-12">
                <div className="serv-section-2 serv-section-2-act">
                  <div className="serv-section-2-icon serv-section-2-icon-act">
                    <span>
                      <img
                        width={60}
                        height={60}
                        src="images/pending.png"
                        alt="service--v2"
                      />
                    </span>
                  </div>
                  <div className="serv-section-desc">
                    <h4>5</h4>
                    <h5>Service Request in Progress</h5>
                  </div>
                  <div className="section-heading-line-left orange"></div>
                </div>
              </div>
              <div className="col-md-4 col-sm-12 col-12">
                <div className="serv-section-2">
                  <div className="serv-section-2-icon serv-section-2-icon-act">
                    <span>
                      <img
                        width={60}
                        height={60}
                        src="images/complete.png"
                        alt="service--v2"
                      />
                    </span>
                  </div>
                  <div className="serv-section-desc">
                    <h4>6</h4>
                    <h5>Service Request Completed</h5>
                  </div>
                  <div className="section-heading-line-left green"></div>
                </div>
              </div> */}
              {/* <div style={{ margin: "10px", width: "100%" }}></div> */}

              {/* <div className="col-md-4 col-sm-12 col-12">
                <div className="serv-section-2">
                  <div className="serv-section-2-icon serv-section-2-icon-act">
                    <i
                      class="fa fa-hourglass-end"
                      style={{ fontSize: "50px", color: "skyblue" }}
                    ></i>
                  </div>
                  <div className="serv-section-desc">
                    <h4>2</h4>
                    <h5>Service Request Pending Verification</h5>
                  </div>
                  <div className="section-heading-line-left sky-blue"></div>
                </div>
              </div>

              <div className="col-md-4 col-sm-12 col-12">
                <div className="serv-section-2">
                  <div className="serv-section-2-icon serv-section-2-icon-act">
                    <span>
                      <img
                        width={60}
                        height={60}
                        src="images/received.png"
                        alt="service--v2"
                      />
                    </span>
                  </div>
                  <div className="serv-section-desc">
                    <h4>2</h4>
                    <h5 style={{ width: "35%" }}>Services Received</h5>
                  </div>
                  <div className="section-heading-line-left red"></div>
                </div>
              </div> */}

              <div style={{ margin: "10px", width: "100%" }}></div>
            </div>
          </div>
        </main>
        <Footer></Footer>
        {isloading && (
          <div className="loderdiv">
            <ClipLoader
              color="hsla(203, 66%, 53%, 1)"
              size={80}
              cssOverride={override}
            />
          </div>
        )}
      </div>
    </div>
  );
}
export default Dashboard;
