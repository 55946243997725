import React, { useState } from "react";
import Navigation from "../NavigationPanel/Navigation";
import Footer from "../Footer";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import { BarLoader, CircleLoader, ClipLoader } from "react-spinners";
import ModalError from "../Error-Span-Modal/ModalError";
import { useTranslation } from "react-i18next";

const override = {
  display: "block",
  margin: "20% auto ",
  borderColor: "white",
};

function Registration(props) {
  const isLoginpage = true;
  let navigate = useNavigate();
  const { t } = useTranslation();

  const [id, setId] = useState("");
  const [isloading, setIsloading] = useState(false);
  const [error, setError] = useState("");
  const [errorblock, setErrorBlock] = useState(false);
  const [errorClass, setErrorClass] = useState("");
  const [isValid, setIsValid] = useState(false);
  const LoginKey = process.env.REACT_APP_LOGIN_SERVICE;

  const errorHandel = (e) => {
    setErrorBlock(true);
    if (e == 0) {
      setErrorClass("errorMessage");
    } else {
      setErrorClass("successMessage");
    }
    // setTimeout(() => {
    //   setErrorBlock(false);
    // }, 4000);
  };
  //ID card number validation check
  const nextregistrationProcess = (event) => {
    event.preventDefault();
    if (id === "") {
      setError("504c46e2c369d441b6eb6c581101fc85");
      errorHandel(0);
    }
    else if(isValid){
      setError("d4321c4fd724115464556b3d0c1ff54f");
      errorHandel(0);
    }
    else {
      console.log('else ')
      setIsloading(true);
      //ID card check service call
      setIsloading(true);
      const forgotData = {
        idNumber: id,
        userCode: id,
      };
      // forgot password change service call
      axios
        .post(
          `${LoginKey}/registration/checkExistingIdCardOnRegistration`,
          forgotData
        )
        .then((response) => {
          setIsloading(false);
          if (response.data.responseObject.Data.StatusCode == 1) {
            // props.handelValidate(true);
            // props.customerData(response.data.responseObject.Data.Customer);
            // props.handelresponse(true);
            setError("fd61a2e32198d4b4d3819fbd8a9a9b96");
            errorHandel(0);
          } else if (response.data.responseObject.Data.StatusCode == -1) {
            // setError("ID Does'not exist")
            props.handelValidate(true);
            props.handelID(id);
            // props.customerData(response.data.responseObject.Data.Customer)
          } 
          else if(response.data.responseObject.Data.StatusCode == 2){
            setError("339234bc46cf4438261567e1eed8d393");
            errorHandel(0);
          }
          else if(response.data.responseObject.Data.StatusCode == 3){
            setError("e140917ef195a4d3b75f79e90fd1a3eb");
            errorHandel(0);
          }else {
            setError("9cabed03d7bc7aa396bef73dab7fe030");
            errorHandel(0);
          }
        })
        .catch((err) => {
          console.log(err);
          setIsloading(false);
          setError("4b2b25d11213a09ce090cda61c0ea175");
          errorHandel(0);
        });
    }
    // navigate("/registrationHolder");
  };

  const idHandler = (e) => {
    const inputValue = e.target.value;
    setId(inputValue);
    if (inputValue.length === 0 || inputValue.length === 9 || inputValue.length === 10) {
      setIsValid(false);
    } else {
      setIsValid(true);
    }
  }

  const erroBlockHandel = () => {
    setErrorBlock(false);
  };

  const errorClose = (e) => {
    setErrorBlock(e);
  };

  return (
    <div>
      {/* {errorblock && (
        <div
          className={`popup ${errorClass}`}
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-evenly",
          }}
        >
          <div>{t(`${error}`)}</div>
          <div>
            <button
              onClick={erroBlockHandel}
              className={`download ${errorClass}`}
            >
              <i class="fa fa-times" aria-hidden="true"></i>
            </button>
          </div>
        </div>
      )} */}
       {errorblock && errorClass == "errorMessage" && (
          <ModalError error={t(`${error}`)} errorClose={errorClose}></ModalError>
        )}
      {/* <Navigation page={isLoginpage}></Navigation> */}
      <div id="layoutSidenav_content">
        <main>
          <header className="page-header page-header-dark bg-gradient-primary-to-secondary1 pb-10 paddingTop-9">
            <div className="container-fluid d-flex justify-content-center col-md-4">
              <div
                className="card card-lg mb-4"
                style={{
                  backgroundColor: "rgb(17,126,191, 0.8)",
                  width: "380px",
                }}
              >
                <div className="KhmerOSmuollighttext card-header" style={{ color: "#fff" }}>
                {t("2fe4fe0a0ebea41ce43eb3dc3fce4ea3")}
                </div>

                <div
                  className="card-body"
                  style={{
                    padding_top: "0.35rem",
                    padding_bottom: "0.35rem",
                    text_align: "left",
                  }} 
                >
                  <form onSubmit={nextregistrationProcess}>
                    {/* <!-- Form Group (username)--> */}
                    <div className="form-group col-md-12">
                      {/* <label className="small mb-1" for="inputUsername">
                        {t("8e233234a83a277872b8ce305dceb914")}
                       
                      </label> */}
                      <input
                        className="KhmerOSsiemreaptext form-control"
                        id="inputUsername"
                        type="number"
                        placeholder={t("504c46e2c369d441b6eb6c581101fc85")}
                        onChange={idHandler}
                      />
                      {/* {isValid ? (
                        <p className="idcard_p" style={{ color: "#a93030" }}>{t("d4321c4fd724115464556b3d0c1ff54f")}</p>
                      ) : null} */}
                    </div>

                    <div className="form-row">
                      {/* <!-- Form Group (last name)--> */}
                      <div className="d-flex justify-content-center form-group col-md-12">
                        <button
                          className="KhmerOSsiemreaptext btn btn-primary boderR login-acc"
                          type="submit"
                        >
                          {" "}
                          {t("d0cab90d8d20d57e2f2b9be52f7dd25d")}
                        </button>
                      </div>
                    </div>

                    <div className="form-group row">
                      <label
                        for="remember-me"
                        className="KhmerOSsiemreaptext text-info col-md-12 col-lg-12 text-center"
                        style={{ text_align: "right" }}
                      >
                        <Link to={"/login"}>
                          {t("9288098bf51c8184caf86bea0722543f")}
                        </Link>
                      </label>
                    </div>
                  </form>
                </div>

                {/* <!-- Save changes button--> */}
              </div>
            </div>
          </header>
        </main>
      </div>
      {/* <Footer></Footer> */}
      {isloading && (
        <div className="loderdiv">
          <ClipLoader
            color="hsla(203, 66%, 53%, 1)"
            size={80}
            cssOverride={override}
          />
        </div>
      )}
    </div>
  );
}

export default Registration;

// to_convert
// Your account is currently pending approval.
// done !
